import { React, useState, useEffect, useContext } from "react";
import Superheader from "./Superheader";
import Superside from "./Superside";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
import Leaddetails from "./Leaddetails";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from "react-paginate";
import emailjs from 'emailjs-com';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Superlead = () => {
    const [demolist, setdemolist] = useState([])
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const Getdemo = () => {

        axios.get(`${process.env.REACT_APP_API_URL}/demo`).then((res) => {
            console.log(res.data, 'success');
            setdemolist(res.data);

        }).catch(() => {

        })

    }
    useEffect(() => {
        Getdemo();

    }, []);
    const [demoname, setDemoname] = useState("");
    const [demodate, setDemodate] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comp_name, setcompname] = useState("");
    const [designation, setDesignation] = useState("");
    const [reason, setReason] = useState("");
    const [statusr, setstatus] = useState("");

    const onInsertdemo = (data, e) => {

        let demo = {

            name: demoname,
            demodate: demodate,
            email: email,
            phoneno: phone,
            designation: designation,
            reason: reason,
            comp_name: comp_name,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/demo`, demo).then((res) => {
            console.log('user updated', res.data);
            alert("Demo booked succesfully");
            window.location = '/superleads'
        }).catch(() => {

        })
    }

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const onUpdate_status = () => {
        let demo = {
            demo_id: bind_user.demo_id,
            status: statusr,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/demostatus/${bind_user.demo_id}`, demo).then((res) => {

            console.log('Demo updated', res.data);
            ShowDetails('');
            Getdemo();
        }).catch(() => {



        })

    }
    const onUpdate_demo = (id) => {
        let demo = {
            demo_id: id,
            status: 'converted',
        };



        axios.put(`${process.env.REACT_APP_API_URL}/demostatus/${bind_user.demo_id}`, demo).then((res) => {

            console.log('Demo updated', res.data);

            sendEmail();



        }).catch(() => {



        })

    }


    let navigate = useNavigate();
    const demo = () => {
        let path = `/superdemo`;
        navigate(path);
    }
    const { update_form, handleSubmit } = useForm();
    const [user_type, setUserType] = useState('');
    const [bind_user, setBindUser] = useState({});
    const ShowDetails = (data, type) => {
        setBindUser(data);
        setfirst(data.full_name);
        set_email(data.email);
        // setpass(data.lastname);
        set_phn(data.phone_no);
        // setstartdate(data.setdate);
        setUserType(type);
    }

    const [query_date, SetdateQuery] = useState([]);
    // get email
    const [loginemail, setloginmail] = useState("");
    // email validator
    const Getemaillist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/users_email`).then((res) => {

                setloginmail(res.data);
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        Getemaillist();
    }, []);

    const handle_Paste = (event) => {

        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };

    // send email
    const sendEmail = (e) => {


        emailjs.sendForm('service_hrquipa', 'template_jyjlb0k', form.current, 'CG6sgr1nRYDufVqn2')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        ShowDetails('');

        Getdemo();
    };
    const form = useRef();
    // new costumer 
    const checkifValidemail = (inputmail) => {
        let isValid = true;
        loginemail.forEach(item => {
            if (item.email.match(inputmail)) {
                isValid = false;
            }
        });
        return isValid
    }
    const [first, setfirst] = useState("");

    const [emailuser, set_email] = useState("");
    const [pass, setpass] = useState("");
    const [setpn, set_phn] = useState("");
    const [setdate, setstartdate] = useState("");
    const generatePassword = () => {

        const randomPassword =
            Math.random().toString(36).slice(2);
        setpass(randomPassword);
        if (navigator.clipboard.writeText(randomPassword)) {

            console.log(randomPassword);
        }

    };
    useEffect(() => {
        generatePassword();
    }, []);
    const onInsert = (data, e) => {
        console.log(emailuser, 'email');
        if (emailuser != "") {
            if (checkifValidemail(emailuser)) {
                try {
                    let signup = {

                        firstname: first,
                        password: pass,
                        email: emailuser,
                        startdate: setdate,
                        phoneno: setpn,


                    };
                    axios.post(`${process.env.REACT_APP_API_URL}/signupcostumer`, signup).then((res) => {
                        console.log('user updated', res.data);
                        onlogin(res?.data?.user_id);
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Email ID already exists',
                    buttons: [
                        {
                            label: t('close') ,
                            onClick: () => { },
                            style:{
                                backgroundColor:'#F36E35'
                               
                           }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        onClick: () => { },
                        style:{
                            backgroundColor:'#F36E35'
                           
                       }
                    },
                ]
            });
        }
    }


    const onlogin = (user_id) => {
        let bas = {
            userid: user_id,
            loginmail: emailuser,
            password: pass,
            admin: 'true',
            company: 'false',
            active_status: true,
        };


        axios.post(`${process.env.REACT_APP_API_URL}/login`, bas).then((res) => {
            console.log('user updated', res.data);
            onUpdate_demo(res?.data?.userid);

        }).catch(() => {

        })
    }

    const [emailError, setEmailError] = useState('');
    const handleEmailChange = (e) => {
        setEmail(e.target.value);

        // Check email format
        const regex = /\S+@\S+\.\S+/;
        if (!regex.test(e.target.value)) {
            setEmailError(t('invalidEmailAddress'));
        } else {
            setEmailError('');
        }
    };
    const isValid = demodate && phone.length > '6' && email && demoname && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
    const RenderView = () => {
        return (
            <div className="container-fluid row sprender">
                <div className="col-9  col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 posit">

                </div>
                <div className="col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3  bg-white">
                    <div className="row ms-3 mt-4 ">
                        <div className="col-11"></div>
                        <div className="col-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" onClick={() => ShowDetails('')} height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>

                    </div>
                    <div className="row ms-3 mt-4">
                        <small className="text-primary">Name</small>
                        <p>{bind_user.full_name}</p>


                    </div>
                    <div className="row ms-3 mt-2">
                        <small className="text-primary">Email</small>
                        <p className="text-lowercase">{bind_user.email}</p>

                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-primary">Phone Number</small>
                        <p>{bind_user.phone_no}</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Company</small>
                        <p>{bind_user.comp_name}</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Demo Date</small>
                        <p>{moment(bind_user.demodate).format('MMM Do YY, h:mm a')}</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Order ID</small>
                        <p className="text-danger">{bind_user.order_demo}</p>


                    </div>
                    <div className="row ms-3  border-bottom mt-2">
                        <small className="text-secondary">Status</small>
                        {(() => {
                            if (bind_user.status == true) {
                                return <p className="text-success">Open</p>
                            } if (bind_user.status == false) {
                                return <p className="text-danger">LOST</p>
                            }
                            if (bind_user.status == null) {
                                return <p className="text-warning">Pending</p>
                            }

                        })()}

                        <select className="form-select inputcolor" onChange={(e) => setstatus(e.target.value)}>
                            <option>Status</option>
                            <option value='Open'>Open</option>
                            <option value='Lost'>Lost</option>
                            <option value='Existing'>Existing</option>
                        </select>
                    </div>
                    <div className="row ms-3  mt-3  ">
                        <div className="col-7 d-grid">
                            <button className="btn btn-pri" onClick={onUpdate_status}>{t('save')}</button>
                        </div>


                    </div>
                    <div className="row ms-3 mt-3 ">
                        <div className="col-7 d-grid">
                            {(() => {
                                if (bind_user.status == "Open") {
                                    return <button className="btn btn-pri" onClick={() => ShowDetails(bind_user, 'edit')}>Convert</button>
                                } if (bind_user.status == "Lost") {
                                    return <button className="btn btn-pri" onClick={() => ShowDetails(bind_user, 'edit')}>Convert</button>
                                }
                                if (bind_user.status == "converted") {
                                    return <button className="btn btn-pri" disabled>Convert</button>
                                }

                            })()}

                        </div>


                    </div>

                </div>
            </div>
        )
    }
    const Convert = () => {
        return (
            <div className="container-fluid row sprender">
                <div className="col-9  col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 posit">

                </div>


                <div className="col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3  bg-white">
                    <form ref={form} onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                        <div className="row ms-3 mt-4 ">
                            <div className="col-11"><p className="fw-bold text-center fs-5">Convert To Costumer</p></div>
                            <div className="col-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" onClick={() => ShowDetails('')} height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>

                        </div>
                        <div className="row ms-3 border-bottom mt-2">
                            <small className="text-secondary">Order ID</small>

                            <input type="address " className='hide_all_pc' name="message" value={pass} />
                            <input type="text" class="  form-control inputcolor text_box_size mt-3 " onChange={(e) => setDemoname(e.target.value)} placeholder="1001" disabled aria-label="Username" />


                        </div>
                        <div className="row ms-3 mt-4">
                            <small className="text-secondary">Name</small>
                            <input type="text" class="  form-control inputcolor text_box_size mt-3 " minlength="3" maxlength="25"
                                onKeyPress={(event) => {
                                    if (!/[a-zA-Z]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} Value={bind_user.full_name} onChange={(e) => setfirst(e.target.value)} placeholder="Costumer Name " aria-label="Username" />


                        </div>
                        <div className="row ms-3 mt-2">
                            <small className="text-secondary">Email</small>
                            <input type="text" class="  form-control inputcolor text_box_size mt-3 text-lowercase" name="user_email" Value={bind_user.email} onChange={(e) => set_email(e.target.value)} placeholder="Costumer Email " aria-label="Username" />


                        </div>
                        <div className="row ms-3 border-bottom mt-2">
                            <small className="text-secondary">Phone Number</small>
                            <input type="text" class="  form-control inputcolor text_box_size mt-3  text-lowercase" Value={bind_user.phone_no} minlength="3" maxlength="12"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} onChange={(e) => set_phn(e.target.value)} placeholder="Costumer Email " aria-label="Username" />



                        </div>
                        <div className="row ms-3 border-bottom mt-2">
                            <small className="text-secondary">Comapny Name</small>
                            <input type="text" class="  form-control inputcolor text_box_size mt-3 " Value={bind_user.comp_name} disabled placeholder="Company Name" aria-label="Username" />



                        </div>
                        <div className="row ms-3 border-bottom mt-2">
                            <small className="text-secondary">Start Date <small className="text-danger">*</small></small>
                            <input type="date" class="  form-control inputcolor text_box_size mt-3 " onChange={(e) => setstartdate(e.target.value)} placeholder="Costumer Email " aria-label="Username" />


                        </div>
                        <div className="row ms-3 border-bottom mt-2">



                        </div>


                        <div className="row ms-3  mt-3  ">
                            <div className="col-2"></div>
                            <div className="col-7 d-grid">
                                <button className="btn btn-pri" type="submit">Convert</button>
                            </div>


                        </div>
                    </form>
                </div>

            </div>
        )
    }

    const currentDate = new Date();
    const currentDateTime = currentDate.toLocaleString();

    //download pdf
    const generatePDF = (data) => {
        console.log(data, 'pdf');
        const doc = new jsPDF('p', 'mm', 'a4');
        //const cmpname = data[0].company_name;
        doc.setFontSize(12);

        doc.text('Leads List Report', 15, 16);

        doc.setFontSize(8);
        // Add symplefyimage
        const logoDataUrl = 'Images/logoql.png';
        const logoWidth = 40;
        const logoHeight = 25;
        doc.addImage(logoDataUrl, 'PNG', 157, 1, logoWidth, logoHeight);

        //doc.text(`${startdate} to ${endate}`, 15, 21);
        // add company name
        //company address
        doc.setFontSize(9);
        //doc.text(cmpname, 140, 16);
        // doc.text('Company Address', 140, 16);

        doc.setFontSize(11);
        //doc.text(data[0].company_address1, 140, 21);
        //doc.text(data[0].company_address2, 140, 26);

        const tableColumn = ['No', 'ID', 'Customer', 'Email', 'Phone No', 'Lead Date', 'Status'];
        const tableRows = [];
        // push data to table rows
        data.forEach((item, index) => {
            const status = (() => {
                if (item.status == null) {
                    return 'Pending';
                } else {
                    return (item.status);
                }

                return '';
            })();
            const dataRow = [
                index + 1,
                item.demo_id,
                item.full_name,
                item.email,
                item.phone_no,
                moment(item.created_on).format('DD/MM/YYYY'),
                status
            ];

            tableRows.push(dataRow);

        });


        const downl = [`Downloaded On: ${currentDateTime}`];
        const addPageContent = function (data) {

            // get current page number and total number of pages

            const pageCount = doc.internal.getNumberOfPages();
            const pageNumber = data.pageNumber;


            // set font size and style for download message
            doc.setFontSize(8);

            // add download message and page number to bottom of page
            doc.text(downl, data.settings.margin.left, doc.internal.pageSize.height - 10);
            doc.text(`Page ${pageNumber} of ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 5);
        };

        // add the table to the PDF
        const tableHeight = doc.autoTable.previous.finalY + 10;
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            height: tableHeight,
            didParseCell: (data) => {
                const rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = 'normal';
                }
            },
            addPageContent: addPageContent
        });



        // save the PDF file
        // doc.save('report.pdf');
        doc.save(`leadsreport.pdf`);
    }


    // download excel
    const generateCSV = (data) => {
        const headers = ['Leads List Report'];

        //const dateRange = [`Report Range: ${startdate} to ${endate}`];
        //const cmpname = data[0].company_name;
        //const companyName = [`Company Name: ${cmpname}`];

        const downl = [`Downloaded ON: ${currentDateTime}`];
        // define the CSV column headers
        const fieldHeaders = ['No', 'ID', 'Customer', 'Email', 'Phone No', 'Lead Date', 'Status'];
        // create a CSV string from the data
        let csv = '';

        [headers, downl, fieldHeaders].forEach((row) => {

            csv += row.join(',') + '\n';

        });
        // iterate over data and add each row to CSV string
        let serialNo = 1;
        data.forEach(item => {

            const status = (() => {
                if (item.status == null) {
                    return 'Pending';
                } else {
                    return (item.status);
                }

                return '';
            })();
            const dataRow = [
                serialNo,
                item.demo_id,
                item.full_name,
                item.email,
                item.phone_no,
                moment(item.created_on).format('DD/MM/YYYY'),
                status
            ];
            csv += dataRow.join(',') + '\n';
            serialNo++;
        });



        // initiate file download
        // const filename = 'report.csv';
        const filename = `leadreport.csv`;
        const blob = new Blob([csv], { type: 'text/csv' });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    };




    // alert  

    const aletdown = (x) => {
        confirmAlert({

            message: t('select_format_to_download_report'),
            buttons: [
                {
                    label: t('pdf'),
                    className: 'btn-pri btn',
                    onClick: () => generatePDF(x)
                },
                {
                    label: t('excel'),
                    className: 'btn-pri btn',
                    onClick: () => generateCSV(x)
                }
            ]
        })
    }

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 9;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = demolist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCount = Math.ceil(demolist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    return (
        <>
            <div className="bg-light vh-100 row  container-fluid">




                <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2">

                </div>

                <div className="col-10  col-xxl-10 col-sm-9 col-md-10 col-lg-10 col-xl-10  ">

                    <input type="text" class=" form-control grey  searchboxcrm ms-5 mt-3 " onChange={(e) => SetdateQuery(e.target.value)} placeholder="Search by Customer Name" id="" aria-label="Username" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>


                    <div className="row ms-2">

                        <div className="col-1 ms-5">
                            <p className="fnt-clr fw-bold border-bottom border-primary border-4 text-center ms-1">Leads</p>
                        </div>
                        <div className="col-1 ">
                            <p className="fnt-clr text-center pointer_" onClick={demo}>Demos</p>
                        </div>
                        <div className="col-4 ms-4">

                        </div>
                        <div className="col-2">
                        </div>
                       
                        <div className="col-2">
                            <input type="date" class="form-control inputcolor d2 mt-1 ms-3" placeholder=" Month" aria-label="" onChange={(e) => { console.log(e.target.value); SetdateQuery(e.target.value) }} />


                        </div>
                    </div>

                    <div className="row ms-1">
                        <div className="card Card_1task mt-4 ms-5  ">
                            <div className="row mt-3">

                                <div className="col-2 d-grid" >
                                    <button className="btn btn-pri" data-bs-toggle="modal" data-bs-target="#exampleModal" >Add New</button>
                                </div>
                                <div className="col-6 d-grid" >
                                </div>
                                <div className="col-2 d-grid" >

                                </div>
                                <div className="col-2 " >
                                    <button type="submit" className="btn btn-pri ms-2 " onClick={() => aletdown(demolist)}>Download Report</button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col">
                                        <div className='row'>


                                            <div className='col'>

                                                <table class="table table-bordered mt-2">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>  <td scope="col"></td>

                                                                <td scope="col" className='headfont text-secondary'>ID</td>

                                                                <td scope="col" className='headfont grey mobsimplytext'>Customer</td>
                                                                <td scope="col" className='headfont grey mobsimplytext'>Email  </td>
                                                                <td scope="col" className='headfont grey mobsimplytext'>Phone No </td>
                                                                <td scope="col" className='headfont grey mobsimplytext'>Lead Date  </td>
                                                                <td scope="col" className='headfont grey mobsimplytext'>Status </td>
                                                                <td scope="col" className='headfont grey mobsimplytext'>Convert </td>


                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {displayUsers.filter((x) => { console.log(moment(x.created_on).format('YYYY-MM-DD'), query_date); return (moment(x.created_on).format('YYYY-MM-DD')).includes(query_date) || x.full_name.toLowerCase().includes(query_date) }).map((x, index) => {
                                                                return (
                                                                    <tr>
                                                                        <th scope="row"><div className="ms-3 ">  </div></th>

                                                                        <td className=''><p>{x.demo_id}</p></td>
                                                                        <td className='pointer_'> <p onClick={() => ShowDetails(x, 'view')}>{x.full_name}</p></td>
                                                                        <td className=''><p className="text-lowercase">{x.email}</p></td>
                                                                        <td className=''><p>{x.phone_no}</p></td>
                                                                        <td className=''><p>{moment(x.created_on).format('MMM Do YY, h:mm a')}</p></td>

                                                                        <td> {(() => {
                                                                            if (x.status == null) {
                                                                                return <p className="text-warning">Pending</p>
                                                                            } else {
                                                                                return <p className="text-secondary">{x.status}</p>

                                                                            }

                                                                        })()}
                                                                        </td>
                                                                        <td className=''>
                                                                            {(() => {
                                                                                if (x.status == "Open") {
                                                                                    return <svg xmlns="http://www.w3.org/2000/svg" onClick={() => ShowDetails(x, 'view')} width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right fw-bold ms-3" viewBox="0 0 16 16">
                                                                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                                                    </svg>
                                                                                } if (x.status == "Lost") {
                                                                                    return <svg xmlns="http://www.w3.org/2000/svg" onClick={() => ShowDetails(x, 'view')} width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right fw-bold ms-3" viewBox="0 0 16 16">
                                                                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                                                    </svg>
                                                                                }
                                                                                if (x.status == "converted") {
                                                                                    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right fw-bold ms-3" viewBox="0 0 16 16">
                                                                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                                                    </svg>
                                                                                }

                                                                            })()}
                                                                        </td>



                                                                    </tr>

                                                                );
                                                            })}





                                                        </tbody>

                                                    </table>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    pageCount={pageCount}
                                                    onPageChange={changePage}
                                                    containerClassName={"paginationBttns"}
                                                    previousLinkClassName={"previousBttn"}
                                                    nextLinkClassName={"nextBttn"}
                                                    disabledClassName={"paginationDisabled"}
                                                    activeClassName={"paginationActive"}
                                                />

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                {/* Add New  */}
                <div class="modal fade mt-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable">
                        <div class="modal-content ">
                            <div class="">
                                <div className="row mt-4">
                                    <div className="col-2"></div>
                                    <div className="col-8">
                                        <div class=" text-center text-primary fs-4 " id="exampleModalLabel">Add New Lead</div>
                                    </div>
                                    <div className="col-1">
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                                    </div>
                                </div>

                            </div>
                            <div class="modal-body ">
                                <div className='row mt-3'>
                                    <div className="col-2"></div>
                                    <div className='col-8 '>
                                        <div class="input-group">
                                            <input type="text" class="  form-control inputcolor text_box_size mt-3 " minlength="3" maxlength="35" Value={demoname} onChange={(e) => setDemoname(e.target.value)}
                                               onKeyPress={(event) => {
                                                if (
                                                  demoname.length === 0 && // Check if input is empty
                                                  !/[a-zA-Z]/.test(event.key) // Allow only alphabet for the first character
                                                ) {
                                                  event.preventDefault();
                                                }
                                                if (
                                                  /[0-9]/.test(event.key) // Disallow numbers
                                                ) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onBlur={(event) => {
                                                if (!/^[a-zA-Z\s]*$/.test(event.target.value)) {
                                                  event.target.value = ''; // Clear the input if it doesn't match the pattern
                                                  setDemoname(''); // Also clear the state variable
                                                }
                                              }}  placeholder="Customer Name " aria-label="Username" />
                                        </div>
                                        <div className="col-2"></div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="col-2"></div>
                                    <div className='col-8 '>
                                        <div class="input-group">
                                            <input type="text" class="  form-control inputcolor text_box_size mt-3 " Value={email} minlength="3" maxlength="55" onChange={handleEmailChange} placeholder="Email " aria-label="Username" />
                                        </div>
                                        {emailError && <span className="text-danger">{emailError}</span>}
                                        <div className="col-2"></div>
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className="col-2"></div>
                                    <div className='col-8 '>
                                        <div class="input-group">
                                            <input type="text" class="  form-control inputcolor text_box_size mt-3 " placeholder="Company" minlength="3" maxlength="35" Value={comp_name} onChange={(e) => setcompname(e.target.value)} aria-label="Username" />
                                        </div>
                                        <div className="col-2"></div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="col-2"></div>
                                    <div className='col-8 '>
                                        <div class="input-group">
                                            <input type="text" class="  form-control inputcolor text_box_size mt-3 "
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} placeholder="Phone No " onPaste={handle_Paste} minlength="7" maxlength="12" Value={phone} onChange={(e) => setPhone(e.target.value)} aria-label="Username" />
                                        </div>
                                        <div className="col-2"></div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className="col-2"></div>
                                    <div className='col-8'>
                                        <small className=' text-center'>Demo Date</small>
                                        <div class="input-group">

                                            <input type="date" minlength="1" maxlength="20" class="  form-control inputcolor text_box_size " Value={demodate} onChange={(e) => setDemodate(e.target.value)} placeholder="preferred Demo Date" aria-label="Username" />
                                        </div>
                                        <div className="col-2"></div>
                                    </div>

                                </div>




                                <div className='row mt-4'>
                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                    </div>
                                    <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                        <button type="button" class="btn btn-pri text_box_size" disabled={!isValid} onClick={onInsertdemo}>Submit</button>
                                    </div>
                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Filter */}
                <div class="modal fade mt-5" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable float-end w-25 ">
                        <div class="modal-content ">
                            <div class="modal-header">
                                <h7 class="modal-title fw-bold fs-6 " id="exampleModalLabel">Filter</h7>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body ">
                                <p className="text-secondary">All</p>
                                <p className="text-secondary">Pending</p>
                                <p className="text-secondary">Processed</p>
                                <p className="text-secondary">Canceled</p>
                                <div className='row mt-4'>

                                    <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                        <button type="button" class="btn btn-pri">Filter</button>
                                    </div>
                                    <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div>{(() => {
                switch (user_type) {
                    case "view": return RenderView();
                    case "edit": return Convert();

                    default: return "";
                }
            })()}</div>
        </>




    )
}
export default Superlead