import { React, useState, useEffect, useContext } from "react";
import "./emp_Dashboard.css";
import Lheader from '../Lheader/Lheader'
import Chart from "react-apexcharts";
import axios from 'axios';
import moment from 'moment';
import { store } from '../../store/user';
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Dashboard_Employee = () => {
    const { state } = useContext(store);
    console.log('profile', state.user);
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const first = current.getDate();
    var firstday = new Date(current.setDate(first)).toUTCString();
    const [holiday_title, setHoliday_title] = useState([])
    const [clockState, setClockState] = useState();

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const Getholidaylist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/holidayslistcurrentmonth/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'holiday');
                setHoliday_title(res.data);
            }).catch(() => {

            })
    }
    const currentDate = new Date();
    const month = currentDate.toLocaleString('default', { month: 'long' });

    const timerup = () => {
        let datat = new Date(date);
        //  const datat = new Date();
        //  const date1 =  moment(datat).format('DD')
        if (datat < '02') {
            onUpdate();

        } else {
            console.log("Success")
        }

    }

    useEffect(() => {
        timerup();

    }, []);
    const onUpdate = () => {
        let banke = {
            leave: 7,



        };

        //console.log("user", user);



        axios.put(`${process.env.REACT_APP_API_URL}/Employee_leave_autoupdate`, banke).then((res) => {

            console.log('leave updated', res.data);

        }).catch(() => {



        })

    }
    useEffect(() => {

        
        setInterval(() => {
            const date = new Date();
            setClockState(date.toLocaleTimeString());
        }, 1000);

    }, []);


    let navigate = useNavigate();
    const sidebarChange4 = () => {
        let path = `/yourwork`;
        navigate(path);
    }
    const emped = () => {
        let path = `/EmpProfileedit`;
        navigate(path);
    }

    const payslip = (id) => {
        let path = `/emppayslip`;
        navigate(path, { state: { emp_id: id } });
    }
    const goguide = () => {
        navigate("/employeequide");

    };
    const [guide, setguide] = useState([]);
    
    const Getguide = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getloginid/${state?.user[0]?.login_id}`).then((res) => {
                console.log(res.data, 'setguide');
                setguide(res.data);
                if(res.data[0]?.empguide==null){
                    goguide()
                }else{
                    Getleave();
                }
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [stat_e, setstate] = useState({
        options: {
            chart: {
                height: 150,
                type: 'bar',
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
           
            title: {
                text: t('attendanceAnalyser'),
                align: 'left',
                style: {
                    fontSize: "14px",
                    color: '#717372',
                    fontFamily: 'Comfortaa'
                }
            },
            fill: {
                //   type: 'gradient',
                //   gradient: {
                //     shade: 'dark',
                //     gradientToColors: [ '#FDD835'],
                //     shadeIntensity: 1,
                //     type: 'horizontal',
                //     opacityFrom: 1,
                //     opacityTo: 1,
                //     stops: [0, 100, 100, 100]
                //   },
                colors: ['#0080FF', 'transparent']
            },
            dataLabels: {
                enabled: false
            },
            yaxis: {
                min: 10,
                max: 300
            },
        },
        xaxis: {
            categories: [], // Initialize as an empty array
          },
          series: [{
            name: 'attendance',
            data: []
          }],

    })
    
    const [paytotal, setPaytotal] = useState([]);
    const [username, setusername] = useState([]);
    const Getpaytotal = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp_pay/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                if (res.data.length == 0) {
                    axios.get(`${process.env.REACT_APP_API_URL}/paynull/22`).then((result) => {
                        console.log(result.data, 'pay');
                        setPaytotal(result.data);
                    })
                } else { setPaytotal(res.data); }
            }).catch(() => {

            })
        }
    }
    const [currency_details, setcurrency_details] = useState([]);
    const Getcurrency = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/currency_2/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                if (res.data.length == 0) {
                    axios.get(`${process.env.REACT_APP_API_URL}/curr/6`).then((result) => {
                        console.log(result.data, 'curr');
                        setcurrency_details(result.data);
                    })
                } else { setcurrency_details(res.data); }

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [leavetotal, setLeavetotal] = useState([]);
    const Getleavetotal = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/empdashboard_leave/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');

                setLeavetotal(res.data);


            }).catch(() => {

            })
        }
    }
    const [hrstotal, setHrstotal] = useState([]);
    const Gethrstotal = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/empdashboard_hrs/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');

                setHrstotal(res.data);


            }).catch(() => {

            })
        }
    }
    const Getempnam = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp_name/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setusername(res.data);
            }).catch(() => {

            })
        }
        // var id = this.props.match.params.emp_id;

    }
    const [att, setatt] = useState([]);
    const Getempatt = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/attgrp/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'att');
                setatt(res.data);
            }).catch(() => {

            })
        }


    }


    useEffect(() => {
        Getholidaylist();
        Getempatt();
    }, [state]);

    useEffect(() => {
        Getempnam();
        Getguide();
        Getcurrency();
        Getpaytotal();
        Getleavetotal();
        Gethrstotal();
    },
        [state]);
    
        // const atten = att.map((entry) => Math.round(entry.total_hours));
        // const monthLabels = att.map((entry) => entry.month); // Replace 'month' with the actual property name
        let monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
          ];
         
          
useEffect(() => {
    const atten = att.map((entry) => Math.round(entry.total_hours));
    const monthLabels = att.map((entry) => monthNames[entry.month - 1]);
    setstate((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        xaxis: {
          categories: monthLabels, // Update the categories array
        },
      },
      series: [
        {
          name: 'attendance',
          data: atten,
        },
      ],
    }));
  }, [att]);
  
    const [leave_details, setUserDetails] = useState([]);

    const Getleave = (emp_id) => {
        if (state.user) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/emp_data/${state?.user[0]?.emp_id}`)
                .then((res) => {
                    console.log(res.data, "success");
                    setUserDetails(res.data);
                })
                .catch((err) => {
                    console.error(err.message);
                    ale_rt(); // Show the error popup
                });
        }
    };
    // useEffect(() => {
    //     Getleave();
    // },
    //     [state]);
    const ale_rt = () => {
        confirmAlert({
            // title: 'Cancle',
            message: t('completeProfile') ,
            buttons: [
                {
                    
                   
                    label: t('complete') ,
                    onClick: () => emped(),
                    style:{
                         backgroundColor:'#F36E35'
                        
                    }
                    
                }

            ]
        })
    }
    return (
        <div className="bg-light vh-100 container-fluid">

            <div className="row mt-5">

                <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab ">


                </div>
                <div className="  col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 bg-light mt-3 ">




                    <div className="lefdash">
                        <div class="card empviewdetails mt-3 ms-5">

                            <div class="card-body">
                                <blockquote class="blockquotemb-0">
                                    {username.map((x, index) => {
                                        return (
                                            <div className="row">

                                                <div className="col-sm-7 cents">  <p className="fw-bold grey supertxtmob"> {t('welcome')} {x.full_name} </p>
                                                    <p className="text-secondary text_small hide_all  greyh ">
                                                    {t('welcomeToPortal')} {t('howCanWeHelp')}</p>
                                                </div>
                                                <div className="col-3 hide_all hid_tab">
                                                    <p className="fw-bold text-center txt_dt grey">{date} </p>
                                                    <p className="text-secondary  text-center text_small txt_sat"> {clockState}</p>

                                                </div>
                                                <div className="col-2">  <button class="btn btn-pri btn_clock" onClick={sidebarChange4} type="button">{t('myWork')}  </button></div>
                                            </div>
                                        );
                                    })}
                                </blockquote>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="ms-4 mt-2 onlytab hide_tab">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                        </div>
                    </div>
                    {hrstotal.map((h, index) => {
                        return (<div>
                            {leavetotal.map((a, index) => {
                                return (<div>
                                    {currency_details.map((y, index) => {
                                        return (<div>
                                            {paytotal.map((x, index) => {
                                                return (
                                                    <div className="row scroll-container">
                                                        <div className="col-4 col-xxl-4  col-md-4 col-lg-4 col-xl-4 pointer_ lefdash">
                                                            <div class="card card_1 ms-5 mt-3" onClick={() => payslip(x.emp_id)} >

                                                                <div class="card-body">
                                                                    <img className="mx-auto d-block" src="./Images/card_1.svg"></img>
                                                                    {(() => {
                                                                        if (x.takehomepay == null) {
                                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                                0
                                                                            </p>
                                                                        } else {
                                                                            return <p className="text-white text-center mt-1 fs-5 text-lowercase">

                                                                                {x.takehomepay.toLocaleString(undefined, { maximumFractionDigits: 0 }).replace(/,/g, '.')}  {y.symbol}

                                                                            </p>
                                                                        }
                                                                    })
                                                                        ()}

                                                                    <p className="text-white text-center mt-3">
                                                                        {t('paySlip')} 
                                                                    </p>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-4 col-xxl-4  col-md-4 col-lg-4 col-xl-4">
                                                            <div class="card card_3 ms-4  mt-3 " >


                                                                <div class="card-body ">
                                                                    <img className="mx-auto d-block" src="./Images/card_2emp.svg"></img>

                                                                    {(() => {
                                                                        if (h.totalhrssum == null) {
                                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                                0
                                                                            </p>
                                                                        } else {
                                                                            return <p className="text-white text-center mt-1 fs-5">
                                                                                {h.totalhrssum.toFixed(1)} Hours


                                                                            </p>
                                                                        }
                                                                    })
                                                                        ()}

                                                                    <p className="text-white text-center mt-3">
                                                                        {t('workingHours')} 
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-4 col-xxl-4  col-md-4 col-lg-4 col-xl-4">
                                                            <div class="card card_2 ms-4  mt-3" >

                                                                <div class="card-body">

                                                                    <img className="mx-auto d-block" src="./Images/card_3emp.svg"></img>

                                                                    {(() => {
                                                                        if (a.totalleave == null) {
                                                                            return <p className="text-white text-center mt-2 fs-5">
                                                                                0
                                                                            </p>
                                                                        } else {
                                                                            return <p className="text-white text-center mt-2 fs-5">
                                                                                {a.totalleave} Days
                                                                            </p>
                                                                        }
                                                                    })
                                                                        ()}
                                                                    <p className="text-white text-center mt-3">
                                                                        {t('leave')} 
                                                                    </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        );
                                    })}
                                </div>
                                );
                            })}
                        </div>
                        );
                    })}
                    <div className="row">
                        <div className="col-sm-9 col-xxl-9 col-md-9 col-lg-9 col-xl-9 graphleft grlf">
                            <div class="card  ms-5 mt-3 graphcardwidthmob" >

                                <div class="card-body">
                                    <Chart

                                        options={stat_e.options}
                                        series={stat_e.series}
                                        type="bar"
                                        height="300px"
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-xxl-3 col-md-3 col-lg-3 col-xl-3 holcard">
                            <div class="card card_holiday mt-3 graphcardwidthmob crdholtabemp" >

                                <div class="card-body _text">
                                    < p className=" fw-bold fs-6 grey text-center">{t('holidaysInDecember')} {month}</p>
                                    {holiday_title.length > 0 ? (
                                        holiday_title.map((x, index) => {
                                            return (
                                                <div key={index}>
                                                    <p className="text_small">
                                                        <p className="fw-bold  text-center">{moment(x.holidaydate).format('DD MMMM,dddd')}
                                                            <p className="text-center fw-normal mt-1">{x.holiday_name}</p></p>
                                                        <p className="border-bottom"></p>
                                                    </p>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className="text-center grey">
                                            There are no holidays in this month
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-8">

                                    </div>

                                    <div className="col-4">
                                        <a href="holidaycalendar#profile" className="text-primary _text  "> {t('viewAll')} </a>

                                    </div>
                                </div>
              
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Lheader />


        </div>
    )
}
export default Dashboard_Employee