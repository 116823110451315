import "./CompanyDetails.css";
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { React, useState } from "react";
import Chart from "react-apexcharts";
import "react-datepicker/dist/react-datepicker.css";
import Lheader from '../Lheader/Lheader';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const CompanyDetails = () => {
    const [company_list, setCompany_list] = useState([])
    const { update, handleSubmit } = useForm();

    const Getcompanylist = (company_id, type) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/company_address`).then((res) => {
                console.log(res.data, 'success');
                setCompany_list(res.data);
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    useEffect(() => {
        Getcompanylist();
    }, []);
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const alet = () => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmCancel'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => window.location.reload(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert( t('confirmCancel')),
                  
                }
            ]
        })
    }

    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmUpdate'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => window.location.reload(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert( t('confirmUpdate')),
                   
                }
            ]
        })
    }

    const back = () => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmGoBack'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => goemp(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert('cancel'),
                   
                }
            ]
        })
    }

    const [startDate, setStartDate] = useState(new Date());
    const [type_address, setTypeAddress] = useState('Address_update');
    const [type_tax, setTypeTax] = useState('tax_update');
    const [type_bank, setTypeBank] = useState('bank_update');



    const TabChange = (type) => {
        console.log('type', type_address)
        if (type == 'Address_edit') {
            console.log('Address_edit', type);
            setTypeAddress(type);
        } else {
            console.log('Address_update', type);
            setTypeAddress(type);
        }
    }
    const TabChange1 = (type) => {
        console.log('type', type_address)
        if (type == 'tax_edit') {
            console.log('tax_edit', type);
            setTypeTax(type);
        } else {
            console.log('tax_update', type);
            setTypeTax(type);
        }
    }
    const TabChange2 = (type) => {
        console.log('type', type_address)
        if (type == 'bank_edit') {
            console.log('bank_edit', type);
            setTypeBank(type);
        } else {
            console.log('bank_update', type);
            setTypeBank(type);
        }
    }
    const [state, setstate] = useState({
        options: {
            chart: {
                height: 150,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000', '6/11/2000', '7/11/2000', '8/11/2000', '9/11/2000', '10/11/2000', '11/11/2000', '12/11/2000', '1/11/2001', '2/11/2001', '3/11/2001', '4/11/2001', '5/11/2001', '6/11/2001'],
                tickAmount: 10,
                labels: {
                    formatter: function (value, timestamp, opts) {
                        return opts.dateFormatter(new Date(timestamp), ' MMM')
                    }
                }
            },
            title: {
                text: t('monthlyAnalyser') ,
                align: 'left',
                style: {
                    fontSize: "16px",
                    color: '#666'
                }
            },
            fill: {
                //   type: 'gradient',
                //   gradient: {
                //     shade: 'dark',
                //     gradientToColors: [ '#FDD835'],
                //     shadeIntensity: 1,
                //     type: 'horizontal',
                //     opacityFrom: 1,
                //     opacityTo: 1,
                //     stops: [0, 100, 100, 100]
                //   },
                colors: ['#0080FF', 'transparent']
            },
            yaxis: {
                min: 0,
                max: 30
            }
        },
        series: [{
            name: 'Sales',
            data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 17, 1]
        }],

    })
    let navigate = useNavigate();
    const goemp = () => {
        navigate("/companylist");

    };


    const addwrk = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }


    return (
        <div className="container-fluid">

            <div className="row mobsimplytext mt-5">
                <div className="col-3"></div>
                <div className="col-9 twoleft ">
                    <div className="row">
                        {/* {company_list.map((x, index) => {
                                                    return ( */}
                        <div className="col-6 border-end twotab-col">
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6 ">
                                            <img className='img-fluid mt-4  img-tab' src={`Images/u1.svg`} ></img>
                                        </div>
                                        <div className="col-6 texttab-az">
                                            <p className="mt-4 companyname "> AZ Company
                                                <p className="companynamesize">Los Angeles
                                                </p></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <p className="ms-5 mt-4">Status</p>

                                    <select class=" text-center text-white  rounded-pill activesele companynamesize border-0" aria-label=" select Day">
                                        <option selected>Active </option>
                                        <option value="1">Inactive</option>
                                        <option value="2">Blocked</option>
                                        <option value="3"></option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="text-primary mt-3 web-mltab">{t('Website')}</p>
                                            <p class="web-mltab1" >az.com</p>
                                        </div>
                                        <div className="col-6">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill mt-3 float-start pencilimg" viewBox="0 0 16 16">
                                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <p className="text-primary ms-5 mt-3">Email</p>
                                    <p className="ms-5">Hello@az.com</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row ">
                                        <div className="col-7 num-tabs">
                                            <p className="text-primary mt-3">{t('phoneNumber')}</p>
                                            <p>+44 5595 6969</p>
                                        </div>
                                        <div className="col-5">

                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 emp-tabs">
                                    <p className="text-primary ms-5 mt-3">No of Employees:</p>
                                    <p className="ms-5 fw-bold fs-4">600</p>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="monthly"
                                id="uncontrolled-tab-example"
                                className="mb-3 border-bottom border-primary  month-tabs">

                                <Tab eventKey="monthly" className="" title="Monthly">
                                    {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}/>   */}
                                    <p className="text-primary company-tabs">
                                        24 june <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="blue" class="bi bi-caret-down-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                    </p>
                                    <div className="row company-tabs">
                                        <div className="col-4">
                                            <p className="text-secondary companynamesize">
                                                Gross pay <p className="fs-5 fw-bold text-black">$6000</p></p>
                                        </div>
                                        <div className="col-4">
                                            <p className="text-secondary companynamesize">
                                                Net Pay <p className="fs-5 fw-bold text-black">$5200</p></p>
                                        </div>
                                        <div className="col-4">
                                            <p className="text-secondary companynamesize">
                                                Deductions<p className="fs-5 fw-bold text-black">$600</p></p>
                                        </div>
                                    </div>
                                    <div class="card tabcardsize company-btntabs" >
                                        <img className='img-fluid clockimg ' src={`Images/clock.svg`} ></img>
                                        <p className="text-white fw-bold text-center ms-5 mt-2 pointer_" onClick={addwrk}>Update Work Schedule</p>
                                    </div>

                                    <div className="row mt-2 chart_graph">
                                        <Chart

                                            options={state.options}
                                            series={state.series}
                                            type="line"
                                            height="250"

                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey="yearly" className="" title="Yearly">

                                </Tab>


                            </Tabs>

                        </div>
                        {/* );
                        })} */}
                        <div className="col-6 tabcol-sec">
                            <Tabs
                                defaultActiveKey="address"
                                id="uncontrolled-tab-example"
                                className="mb-3 ms-3">

                                <Tab eventKey="address" className="ms-5" title={t('address')}>
                                    {(() => {
                                        if (type_address == "Address_edit") {
                                            return <div>
                                                <div className='row mt-3'>
                                                    <div className='col ms-1'>
                                                        <div class="input-group ms-5 ">
                                                            <input type="text" class=" ms-3 _textwork mt-3" placeholder="Joseph George" aria-label="Username" />
                                                        </div>
                                                        <p class='textwork_ text-center'> {t('name')}</p>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col mt-5'>
                                                        <div class="mt-3">
                                                            <input type="address" class="form-control inputcolor  txtaddr tabtext1 bg-light" placeholder={t('address1')} id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col mt-5 '>
                                                        <div class="">
                                                            <input type="address" class="form-control inputcolor txtaddr tabtext1 bg-light" placeholder={t('address2')} id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col mt-5 '>
                                                        <div class="">
                                                            <input type="address" class="form-control inputcolor txtaddr tabtext1 bg-light" placeholder={t('cityTown')} id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-4  '>
                                                    <div className='col mt-5 '>
                                                        <div class="">
                                                            <select class="form-select inputcolor text-start text-secondary txtaddr tabtext1 bg-light" aria-label=" select Day">
                                                                <option selected>{t('pincode')} </option>
                                                                <option value="1">560009</option>
                                                                <option value="2">560068</option>
                                                                <option value="3">560001</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col mt-5 '>
                                                        <div class="">
                                                            <input type="address" class="form-control inputcolor txtaddr tabtext1 bg-light" placeholder={t('country')} id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col mt-5 '>
                                                        <div class="">
                                                            <div class="input-group  border bg-white phonedrop txtaddr phoneleft tabphonetxtbox ">

                                                                <button class="btn  dropdown-toggle " type="button" data-bs-toggle="dropdown" aria-expanded="false">+44</button>
                                                                <ul class="dropdown-menu">
                                                                    <li><a class="dropdown-item" href="#">+1</a></li>
                                                                    <li><a class="dropdown-item" href="#">+86</a></li>
                                                                    <li><a class="dropdown-item" href="#">+7</a></li>

                                                                    <li><a class="dropdown-item" href="#">+94</a></li>
                                                                </ul>

                                                                <input type="tel" class="form-control inputcolor border-top-0 border-bottom-0 border-end-0 bg-light" aria-label="Text input with dropdown button" id="phone" name="phone" pattern="+91[7-9]{2}-[0-9]{3}-[0-9]{4}" placeholder={t('phoneNumber')} width={"-10%"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col '>
                                                        <div class="">
                                                            <input type="address" class="form-control inputcolor txtaddr tabtext1 bg-light " placeholder={t('fax')} id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-3">
                                                        <button class="btn btn-secondary company_btn" type="button" onClick={alet}>{t('cancel')}</button>
                                                    </div>
                                                    <div className="col-1"></div>
                                                    <div className="col-3">

                                                        <button class="btn btn-pri company_btn1" type="button" onClick={save}>{t('update')}</button>

                                                    </div>
                                                </div>

                                            </div>

                                        } else {
                                            return <div className="row">
                                                <p className="text-secondary companynamesize">{t('companyName')}
                                                    <p className="text-black fs-6">AZ Company</p></p>
                                                <p className="text-secondary companynamesize">{t('address1')}
                                                    <p className="text-black fs-6">1791 Yorkshire Circle Kitty Hawk, NC 279499</p></p>
                                                <p className="text-secondary companynamesize">{t('address2')}
                                                    <p className="text-black fs-6">1791 Yorkshire Circle Kitty Hawk, NC 279499</p></p>
                                                <p className="text-secondary companynamesize">{t('cityTown')}
                                                    <p className="text-black fs-6">Denver</p></p>
                                                <p className="text-secondary companynamesize">{t('pincode')}
                                                    <p className="text-black fs-6">80014</p></p>
                                                <p className="text-secondary companynamesize">{t('country')}
                                                    <p className="text-black fs-6">US</p></p>
                                                <p className="text-secondary companynamesize">{t('phoneNumber')}
                                                    <p className="text-black fs-6">+44 5595 6969</p></p>
                                                <p className="text-secondary companynamesize">{t('fax')}
                                                    <p className="text-black fs-6">Lorem Ipsum</p></p>
                                                <div className="row">
                                                    <div className="col-5">
                                                        <button class="btn btn-secondary btn1cancel" type="button" onClick={back}>{t('back')}</button>

                                                    </div>

                                                    <div className="col-5">
                                                        <button class="btn btn-pri btn2edit" onClick={() => { TabChange("Address_edit") }} type="button">Edit</button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })()}


                                </Tab>

                                <Tab eventKey="tax" className="ms-5 tax-tab" title="Tax">
                                    {(() => {
                                        if (type_tax == "tax_edit") {
                                            return <div>
                                                <div className='row mt-3'>
                                                    <div className='col ms-1'>
                                                        <div class="input-group ms-5 ">
                                                            <input type="text" class=" ms-3 _textwork mt-3" placeholder="Symplefy" aria-label="Username" />
                                                        </div>
                                                        <p class='textwork1_ text-center'> Comapny Legal Name</p>
                                                    </div>
                                                </div>
                                                <div className='row mt-4'>
                                                    <div className='col mt-5'>
                                                        <div class="mt-3">
                                                            <input type="address" class="form-control inputcolor  txtaddr tabtext1 bg-light" placeholder="Company Tax Code" id="exampleFormControlInput1" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-3">
                                                        <button class="btn btn-secondary company_btn" type="button" onClick={alet}>{t('cancel')}</button>
                                                    </div>
                                                    <div className="col-1"></div>
                                                    <div className="col-3">

                                                        <button class="btn btn-pri company_btn1" type="button" onClick={save}>{t('update')}</button>

                                                    </div>
                                                </div>
                                            </div>

                                        } else {
                                            return <div className="row">
                                                <p className="text-secondary companynamesize mt-5">Company Legal Name
                                                    <p className="text-black fs-6">AZ Company</p></p>
                                                <p className="text-secondary companynamesize">Company Tax Code
                                                    <p className="text-black fs-6">Lorem Ipsum</p></p>

                                                <div className="row">
                                                    <div className="col-5">
                                                        <button class="btn btn-secondary btn2cancel" type="button" onClick={back}>{t('back')}</button>

                                                    </div>

                                                    <div className="col-5">
                                                        <button class="btn btn-pri btn3edit " onClick={() => { TabChange1("tax_edit") }} type="button">Edit</button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })()}
                                </Tab>

                                <Tab eventKey="bank" className="ms-5" title="Bank" >
                                    {(() => {
                                        if (type_bank == "bank_edit") {
                                            return <div>
                                                <div className='row mt-3'>
                                                    <div className='col ms-1'>
                                                        <select class="form-select inputcolor text-start text-secondary txtaddr tabtext1 mt-5 bankborder" aria-label=" select Day">
                                                            <option selected>Symplefy </option>
                                                            <option value="1">other</option>
                                                            <option value="2"></option>
                                                            <option value="3"></option>
                                                        </select>
                                                        <p class='textwork2_ text-center'> Bank Account</p>
                                                    </div>
                                                </div>
                                                <div className='row mt-5'>
                                                    <div className="col-3">
                                                        <button class="btn btn-secondary company_btn" type="button" onClick={alet}>{t('cancel')}</button>
                                                    </div>
                                                    <div className="col-1"></div>
                                                    <div className="col-3">

                                                        <button class="btn btn-pri company_btn1" type="button" onClick={save}>{t('update')}</button>

                                                    </div>
                                                </div>
                                            </div>
                                        } else {
                                            return <div className="row">
                                                <p className="text-secondary companynamesize mt-5">Bank Account
                                                    <p className="text-black fs-6">American Express-63337833398333230</p></p>


                                                <div className="row">
                                                    <div className="col-5">
                                                        <button class="btn btn-secondary btn2cancel btn2top" type="button" onClick={goemp}>{t('back')}</button>

                                                    </div>

                                                    <div className="col-5">
                                                        <button class="btn btn-pri btn3edit btn2top " onClick={() => { TabChange2("bank_edit") }} type="button">Edit</button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })()}
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                </div>


            </div>
            <div className="row mobhide mt-5">
                <Tabs
                    defaultActiveKey="pro"
                    id="uncontrolled-tab-example"
                    className="mb-3 "
                >
                    <Tab eventKey="pro" className="ms-3" title="Company Details">
                        <div className="row">
                            <div className="col border-end">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <img className='img-fluid mt-4 ' src={`Images/u1.svg`} ></img>
                                            </div>
                                            <div className="col-6">
                                                <p className="mt-4 companyname"> AZ Company
                                                    <p className="companynamesize">Los Angeles
                                                    </p></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="ms-4 mt-4">Status</p>

                                        <select class=" text-center text-white  rounded-pill activesele companynamesize border-0" aria-label=" select Day">
                                            <option selected>Active </option>
                                            <option value="1">Inactive</option>
                                            <option value="2">Blocked</option>
                                            <option value="3"></option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-primary mt-3">{t('Website')}</p>
                                                <p>az.com</p>
                                            </div>
                                            <div className="col-6">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill mt-3 float-start pencilimg" viewBox="0 0 16 16">
                                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="text-primary ms-4 mt-3">Email</p>
                                        <p className="ms-4">Hello@az.com</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-8">
                                                <p className="text-primary mt-3">{t('phoneNumber')}</p>
                                                <p>+44 5595 6969</p>
                                            </div>
                                            <div className="col-4">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="text-primary ms-4 mt-3">No of Employees:</p>
                                        <p className="ms-4 fw-bold fs-4">600</p>
                                    </div>
                                </div>
                                <Tabs
                                    defaultActiveKey="monthly"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 border-bottom border-primary  ">

                                    <Tab eventKey="monthly" className="" title="Monthly">
                                        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}/>   */}
                                        <p className="text-primary ">
                                            24 june <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="blue" class="bi bi-caret-down-fill ms-2" viewBox="0 0 16 16">
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg>
                                        </p>
                                        <div className="row">
                                            <div className="col-4">
                                                <p className="text-secondary companynamesize">
                                                    Gross pay <p className="fs-5 fw-bold text-black">$6000</p></p>
                                            </div>
                                            <div className="col-4">
                                                <p className="text-secondary companynamesize">
                                                    Net Pay <p className="fs-5 fw-bold text-black ">$5200</p></p>
                                            </div>
                                            <div className="col-4">
                                                <p className="text-secondary companynamesize">
                                                    Deductions<p className="fs-5 fw-bold text-black">$600</p></p>
                                            </div>
                                        </div>
                                        <div class="card tabcardsize pointer_" >
                                            <img className='img-fluid clockimg ' src={`Images/clock.svg`} ></img>
                                            <p className="text-white fw-bold text-end me-5 mt-2  " onClick={addwrk}>Update Work Schedule</p>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-12">
                                                <Chart

                                                    options={state.options}
                                                    series={state.series}
                                                    type="line" />
                                            </div>

                                        </div>
                                    </Tab>
                                    <Tab eventKey="yearly" className="" title="Yearly">

                                    </Tab>


                                </Tabs>

                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="edit" className="" title="Edit Details">
                        <div className="row">
                            <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                <div className="card editaddcard"><p className="fw-bold fs-5 mt-2">{t('address')}</p>  </div>
                            </button>
                            <div class="collapse" id="collapseExample">
                                {(() => {
                                    if (type_address == "Address_edit") {
                                        return <div>
                                            <div className='row mt-3'>
                                                <div className='col ms-1'>
                                                    <div class="input-group ms-5 ">
                                                        <input type="text" class=" ms-3 _textwork mt-3" placeholder="Joseph George" aria-label="Username" />
                                                    </div>
                                                    <p class='textwork_ text-center'> {t('name')}</p>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col mt-5'>
                                                    <div class="mt-3">
                                                        <input type="address" class="form-control inputcolor  txtaddr tabtext1 bg-light" placeholder="Address 1" id="exampleFormControlInput1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col mt-5 '>
                                                    <div class="">
                                                        <input type="address" class="form-control inputcolor txtaddr tabtext1 bg-light" placeholder="Address 2" id="exampleFormControlInput1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col mt-5 '>
                                                    <div class="">
                                                        <input type="address" class="form-control inputcolor txtaddr tabtext1 bg-light" placeholder={t('cityTown')} id="exampleFormControlInput1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4  '>
                                                <div className='col mt-5 '>
                                                    <div class="">
                                                        <select class="form-select inputcolor text-start text-secondary txtaddr tabtext1 bg-light" aria-label=" select Day">
                                                            <option selected>{t('pincode')} </option>
                                                            <option value="1">560009</option>
                                                            <option value="2">560068</option>
                                                            <option value="3">560001</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col mt-5 '>
                                                    <div class="">
                                                        <input type="address" class="form-control inputcolor txtaddr tabtext1 bg-light" placeholder={t('country')} id="exampleFormControlInput1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col mt-5 '>
                                                    <div class="">
                                                        <div class="input-group  border bg-white phonedrop txtaddr phoneleft tabphonetxtbox ">

                                                            <button class="btn  dropdown-toggle " type="button" data-bs-toggle="dropdown" aria-expanded="false">+44</button>
                                                            <ul class="dropdown-menu">
                                                                <li><a class="dropdown-item" href="#">+1</a></li>
                                                                <li><a class="dropdown-item" href="#">+86</a></li>
                                                                <li><a class="dropdown-item" href="#">+7</a></li>

                                                                <li><a class="dropdown-item" href="#">+94</a></li>
                                                            </ul>

                                                            <input type="tel" class="form-control inputcolor border-top-0 border-bottom-0 border-end-0 bg-light" aria-label="Text input with dropdown button" id="phone" name="phone" pattern="+91[7-9]{2}-[0-9]{3}-[0-9]{4}" placeholder={t('phoneNumber')}width={"-10%"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col '>
                                                    <div class="">
                                                        <input type="address" class="form-control inputcolor txtaddr tabtext1 bg-light " placeholder={t('fax')} id="exampleFormControlInput1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-3">
                                                    <button class="btn btn-secondary company_btn" type="button" onClick={alet}>{t('cancel')}</button>
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-3">

                                                    <button class="btn btn-pri company_btn1" type="button" onClick={save}>{t('update')}</button>

                                                </div>
                                            </div>

                                        </div>

                                    } else {
                                        return <div className="row mobeditpag">
                                            <p className="text-secondary companynamesize">{t('companyName')}
                                                <p className="text-black fs-6">AZ Company</p></p>
                                            <p className="text-secondary companynamesize">{t('address1')}
                                                <p className="text-black fs-6">1791 Yorkshire Circle Kitty Hawk, NC 279499</p></p>
                                            <p className="text-secondary companynamesize">{t('address2')}
                                                <p className="text-black fs-6">1791 Yorkshire Circle Kitty Hawk, NC 279499</p></p>
                                            <p className="text-secondary companynamesize">{t('cityTown')}
                                                <p className="text-black fs-6">Denver</p></p>
                                            <p className="text-secondary companynamesize">{t('pincode')}
                                                <p className="text-black fs-6">80014</p></p>
                                            <p className="text-secondary companynamesize">{t('country')}
                                                <p className="text-black fs-6">US</p></p>
                                            <p className="text-secondary companynamesize">{t('phoneNumber')}
                                                <p className="text-black fs-6">+44 5595 6969</p></p>
                                            <p className="text-secondary companynamesize">{t('fax')}
                                                <p className="text-black fs-6">Lorem Ipsum</p></p>
                                            <div className="row">
                                                <div className="col-5">
                                                    <button class="btn btn-secondary btn1cancel" type="button" onClick={back}>{t('back')}</button>

                                                </div>

                                                <div className="col-5">
                                                    <button class="btn btn-pri btn2edit" onClick={() => { TabChange("Address_edit") }} type="button">Edit</button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                })()}
                            </div>
                        </div>
                        <div className="row mt-5">
                            <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample">
                                <div className="card editaddcard"><p className="fw-bold fs-5 mt-2">{t('tax')}</p>  </div>
                            </button>
                            <div class="collapse" id="collapseExample1">
                                {(() => {
                                    if (type_tax == "tax_edit") {
                                        return <div>
                                            <div className='row mt-3'>
                                                <div className='col ms-1'>
                                                    <div class="input-group ms-5 ">
                                                        <input type="text" class=" ms-3 _textwork mt-3" placeholder="Symplefy" aria-label="Username" />
                                                    </div>
                                                    <p class='textwork1_ text-center'> Comapny Legal Name</p>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col mt-5'>
                                                    <div class="mt-3">
                                                        <input type="address" class="form-control inputcolor  txtaddr tabtext1 bg-light" placeholder="Company Tax Code" id="exampleFormControlInput1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-3">
                                                    <button class="btn btn-secondary company_btn" type="button" onClick={alet}>{t('cancel')}</button>
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-3">

                                                    <button class="btn btn-pri company_btn1" type="button" onClick={save}>{t('update')}</button>

                                                </div>
                                            </div>
                                        </div>

                                    } else {
                                        return <div className="row mobeditpag">
                                            <p className="text-secondary companynamesize mt-5">Company Legal Name
                                                <p className="text-black fs-6">AZ Company</p></p>
                                            <p className="text-secondary companynamesize">Company Tax Code
                                                <p className="text-black fs-6">Lorem Ipsum</p></p>

                                            <div className="row">
                                                <div className="col-5">
                                                    <button class="btn btn-secondary btn2cancel" type="button" onClick={back}>{t('back')}</button>

                                                </div>

                                                <div className="col-5">
                                                    <button class="btn btn-pri btn3edit " onClick={() => { TabChange1("tax_edit") }} type="button">Edit</button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                })()}
                            </div>
                        </div>
                        <div className="row mt-5">
                            <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample">
                                <div className="card editaddcard"><p className="fw-bold fs-5 mt-2">Bank</p>  </div>
                            </button>
                            <div class="collapse" id="collapseExample2">
                                {(() => {
                                    if (type_bank == "bank_edit") {
                                        return <div>
                                            <div className='row mt-3'>
                                                <div className='col ms-1'>
                                                    <select class="form-select inputcolor text-start text-secondary txtaddr tabtext1 mt-5 bankborder" aria-label=" select Day">
                                                        <option selected>Symplefy </option>
                                                        <option value="1">other</option>
                                                        <option value="2"></option>
                                                        <option value="3"></option>
                                                    </select>
                                                    <p class='textwork2_ text-center'> Bank Account</p>
                                                </div>
                                            </div>
                                            <div className='row mt-5'>
                                                <div className="col-3">
                                                    <button class="btn btn-secondary company_btn" type="button" onClick={alet}>{t('cancel')}</button>
                                                </div>
                                                <div className="col-1"></div>
                                                <div className="col-3">

                                                    <button class="btn btn-pri company_btn1" type="button" onClick={save}>{t('update')}</button>

                                                </div>
                                            </div>
                                        </div>
                                    } else {
                                        return <div className="row mobeditpag">
                                            <p className="text-secondary companynamesize mt-5">Bank Account
                                                <p className="text-black fs-6">American Express-63337833398333230</p></p>


                                            <div className="row">
                                                <div className="col-5">
                                                    <button class="btn btn-secondary btn2cancel btn2top" type="button" onClick={back}>{t('back')}</button>

                                                </div>

                                                <div className="col-5">
                                                    <button class="btn btn-pri btn3edit btn2top " onClick={() => { TabChange2("bank_edit") }} type="button">Edit</button>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                })()}

                            </div>
                        </div>






                    </Tab>
                </Tabs>
            </div>
            <Lheader />
        </div>
    )

}
export default CompanyDetails;