import { React, useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import Superheader from "./Superheader";
import { useNavigate } from "react-router-dom";
import Superside from "./Superside";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
import { Checkbox } from "antd";
import ReactPaginate from "react-paginate";
import CryptoJS from 'crypto-js';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Superadmincustomers = () => {

    const [company_list, setCompany_list] = useState([])

    const [userid, setuserid] = useState([])


    const Getcompanylist = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/alluser`).then((res) => {
            console.log(res.data, 'success');
            setCompany_list(res.data);

        }).catch(() => {

        })
    }

    useEffect(() => {
        Getcompanylist();

    }, []);

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const [query, SetQuery] = useState("");
    const [payments, setpayment] = useState([])
    const sum = payments.reduce((acc, item) => acc + item.amount, 0);

    const tax = payments.reduce((acc, item) => acc + item.tax, 0);
    const total = sum + tax;
    const Getpayment = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/alluserpayment/${x}`).then((res) => {
            console.log(res.data, 'success');
            setpayment(res.data);

        }).catch(() => {

        })
    }





    // UPDATE costumer trail
    const onUpdate_trail = (x, y) => {
        let demo = {
            user_id: x.user_id,
            trail: true,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/trailstatus/${x.user_id}`, demo).then((res) => {

            console.log('trail period updated', res.data);
            alert("Updated trail status");
            sendEmail(x, y);
            ShowDetails('');
            Getcompanylist();
        }).catch(() => {

            console.log(x, 'x');

        })

    }
    const onUpdate_null = (x, y) => {
        let demo = {
            user_id: x.user_id,
            trail: null,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/trailstatus/${x.user_id}`, demo).then((res) => {

            console.log('trail period updated', res.data);
            alert("Updated trail status");
            sendEmail(x, y);
            ShowDetails('');
            Getcompanylist();

        }).catch(() => {



        })

    }
    const onUpdate_cos = (x, y) => {
        let demo = {
            user_id: x.user_id,
            trail: false,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/trailstatus/${x.user_id}`, demo).then((res) => {

            console.log(res.data, 'trail period updated');
            alert("Updated trail status");
            sendEmail(x, y);
            ShowDetails('');
            Getcompanylist();
        }).catch(() => {



        })

    }
    // send email 
    const sendEmail = (x, y) => {
        console.log(y, 'status');
        const emailData = {

            user_email: `${x.email}`,
            user_name: `${x.first_name}`,
            subject: '',
            message: '',



        };
        if (y == true) {
            emailData.subject = 'Trial period started for your Symplefy account';
        } else if (y == false) {
            emailData.subject = 'Symplefy account has been upgraded';
        } else {
            emailData.subject = 'End of Trial Period for Symplefy Account';
        }

        if (y == true) {
            emailData.message = 'Your Symplefy account has officially started, now have access to all the features and benefits of our platform for 30 days, free of charge.  Thank you for choosing Symplefy as your preferred platform. We look forward to providing you with the best possible service and support.';
        } else if (y == false) {
            emailData.message = 'We would like to inform you that your Symplefy account has been upgraded to the paid version. Thank you for choosing Symplefy as your preferred platform. We look forward to providing you with the best possible service and support.';
        } else {
            emailData.message = 'We regret to inform you that the trial period for your Symplefy account has come to an end. We hope that you were able to take advantage of this opportunity to explore our platform and its features. If you are interested in continuing to use Symplefy, we encourage you to upgrade to a paid version to access all the benefits and features of our platform. Our customer support team is available to assist you with any questions you may have and to help you get started with a paid version. Thank you for considering Symplefy as your preferred platform.';
        }


        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendtrailstatus`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }
    const send_Email = (x, y) => {
        console.log(y, 'status');
        const emailData = {

            user_email: `${x.email}`,
            user_name: `${x.first_name}`,

            message: '',



        };
        if (y == true) {
            emailData.message = 'Your Symplefy Account has been Enabled';
        } else if (y == false) {
            emailData.message = 'Your Symplefy Account has been Disabled';
        }

        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendactivestatus`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }
    // auto update trail period
    const onupdateactive = (x, y) => {

        let demo = {
            user_id: x.user_id,
            active: true,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/activestatus/${x.user_id}`, demo).then((res) => {

            console.log('trail period updated', res.data);
            alert("Updated active status");
            send_Email(x, y)
            ShowDetails('');
            Getcompanylist();
        }).catch(() => {



        })

    }
    const onupdatedisable = (x, y) => {

        let demo = {
            user_id: x.user_id,
            active: false,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/activestatus/${x.user_id}`, demo).then((res) => {

            console.log('trail period updated', res.data);
            alert("Updated trail status ");
            send_Email(x, y);
            ShowDetails('');
            Getcompanylist();
        }).catch(() => {



        })

    }
    let navigate = useNavigate();
    const dues = () => {
        let path = `/superadmindues`;
        navigate(path);
    }

    const pay = (x) => {
        let path = `/payments`;
        navigate(path, { state: { x: x } });
    }

    const [user_type, setUserType] = useState('');
    const [bind_user, setBindUser] = useState({});
    const [active_status, setactive_status] = useState();

    const [active_sstatus, setsactive_status] = useState();
    const ShowDetails = (data, type) => {
        setBindUser(data);
        setUserType(type);
    }
    let currentYear = new Date().getFullYear();
    // disable
    const alet = (x, y) => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Confirm to enable account',
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onupdateactive(x, y),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),

                }
            ]
        })
    }
    const alet1 = (x, y) => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Confirm to disable account',
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onupdatedisable(x, y),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),

                }
            ]
        })
    }
    const showpaid = (x) => {


        return (<div className="row">
            <div className="col-3">

            </div>
            <div className="col-3">
                <p>Payment Completed ON {x.payment_date}</p>
                <p>Amount : {x.amount}</p>
            </div>
        </div>

        )

    }

    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal(true);

    };

    const [messageVisibility, setMessageVisibility] = useState(false);

    const RenderView = () => {

        return (
            <div className="container-fluid row sprender">
                <div className="col-9  col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 posit">

                </div>
                <div className="col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3  bg-white">
                    <div className="row ms-3 mt-4 ">
                        <div className="col-11"></div>
                        <div className="col-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" onClick={() => ShowDetails('')} height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>

                    </div>
                    <div className="row ms-3 mt-4">
                        <small className="text-primary">Name</small>
                        <p>{bind_user.first_name}</p>


                    </div>
                    <div className="row ms-3 mt-2">
                        <small className="text-primary">Email</small>
                        <p className="text-lowercase">{bind_user.email}</p>

                    </div>

                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Company</small>
                        <p>{bind_user.company_name}</p>


                    </div>

                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Order Id</small>
                        <p>{bind_user.order_id}</p>


                    </div>
                    <div className="row ms-3 border-bottom mt-2">
                        <small className="text-secondary">Order Date</small>
                        <p>{moment(bind_user.createdon).format('MMM Do YYYY')}</p>


                    </div>

                    <div className="row ms-3  border-bottom mt-2">
                        <small className="text-secondary">Payment Status</small>
                        <p>Open</p>


                    </div>
                    {/* {payments.map((x, index) => {
                        
                                                            return ( */}
                    <div className="row ms-3  border-bottom mt-2">
                        <small className="text-secondary">Total Paid</small>
                        <p>{Math.round(total).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p>
                        <small className="text-secondary">{t('tax')}</small>
                        <p>{Math.round(tax).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p>


                    </div>
                    {/* )})} */}
                    <div className="row ms-3  mt-2">
                        <div className="col-1">
                            <p className="fw-bold">{currentYear}</p>
                        </div>
                    </div>

                    <div className="row   mt-2">

                        <div className="col-2">
                            <div className="border h-50 w-50 bg-primary mt-2 ms-4"></div>
                        </div>
                        <div className="col-2">
                            <small className="">Paid</small>
                        </div>

                        <div className="col-2">
                            <div className="border h-50 w-50 bg-secondary mt-2 ms-4"></div>
                        </div>
                        <div className="col-3">
                            <small className="">Not-Paid</small>
                        </div>
                        <div className="col-2">
                            <div className="border h-50 w-50 bg-danger mt-2 ms-4"></div>
                        </div>
                        <div className="col-1">
                            <small className="">Due</small>
                        </div>


                    </div>


                    <div className="row ms-3 ">

                        {payments.map((x, index) => {
                            return (<div className="col-2" >
                                {(() => {

                                    if (x.payment_date != null && x.amount > 0) {

                                        return <div className="border bg-primary text-white text-center pointer_"
                                            //  onClick={()=>pay(x)} 
                                            style={{ width: 50, height: 30 }}>{moment(x.payment_date).format('MMM')}</div>

                                    } const nextMonth = () => {
                                        let date = new Date();
                                        let nextMonth = date.getMonth();
                                        date.setMonth(nextMonth);

                                        return date;
                                    };
                                    if (x.payment_date != null && x.amount == 0) {

                                        return <div className="border bg-danger text-white text-center pointer_" style={{ width: 50, height: 30 }}>{moment(x.month).format('MMM')}</div>

                                    }


                                    else {
                                        return <div className="border bg-secondary text-white text-center pointer_" style={{ width: 50, height: 30 }}>{moment(x.month).format('MMM')}</div>

                                    }
                                })()}
                            </div>

                            )
                        })}







                    </div>

                    <div className="row ms-3  mt-3  ">

                        {(() => {
                            if (bind_user.trail == false) {
                                return <div className="col-7 d-grid">
                                    <button className="btn btn-pri" onClick={() => pay(bind_user)}>Payment Details</button>
                                </div>
                            } if (bind_user.trail == true) {
                                return
                            } if (bind_user.trail == false) {

                            }
                        })()}

                    </div>

                    <div className="row ms-3 mt-3 ">
                        <div className="col d-grid">


                            {(() => {
                                if (bind_user.active == true || bind_user.active == null) {
                                    return <p className="text-success"> Current Status :Active </p>

                                } if (bind_user.active == false) {
                                    return <p className="text-danger">Current Status :In-Active </p>

                                }
                            })()}
                        </div>
                        <small className="text-secondary">Change Account  Status</small>
                        <select class="text-center text-white  rounded-pill btn btn-primary bgbtn   mb-3" onChange={(e) => setactive_status(e.target.value)} aria-label=" select Day">
                            <option selected> Change Status </option>
                            <option value='1'>Active</option>
                            <option value='0'>InActive</option>


                        </select>
                        {(() => {
                            if (active_status == 1) {
                                return <button className='btn btn-pri' onClick={() => alet(bind_user, true)}>{t('update')}</button>


                            } if (active_status == 0) {
                                return <button className='btn btn-pri' onClick={() => alet1(bind_user, false)} >update</button>


                            }
                            else {
                                return
                            }
                        })()}
                        <small className="text-secondary">Change Trail Status</small>
                        <select class="text-center text-white btn btn-primary bgbtn rounded-pill   mb-3" onChange={(e) => setsactive_status(e.target.value)} aria-label=" select Day">
                            <option selected> Change Status </option>

                            <option value='2'>Enable Trail</option>
                            <option value='3'>Disable Trail</option>
                            <option value='4'>Paid Costumer</option>

                        </select>
                        {(() => {
                            if (active_sstatus == 2) {
                                return <button className='btn btn-pri' onClick={() => onUpdate_trail(bind_user, true)}>update</button>


                            } if (active_sstatus == 3) {
                                return <button className='btn btn-pri' onClick={() => onUpdate_null(bind_user, null)} >update</button>


                            } if (active_sstatus == 4) {
                                return <button className='btn btn-pri' onClick={() => onUpdate_cos(bind_user, false)}>update</button>


                            }
                            else {
                                return
                            }
                        })()}



                    </div>
                    <div className="row ms-3  mt-3  ">
                        <div className="col-7 d-grid">

                        </div>


                    </div>
                </div>
            </div>
        )
    }


    const currentDate = new Date();
    const currentDateTime = currentDate.toLocaleString();

    //download pdf
    const generatePDF = (data) => {
        console.log(data, 'pdf');
        const doc = new jsPDF('p', 'mm', 'a4');
        //const cmpname = data[0].company_name;
        doc.setFontSize(12);

        doc.text('Customer List Report', 15, 16);

        doc.setFontSize(8);
        // Add symplefyimage
        const logoDataUrl = 'Images/logoql.png'; 
        const logoWidth = 40; 
        const logoHeight = 25; 
        doc.addImage(logoDataUrl, 'PNG', 157, 1, logoWidth, logoHeight);

        //doc.text(`${startdate} to ${endate}`, 15, 21);
        // add company name
        //company address
        doc.setFontSize(9);
        //doc.text(cmpname, 140, 16);
        // doc.text('Company Address', 140, 16);

        doc.setFontSize(11);
        //doc.text(data[0].company_address1, 140, 21);
        //doc.text(data[0].company_address2, 140, 26);

        const tableColumn = ['No', 'Order ID', 'Customer', 'Date', 'Email', 'Type', 'Price', 'Status'];
        const tableRows = [];
        // push data to table rows
        data.forEach((item, index) => {
            const trialStatus = (() => {
                if (item.trail == false) {
                    return 'Subscribed';
                } else if (item.trail == true) {
                    return 'Trail Period';
                } else if (item.trail == null) {
                    return 'Expired';
                }
                return '';
            })();
            const amount = (() => {
                if (item.amount != null) {
                    return item.amount;
                } else if (item.amount == null || item.amount == undefined && item.trail == false) {
                    return '-';
                } else if (item.trail == true) {
                    return 'Trail';
                }
                return '';
            })();
            const status = (() => {
                if (item.active == false) {
                    return 'In-Active';
                } else if (item.active == true) {
                    return 'Active';
                } else if (item.active == null) {
                    return 'In-Active';
                }
                return '';
            })();
            const dataRow = [
                index + 1,
                item.order_id,
                item.first_name,
                moment(item.createdon).format('DD/MM/YYYY'),
                item.email,
                trialStatus,
                amount,
                status
            ];

            tableRows.push(dataRow);

        });


        const downl = [`Downloaded On: ${currentDateTime}`];
        const addPageContent = function (data) {

            // get current page number and total number of pages

            const pageCount = doc.internal.getNumberOfPages();
            const pageNumber = data.pageNumber;


            // set font size and style for download message
            doc.setFontSize(8);

            // add download message and page number to bottom of page
            doc.text(downl, data.settings.margin.left, doc.internal.pageSize.height - 10);
            doc.text(`Page ${pageNumber} of ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 5);
        };

        // add the table to the PDF
        const tableHeight = doc.autoTable.previous.finalY + 10;
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            height: tableHeight,
            didParseCell: (data) => {
                const rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = 'normal';
                }
            },
            addPageContent: addPageContent
        });



        // save the PDF file
        // doc.save('report.pdf');
        doc.save(`customerreport.pdf`);
    }

    // download excel
    const generateCSV = (data) => {
        const headers = ['Customer List Report'];

        //const dateRange = [`Report Range: ${startdate} to ${endate}`];
        //const cmpname = data[0].company_name;
        //const companyName = [`Company Name: ${cmpname}`];

        const downl = [`Downloaded ON: ${currentDateTime}`];
        // define the CSV column headers
        const fieldHeaders = ['No', 'Order ID', 'Customer', 'Date', 'Email', 'Type', 'Price', 'Status'];
        // create a CSV string from the data
        let csv = '';

        [headers, downl, fieldHeaders].forEach((row) => {

            csv += row.join(',') + '\n';

        });
        // iterate over data and add each row to CSV string
        let serialNo = 1;
        data.forEach(item => {
            const trialStatus = (() => {
                if (item.trail == false) {
                    return 'Subscribed';
                } else if (item.trail == true) {
                    return 'Trail Period';
                } else if (item.trail == null) {
                    return 'Expired';
                }
                return '';
            })();
            const amount = (() => {
                if (item.amount != null) {
                    return item.amount;
                } else if (item.amount == null || item.amount == undefined && item.trail == false) {
                    return '-';
                } else if (item.trail == true) {
                    return 'Trail';
                }
                return '';
            })();
            const status = (() => {
                if (item.active == false) {
                    return 'In-Active';
                } else if (item.active == true) {
                    return 'Active';
                } else if (item.active == null) {
                    return 'In-Active';
                }
                return '';
            })();
            const dataRow = [
                serialNo,
                item.order_id,
                item.first_name,
                moment(item.createdon).format('DD/MM/YYYY'),
                item.email,
                trialStatus,
                amount,
                status
            ];
            csv += dataRow.join(',') + '\n';
            serialNo++;
        });

        

        // initiate file download
        // const filename = 'report.csv';
        const filename = `customerreport.csv`;
        const blob = new Blob([csv], { type: 'text/csv' });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    };



    // alert  

    const aletdown = (x) => {
        confirmAlert({

            message: t('select_format_to_download_report'),
            buttons: [
                {
                    label: t('pdf'),
                    className: 'btn-pri btn',
                    onClick: () => generatePDF(x)
                },
                {
                    label: t('excel'),
                    className: 'btn-pri btn',
                    onClick: () => generateCSV(x)
                }
            ]
        })
    }

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 9;
    const pagesVisited = pageNumber * usersPerPage;


    const filteredUsers = company_list.filter((x) => x.first_name.toLowerCase().includes(query.toLowerCase()));

    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);


    const pageCount = Math.ceil(company_list.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (<div>
        <div className=" vh-100 row container-fluid">
            <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 ">

            </div>

            <div className="col-10">

                <input type="text" class=" form-control grey mt-4 ms-5 searchbox " placeholder="Search by Customer Name"
                    onChange={(event) => { SetQuery(event.target.value); }} id="" aria-label="Username" /> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>

                <div className="row mt-3">

                    <div className="col-1 ms-5 ">
                        <p className="fnt-clr ms-2 fw-bold border-bottom border-primary border-4 text-center">ALL</p>
                    </div>
                    <div className="col-1 ">
                        <p className="fnt-clr text-center pointer_" onClick={dues}>Dues</p>
                    </div>
                    <div className="col-2 ">
                    </div>
                    <div className="col-4 ms-4">

                    </div>

                    <div className="col-2">
                        <input className="form-control inputcolor ms-2" type='date'></input>
                    </div>
                </div>

                <div className="row ms-1">
                    <div className="card Card_1task mt-4 ms-5">
                        <div className="row mt-3">

                            <div className="col-2 d-grid" >
                            </div>
                            <div className="col-6 d-grid" >
                            </div>
                            <div className="col-2 d-grid" >
                            </div>
                            <div className="col-2 " >
                                <button type="submit" className="btn btn-pri ms-2 " onClick={() => aletdown(company_list)}>Download Report</button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">

                                <div className="col">
                                    <div className='row'>

                                        <div className='col'>

                                            <table class="table table-bordered mt-2">
                                                <table class="table">
                                                    <thead>
                                                        <tr>  <td scope="col"></td>

                                                            <td scope="col" className='fs-6  grey fw-bold'>Order ID</td>

                                                            <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Customer</td>
                                                            <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Date  </td>
                                                            <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'> Email  </td>
                                                            {/* <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Status </td> */}
                                                            <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'> Type  </td>
                                                            <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Price </td>
                                                            <td scope="col" className='fs-6 grey  fw-bold mobsimplytext'>Status</td>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {displayUsers.filter((x) => x.first_name.toLowerCase().includes(query)).map((x, index) => {

                                                            return (
                                                                <tr>
                                                                    <th scope="row"></th>

                                                                    <td className=''><p>{x.order_id}</p></td>
                                                                    <td className='pointer_'><p onClick={() => (ShowDetails(x, 'view'), Getpayment(x.user_id))}>{x.first_name}</p></td>
                                                                    <td className=''><p>{moment(x.createdon).format('MMM Do YYYY')}</p></td>
                                                                    <td className='text-lowercase'><p className="text-lowercase" >{x.email}</p></td>


                                                                    <td className=''>{(() => {
                                                                        if (x.trail == false) {
                                                                            return <p className="text-success">Subscribed</p>
                                                                        } if (x.trail == true) {
                                                                            return <p className="text-warning" >Trail Period</p>
                                                                        } if (x.trail == null) {
                                                                            return <p className="text-danger">Expired</p>
                                                                        }
                                                                    })()}</td>
                                                                    <td className='fw-bold'>
                                                                        {(() => {
                                                                            if (x.amount != null) {
                                                                                return <p className="text-success">{Math.round(x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p>
                                                                            } if (x.amount == null || x.amount == undefined && x.trail == false) {
                                                                                return <p className="text-danger" >-</p>
                                                                            }
                                                                            if (x.trail == true) {
                                                                                return <p className="text-warning" >Trail</p>
                                                                            }
                                                                        })()}
                                                                    </td>
                                                                    <td>
                                                                        {(() => {
                                                                            if (x.active == false) {
                                                                                return <p className="text-success">In-Active</p>
                                                                            } if (x.active == true) {
                                                                                return <p className="text-success" >Active</p>
                                                                            } if (x.active == null) {
                                                                                return <p className="text-danger">In-Active</p>
                                                                            }
                                                                        })()}
                                                                    </td>

                                                                    {/* <td className=''><svg id="dropdownMenuButton1 " data-bs-toggle="dropdown" aria-expanded="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="grey" class="bi  bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />

                                                                    </svg></td> */}

                                                                </tr>
                                                            )
                                                        })}


                                                    </tbody>

                                                </table>
                                            </table>
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Add New  */}



                    {/* Filter */}
                    <div class="modal fade mt-5" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable float-end w-25 ">
                            <div class="modal-content ">
                                <div class="modal-header">
                                    <h7 class="modal-title fw-bold fs-6 " id="exampleModalLabel">Filter</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body ">
                                    <p className="text-secondary">All</p>
                                    <p className="text-secondary">Pending</p>
                                    <p className="text-secondary">In Transit</p>
                                    <p className="text-secondary">Delayed</p>
                                    <p className="text-secondary">Delivered</p>
                                    <p className="text-secondary">Canceled</p>
                                    <div className='row mt-4'>

                                        <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-grid'>
                                            <button type="button" class="btn btn-pri">Filter</button>
                                        </div>
                                        <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






            </div>


        </div>
        <div>{(() => {
            switch (user_type) {
                case "view": return RenderView();
                default: return "";
            }
        })()}</div>

    </div>

    )
}
export default Superadmincustomers