// GeofenceComponent.js
// import React, { useState, useEffect } from 'react';
// const GeofenceComponent = () => {
  
//   const calculateDistance = (point1, point2) => {
//     // Your existing code for calculating distance
//     const radianConversion = Math.PI / 180;
//     const R = 6371; // Earth radius in kilometers

//     const lat1 = point1.latitude * radianConversion;
//     const lon1 = point1.longitude * radianConversion;
//     const lat2 = point2.latitude * radianConversion;
//     const lon2 = point2.longitude * radianConversion;

//     const dLat = lat2 - lat1;
//     const dLon = lon2 - lon1;

//     const a =
//       Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//       Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

//     const distance = R * c * 1000; // in meters
//     return distance;
//   };

//   return (
//     <div className="row">
//       <div className="col-6">
//         <div className="mt-3">
//           <label>
//             Latitude:
//             <input
//               type="number"
//               className="form-control"
//               name="latitude"
//               value={geofence.latitude}
//               onChange={handleInputChange}
//             />
//           </label>
//         </div>
//         <div className="mt-3">
//           <label>
//             Longitude:
//             <input
//               type="number"
//               className="form-control"
//               name="longitude"
//               value={geofence.longitude}
//               onChange={handleInputChange}
//             />
//           </label>
//         </div>
//         <div className="mt-3">
//           <label>
//             Radius (meters):
//             <input
//               type="number"
//               className="form-control"
//               name="radius"
//               value={geofence.radius}
//               onChange={handleInputChange}
//             />
//           </label>
//         </div>
//         <p>{isInsideGeofence ? 'Inside Geofence' : 'Outside Geofence'}</p>
//       </div>
//       {/* Your existing map display code */}
//     </div>
//   );
// };


// export default GeofenceComponent;

// Example: LocationSelection.js

import React, { useState, useRef, useEffect } from 'react';
import { LoadScript, Autocomplete, GoogleMap, Marker, Circle } from '@react-google-maps/api';
import axios from 'axios';

const GeofenceComponent = () => {
  const api = 'AIzaSyDnLeChdkHjdcB8cwxMLj61U_6j2KOZUwY';
  const [selectedPlace, setSelectedPlace] = useState('');
  const [selectedPlaceCoordinates, setSelectedPlaceCoordinates] = useState(null);
  const [radius, setRadius] = useState(100); // Default radius in meters
  const [recordedLocations, setRecordedLocations] = useState([]);
  const [message, setMessage] = useState('');

  const autocompleteRef = useRef(null);

  const onAutocompleteLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();

    if (place.geometry) {
      setSelectedPlace(place.formatted_address);
      setSelectedPlaceCoordinates({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  const onMarkerDragEnd = (e) => {
    setSelectedPlaceCoordinates({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };




  



  const center = selectedPlaceCoordinates || { lat: 0, lng: 0 };

  // useEffect(() => {
  //   // Display an alert when a location is recorded
  //   if (recordedLocations.length > 0) {
  //     alert(recordedLocations.lat,'Location recorded!');
  //     console.log(recordedLocations);
  //   }
  // }, [recordedLocations]);

  const [isInsideGeofence, setIsInsideGeofence] = useState(false);
  // const [geofence, setGeofence] = useState([]);

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setGeofence((prevGeofence) => ({
  //     ...prevGeofence,
  //     [name]: value,
  //   }));
  // };

  // useEffect(() => {
  //   // Get user's current position when the component mounts
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const userLocation = {
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude,
  //       };

  //       setGeofence({
  //         ...geofence,
  //         latitude: userLocation.latitude,
  //         longitude: userLocation.longitude,
  //       });

  //       // const distance = calculateDistance(userLocation, geofence);
  //       // setIsInsideGeofence(distance <= geofence.radius);
  //     },
  //     (error) => {
  //       console.error('Error getting location:', error);
  //     },
  //     {
  //       enableHighAccuracy: true,
  //       timeout: 5000,
  //       maximumAge: 0,
  //     }
  //   );
  // }, []); // Empty dependency array to ensure the effect runs only once

  const [geofence, setGeofence] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [currentPlace, setCurrentPlace] = useState(null);

  useEffect(() => {
    const fetchCurrentLocationAndPlace = async () => {
      try {
        // Fetch user's current position using Google Geolocation API
        const geoResponse = await fetch(
          `https://www.googleapis.com/geolocation/v1/geolocate?key=${api}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ considerIp: true }),
          }
        );

        if (!geoResponse.ok) {
          throw new Error('Failed to fetch location information');
        }

        const geoData = await geoResponse.json();
        const { location } = geoData;

        // Use Google Places API to reverse geocode coordinates to get place information
        const placeResponse = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${api}`
        );

        if (!placeResponse.ok) {
          throw new Error('Failed to fetch place information');
        }

        const placeData = await placeResponse.json();
        if (placeData.results && placeData.results.length > 0) {
          const placeName = placeData.results[0].formatted_address;
          setCurrentPlace(placeName);
        }
      } catch (error) {
        console.error('Error fetching location or place:', error);
      }
    };

    fetchCurrentLocationAndPlace();
  }, []);


  const recordCurrentLocation = () => {
    if (geofence) {
      setRecordedLocations((prevLocations) => [...prevLocations, geofence]);
      console.log(recordedLocations,'rec')
    }
    // const iswithinRadius = (() => {
      // const distance = isWithinRadius();
      const isWithinRadius = () => {
        const distance = haversine(
          selectedPlaceCoordinates,geofence
        );
    
        return distance >= 100;
      };
      const distance = isWithinRadius();
      // console.log(distance,radius,'cord')
      // return distance;
    // });

    // Set the message based on the check
    setMessage(
      distance
        ? 'You are outside the selected location radius.'
        : 'You are  inside the selected location radius.'
    );
  };

  const haversine = (selectedPlaceCoordinates, geofence) => {
    // Convert latitude and longitude from degrees to radians
    const toRadians = (angle) => (angle * Math.PI) / 180;
    const lat1Rad = toRadians(selectedPlaceCoordinates.lat);
    const lon1Rad = toRadians(selectedPlaceCoordinates.lng);
    const lat2Rad = toRadians(geofence.latitude);
    const lon2Rad = toRadians(geofence.longitude);

    // Haversine formula
    const dlat = lat2Rad - lat1Rad;
    const dlon = lon2Rad - lon1Rad;

    const a =
      Math.sin(dlat / 2) ** 2 +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Radius of the Earth in meters (assuming a spherical Earth)
    const radiusEarth = 6371000;

    // Calculate the distance
    const distance = radiusEarth * c;
console.log(distance,'distance');
    return distance;
  };


  useEffect(() => {
    const options = {
      enableHighAccuracy: true, // Request high accuracy
      timeout: 5000, // Maximum time allowed for obtaining the position
      maximumAge: 0 // Maximum age of the cached position
    };

    const success = (position) => {
      const { latitude, longitude } = position.coords;
      setGeofence({ latitude, longitude });
    };

    const handleError = (error) => {
      // setError(error.message);
    };

    navigator.geolocation.getCurrentPosition(success, handleError, options);

  }, []);
  
  // const isWithinRadius = (coord1, coord2, radius) => {
  //   // Convert latitude and longitude from degrees to radians
  //   console.log(coord1, coord2, radius,'cords')
  //   const φ1 = (coord1.lat * Math.PI) / 180;
  //   const φ2 = (coord2.latitude * Math.PI) / 180;
  
  //   // Differences in latitude and longitude
  //   const Δφ = ((coord2.latitude - coord1.lat) * Math.PI) / 180;
  //   const Δλ = ((coord2.longitude - coord1.lng) * Math.PI) / 180;
  
  //   // Haversine formula to calculate distance
   

  //   const a =
  //     Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
  //     Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
  //   // Distance in meters
  //   const distance = 6371e3 * c;
  
  //   // Check if the distance is within the specified radius
  //   console.log('φ1:', φ1);
  //   console.log('φ2:', φ2);
  //   console.log('Δφ:', Δφ);
  //   console.log('Δλ:', Δλ);
  //   console.log('Distance:', distance);
  //   return distance >= radius;
  // };
  // const handleShowLocation = () => {
  //   console.log('Selected location:', selectedPlaceCoordinates);

  //   // Check if the selected location is within the radius of any recorded location
  //   const isWithinRadius = recordedLocations.some((location) => {
  //     const distance = calculateDistance(selectedPlaceCoordinates, location);
  //     return distance <= radius;
  //   });

  //   // Set the message based on the check
  //   setMessage(
  //     isWithinRadius
  //       ? 'You are inside the selected location radius.'
  //       : 'You are outside the selected location radius.'
  //   );
  // };
  return (
    <div className="row">
      <div className="col-3"></div>
      <div className="col-8">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-4">
            <LoadScript googleMapsApiKey={api} libraries={['places']} loading="async">
              <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                <input
                  type="text"
                  className="form-control mt-5"
                  value={selectedPlace}
                  onChange={(e) => setSelectedPlace(e.target.value)}
                  placeholder="Search for a place"
                />
              </Autocomplete>
            </LoadScript>
            {/* <button className="mt-3 btn btn-primary" onClick={handleShowLocation}>
              Show Selected Location
            </button> */}
            <button className="mt-3 btn btn-success" onClick={()=>recordCurrentLocation()}>
              Record Current Location
            </button>
            <input
              className="form-control mt-3"
              disabled
              value={selectedPlaceCoordinates ? `Lat: ${selectedPlaceCoordinates.lat}, Lng: ${selectedPlaceCoordinates.lng}` : ''}
            ></input>

            <select
              className="form-select mt-3"
              value={radius}
              onChange={(e) => setRadius(Number(e.target.value))}
            >
              <option value={50}>50 meter</option>
              <option value={100}>100 meter</option>
              <option value={200}>200 meter</option>
            </select>
            {/* <p>Current Latitude: {geofence.latitude}</p>
      <p>Current Longitude: {geofence.longitude}</p> */}
      
      <p>Current Place: {currentPlace || 'Loading...'}</p>
            {/* Display the message */}
            <p className="mt-3">{message}</p>
      <p> {geofence.latitude}</p>
            {/* <p>{isInsideGeofence ? 'Inside Geofence' : 'Outside Geofence'}</p> */}
    
          </div>
          <div className="col-4">
            <LoadScript googleMapsApiKey={api} libraries={['places']} loading="async">
              <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={{ width: '100%', height: '400px' }}
              >
                {selectedPlaceCoordinates && (
                  <>
                    <Marker
                      position={selectedPlaceCoordinates}
                      draggable={true}
                      onDragEnd={onMarkerDragEnd}
                    />
                    <Circle
                      center={selectedPlaceCoordinates}
                      radius={radius}
                      options={{
                        fillColor: '#007BFF',
                        fillOpacity: 0.2,
                        strokeColor: '#007BFF',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                      }}
                    />
                  </>
                )}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeofenceComponent;


