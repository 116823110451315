
import React, { useCallback, useState, useEffect, useContext } from 'react'
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { store } from '../../store/user';
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const Empleave = () => {
    const { state } = useContext(store);
    console.log('payslip', state.user);

    // const handleDownloadPdf = async () => {
    //     const element = printRef.current;
    //     const canvas = await html2canvas(element);
    //     const data = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF();
    //     const imgProperties = pdf.getImageProperties(data);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight =
    //         (imgProperties.height * pdfWidth) / imgProperties.width / 1.5;
    //     pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //     pdf.save('Payslip.pdf');

    // };
    const printRef = React.useRef();

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const handleDownloadPdf = () => {
        const element = printRef.current;

        html2canvas(element, { useCORS: true, scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();

            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = canvas.width;
            const imgHeight = canvas.height;
            const ratio = imgWidth / imgHeight;

            let pdfHeight = pageHeight;
            let position = 0;

            while (position < imgHeight) {
                const pdfWidth = pdfHeight * ratio;

                // Check if the content fits on the current page
                if (position + pdfHeight > imgHeight) {
                    pdfHeight = imgHeight - position;
                }

                // Add a new page if the content doesn't fit on the current page
                if (pdfHeight > pageHeight) {
                    pdf.addPage([pdfWidth, pdfHeight]);
                    pdfHeight = pageHeight;
                }

                pdf.addImage(imgData, 'PNG', (pageWidth - pdfWidth) / 2, position, pdfWidth, pdfHeight, undefined, 'FAST', false, { align: 'center' });

                position += pdfHeight;
            }

            pdf.save('Payslip.pdf');
        });
    };

    useEffect(() => {
        Getpaytotal();
    },
        [state]);

    const [paytotal, setPaytotal] = useState([]);
    const Getpaytotal = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/empallpay/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setPaytotal(res.data);
            }).catch(() => {

            })
        }
    }
    const [paysliptotal, setPaysliptotal] = useState([]);
    const Getpaysliptotal = (payroll_cycle_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/employeepayslip/${state?.user[0]?.emp_id}/${payroll_cycle_id}`).then((res) => {
                console.log(res.data, 'payslip');
                setPaysliptotal(res.data);
            }).catch(() => {

            })
        }
    }

    let navigate = useNavigate();
    const employeedashboard = () => {
        let path = `/employeedashboard`;
        navigate(path);
    }
    const supervisordashboard = () => {
        let path = `/supervisordashboard`;
        navigate(path);
    }

    const [bind_user, setBindUser] = useState({});
    const [user_type, setUserType] = useState('');
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setUserType(type);
    }




    const payslip = () => {

        {
            paysliptotal.map((x, index) => {

                let earningstypeArray = [];
                if (x.earningscomponents != null || x.earningscomponents != undefined) {
                    earningstypeArray = x.earningscomponents.split(",");
                } else {
                    earningstypeArray = [""];
                }
                let earningsatypeArray = [];
                if (x.earningsamounts != null || x.earningsamounts != undefined) {
                    earningsatypeArray = x.earningsamounts.split(",");
                } else {
                    earningsatypeArray = [""]
                }
                let deductiontypeArray = [];
                if (x.deductionscomponents != null || x.deductionscomponents != undefined) {
                    deductiontypeArray = x.deductionscomponents.split(",");
                } else {
                    deductiontypeArray = [""]
                }
                let deductionamtypeArray = [];
                if (x.deductionsamounts != null || x.deductionsamounts != undefined) {
                    deductionamtypeArray = x.deductionsamounts.split(",");
                } else {
                    deductionamtypeArray = [""]
                }
                let nontaxableearningstypeArray = [];
                if (x.nontaxableearningscomponents == null || x.nontaxableearningscomponents == undefined) {
                    nontaxableearningstypeArray = [""]
                } else {
                    nontaxableearningstypeArray = x.nontaxableearningscomponents.split(",");
                }
                let nontaxableearningsamtypeArray = [];
                if (x.nontaxableearningsamounts != null || x.nontaxableearningsamounts != undefined) {
                    nontaxableearningsamtypeArray = x.nontaxableearningsamounts.split(",");
                } else {
                    nontaxableearningsamtypeArray = [""];
                }
                let nontaxabledeductionstypeArray = [];
                if (x.nontaxabledeductioncomponents != null || x.nontaxabledeductioncomponents != undefined) {
                    nontaxabledeductionstypeArray = x.nontaxabledeductioncomponents.split(",");
                } else {
                    nontaxabledeductionstypeArray = [""];
                }
                let nontaxabledeductionsamtypeArray = [];
                if (x.nontaxabledeductionamounts != null || x.nontaxabledeductionamounts != undefined) {
                    nontaxabledeductionsamtypeArray = x.nontaxabledeductionamounts.split(",");
                } else {
                    nontaxabledeductionsamtypeArray = [""];
                }

                // Map over the earningstype array and create a new array with each item in a separate line
                const earningstypeLines = earningstypeArray.map((earning) => {
                    return <div key={earning}>{earning}</div>;
                });
                const earningsam = earningsatypeArray.map((earning) => {
                    return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
                });
                const deductiontype = deductiontypeArray.map((earning) => {
                    return <div key={earning}>{earning}</div>;
                });
                const deductionam = deductionamtypeArray.map((earning) => {
                    return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
                });
                const nontaxableearningstypeLines = nontaxableearningstypeArray.map((earning) => {
                    return <div key={earning}>{earning}</div>;
                });
                const nontaxableearningsam = nontaxableearningsamtypeArray.map((earning) => {
                    return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
                });
                const nontaxabledeductiontype = nontaxabledeductionstypeArray.map((earning) => {
                    return <div key={earning}>{earning}</div>;
                });
                const nontaxabledeductionam = nontaxabledeductionsamtypeArray.map((earning) => {
                    return <div key={earning}>{Number(earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</div>;
                });
                return (
                    confirmAlert({


                        message: <div className='row downsize downmob1'>

                            <div className='row downmob' ref={printRef} >
                                <div className='row'>
                                    <div className='col-4'><img className='img-fluid' src={x.company_logo} ></img></div>

                                    <div className='col-6 ms-4'> <p className=' fw-bold fs-5 text-capitalize'>{x.company_name}
                                        <p className=' fw-normal fs-6 text-capitalize'>{x.company_address1}</p></p></div>

                                </div>
                                <div className='row'>
                                    <div className='col-4'></div>

                                    <div className='col-6 ms-4'> <p className=' fw-bold fs-5 text-capitalize text-black'>Payslip - {x.month} / {moment(x.startdate).format('YYYY')}
                                        <p className=' fw-normal fs-6 text-capitalize ms-5'>{x.fullname}. {x.last_name}</p></p></div>

                                </div>

                                <div className='row border-top'>
                                    <div className='col-6 mt-3'>
                                        <h5>Employee Details</h5>
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td><td>Employee ID</td></td>
                                                    <td>{('000' + x.employeeid).slice(-3)}</td>

                                                </tr>
                                                <tr>

                                                    <td><td>Designation</td></td>
                                                    <td>{x.job_title}</td>

                                                </tr>
                                                <tr>

                                                    <td><td>Passport/National ID</td></td>
                                                    <td>{x.pass_port}</td>


                                                </tr>
                                                <tr>

                                                    <td><td>{t('hireDate')}</td></td>
                                                    <td>{moment(x.hiredate).format('MMM Do YYYY')}</td>
                                                </tr>
                                                <tr>

                                                    <td><td>Location </td></td>
                                                    <td>{x.city}</td>


                                                </tr>
                                                <tr>

                                                    <td><td>{t('labourContractNumber')}</td></td>
                                                    <td>{x.labourcontract}</td>


                                                </tr>

                                                <tr>

                                                    <td><td>Total Worked Days</td></td>
                                                    <td>{x.workeddays}</td>


                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-6 mt-3'>
                                        <h5>Summary</h5>
                                        <table class="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">YTD</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td><td>{t('Gross pay')}</td></td>
                                                    <td>{Math.round(x.grossbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                    <td>{Math.round(x.ytd_grossbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                                </tr>
                                                <tr>

                                                    <td><td>Taxable Earnings</td></td>
                                                    {(() => {
                                                        if (x.taxableamt == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.taxableamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (x.ytd_taxableamt == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.ytd_taxableamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}

                                                </tr>
                                                <tr>

                                                    <td><td>Taxable Deductions</td></td>
                                                    {(() => {
                                                        if (x.grossded == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.grossded).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (x.ytd_grossded == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.ytd_grossded).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}


                                                </tr>
                                                <tr>

                                                    <td><td>{t('personalIncomeTax')}</td></td>
                                                    {(() => {
                                                        if (x.numnon == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.numnon).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (x.ytd_numnon == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.ytd_numnon).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}


                                                </tr>
                                                <tr>

                                                    <td><td>Non-Taxable Earnings</td></td>
                                                    {(() => {
                                                        if (x.totalnontaxable_earning == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (x.ytd_totalnontaxable_earning == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.ytd_totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}

                                                </tr>
                                                <tr>

                                                    <td><td>Non-Taxable Deductions</td></td>
                                                    {(() => {
                                                        if (x.totalnontaxable_deduction == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (x.ytd_totalnontaxable_deduction == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.ytd_totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}


                                                </tr>

                                                <tr>

                                                    <td><td className='fw-bold'>{t('takeHome')}</td></td>
                                                    {(() => {
                                                        if (x.takehomepay == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.takehomepay).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (x.ytd_takehomepay == null) {
                                                            return <td className='calicn mobsimplytext'>0</td>
                                                        } else {
                                                            return <td className='calicn mobsimplytext'>{Math.round(x.ytd_takehomepay).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        }
                                                    })()}
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <h5>Taxable Earnings</h5>
                                            <table class="table table-bordered mt-4 table-striped ">

                                                <thead>
                                                    <tr>

                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Description</th>
                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Amount</th>
                                                    </tr>
                                                </thead>

                                                <tbody className=''>
                                                    <tr>

                                                        <td><td>{t('Gross pay')}</td></td>
                                                        <td>{Math.round(x.grossbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                                    </tr>
                                                    <tr>

                                                        <td><td>OverTime</td></td>
                                                        <td>{Math.round(x.overtimeamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                                    </tr>
                                                    <tr>

                                                        <td><td className='text-capitalize'>{earningstypeLines}</td></td>
                                                        <td>{earningsam}</td>

                                                    </tr>

                                                    <tr>

                                                        <td><td className='fw-bold'>Total</td></td>
                                                        <td>{Math.round(x.taxableamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                                    </tr>


                                                </tbody>

                                            </table>
                                        </div>
                                        <div className='col-6'>
                                            <h5>Taxable Deductions</h5>
                                            <table class="table table-bordered mt-4 table-striped ">

                                                <thead>
                                                    <tr>

                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Description</th>
                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Amount</th>
                                                    </tr>
                                                </thead>

                                                <tbody className=''>
                                                    <tr>

                                                        <td><td>Compulsory insurances</td></td>
                                                        {(() => {
                                                            if (x.compinsurance == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.compinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}

                                                    </tr>
                                                    <tr>

                                                        <td><td>Personal Dependent Deduction</td></td>
                                                        {(() => {
                                                            if (x.personalddamt == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.personalddamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}

                                                    </tr>
                                                    <tr>

                                                        <td><td className='text-capitalize'>{deductiontype}</td></td>
                                                        <td>{deductionam}</td>

                                                    </tr>

                                                    <tr>

                                                        <td><td> OT, Night Shift, Holiday Premium Deduction</td></td>
                                                        {(() => {
                                                            if (x.otpremium == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.otpremium).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}

                                                    </tr>
                                                    <tr>

                                                        <td><td className='fw-bold'>Total</td></td>
                                                        {(() => {
                                                            if (x.grossded == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.grossded).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}


                                                    </tr>


                                                </tbody>

                                            </table>
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <h5>Non Taxable Earnings</h5>
                                            <table class="table table-bordered mt-4 table-striped ">

                                                <thead>
                                                    <tr>

                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Description</th>
                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Amount</th>
                                                    </tr>
                                                </thead>

                                                <tbody className=''>
                                                    <tr>

                                                        <td><td className='text-capitalize'>{nontaxableearningstypeLines}</td></td>
                                                        <td>{nontaxableearningsam}</td>

                                                    </tr>


                                                    <tr>

                                                        <td><td className='fw-bold'>Total</td></td>
                                                        <td>{Math.round(x.totalnontaxable_earning).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                                    </tr>


                                                </tbody>

                                            </table>
                                        </div>
                                        <div className='col-6'>
                                            <h5>Non Taxable Deductions</h5>
                                            <table class="table table-bordered mt-4 table-striped ">

                                                <thead>
                                                    <tr>

                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Description</th>
                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Amount</th>
                                                    </tr>
                                                </thead>

                                                <tbody className=''>
                                                    <tr>

                                                        <td><td className='text-capitalize'>{nontaxabledeductiontype}</td></td>
                                                        <td>{nontaxabledeductionam}</td>

                                                    </tr>

                                                    <tr>

                                                        <td><td className='fw-bold'>Total</td></td>
                                                        {(() => {
                                                            if (x.totalnontaxable_deduction == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.totalnontaxable_deduction).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}


                                                    </tr>


                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <h5>Employee Contributions</h5>
                                            <table class="table table-bordered mt-4 table-striped ">

                                                <thead>
                                                    <tr>

                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Description</th>
                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Amount</th>
                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>YTD</th>
                                                    </tr>
                                                </thead>

                                                <tbody className=''>
                                                    <tr>

                                                        <td><td>Social Insurance</td></td>
                                                        {(() => {
                                                            if (x.socialins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.socialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (x.ytd_socialins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.ytd_socialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                    </tr>
                                                    <tr>

                                                        <td><td>Health Insurance</td></td>
                                                        {(() => {
                                                            if (x.healthins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.healthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (x.ytd_healthins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.ytd_healthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                    </tr>
                                                    <tr>

                                                        <td><td>Unemployment Insurance</td></td>
                                                        {(() => {
                                                            if (x.unempins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.unempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (x.ytd_unempins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.ytd_unempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                    </tr>

                                                    <tr>

                                                        <td><td className='fw-bold'>Total</td></td>
                                                        {(() => {
                                                            if (x.compinsurance == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.compinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (x.ytd_compinsurance == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.ytd_compinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}

                                                    </tr>


                                                </tbody>

                                            </table>
                                        </div>
                                        <div className='col-6'>
                                            <h5>Employer Contributions</h5>
                                            <table class="table table-bordered mt-4 table-striped ">

                                                <thead>
                                                    <tr>

                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Description</th>
                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>Amount</th>
                                                        <th scope="col" className='fs-6 text-secondary mobsimplytext'>YTD</th>
                                                    </tr>
                                                </thead>

                                                <tbody className=''>
                                                    <tr>

                                                        <td><td>Social Insurance</td></td>
                                                        {(() => {
                                                            if (x.compsocialins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.compsocialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (x.ytd_compsocialins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.ytd_compsocialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                    </tr>
                                                    <tr>

                                                        <td><td>Health Insurance</td></td>
                                                        {(() => {
                                                            if (x.comphealthins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.comphealthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (x.ytd_comphealthins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.ytd_comphealthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                    </tr>
                                                    <tr>

                                                        <td><td>Unemployment Insurance</td></td>
                                                        {(() => {
                                                            if (x.compunempins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.compunempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (x.ytd_compunempins == null) {
                                                                return <td>0</td>
                                                            } else {
                                                                return <td>{Math.round(x.ytd_compunempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                    </tr>

                                                    <tr>

                                                        <td><td className='fw-bold'>Total</td></td>
                                                        {(() => {
                                                            if (x.companycompinsurance == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.companycompinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (x.ytd_companycompinsurance == null) {
                                                                return <td className='calicn mobsimplytext'>0</td>
                                                            } else {
                                                                return <td className='calicn mobsimplytext'>{Math.round(x.ytd_companycompinsurance).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            }
                                                        })()}


                                                    </tr>


                                                </tbody>

                                            </table>
                                        </div>
                                    </div>


                                </div>
                                <p className='text-center text-black fs-6 fw-bold'>This is a Computer-Generated Pay Slip
                                    <p className='text-center text-black'>Signature is not required </p></p>

                            </div>

                            <div className='col'>

                                {/* <button type='submit' onClick={handleDownloadPdf} className='btn btn-pri'>Download</button> */}

                            </div>

                        </div>,
                        buttons: [

                            {
                                label: t('close') ,
                                onClick: () => window.location = "/emppayslip",
                                className: 'Close2',
                            },
                            {
                                label: 'Download',
                                className: 'btn btn-pri',
                                onClick: () => handleDownloadPdf(),
                            },


                        ]
                    })
                );
            })
        }

    }

    return (
        <>

            <div className='bg-light vh-100 container-fluid'>

                <div className='row mt-4'>
                    <div className='col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2  hid_tab '>

                    </div>
                    <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-9 col-lg-10 col-xl-10 bg-light mt-3 col-10 mt-4'>
                        <div class="card taskcard  graphcardwidthmob6 ms-sm-5 shadow mb-5 p-2 bg-white rounded">


                            <div class="card-body">
                                <div className='row'>
                                    <div className='col-10'>
                                        <p className='fw-bold fnt-clr'>{t('paySlip')}</p>
                                    </div>
                                    <div className='col-1 mobpdficon'>


                                    </div>
                                    {/* <div className='col-1'>
                                    <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-3 " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                    </svg>
                                    
                                </div> */}
                                    {(() => {
                                        if (state?.user && state?.user[0]?.usertype == 10) {
                                            return <button type="button" class="btn-close ms-5" onClick={employeedashboard} data-bs-dismiss="modal" aria-label="Close"></button>
                                        }
                                        if (state?.user && state?.user[0]?.usertype == 2) {
                                            return <button type="button" class="btn-close ms-5" onClick={supervisordashboard} data-bs-dismiss="modal" aria-label="Close"></button>
                                        }
                                    })()}

                                </div>

                                <div className='row' ref={printRef}>
                                    <div className='col'>

                                        <div className="row">
                                            <div className="ms-4 mt-2 onlytab">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <table class="table table-bordered mt-4 year-buttonss">
                                            <table class="table table-striped">
                                                <thead className='fw-bold'>
                                                    <tr>
                                                        <td></td>
                                                        <td scope="col" className=' text-center text-uppercase headfont grey'>{t('name')}</td>
                                                        <td scope="col" className=' text-center text-uppercase headfont grey'>Emp Id</td>
                                                        <td scope="col" className=' text-center text-uppercase headfont grey'>{t('month')}</td>
                                                        <td scope="col" className=' text-center text-uppercase headfont grey'>{t('Gross pay')}</td>

                                                        <td scope="col" className=' text-center text-uppercase headfont grey'>{t('incomeTax')}</td>
                                                        <td scope="col" className=' text-center text-uppercase headfont grey'>{t('takeHome')}</td>

                                                    </tr>
                                                </thead>
                                                <tbody>




                                                    {paytotal.map((x, index) => {
                                                        return (

                                                            <tr className='pointer_  '>



                                                                <td></td>
                                                                <td className='text-center'> {x.fullname}</td>

                                                                <td className='text-center'>{('000' + x.emp_id).slice(-3)}</td>
                                                                <td className='text-center'>{x.month}/ {moment(x.startdate).format('YYYY')}</td>
                                                                <td className='text-center'>{Math.round(x.grossbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"} </td>

                                                                <td className=' text-center'>{Math.round(x.numnon).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                <td className=' text-center'>{x.takehomepay.toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                {/* <td className='text-center'>
                                                                    <svg onClick={x.paidstatus ? () => ShowDetails(x, 'edit') : null}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="25"
                                                                        height="25"
                                                                        fill={x.paidstatus ? "green" : "gray"}  // conditionally set fill color
                                                                        className={x.paidstatus ? "bi bi-eye-fill" : "bi bi-eye"}  // conditionally set class name
                                                                        viewBox="0 0 16 16"
                                                                        disabled={!x.paidstatus}  // disable if paidstatus is null or false
                                                                    > */}
                                                                <td className='text-center'>
                                                                    <svg onClick={x.paidstatus ? () => { payslip(); Getpaysliptotal(x.payroll_cycle_id); } : null}
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="25"
                                                                        height="25"
                                                                        // fill={x.paidstatus ? "green" : "gray"}  // conditionally set fill color
                                                                        fill={x.paidstatus ? "#4EB4E6" : "gray"}
                                                                        className={x.paidstatus ? "bi bi-eye-fill" : "bi bi-eye"}  // conditionally set class name
                                                                        viewBox="0 0 16 16"
                                                                        disabled={!x.paidstatus}  // disable if paidstatus is null or false
                                                                    >
                                                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                                    </svg>
                                                                </td>




                                                            </tr>
                                                        );
                                                    })}

                                                </tbody>
                                            </table>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>{(() => {
                        switch (user_type) {
                            //case "view": return RenderView();
                            //case "edit": return RenderEdit();
                            //case "delete": return RenderDelete();
                            default: return "";
                        }
                    })()}</div>
                </div>
                <Lheader />
            </div>
        </>
    )

};

export default Empleave