import React from 'react'
import CryptoJS from 'crypto-js';
import { useEffect, useContext, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { store } from '../../store/user'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { useRef } from 'react';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const Otpverify = () => {

  const [user, setUserId] = useState([]);

  const [otpverified, setverified] = useState();
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const [inputOtp, setInputOtp] = useState('');
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const location = useLocation();
  const { state } = useContext(store);
  const [sndotp, setsndotp] = useState(false);
  console.log('payroll', location?.state?.x[0].login_id);
  const [stdate, startdate] = useState('');
  const [user_details, setuserDetails] = useState([]);




  let navigate = useNavigate();
  const Gosignin = () => {
    //debugger;
    navigate("/signup");
  };
  const gohome = () => {
    //debugger;
    navigate("/admindashboard");
  };
  const gohomes = () => {
    //debugger;
    navigate("/supervisordashboard");
  };
  const goaddress = () => {
    //debugger;
    navigate("/quickguide");
  };
  const gopasswordchange = () => {
    //debugger;
    navigate("/password");
  };
  const goemployee = () => {
    //debugger;
    navigate("/employeedashboard");
  };
  // auto update trail period
  const onUpdateauto_null = (id) => {

    let demo = {
      user_id: id,
      trail: null,
    };
    axios.put(`${process.env.REACT_APP_API_URL}/trailstatus/${id}`, demo).then((res) => {

      console.log('trail period updated', res.data);
      alert("Trail Period is Completed");
      window.location = '/login';
    }).catch(() => {



    })

  }
  const gobill = () => {
   
    navigate("/billing"); // Navigate to billing page
 
};
const currentDate = new Date();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  // Issues in this chage
  // const login = () => {

  //   startdate(location?.state?.x[0].createdon);
  //   const today = new Date();
  //   console.log(location?.state?.x[0].createdon, 'datest')
  //   const otherDate = new Date(location?.state?.x[0].createdon);
  //   const diffTime = Math.abs(today - otherDate);
  //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //   console.log(diffDays, 'datediff')
  //   const data = localStorage.getItem('username');
  //   console.log('logged', data);
  //   if (location?.state?.x[0].trail == true && diffDays > 30) {


  //     onUpdateauto_null(location?.state?.x[0].user_id);


  //   }
  //   else {
  //     console.log(location?.state?.x[0].active, 'location trail');
  //     if (location?.state?.x[0].active == true || location?.state?.x[0].active == null) {
  //       console.log(location?.state?.x[0].trail, 'location trail');
  //       if ((location?.state?.x[0].trail == true || location?.state?.x[0].trail == false)) {


  //         // alert("success");
  //         setuserDetails(location?.state?.x);
  //         localStorage.setItem('user_details', JSON.stringify(location?.state?.x));
  //         localStorage.setItem('username', location?.state?.x[0].loginmail);
  //         setUserId(location?.state?.x[0].login_id);
  //         console.log(location?.state?.x[0].login_id, 'login_id')
  //         dispatch({ type: 'ADD_USER', payload: { user: location?.state?.x } });//context api updated.

  //         if (location?.state?.x[0].passwordgen == true) {


  //           onUpdateauto_null(location?.state?.x[0].user_id);


  //         }

  //         else {
  //           console.log(location?.state?.x[0].active, 'location trail');
  //           if (location?.state?.x[0].active == true || location?.state?.x[0].active == null) {
  //             console.log(location?.state?.x[0].trail, 'location trail');
  //             if ((location?.state?.x[0].trail == true || location?.state?.x[0].trail == false)) {


  //               // alert("success");
  //               setuserDetails(location?.state?.x);
  //               localStorage.setItem('user_details', JSON.stringify(location?.state?.x));
  //               localStorage.setItem('username', location?.state?.x[0].loginmail);
  //               setUserId(location?.state?.x[0].login_id);
  //               console.log(location?.state?.x[0].login_id, 'login_id')
  //               dispatch({ type: 'ADD_USER', payload: { user: location?.state?.x } });//context api updated.

  //               if (location?.state?.x[0].passwordgen == true) {


  //                 gopasswordchange();
  //               }
  //               else {



  //                 if (location?.state?.x[0].admin == true && location?.state?.x[0].company == false) {

  //                   goaddress();
  //                 }
  //                 if (location?.state?.x[0].admin == true && location?.state?.x[0].company == true) {

  //                   gohome();
  //                 }
  //                 if (location?.state?.x[0].admin == false && location?.state?.x[0].company == true && location?.state?.x[0].usertypename == 'HR Manager') {

  //                   gohome();
  //                 }
  //                 if (location?.state?.x[0].admin == false && location?.state?.x[0].company == true && location?.state?.x[0].usertypename == 'Supervisor') {

  //                   gohomes();
  //                 }
  //                 if (location?.state?.x[0].admin == false && location?.state?.x[0].company == true && location?.state?.x[0].usertypename == 'Employee') {

  //                   goemployee();
  //                 }
  //               }
  //             }
  //             else {
  //               alert("Trail Period Completed Contact Admin");
  //             }
  //           }
  //           else {
  //             alert("Account Has been Blocked");
  //           }

  //         }
  //       }
  //     }
  //   }

  // }

  const login = () => {

    startdate(location?.state?.x[0].createdon);
    const today = new Date();
    console.log(location?.state?.x[0].createdon, 'datest')
    const otherDate = new Date(location?.state?.x[0].createdon);
    const diffTime = Math.abs(today - otherDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(diffDays, 'datediff')
    const data = localStorage.getItem('username');
    console.log('logged', data);
    if (location?.state?.x[0].trail == true && diffDays > 30) {


      onUpdateauto_null(location?.state?.x[0].user_id);


    }
    else {
      console.log(location?.state?.x[0], 'location trail');
      if (location?.state?.x[0].active == true || location?.state?.x[0].active == null || location?.state?.x[0].admin == true ) {
        console.log(location?.state?.x[0].trail, 'location trail');
        if ((location?.state?.x[0].trail == true || location?.state?.x[0].trail == false)) {


          // alert("success");
          setuserDetails(location?.state?.x);
          localStorage.setItem('user_details', JSON.stringify(location?.state?.x));
          localStorage.setItem('username', location?.state?.x[0].loginmail);
          setUserId(location?.state?.x[0].login_id);
          console.log(location?.state?.x[0].login_id, 'login_id')
          dispatch({ type: 'ADD_USER', payload: { user: location?.state?.x } });//context api updated.
          const paymentDate = new Date(location?.state?.x[0].payment_date);
 
          // Calculate the difference in days
          const daysDifference = Math.floor((currentDate - paymentDate) / (1000 * 60 * 60 * 24));
          
          if (location?.state?.x[0].passwordgen == true) {


            gopasswordchange();
          }
          else {



            if (location?.state?.x[0].admin == true && location?.state?.x[0].company == false) {

              goaddress();
            }
            if (location?.state?.x[0].admin == true && location?.state?.x[0].company == true && location?.state?.x[0].payment_amount === 0 && daysDifference > 30) {
                      
              gobill();
              
            }
            if (location?.state?.x[0].admin == true && location?.state?.x[0].company == true && location?.state?.x[0].payment_amount != 0) {
          
              gohome();
              
            }
            if (location?.state?.x[0].admin == true && location?.state?.x[0].company == true && location?.state?.x[0].payment_amount === 0 && daysDifference < 30) {
          
              gohome();
              
            }
            if (location?.state?.x[0].admin == false && location?.state?.x[0].company == true && location?.state?.x[0].usertypename == 'HR Manager') {

              gohome();
            }
            if (location?.state?.x[0].admin == false && location?.state?.x[0].company == true && location?.state?.x[0].usertypename == 'Admin') {

              gohome();
            }
            if (location?.state?.x[0].admin == false && location?.state?.x[0].company == true && location?.state?.x[0].usertypename == 'Supervisor') {

              gohomes();
            }
            if (location?.state?.x[0].admin == false && location?.state?.x[0].company == true && location?.state?.x[0].usertypename == 'Employee') {

              goemployee();
            }
          }
        }
        else {
          alert("Trail period completed contact admin");
        }
      }
      else {
        alert("Account has been blocked contact admin");
      }

    }


  }
  useEffect(() => {
    setGeneratedOtp(Math.floor(100000 + Math.random() * 900000).toString());
  }, []);
  //  console.log('emp_id: ', location?.state?.x[0].createdon);

  const generateOtp = () => {
    setsndotp(true);
    //  console.log(generatedOtp,"otp");
    send_Email();

  };


  const form = useRef();
  const handleChange = (event) => {
    setInputOtp(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputOtp !== generatedOtp) {
      setError('OTP is incorrect');
      setIsValid(false);
    } else {
      setError('');
      login();
      setIsValid(true);

    }
  };



  // send email to back end
  const send_Email = () => {
    const emailData = {
      user_email: `${location?.state?.x[0].loginmail}`,
      user_name: `${location?.state?.x[0].first_name}`,
      message: `${generatedOtp}`,
    };
    const secretKey = 'mysecretkey';
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

    fetch(`${process.env.REACT_APP_API_URL}/send-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: encryptedData }),
      // body: JSON.stringify(emailData),
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
  }

  const { language,  } = useLanguage();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  return (
    <div class="bg-primary vh-100 container-fluid sceen_size sceenmob">
      <div className='row'>
        <div class="circle1img"> <img className='img-fluid mobsimplytext hide_tab' src={`Images/smallcrl.png`} ></img></div>

        <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
          <div className="text-white fw-bold fs-1 mt-4 ms-4 ">
            <img className='img-fluid logo_size' src={`Images/logoq.png`} ></img>
            <img className='img-fluid mt-5 mobsimplytext' src={`Images/Ellipse12.svg`} ></img>
          </div></div>
        <div className='col-sm-8 col-xxl-8  col-md-8 col-lg-8 col-xl-8'>
          <div className='row mt-5'>
            <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 ms-5 hide_tab'> </div>
            <div className='col-sm-8 col-xxl-8  col-md-8 col-lg-8 col-xl-8 mt-5 ms-sm-4'>
              <img className='img-fluid  float-end mobsimplytext' src={`Images/bigcircle.png`} ></img>
              <div className="text-white fw-bold fs-2 text-center mt-5 ms-sm-5">{t('designedFor')}<p>{t('globalWorkforce')}.</p>
              </div>
              <div className='col-2'>

              </div>
            </div>
          </div>




          <div class=""> <img className='img-fluid mobemailimg mobhide  mobsimplytext' src={`Images/mobemailimg.svg`} ></img></div>
          <button type="button" class="btn btn-secondary  border-0 mobverifybtn mobhide mobsimplytext"><p className='text-primary fw-bold mt-1 fs-5'>Send Email</p></button>
          <div className='row'>

            <div className='col-sm-12'>
              <div class="card email_cardsize_1 mx-auto">

                <div class="card-body">
                  <div className='row'>
                    <div className='col-3 mx-auto mt-1'>
                      <div class=""> <img className='img-fluid' src={`Images/email.png`} ></img></div>
                    </div>
                  </div>
                  <div>
                    <h5 class="card-title text-center mt-4">{t('hey')} </h5>

                    <div class="card-text text-center mt-2">{t('securityMessage')}</div>
                    <div className='row'>
                      <div className='col'>

                        <div class="ml-5">

                          <form ref={form} action="#" method="POST">
                            <input type="text " className='hide_all_pc' name="user_name" value={location?.state?.x[0].first_name} />

                            <input type="text " className='hide_all_pc' name="user_email" value={location?.state?.x[0].loginmail} />
                            <input type="text " className='hide_all_pc' name="message" value={generatedOtp} />
                          </form>

                        </div>
                        <br></br>
                        {(() => {
                          if (sndotp == false) {
                            return <div className='row ' >
                              <div className='col-1'></div>
                              <div className='col-6'><button class="btn btn-pri ms-sm-1 mt-2 email_btn" onClick={generateOtp} type='submit' >{t('sendEmail')}</button>

                              </div>
                              <div className='col-3'></div>

                            </div>

                          }
                          if (sndotp == true) {
                            return <form onSubmit={handleSubmit}>
                              <div className='row ' >
                                <div className='col-3'></div>
                                <div className='col-6'><input type="text" className=' form-control inputcolor' value={inputOtp} onChange={handleChange} />
                                </div>
                                <div className='col-3'></div>

                              </div>
                              <div className='row '>
                              {error && <p className=' text-danger text-center'>{error}</p>}
                                <div className='col-4'></div>

                                <div className='col-3'> 
                                  <button type="submit" className=' mt-2 ms-sm-3 btn btn-pri'>{t('submit')}</button>
                                  {isValid && <p>OTP is valid</p>}</div>
                                <div className='col-3'></div>

                              </div>

                            </form>

                          }
                        })()}


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div className='row mobsimplytext hide_tab'>

        <img className=" mx-auto image_email img-fluid d-block" src="./Images/emailback.svg" />

      </div>

    </div>

  );
}

export default Otpverify;