// SuccessPage.js
import React, { useCallback, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { store } from '../../store/user';
const FailurePage = () => {

let navigate = useNavigate();


const gohome=()=>{
  navigate("/billing");
}
  return (
    <div className="failure-container">
      <div className="failure-card card">
        <div className="failure-icon">&#10060;</div> {/* "x" mark */}
        <h5 className='text-danger'>Payment Declined!</h5>
        <p>Unfortunately, your payment was declined.</p>
        <button className='btn btn-primary' onClick={gohome}>Home</button>
      </div>
    </div>
  );
};

export default FailurePage;
