import React from "react";
import { useCallback, useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './HolidayCalendar.css';
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import moment from 'moment';
import { store } from '../../store/user';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import ReactPaginate from "react-paginate";
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const HolidayCalendar = (props) => {
    const [holiday_title, setHoliday_title] = useState([])
    const { state } = useContext(store);
    console.log('holidays', state.user);

    const Getholidaylist = (x) => {
        if (state.user) {
        axios.get(`${process.env.REACT_APP_API_URL}/holidaysupcoming/${state?.user[0]?.company_id}`).then((res) => {
            console.log(res.data, 'hld');
            setHoliday_title(res.data);
            let aa = [];

            res.data.forEach(item => {

                let a = {
                    id: item.holiday_id,
                    title: item.holiday_name,
                    allDay: true,
                    start: new Date(item.holidaydate),
                    end: new Date(item.holidaydate),
                };
                aa.push(a);


            });
            setEvents(aa);
        }).catch((error) => {
            console.log(error, 'success');
        });
    }

    }

    useEffect(() => {
        Getholidaylist();
    }, [state]);

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    let navigate = useNavigate();
    const Getapplyleave = () => {
        let path = `/applyleave`;
        navigate(path);
    };
    const Getleavehistory = () => {
        let path = `/leavehistory`;
        navigate(path);
    };
    const Getholidaycalendar = () => {
        let path = `/holidaycalendar`;
        navigate(path);
    };

    const [events, setEvents] = useState([]);
    const localizer = momentLocalizer(moment);
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 9;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = holiday_title

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCount = Math.ceil(holiday_title.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className="container-fluid">
            <div class="row mt-5">

                <div class="col-sm-2  hid_tab">

                </div>

                <div class="col-sm-7 col-md-12 col-lg-2 col-xl-7 col-xxl-7   mt-4 ms-sm-4">

                    <ul class="nav nav-tabs nav-justified nav-web nav-normalview" role="tablist">
                        <div class="slider hid_tab"></div>
                        <li class="nav-item  apply-fss">
                            <a class="nav-link fnt-clr ms-5" id="home-tab" data-toggle="tab" onClick={() => Getapplyleave()} role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('applyForLeave')} </a>
                        </li>
                        <li class="nav-item leave2-fs">
                            <a class="nav-link fnt-clr" id="profile-tab" data-toggle="tab" onClick={() => Getleavehistory()} href="#profile" role="tab" aria-controls="profile" aria-selected="false"><i class="fas fa-id-card"></i>{t('leaveHistory')} </a>
                        </li>
                        <li class="nav-item fw-bold calhide">
                            <a class="nav-link fnt-clr" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><i class="fas fa-id-card"></i>{t('holidayCalendar')} </a>
                        </li>
                        <li class="nav-item fw-bold calhideother holiday2-mb">
                            <a class="nav-link fnt-clr" id="profile-tab" onClick={() => Getholidaycalendar()} data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><i class="fas fa-id-card"></i>{t('holidays')} </a>
                        </li>
                    </ul>
                    <div class="settb">

                        <p className="ms-5 mt-3 fw-bold fnt-clr fs-5 ">{t('holidayCalendar')} </p>
                      

                        <div class="ms-5 hide_all mt-5" style={{ height: '350pt', width: '500pt', alignItems: 'center' }}>

                            <Calendar
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                defaultDate={moment().toDate()}
                                localizer={localizer}
                            />

                        </div>
                        <div class="hide_for_pc ms-3 mt-4" style={{ height: '160pt', width: '200pt', alignItems: 'center' }}>

<Calendar
    events={events}
    startAccessor="start"
    endAccessor="end"
    defaultDate={moment().toDate()}
    localizer={localizer}
/>

</div>


                    </div>



                </div>

                <div class="col-sm-2 ms-sm-2  col-md-8 col-lg-8 col-xl-2 col-xxl-2 ">
                    <div class="row mt-5">

                    </div>
                    {displayUsers.map((x, index) => {
                        return (<div>
                            <div class="row  ">
                                <div class="col-12 fs-6   fw-bold ">{moment(x.holidaydate).format('DD MMM YYYY, dddd')}</div>
                            </div>
                            <div class="row ">
                                <div class="col-12 text-secondary  ">{x.holiday_name}</div>

                            </div>
                            <img className="displayhidetab" src="Line 47.png" />
                        </div>
                        );
                    })}






                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />



                </div>
            </div>

            <div class="holidayhl ">
               
            </div>
            <Lheader />
        </div>
    )
}
export default HolidayCalendar;