import React, { useCallback, useState, useContext, useEffect } from 'react';

import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import { Regular } from '../constant';
import { Holiday } from '../constant';
import { Rest } from '../constant';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Overtimerate = (integer) => {
    const { state } = useContext(store);
    console.log('Ot rate', state.user);
    const [tax_details, setTaxDetails] = useState([]);
    const { update, handleSubmit } = useForm();
    const [taxtype, setTaxtype] = useState("");
    const [taxpercent, setTaxpercent] = useState("");
    const [bind_user, setBindUser] = useState({ integer });
    const [user_type, setUserType] = useState('');
    const [taxtypes, setTaxtypes] = useState("");
    const [taxpercents, setTaxpercents] = useState("");
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmSave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const clickdelete = (rate_id) => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmDelete'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Delete(rate_id),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToDelete'))
                }
            ]
        })
    }
    const clickupdate = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmUpdate'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onUpdate(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert( t('confirmUpdate'))
                }
            ]
        })
    }

    const GetTax = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/overtimerate/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setTaxDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const onInsert = (data, e) => {
        try {
            let tax = {
                overtime: bname,
                overtime_percentage: otname,
                user_id: state?.user[0]?.user_id,
                company_id: state?.user[0]?.company_id,
            };

            axios.post(`${process.env.REACT_APP_API_URL}/overtimerate`, tax).then((res) => {
                console.log('overtime added', res.data);
                //GetTax();
                window.location = "/overtimerate";
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        GetTax();
    }, [state]);

    const Delete = (rate_id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/overtimerate/` + rate_id).then((res) => {
                console.log(res.data, 'success');

                if (res.data == true) {

                }
                GetTax();
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const onUpdate = (rate_id, e) => {

        let taxs = {
            rate_id: bind_user.rate_id,
            overtime: taxtypes,
            overtime_percentage: taxpercents,

        };

        axios.put(`${process.env.REACT_APP_API_URL}/overtimerate/${bind_user.rate_id}`, taxs).then((res) => {
            console.log('Overtime updated', res.data);
            GetTax();
            ShowDetails('');
        }).catch(() => {

        })
    }
    const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className='row'>
            <div className='col-5'></div>
            <div className='col-5'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(clickupdate)} action="#" method="POST">
                            <div class="input-group">
                                {/* <input type="text" class=" form-control inputcolor" autocomplete="tax_type" Value={bind_user.overtime}  onChange={(e)=>setTaxtypes(e.target.value)} placeholder="overtime" aria-label="Username" /> */}
                                <select Value={bind_user.overtime} onChange={(e) => setTaxtypes(e.target.value)} placeholder='Over Time Name'
                                    className={`form-select inputcolor txtaddr ${errors.bname &&
                                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                    {...register("fname", {
                                        required: {
                                            value: true,
                                            message:t('dataRequired')
                                        },
                                        minLength: {
                                            value: 3,
                                            message: t('enterFullNamee'),
                                        },
                                        maxLength: {
                                            value: 60,
                                            message:
                                               t('maxAllowedLengthh'),
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9.-]{2,}$/i,
                                            message: t('enterProperName')
                                        },
                                    })} >
                                    <option>Over Time Name</option>
                                    <option value={Rest}>{Rest}</option>
                                    <option value={Holiday}>{Holiday}</option>
                                    <option value={Regular}>{Regular}</option>
                                </select>
                            </div>
                            <div class="input-group">
                                <input type="text" class=" form-control inputcolor mt-3" autocomplete="tax_percentage" Value={bind_user.overtime_percentage} onChange={(e) => setTaxpercents(e.target.value)} placeholder="Overtime Percentage" aria-label="Username" />
                            </div>
                            <div className='row'>
                                <button type='submit' onSubmit={handleSubmit(clickupdate)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">{t('update')}</button>
                                <button type='submit' class="btn btn-danger createbtn  mt-4 masteradd tabbtn" onClick={() => ShowDetails('')}>{t('cancel')}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }


    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setTaxtypes(data.overtime);
        setTaxpercents(data.overtime_percentage);
        setUserType(type);
    }
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    const minwage = () => {
        let path = `/addminimumwage`;
        navigate(path);
    }
    const allocation = () => {
        let path = `/allocation`;
        navigate(path);
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    const bname = watch('fname')
    const otname = watch('otname')
    const isValid = bname && otname
    return (
        <div className='bg-light vh-100 container-fluid'>
            <div class="row">
                <div class="col-2">
                    <div >
                        <div className='setting_side hide_all hid_tab'>
                            <p className=" ms-3 fs-6 fw-bold mt-3">{t('settings')} </p>

                            <ul className='pointer_'>

                               
                                <div className='mt-2' onClick={weekday}>{t('workSchedules')}  </div>
                                <div className='mt-2 ' onClick={loc}>{t('workLocation')} </div>

                                <div className='mt-2' onClick={dept}>{t('department')} </div>





                                <div className='mt-2' onClick={job}>{t('jobTitles')}  </div>



                                <div className='mt-2' onClick={salary}>{t('salary')}  </div>


                                <div className='mt-2' onClick={holidays}>{t('holidays')}  </div>
                                <div className='mt-2' onClick={currency}>{t('currency')}  </div>

                                <div className='mt-2' onClick={financial}>{t('financialYear')}  </div>
                                
                                <div className='mt-2' onClick={Leavetype}>{t('leaveTypes')}  </div>
                                <div className='mt-2' onClick={allocation}>{t('leaveAllocation')}  </div>
                                <div className='mt-2 fw-bold' onClick={overtype}>{t('overtimeRates')}  </div>
                                <div className='mt-2' onClick={ins}>{t('insurance')}  </div>
                                
                                <div className='mt-2' onClick={minwage}>{t('minimumWage')}  </div>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='row mt-5'>
                    <div className='col-5 col-xxl-5 col-sm-5 col-md-2 col-lg-5 col-xl-5'></div>
                    <div className='col-sm-6 col-xxl-6 col-sm-6 col-md-9 col-lg-6 col-xl-6'>
                    <div className='row'>
                            <div className='col-8'>
                        <p className=' mt-3 fs-5 fw-bold grey'>  {t('overtimeRate')} </p>
                        </div>
                        <div className='col-3 onlytab'>
                                            {/* mobile setting sidebar */}
                                            <li class="item dropdown">
                                                <div className="float-start me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                                </svg> </div>
                                                <a class=" dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div></div>
                                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ marginLeft: -70,backgroundColor:"#E7E7E7" }}>
                                                    <p className="text-center fs-4 fw-bold mt-3 ">{t('settings')} </p>
                                                    <div className='mt-2  ms-2' onClick={weekday}>{t('workSchedule')} </div>
                                                    <div className='mt-2  ms-2' onClick={loc}>{t('workLocation')} </div>

                                                    <div className='mt-2 ms-2' onClick={dept}>{t('department')} </div>





                                                    <div className='mt-2 ms-2 ' onClick={job}>{t('jobTitles')}  </div>




                                                    <div className='mt-2 ms-2' onClick={salary}>{t('salary')}  </div>


                                                    <div className='mt-2 ms-2' onClick={holidays}>{t('holidays')}  </div>
                                                    <div className='mt-2 ms-2' onClick={currency}>{t('currency')}  </div>

                                                    <div className='mt-2 ms-2' onClick={financial}>{t('financialYear')}  </div>
                                                    <div className='mt-2 ms-2' onClick={Leavetype}>{t('leaveTypes')}  </div>
                                                    <div className='mt-2 ms-2' onClick={allocation}>{t('leaveAllocation')}  </div>
                                                    <div className='mt-2 ms-2 fw-bold' onClick={overtype}>{t('overtimeRates')}  </div>
                                                    <div className='mt-2 ms-2' onClick={ins}>{t('insurance')}  </div>
                                                    <div className='mt-2 ms-2' onClick={minwage}>{t('minimumWage')}  </div>

                                                </ul>
                                            </li>
                                        </div>
                        </div>
                        <div className='row'>
                            <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                <div class="input-group">
                                    {/* <input type="text" class=" form-control inputcolor textbox-work  mt-3" Value={bname} onChange={(e) => setTaxtype(e.target.value)} placeholder="OverTime" aria-label="Username"  name="fname"
                                      className={` form-control inputcolor txtaddr ${errors.bname &&
                                          " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                      {...register("fname", {
                                          required: {
                                              value: true,
                                              message:t('dataRequired')
                                          },
                                          minLength: {
                                              value: 3,
                                              message: t('enterFullNamee'),
                                          },
                                          maxLength: {
                                              value: 60,
                                              message:
                                                 t('maxAllowedLengthh'),
                                          },
                                          pattern: {
                                              value: /^[A-Z0-9.-]{2,}$/i,
                                              message: t('enterProperName')
                                          },
                                      })} />  */}
                                    <div>
                                        {errors.bname && <span className=' text-danger error_f mt-5 ms-3'>{errors.bname.message}</span>}
                                    </div>
                                </div>
                                {/* <select Value={bname} onChange={(e) => setTaxtype(e.target.value)} placeholder='Over Time Name'
                                    className={`form-select inputcolor txtaddr ${errors.bname &&
                                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                    {...register("fname", {
                                        required: {
                                            value: true,
                                            message:t('dataRequired')
                                        },
                                        minLength: {
                                            value: 3,
                                            message: t('enterFullNamee'),
                                        },
                                        maxLength: {
                                            value: 60,
                                            message:
                                               t('maxAllowedLengthh'),
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9.-]{2,}$/i,
                                            message: t('enterProperName')
                                        },
                                    })} >
                                    <option>Over Time Name</option>
                                    <option value={Rest}>{Rest}</option>
                                    <option value={Holiday}>{Holiday}</option>
                                    <option value={Regular}>{Regular}</option>
                                </select>
                                <div class="input-group mt-3">
                                    <input type="text" class=" form-control inputcolor textbox-work  mt-3" Value={otname} onChange={(e) => setTaxpercent(e.target.value)} placeholder="Overtime Percentage" aria-label="Username"
                                        name="fname"
                                        className={` form-control inputcolor txtaddr ${errors.otname &&
                                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                        {...register("otname", {
                                            required: {
                                                value: true,
                                                message:t('dataRequired')
                                            },
                                            minLength: {
                                                value: 1,
                                                message: t('enterFullNamee'),
                                            },
                                            maxLength: {
                                                value: 60,
                                                message:
                                                   t('maxAllowedLengthh'),
                                            },
                                            pattern: {
                                                value: /[a-zA-Z0-9]+/,
                                                message: t('enterProperName')
                                            },
                                        })} />
                                    <div>
                                        {errors.otname && <span className=' text-danger error_f mt-5 ms-3'>{errors.otname.message}</span>}
                                    </div>
                                </div> */}

                            </div>
                            {/* <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                <form onSubmit={handleSubmit(save)} action="#" method="POST">
                                    <button type='submit' class="btn btn-pri w-75 ms-5 setbtn mt-3" disabled={!isValid} >Add</button>
                                </form>
                            </div> */}
                        </div>
                        <table class="table table-bordered mt-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'> {t('overtime')} </td>
                                        <td scope="col" className='fs-6 fw-bold grey'> {t('overtimePercentage')} </td>
                                        <td scope="col " className='fs-6 fw-bold mobsimplytext grey'>{t('createdDate')} </td>
                                        <td scope="col" ></td>
                                        <td scope="col" ></td>
                                        {/* <td scope="col" className='fs-6'>Edit </td>
                                        <td scope="col" className='fs-6'>Delete </td> */}

                                    </tr>
                                </thead>
                                <tbody>

                                    {tax_details.map((x, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{x.overtime}</td>
                                                <td>{x.overtime_percentage}</td>
                                                <td className='mobsimplytext'>{moment(x.createdon).format('MMM Do YYYY')}</td>
                                                <td></td>
                                                <td></td>
                                                {/* <td><svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></td>
                                                <td><svg onClick={() => clickdelete(x.rate_id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3" viewBox="0 0 16 16">
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                </svg></td> */}
                                            </tr>
                                        );
                                    })}



                                </tbody>
                            </table>
                        </table>
                    </div>
                </div>




            </div>
            <div>{(() => {
                switch (user_type) {
                    //case "view": return RenderView();
                    case "edit": return RenderEdit();
                    //case "delete": return RenderDelete();
                    default: return "";
                }
            })()}</div>
            <Lheader />
        </div>
    )
}
export default Overtimerate