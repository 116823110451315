import "../CompanyDetails/CompanyDetails.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { React, useState } from "react";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';


const Emppayroll = () => {

    const [startDate, setStartDate] = useState(new Date());
    const [type_address, setTypeAddress] = useState('Address_update');
    const [type_tax, setTypeTax] = useState('tax_update');
    const [type_bank, setTypeBank] = useState('bank_update');

    const TabChange = (type) => {
        console.log('type', type_address)
        if (type == 'Address_edit') {
            console.log('Address_edit', type);
            setTypeAddress(type);
        } else {
            console.log('Address_update', type);
            setTypeAddress(type);
        }
    }
  
    const TabChange1 = (type) => {
        console.log('type', type_address)
        if (type == 'tax_edit') {
            console.log('tax_edit', type);
            setTypeTax(type);
        } else {
            console.log('tax_update', type);
            setTypeTax(type);
        }
    }
    const TabChange2 = (type) => {
        console.log('type', type_address)
        if (type == 'bank_edit') {
            console.log('bank_edit', type);
            setTypeBank(type);
        } else {
            console.log('bank_update', type);
            setTypeBank(type);
        }
    }

    let navigate = useNavigate();
    const goemp = () => {
        navigate("/companylist");

    };


    const addwrk = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    return (
        <div className='bg-light vh-100 container-fluid'>

            <div className="row  mt-5 hide_all">
                <div className="col-sm-3 hid_tab "></div>
                <div className="col-9  ">
                    <div className="row">
                        <div className="col-4 border-end ">
                            <div className="row mt-5">
                                <div className="col-sm-6 ">

                                    <img className='img-fluid profileimg_position' src={`Images/profile image.svg`} ></img>
                                    <p className="profilename"> Alexander George
                                        <p>Emp Id:130345
                                        </p></p>
                                </div>

                                <div className="col-6">
                                    <div className="row ms-3">
                                        <p className="activesele_2">Status</p>

                                        <select class="text-center text-white  rounded-pill activesele_1 " aria-label=" select Day">
                                            <option selected>Active </option>
                                            <option value="1">Inactive</option>
                                            <option value="2">Blocked</option>
                                            <option value="3"></option>
                                        </select>
                                    </div>



                                </div>
                            </div>

                            <div className="row ">
                                <p className="text-primary ">Email</p>
                                <p>Alexander George@gmail.com</p>
                                <p className="text-primary " >Phone Number</p>
                                <p>+44 5595 6969</p>
                                <p className="text-secondary  fw-bold"> Basic</p>
                                <p>Regular Hours</p>
                                <p>160</p>
                                <p className="text-secondary  fw-bold">Overtime</p>
                                <p>Regular Overtime</p>
                                <p>160</p>
                                <p>Rest Day/Weekend </p>
                                <p>160</p>
                                <p>Holiday Overtime </p>
                                <p>160</p>
                                <p className="text-secondary  fw-bold">Night Shift</p>
                                <p>Night Shift </p>
                                <p>160</p>
                            </div>





                        </div>
                        <div className="col-8 ">
                            <Tabs
                                defaultActiveKey="address"
                                id="uncontrolled-tab-example"
                                className="mb-3 neg2">

                                <Tab eventKey="address" className="" title="Basic">

                                    <div className="row mt-5">
                                        <div className="col-1">

                                        </div>
                                        <div className="col-8">
                                        <p className='text_pay2 text-center'> Regular Hours*</p>
                                           
                                            <select class=" form-select inputcolor text-start ms-3 " aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                        </div>

                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-4">

                                        </div>
                                        <div className="col-5">
                                            <button className="btn btn-pri">{t('update')}</button>
                                            <button className="btn btn-secondary ms-3">{t('back')}</button>
                                        </div>
                                    </div>

                                </Tab>

                                <Tab eventKey="tax" className=" " title="Overtime">
                                    
                                    <div className="row mt-5">
                                        <div className="col-1 ">

                                        </div>
                                        <div className="col-8">
                                        <p className='text_pay2 text-center'> Regular Overtime *</p>
                                        
                                        <select class=" form-select inputcolor text-start ms-3 " aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                            <p className='text_pay text-center'> Rest Day/Weekend Overtime *</p>
                                        
                                            <select class=" form-select inputcolor text-start ms-3 mt-4" aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                            <p className='text_pay text-center'>Holiday Overtime *</p>
                                          
                                            <select class=" form-select inputcolor text-start ms-3 mt-4" aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                        </div>

                                        <div className="row mt-4">
                                            <div className="col-4">

                                            </div>
                                            <div className="col-5">
                                                <button className="btn btn-pri">{t('update')}</button>
                                                <button className="btn btn-secondary ms-3">{t('back')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="bank" className="" title="Night Shift" >
                                    <div className="row mt-5">
                                        <div className="col-1">

                                        </div>
                                        <div className="col-8">
                                        <p className='text_pay2 text-center'> Night Shift Hours*</p>
                                        
                                            <select class=" form-select inputcolor text-start ms-3 " aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>

                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-4">

                                            </div>
                                            <div className="col-5">
                                                <button className="btn btn-pri">{t('update')}</button>
                                                <button className="btn btn-secondary ms-3">{t('back')}</button>
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                {/* <Tab eventKey="Allowances" className="" title="Allowances" >
                                    <div className="row mt-5">
                                        <div className="col-1">

                                        </div>
                                        <div className="col-8">
                                        <p className='text_pay text-center'> Regular Hours*</p>
                                        
                                            <select class=" form-select inputcolor text-start ms-3 " aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-4">

                                            </div>
                                            <div className="col-5">
                                                <button className="btn btn-pri">{t('update')}</button>
                                            </div>
                                        </div>

                                    </div>
                                </Tab> */}

                            </Tabs>
                        </div>
                    </div>
                </div>


            </div>
            <div className="row hide_all_pc mt-5">
                <Tabs
                    defaultActiveKey="pro"
                    id="uncontrolled-tab-example"
                    className="mb-3 "
                >
                    <Tab eventKey="pro" className="ms-3" title="Employee Details">
                    <div className="row">
                        <div className="col border-end ">
                            <div className="row mt-5">
                                <div className="col-sm-6 ">

                                    <img className='img-fluid ' src={`Images/profile image.svg`} ></img>
                                    <p className=""> Alexander George
                                        <p>Emp Id:130345
                                        </p></p>
                                </div>

                                <div className="col-6">
                                    <div className="row ms-3">
                                        <p className="activesele_2">Status</p>

                                        <select class="text-center text-white  rounded-pill activesele_1 " aria-label=" select Day">
                                            <option selected>Active </option>
                                            <option value="1">Inactive</option>
                                            <option value="2">Blocked</option>
                                            <option value="3"></option>
                                        </select>
                                    </div>



                                </div>
                            </div>

                            <div className="row ">
                                <p className="text-primary ">Email</p>
                                <p>Alexander George@gmail.com</p>
                                <p className="text-primary " >Phone Number</p>
                                <p>+44 5595 6969</p>
                                <p className="text-secondary  fw-bold"> Basic</p>
                                <p>Regular Hours</p>
                                <p>160</p>
                                <p className="text-secondary  fw-bold">Overtime</p>
                                <p>Regular Overtime</p>
                                <p>160</p>
                                <p>Rest Day/Weekend </p>
                                <p>160</p>
                                <p>Holiday Overtime </p>
                                <p>160</p>
                                <p className="text-secondary  fw-bold">Night Shift</p>
                                <p>Night Shift </p>
                                <p>160</p>
                            </div>





                        </div>
                        
                    </div>
                    </Tab>
                    <Tab eventKey="edit" className="" title="Edit Details">
                        <div className="row">
                            <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                Basic
                            </button>
                            <div class="collapse" id="collapseExample">

                            <div className="col mt-5">
                                        <p className='text_pay text-center'> Regular Hours*</p>
                                        
                                            <select class=" form-select inputcolor text-start ms-3 " aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                        </div>

                              
                            </div>
                        </div>
                       
                        <div className="row mt-5">
                            <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample1" aria-expanded="false" aria-controls="collapseExample">
                                Overtime 
                            </button>
                            <div class="collapse" id="collapseExample1">

                            <div className="col mt-5">
                                        <p className='text_pay text-center'>Over Time*</p>
                                        
                                            <select class=" form-select inputcolor text-start ms-3 mt-3" aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                            <p className='text_pay text-center'> Rest Day/Weekend *</p>
                                        
                                            <select class=" form-select inputcolor text-start ms-3 mt-3" aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                            <p className='text_pay text-center'> Holiday Overtime*</p>
                                        
                                            <select class=" form-select inputcolor text-start ms-3  mt-3" aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                        </div>

                              
                            </div>
                        </div>

                        <div className="row mt-5">
                            <button class="btn btn-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample2" aria-expanded="false" aria-controls="collapseExample">
                               Night Shift
                            </button>
                            <div class="collapse" id="collapseExample2">

                            <div className="col mt-5">
                                        <p className='text_pay text-center'>Night Shift*</p>
                                        
                                            <select class=" form-select inputcolor text-start ms-3 " aria-label=" Working Hours">
                                                <option selected> 160</option>
                                                <option value="1">180</option>
                                                <option value="2">180</option>
                                                <option value="3">180</option>

                                            </select>
                                        </div>

                              
                            </div>
                        </div>


                    </Tab>
                </Tabs>
            </div>
            <Lheader />
        </div>
    )
}
export default Emppayroll