import React from "react";
import "./Header.css";
import { useCallback, useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Workshedule from "../Workshedule/Workshedule";
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const EHeader = () => {
    const [islogged, setLogged] = useState(true);
    const [User, setUser] = useState(false);
    let navigate = useNavigate();

    const companyaddress = () => {
        let path = `/companyaddress`;
        navigate(path);
    }
    const Workshedule = () => {
        let path = `/workshedule`;
        navigate(path);
    }
    const addemployee = () => {
        let path = `/baseprofile`;
        navigate(path);
    }
    const logOut = () => {

        if (islogged == true) {
            localStorage.removeItem('user_details');
            setUser(false);
            goLogin();
        }
        else {
            localStorage.setItem('user_details');
        }
    }

    const goLogin = () => {
        navigate("/");

    };
    
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    return (
        <div className=" ">
            <div className="row container-fluid fix ">
                <div className="col-2 hid_tab"></div>
                <div className="col-10">
                    <div className="row" id="header">
                        <div className="col"  >

                            <ul class="nav nav-tabs headeralignment headerposition mobeheadali extend-border-md" >

                                <li class="nav-item ms-2 mobsimplytext  hide_tab">
                                    <a class="nav-link fw-bold text-primary" aria-current="page"  ><img className="" src="./Images/Ch.svg" onClick={companyaddress}></img> {t('companyInformation')}</a>
                                </li>

                                <li class="nav-item ms-2 onlytab">
                                    <a class="nav-link fw-bold text-primary" aria-current="page"  ><img className="img-fluid mobimgcomp" src="/Images/sygl_img.png" onClick={companyaddress}></img></a>
                                </li>

                                {/* <div className='col-sm-2'>

                                    <img className='img-fluid mobimg' src={`/Images/sygl_img.png`}></img>

                                </div> */}

                            </ul>

                        </div>

                    </div>


                </div>

            </div>

            {/* <nav class="navbar mobhide  navbar-light bg-primary container-fluid fix ">

                <span class="navbar-brand mb-0 h5 text-white ms-5"> Overview</span>
                <span>

                </span>

                <span class="navbar-brand mb-0 h5  "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-bell-fill" viewBox="0 0 16 16">
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                </svg> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class=" ms-1 bi bi-circle-fill" viewBox="0 0 16 16">
                        <circle cx="8" cy="8" r="8" />
                    </svg><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="white" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>

                </span>


            </nav> */}
        </div>
    )
}
export default EHeader