import React, { useCallback, useState } from 'react';
import { useContext, useEffect } from 'react';
import "./Guide.css";
import { useNavigate } from "react-router-dom";
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const Guidecm = () => {

  const [activePage, setActivePage] = useState(0);
 
  const handlePageChange = (page) => {
    setActivePage(page);
  };
  const { language,  } = useLanguage();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const pages = [
 


   
    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4 col-md-1 col-lg-1   col-xl-4 col-xxl-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>{t('quickStartGuide')}</h1>

            <div className='text-black text-center'> {t('es')}</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4 '></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi ' src={`Images/c_m.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/cmbotm.png`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,

    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>{t('quickStartGuide')}</h1>

            <div className='text-center text-black'> {t('ei')}</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi ' src={`Images/gdwk.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/gdwkb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,
    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>{t('quickStartGuide')}</h1>

            <div className='text-center text-black'>{t('ed')}
</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto  im_hi' src={`Images/gdwkl.svg`} ></img>

          </div>
        </div>
        <div className='col-sm-2 hide_tab'>
        <img className='img-fluid d-grid mx-auto gbtmimg1 ' src={`Images/gdlcb.svg`} ></img>
</div>


      </div>
    </div>,

    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>{t('quickStartGuide')}</h1>

            <div className='text-center text-black'>{t('ea')}</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi' src={`Images/gdae.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/gdaeb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,

    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>{t('quickStartGuide')}</h1>

            <div className='text-center text-black'>{t('eb')}</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid  mx-auto im_hi ' src={`Images/gdema.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/gdatb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,
    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>{t('quickStartGuide')}</h1>

            <div className='text-center text-black'>{t('ec')} </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi' src={`Images/gdlm.svg`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/mglvb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,
    <div className='bg-light container-fluid'>

      <div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>
            <h1 className='text-center'>{t('quickStartGuide')}</h1>

            <div className='text-center text-black'>{t('en')}!</div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'></div>

          <div className='col-sm-6 col-md-10  col-lg-10  col-xl-6 col-xxl-6 '>

            <img className='img-fluid d-grid mx-auto im_hi' src={`Images/myrp.png`} ></img>

          </div>
        </div>
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-sm-2 hide_tab'>
            <img className='img-fluid d-grid mx-auto gbtmimg1' src={`Images/gdrvb.svg`} ></img>

          </div>
          <div className='col-6'>


          </div>
        </div>



      </div>
    </div>,
  ];
 
  
  return (
    <div>
      {pages && <BottomDots activePage={activePage} onPageChange={handlePageChange} pages={pages} />}
    </div>
  );
};



function BottomDots({ activePage, onPageChange, pages }) {
  const { state } = useContext(store);
  const { language,  } = useLanguage();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  let navigate = useNavigate();

  const goaddress = () => {
    //debugger;
    navigate("/companyaddress");
  };

  const godash = () => {
    //debugger;
    navigate("/");
  };
  const [dots, setDots] = useState([]);

  useEffect(() => {
    if (pages) {
      const newDots = [];
      for (let i = 0; i < pages.length; i++) {
        newDots.push(i);
      }
      setDots(newDots);
    }
  }, [pages]);

  if (!pages) {
    return null;
  }
  
  return (
    <div className='bg-light'>
      <div className='row'>
        <div className="page-content">{pages[activePage]}</div>
      </div>
      <div className='row'>
        <div className='col-3'></div>
        <div className='col-3'></div>
        <div className='col-3'>
          <div className="bottom-dots-wrapper ms-5">
            <div className="bottom-dots">
              {/* <span className="arrow" onClick={() => onPageChange(activePage - 1)}>{'<'}</span> */}
              {dots.map((dotIndex) => (
                <span
                  key={dotIndex}
                  className={`dotbtm ${dotIndex === activePage ? "active" : ""}`}
                  onClick={() => onPageChange(dotIndex)}
                />
              ))}
              {/* <span className="arrow" onClick={() => onPageChange(activePage + 1)}>{'>'}</span> */}
            </div>

          </div>
        </div>

        <div className='col-1 ms-sm-5 viewtop'>
          {(() => {
            if (activePage > 5) {
              return <>
                {(() => {
                  if (state.user[0].company == true) {
                    return <>
                      <button onClick={godash} className=' btn d-grid btn-pri bottombtn mobnextbt'>{t('done')}</button>


                    </>
                  }

                  else {
                    return <button onClick={goaddress} className=' btn d-grid btn-pri bottombtn mobnextbt'>{t('getStarted')}</button>


                  }
                })()}

              </>

            }
            else {
              return <button onClick={() => onPageChange(activePage + 1)} className=' btn d-grid btn-pri bottombtn mobnextbt'>{t('next')}</button>

            }
          })()}
        </div>
        <div className='col-sm-1  viewtop skipleft'>

          <button onClick={goaddress} className='ms-3 d-grid btn btn-secondary bottombtn'>{t('skip')}</button> </div>


      </div>

    </div>
  );
        
}

export default Guidecm;


