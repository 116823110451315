import TimeSheetheader from './Timeheader';
import React, { useCallback, useState, useRef, useContext, useEffect } from 'react';
import Header from '../Header/Header'
import "./Your_work.css";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { store } from '../../store/user';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

import moment from 'moment';
const YourWork = () => {
    const { state } = useContext(store);
    console.log('profile_cmp', state.user);

    const [islogged, setLogged] = useState(true);
    const [User, setUser] = useState(false);
    const [outtime, setouttime] = useState("");

    const logOut = () => {

        if (islogged == true) {
            localStorage.removeItem('user_details');
            setUser(false);
            goLogin();
        }
        else {
            localStorage.setItem('user_details');
        }
    }
    let navigate = useNavigate();
    const goLogin = () => {
        let path = `/`;
        navigate(path);
    }
    const gotime = () => {
        let path = `/timesheet`;
        navigate(path);
    }
    const currTime = new Date().toLocaleTimeString();
    const [intime, setintime] = useState("");
    const [cmnt, setcmnt] = useState("");
    ;
    const onInsert = (data, e) => {
        setintime(currTime);
        try {
            let pay = {
                emp_id: state?.user[0]?.emp_id,
                intime: intime,
                time_comment: cmnt,
                user_id: state?.user[0]?.user_id,
                company_id: state?.user[0]?.company_id
            };

            axios.post(`${process.env.REACT_APP_API_URL}/emptimesheet`, pay).then((res) => {
                console.log('time sheeet updated added', res.data);
                gotime();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [time_1details, set_UserDetails] = useState([]);
    const get_timesheet = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp_timesheet/${state?.user[0]?.emp_id}`).then((res) => {

                console.log(res.data, 'time_1details');
                if (res.data.length === 0) {
                    set_UserDetails([{ out: "00", emp_id: state.user[0].emp_id }]);
                } else {
                    set_UserDetails(res.data);
                }
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    useEffect(() => {
        get_timesheet();
    }, [state]);
    // location
    const [currentLocation, setCurrentLocation] = useState(null);
    const [long, setlong] = useState();

    const [lat, setlat] = useState();
    const onlineip = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setCurrentLocation([latitude, longitude]);
                setlong(longitude);
                setlat(latitude);
            },
            (error) => console.log(error)
        );
    }
    const online_ip = () => {
        fetch("https://api.ipify.org?format=json")
            .then(res => res.json())
            .then(res => setIp(res.ip));
    }
    useEffect(() => {
        onlineip();
    }, []);
    // State to store IP
    const [ip, setIp] = React.useState("");
    const [nip, nsetIp] = React.useState([]);


    // Fetch IP when component mounts
    useEffect(() => {
        online_ip();
    }, []);
    const GetIp = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_location/${state?.user[0]?.worklocation}`).then((res) => {
                console.log(res.data, 'ip data');
                nsetIp(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].ip != ip) {
                        setShowText(true);

                    } else {
                        setShowText(false);
                    }
                }

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const [showText, setShowText] = useState(true);
    useEffect(() => {
        GetIp();
    },
        [state]);
    const [employee, setempdetails] = useState([]);

    const Getleave = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp_emp/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'wfo');
                setempdetails(res.data);
            }).catch(() => {

            })
        }


    }
    useEffect(() => {
        Getleave();
    },
        [state]);

    const gettime2 = () => {

        setouttime(currTime);
        // setwrkhr(renderedStreamDuration);
        save2();
    }

    const save2 = () => {
        confirmAlert({
            //title: 'Cancle',
            message: ' Clock OUT',
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onupclockout(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave')),

                }
            ]
        })
    }
    // clock out update 
    const [wrkhr, setwrkhr] = useState("");
    // const onupclockout = (data, e) => {
    //     try {
    //         let pay = {

    //             out: outtime,
    //             time_comment: wrkhr,
    //         };

    //         axios.put(`${process.env.REACT_APP_API_URL}/emptimesheet/${state?.user[0]?.emp_id}`, pay).then((res) => {
    //             console.log('outupdate', res.data);
    //             // save();
    //             window.location = '/timesheet';
    //         })
    //     } catch (err) {
    //         console.log(err.message);
    //     }
    // }
    const onupclockout = (data, e) => {
        try {
            let pay = {

                out: outtime,
                time_comment: wrkhr,
            };

            axios.put(`${process.env.REACT_APP_API_URL}/emptimesheet/${state?.user[0]?.emp_id}`, pay).then((res) => {
                console.log('outupdate', res.data);
                // save();

                getnighttimesheet();

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [nightdetails, setnightDetails] = useState([]);
    const getnighttimesheet = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/emp_timesheet/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'timeanddate');
                setnightDetails(res.data);
                Nighttimehrs(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });

    }

    const Nighttimehrs = (x) => {
        const clockInTime = new Date(x[0].intim);
        const clockOutTime = new Date(x[0].out);
        const startHour = 22; // 10pm
        const endHour = 6; // 6am 
        const clockInHour = clockInTime.getHours();
        const clockOutHour = clockOutTime.getHours();
        let hoursInRange = 0;
        let minutesInRange = 0;

        if (clockOutHour >= startHour || clockOutHour < endHour || clockOutHour < clockInHour) {
            const diff = clockOutTime.getTime() - clockInTime.getTime();
            hoursInRange = Math.floor(diff / (1000 * 60 * 60));
            minutesInRange = Math.floor((diff / (1000 * 60)) % 60);

            if (clockOutHour < clockInHour) {
                hoursInRange = Math.abs(hoursInRange); // convert negative value to positive
                minutesInRange = 60 - minutesInRange; // adjust minutes
            }

            if (clockOutHour < startHour && clockOutHour >= endHour) {
                // If clock out happens before startHour but on the next day, reset the duration to 0
                hoursInRange = 0;
                minutesInRange = 0;
            }

            // Limit the duration to the range between startHour and endHour
            if (clockOutHour >= startHour) {
                hoursInRange = Math.min(hoursInRange, clockOutHour - startHour);
                minutesInRange = Math.min(minutesInRange, 60);
            }

            if (clockOutHour < endHour) {
                hoursInRange = Math.min(hoursInRange, 24 - startHour);
                minutesInRange = Math.min(minutesInRange, 60);
            }
            if (clockOutHour < clockInHour) {
                let hoursWorkedFirstDay = 0
                let hoursWorkedSecondDay = 0

                if (clockInHour > startHour) {
                    hoursWorkedFirstDay = 24 - clockInHour;
                } else {
                    hoursWorkedFirstDay = 24 - startHour;

                }
                if (clockOutHour > endHour) {
                    hoursWorkedSecondDay = endHour;

                } else {
                    hoursWorkedSecondDay = clockOutHour;
                }
                hoursInRange = hoursWorkedFirstDay + hoursWorkedSecondDay
                console.log(hoursInRange, 'nextday')
            }
        }


        console.log(`${hoursInRange} hours and ${minutesInRange} minutes`);
        try {
            let pay = {
                emp_id: state?.user[0]?.emp_id,
                totalnighthr: hoursInRange + (minutesInRange / 100),
                nightmin: minutesInRange,
                date: moment(x[0].intim).format('YYYY MM DD'),
                clockin: x[0].intim,
                clockout: x[0].out,
                nightstatus: 'Approved',
                ottype: 1,
            };

            axios.post(`${process.env.REACT_APP_API_URL}/nightshift`, pay).then((res) => {
                console.log('nighthrs added', res.data);
                window.location = '/timesheet';
            });
        } catch (err) {
            console.log(err.message);
        }
    };
    // work location
    const [work_list, setWork_list] = useState([]);
    const Getworklist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/work_schedule/${state?.user[0]?.workschedule}`).then((res) => {
                console.log(res.data, 'wrk');
                setWork_list(res.data);

            }).catch(() => {

            })
        }
    }
    useEffect(() => {
        Getworklist();
    }, [state]);

    return (
        <div className="bg-light vh-100 container-fluid">
            <nav class="navbar hide_all_pc navbar-light fix hid_tab ">
                <div class="container-fluid ">
                    <a class="nav-link fw-bold " aria-current="page" href="#"><img className="" src="./Images/time_wrk2.svg"></img>Your Work</a>
                    <a class="nav-link fw-bold " href="#"><img className="" src="./Images/time_wrk.svg"></img>Your TimeSheet</a>
                    <span class="navbar-brand mb-0 h5 "></span>
                </div>
            </nav>

            <div className='row'>
                <div className='col-sm-3  col-md-2 col-lg-2 col-xl-3 col-xxl-3'></div>
                <div className='col-sm-4  col-md-9 col-lg-9 col-xl-4 col-xxl-4'>
                    <div className='row'><p className=" ">Your work location. </p></div>
                    <div className='row'><p className=""> From</p></div>
                    <div className='row'> {nip.map((x, index) => {
                        return (<div>
                            <p className=" fnt-clr"> {x.worklocation}</p>
                            {(() => {

                                if (x.ip == ip) {
                                    return < div className='bg-white '>
                                        <p className=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                        </svg> {ip}</p>
                                        <p className=" fnt-clr"> You are in Correct Work location</p>

                                    </div>
                                } if (x.ip != ip) {
                                    return <>
                                        <p>   {ip}</p>
                                        {employee.map((x, index) => {
                                            return (<div>
                                                {(() => {

                                                    if (x.wfo == true) {
                                                        return <p className=" text-danger"> You are in Wrong location</p>

                                                    } else {
                                                        return <p className=" text-success"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                                                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                        </svg> Work From Home</p>
                                                    }

                                                })()}
                                            </div>);
                                        })}



                                    </>
                                }

                            })()}
                        </div>);
                    })}      </div>
                    <div className='row'>
                        {employee.map((x, index) => {
                            return (
                                <div>
                                    <p className="">{t('employeeName')} </p>
                                    <p className="">{x.full_name}</p>

                                </div>
                            );
                        })}
                    </div>
                    <div className='row'>
                        <div className='col-9'>
                            <textarea type="text" class=" mt-4 form-control inputcolor" onChange={(e) => setcmnt(e.target.value)} placeholder={t('addYourComments')}  aria-label="Username" />

                        </div>
                    </div>
                    <div className='row'>
                        {employee.map((x, index) => {
                            return (
                                <div>
                                    {(() => {
                                        if (x.wfo == true) {
                                            return <div>
                                                {nip.map((x, index) => {
                                                    return (
                                                        <div>
                                                            {(() => {

                                                                if (x.ip == ip) {
                                                                    return <div>
                                                                        {time_1details.map((x, index) => {
                                                                            return (
                                                                                <div className="d-grid">
                                                                                    <div className='col-6 d-grid'>
                                                                                        {(() => {
                                                                                            if (x.out == null) {
                                                                                                return <button class="btn btn-danger d-grid   mt-3 " type="button" onClick={gettime2} >{t('clockOut')} </button>
                                                                                            }

                                                                                            else {
                                                                                                return <button class="btn btn-pri d-grid   mt-3 " type="button" onClick={onInsert} >{t('clockIn')} </button>



                                                                                            }
                                                                                        })()}
                                                                                    </div>


                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                                else {

                                                                    return <div>{showText && <p className='text-center  text-danger'>Wrong Location / Clocked In</p>} </div>

                                                                }



                                                            })()}
                                                        </div>);
                                                })}
                                            </div>
                                        }
                                        else {
                                            return <div>
                                                {time_1details.map((x, index) => {
                                                    return (
                                                        <div className="d-grid">
                                                            <div className='col-6 d-grid'>
                                                                {(() => {
                                                                    if (x.out == null) {
                                                                        return <button class="btn btn-danger d-grid   mt-3 " type="button" onClick={gettime2} >{t('clockOut')} </button>
                                                                    }

                                                                    else {
                                                                        return <button class="btn btn-pri d-grid  mt-3 " type="button" onClick={onInsert} >{t('clockIn')} </button>



                                                                    }
                                                                })()}


                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        }
                                    })()}
                                </div>
                            );
                        })}
                    </div>


                    <div className="row">
                            <div className="ms-4 mt-4 onlytab hide_tab">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                </svg>
                            </div>
                        </div>

                    <div className='row settb '>
                  
                        
                        <table class="table table-bordered mt-5 ms-2 year-buttonss ">
                            
                            

                            <table class="table ">
                                <thead>
                                    <tr>
                                        <td scope="col"></td>
                                        <td scope="col" className='fs-6 fw-bold grey'>{t('name')} </td>

                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('mon')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('tue')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('wed')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('thu')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('fri')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('sat')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('sun')}</td>


                                    </tr>
                                </thead>
                                <tbody>
                                    {work_list.map((x, index) => {
                                        return (
                                            <tr key={index}>
                                                <td scope="row"></td>
                                                <td className=''>{x.workschedule_name}</td>
                                                {(() => {
                                                    if (x.monday == 7) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.monstime}-{x.monetime}</p></td>
                                                    } if (x.monday == 8) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.monstime}-{x.monetime}</p></td>
                                                    } else {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg></td>
                                                    }

                                                })()}
                                                {(() => {
                                                    if (x.tuesday == 7) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.tuestime}-{x.tueetime}</p></td>
                                                    } if (x.tuesday == 8) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.tuestime}-{x.tueetime}</p></td>
                                                    } else {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg></td>
                                                    }

                                                })()}
                                                {(() => {
                                                    if (x.wednesday == 7) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.wedstime}-{x.wedetime}</p></td>
                                                    } if (x.wednesday == 8) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.wedstime}-{x.wedetime}</p></td>
                                                    } else {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg></td>
                                                    }

                                                })()}
                                                {(() => {
                                                    if (x.thursday == 7) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.thustime}-{x.thuetime}</p></td>
                                                    } if (x.thursday == 8) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.thustime}-{x.thuetime}</p></td>
                                                    } else {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg></td>
                                                    }

                                                })()}
                                                {(() => {
                                                    if (x.friday == 7) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.fristime}-{x.frietime}</p></td>
                                                    } if (x.friday == 8) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.fristime}-{x.frietime}</p></td>
                                                    } else {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg></td>
                                                    }

                                                })()}
                                                {(() => {
                                                    if (x.saturday == 7) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.satstime}-{x.satetime}</p></td>
                                                    } if (x.saturday == 8) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.satstime}-{x.satetime}</p></td>
                                                    } else {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg></td>
                                                    }

                                                })()}
                                                {(() => {
                                                    if (x.sunday == 7) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.sunstime}-{x.sunetime}</p></td>
                                                    } if (x.sunday == 8) {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg><p>{x.sunstime}-{x.sunetime}</p></td>
                                                    } else {
                                                        return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                        </svg></td>
                                                    }

                                                })()}

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </table>
                    </div>




                </div>

                <div className='col-sm-4 hide_tab'>
                    <div className="row mt-3">

                    </div>

                    <img className="rounded mx-auto  mt-5 d-block img-fluid " src="./Images/Your_Work.svg"></img>

                </div>
            </div>



            <TimeSheetheader />
        </div>
    )
}
export default YourWork
