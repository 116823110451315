import React, { useCallback, useState, useContext,useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const EmployeeSidebar = (props, isopen) => {
    const { state } = useContext(store);
    console.log('employee list', state.user);

   
    let navigate = useNavigate();
    const sidebarChange = () => {
        let path = `/admindashboard`;
        navigate(path);
    }

    const sidebarChange1 = () => {
        let path = `/EmpProfileedit`;
        navigate(path);
    }

    const sidebarChange2 = () => {
        let path = `/attantance`;
        navigate(path);
    }

    const sidebarChange3 = () => {
        let path = `/applyleave`;
        navigate(path);
    }

    const sidebarChange4 = () => {
        let path = `/yourwork`;
        navigate(path);
    }

    const sidebarChange5 = () => {
        let path = `/timesheet`;
        navigate(path);
    }
    const sidebarChange6 = () => {
        let path = `/account`;
        navigate(path);
    }

    const { t, i18n } = useTranslation();
    const handleLanguageChange = (newLanguage) => {
        i18n.changeLanguage(newLanguage);
        // Store the selected language in localStorage
        localStorage.setItem('selectedLanguage', newLanguage);
      };
    
      useEffect(() => {
        // Retrieve the selected language from localStorage on page load
        const storedLanguage = localStorage.getItem('selectedLanguage');
        if (storedLanguage) {
          i18n.changeLanguage(storedLanguage);
        }
      }, [i18n]);
    


    const currentUrl = window.location.pathname;
    
    return (
        <div className='container-fluid '>

            <div className={currentUrl === '/password' ? 'disabled disp_mobile_none' : ' sidebar hide_all hid_tab'}>

                <img className='img-fluid ' src={`Images/logoq.png`} ></img>
                <ul className='ulleft'>
                    <li className={currentUrl === '/employeedashboard' ? 'active_s' : ' sidebarlist text-white custom-hover'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-speedometer2 ms-4 icon" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                            <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
                        </svg>
                        <div className='ms-4' onClick={() => { navigate('employeedashboard') }}>{t('dashboard')}  </div>
                    </li>


                    <li className={currentUrl === '/EmpProfileedit' ? 'active_s' : ' sidebarlist text-white custom-hover'}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-person-video2 ms-4 icon" viewBox="0 0 16 16">
                            <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                            <path d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z" />
                        </svg>
                        <div className='ms-4' onClick={() => { navigate('EmpProfileedit') }}>{t('profile')} </div>
                    </li>

                    <li className={currentUrl === '/attantance' ? 'active_s' : ' sidebarlist text-white custom-hover'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-card-checklist ms-4 icon" viewBox="0 0 16 16">
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                            <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                        </svg>

                        <div className='ms-4' onClick={() => { navigate('attantance') }} >{t('attendance')} </div>
                    </li>

                    <li className={currentUrl === '/applyleave' || currentUrl === '/leavehistory' || currentUrl === '/holidaycalendar' ? 'active_s' : ' sidebarlist text-white custom-hover'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-calendar-week ms-4 icon" viewBox="0 0 16 16">
                            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                        </svg>
                        <div className='ms-4' onClick={() => { navigate('applyleave') }}>{t('leave')} </div>
                    </li>
                    {(() => {
                        if (state.user[0].timesheet == "1") {
                            return (
                                <li className={currentUrl === '/yourwork' ? 'active_s' : ' sidebarlist text-white custom-hover'}>
                                    <div class=" ms-4 icon"> <img src={`Images/sidework.svg`} ></img></div>
                                    <div className='ms-4' onClick={() => { navigate('yourwork') }} >{t('myWork')} </div>
                                </li>
                            )
                        } else {

                        }
                    })()}
                    {(() => {
                        if (state.user[0].timesheet == "1") {
                            return (
                                <li className={currentUrl === '/timesheet' ? 'active_s' : ' sidebarlist text-white custom-hover'}>
                                    <div class="ms-4 icon"> <img src={`Images/task.svg`} ></img></div>
                                    <div className='ms-4' onClick={() => { navigate('timesheet') }}>{t('myTimesheet')}   </div>
                                </li>
                            )
                        } else {


                        }
                    })()}



                </ul>


                <div class="mt-3">
                    <ul>






                    </ul>
                </div>

            </div>

            {/* mobile view empsidebar */}
            <div className="head_side onlytab">
                <p className=''>

                    <a class={currentUrl === '/password'   ? 'disabled disp_mobile_none' : ''} ><svg xmlns="http://www.w3.org/2000/svg" 
                     width="55" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" height="46" fill="#4EB4E6" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg></a>
                </p>
                <div class="row">
                    <div class="col-2">
                        <div class="collapse multi-collapse" id="multiCollapseExample1">
                            <div className={currentUrl === '/password' ? 'disabled disp_mobile_none' : ' sidebar'}>

                                <img className='img-fluid ' src={`Images/logoq.png`} ></img>
                                <ul className='ulleft'>
                                    <li className={currentUrl === '/employeedashboard' ? 'sidebarlist text-white li-mobile' : ' sidebarlist text-white li-mobile'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-speedometer2 ms-4 icon" viewBox="0 0 16 16">
                                            <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                                            <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z" />
                                        </svg>
                                        <div className='ms-4' onClick={() => { navigate('employeedashboard') }}>{t('dashboard')}  </div>
                                    </li>


                                    <li className={currentUrl === '/EmpProfileedit' ? 'sidebarlist text-white li-mobile' : ' sidebarlist text-white li-mobile'}>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-person-video2 ms-4 icon" viewBox="0 0 16 16">
                                            <path d="M10 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                            <path d="M2 1a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2ZM1 3a1 1 0 0 1 1-1h2v2H1V3Zm4 10V2h9a1 1 0 0 1 1 1v9c0 .285-.12.543-.31.725C14.15 11.494 12.822 10 10 10c-3.037 0-4.345 1.73-4.798 3H5Zm-4-2h3v2H2a1 1 0 0 1-1-1v-1Zm3-1H1V8h3v2Zm0-3H1V5h3v2Z" />
                                        </svg>
                                        <div className='ms-4' onClick={() => { navigate('EmpProfileedit') }}>{t('profile')} </div>
                                    </li>

                                    <li className={currentUrl === '/attantance' ? 'sidebarlist text-white li-mobile' : ' sidebarlist text-white li-mobile'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-card-checklist ms-4 icon" viewBox="0 0 16 16">
                                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                            <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                        </svg>

                                        <div className='ms-4' onClick={() => { navigate('attantance') }} >{t('attendance')} </div>
                                    </li>

                                    <li className={currentUrl === '/applyleave' || currentUrl === '/leavehistory' || currentUrl === '/holidaycalendar' ? 'sidebarlist text-white li-mobile' : ' sidebarlist text-white li-mobile'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-calendar-week ms-4 icon" viewBox="0 0 16 16">
                                            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                        </svg>
                                        <div className='ms-4' onClick={() => { navigate('applyleave') }}>{t('leave')} </div>
                                    </li>
                                    {(() => {
                                        if (state.user[0].timesheet == "1") {
                                            return (
                                                <li className={currentUrl === '/yourwork' ? 'sidebarlist text-white li-mobile' : ' sidebarlist text-white li-mobile'}>
                                                    <div class=" ms-4 icon"> <img src={`Images/sidework.svg`} ></img></div>
                                                    <div className='ms-4' onClick={() => { navigate('yourwork') }} >{t('myWork')} </div>
                                                </li>
                                            )
                                        } else {

                                        }
                                    })()}
                                    {(() => {
                                        if (state.user[0].timesheet == "1") {
                                            return (
                                                <li className={currentUrl === '/timesheet' ? 'sidebarlist text-white li-mobile' : ' sidebarlist text-white li-mobile'}>
                                                    <div class="ms-4 icon"> <img src={`Images/task.svg`} ></img></div>
                                                    <div className='ms-4' onClick={() => { navigate('timesheet') }}>{t('myTimesheet')}   </div>
                                                </li>
                                            )
                                        } else {


                                        }
                                    })()}



                                </ul>


                                <div class="mt-3">
                                    <ul>






                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EmployeeSidebar;