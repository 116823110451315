import React from 'react'
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { useRef } from 'react';
import moment from 'moment';
import Superside from "./Superside";
import CryptoJS from 'crypto-js';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const Superadminusers = () => {

  const { update, handleSubmit } = useForm();

  let navigate = useNavigate();
  const goadh = () => {
    //debugger;
    navigate("/superadmindashboard");
  };

  const [mail, setmail] = useState("");
  const [password, setpassword] = useState("");
  const [name, setname] = useState("");
  const [loginemail, setloginmail] = useState([]);
  const [loginid, setloginid] = useState("");
  const [editname, seteditname] = useState("");
  const [status, setstatus] = useState();
  const [bank_details, setUserDetails] = useState([]);
  // const [bank, set_bank] = useState("")
  const [bind_user, setBindUser] = useState({});

  const [user_type, setUserType] = useState('');

  // email validator
  const Getemaillist = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/getsadmin`).then((res) => {

        setloginmail(res.data);
      })
    } catch (err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    Getemaillist();
  }, []);
  

  
  const checkifValidemail = (inputmail) => {
    let isValid = true;
    loginemail.forEach(item => {
      if (item.loginid.match(inputmail)) {
        isValid = false;
      }
    });
    return isValid
  }


  const onInsert = (data, e) => {
    if (mail != "") {
      if (checkifValidemail(mail)) {
        try {
          let signup = {

            loginid: mail,
            password_admin: password,
            name: name,
            primary: false,
            status: true,

          };
          axios.post(`${process.env.REACT_APP_API_URL}/superadminup`, signup).then((res) => {
            console.log('user updated', res.data);
            sendEmail();

            Getemaillist();
                    })
        } catch (err) {
          console.log(err.message);
        }
      } else {
        confirmAlert({
          message: 'Email ID already exists',
          buttons: [
            {
              label: t('close') ,
              onClick: () => { },
              style:{
                backgroundColor:'#F36E35'
               
           }
            },
          ]
        });
      }

    } else {
      confirmAlert({
        message: t('fillAllData') ,
        buttons: [
          {
            label: t('close') ,
            onClick: () => { },
            style:{
              backgroundColor:'#F36E35'
             
         }
          },
        ]
      });
    }
  }

  // delete admin
  const Delete = (admin_id) => {
    try {

      axios.delete(`${process.env.REACT_APP_API_URL}/deladmin/` + admin_id).then((res) => {
        console.log(res.data, 'success');

        if (res.data == true) {

        }
        Getemaillist();

      })
    } catch (err) {
      console.log(err.message);
    }
  }
  // password
  const generatePassword = () => {

    const randomPassword =
      Math.random().toString(36).slice(2);

    setpassword(randomPassword);
    if (navigator.clipboard.writeText(randomPassword)) {

      console.log(randomPassword);
    }
  };
  useEffect(() => {
    generatePassword();
  }, []);

  const clickdelete = (admin_id) => {
    confirmAlert({
      //title: 'Cancle',
      message: t('confirmDelete'),
      buttons: [
        {
          label:  t('yes'),
          onClick: () => Delete(admin_id),
          style:{
            backgroundColor:'#F36E35'
           
       }
        },
        {
          label:  t('no'),
          onClick: () => alert(t('areYouSureNotToDelete'))
        }
      ]
    })
  }

  const { language,  } = useLanguage();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const sendEmail = (x) => {
    const emailData = {

      user_email: `${mail}`,
      user_name: `${name}`,
      message: `${password}`,
    };

    const secretKey = 'mysecretkey';
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

    fetch(`${process.env.REACT_APP_API_URL}/sendemailsuper`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: encryptedData }),
      // body: JSON.stringify(emailData),
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
  }
  const send_Email = (x, y) => {
    const emailData = {

      user_email: `${x}`,
      user_name: `${y}`,
      message: `${password}`,
    };

    const secretKey = 'mysecretkey';
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

    fetch(`${process.env.REACT_APP_API_URL}/sendemailsuperres`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: encryptedData }),
      // body: JSON.stringify(emailData),
    })
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
  }



  const form = useRef();

  const ShowDetails = (data, type) => {
    console.log('data', data);
    setBindUser(data);
    setloginid(data.loginid);
    seteditname(data.name);
    setstatus(data.status);
    setUserType(type);
  }
  const updat = () => {
    confirmAlert({
      //title: 'Cancle',
      message:  t('confirmUpdate'),
      buttons: [
        {
          label:  t('yes'),
          onClick: () => onUpdate(),
          style:{
            backgroundColor:'#F36E35'
           
       }
        },
        {
          label:  t('no'),
          onClick: () => alert(t('areYouSureNotToSave'))
        }
      ]
    })
  }
  const onUpdate = (id, e) => {
    let banke = {
      id: bind_user.admin_id,
      loginid: loginid,
      name: editname,
      status: status,

    };

    //console.log("user", user);

    axios.put(`${process.env.REACT_APP_API_URL}/updateadm/${bind_user.admin_id}`, banke).then((res) => {

      console.log('Bank updated', res.data);

      ShowDetails('');
      Getemaillist();
    }).catch(() => {



    })

  }
  const RenderEdit = () => {
    console.log('bind', bind_user);

    return <div className='row'>
      <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>
        <div class="card tabeditcard" >

          <div class="card-body">
            <form onSubmit={handleSubmit(updat)} action="#" method="POST">
              <div class="input-group">
                <input type="text" minlength="3" maxlength="55" class=" form-control inputcolor" autocomplete="" Value={bind_user.loginid} onChange={(e) => setloginid(e.target.value)} placeholder="Bank" aria-label="Username" />
              </div>
              <div class="input-group my-2">
                <input type="text" minlength="3" maxlength="25" class=" form-control inputcolor" autocomplete="" Value={bind_user.name} onChange={(e) => seteditname(e.target.value)} placeholder="Bank" aria-label="Username" />
              </div>
              <div class="input-group my-2">

                <select class="form-select inputcolor" aria-label=" select Day" Value={bind_user.status} onChange={(e) => setstatus(e.target.value)}>
                  <option >status</option>

                  <option value='1'>Active</option>
                  <option value='0'>Disable</option>

                </select>
              </div>
              <div className='row'>
                <button type='submit' class="btn btn-pri createbtn  mt-4 masteradd tabbtn">{t('update')}</button>
                <button type='submit' class="btn btn-danger createbtn  mt-4 masteradd tabbtn" onClick={() => ShowDetails('')}>{t('cancel')}</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>;
  }

  const Renderreset = () => {
    console.log('bind', bind_user);

    return <div className='row'>
      <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>
        <div class="card tabeditcard" >

          <div class="card-body" >
            <form onSubmit={handleSubmit(onreset)} ref={form} action="#" method="POST">
              <div className='row'>
                <p className='text-center fw-bold'>Reset  Password</p>
              </div>
              <div class="input-group">
                <input type="email" name="user_email" class=" form-control inputcolor" autocomplete="" value={bind_user.loginid} placeholder="mail" />
                <input type="text" name="message" class="  form-control inputcolor text_box_size hide_all_pc" id="exampleInputEmail1" aria-describedby="emailHelp" value={password} />

              </div>

              <div className='row'>
                <button type='submit' class="btn btn-pri createbtn  mt-4 masteradd tabbtn"  >Reset Password</button>
                <button class="btn btn-danger createbtn  mt-4 masteradd tabbtn" onClick={() => ShowDetails('')}>{t('cancel')}</button>
              </div>

            </form>
          </div>

        </div>
      </div>
    </div>;
  }
  // reset password
  const onreset = () => {
    let bas = {

      password_admin: password,

    };
    axios.put(`${process.env.REACT_APP_API_URL}/resetbyadmin/${bind_user.admin_id}`, bas).then((res) => {
      console.log('user updated', res.data);
      send_Email(bind_user.loginid, bind_user.name);
      ShowDetails('');
      generatePassword();
    }).catch(() => {

    })
  }



  return (

    <div className='container-fluid '>
      <div className='row vh-100'>
        <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 ">
          <Superside />
        </div>
        <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 bg-white'>
          <div className='row mt-4'>
            <p className='text-center fs-5 fw-bold grey'>Add User</p>
          </div>
          <form onSubmit={handleSubmit(onInsert)} ref={form} action="#" method="POST">
            <div className='row mt-5'>
              <div className='col-2'> </div>
              <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                <label for="exampleInputEmail1" class="form-label ">Login Email</label>
                <input type="email" minlength="3" maxlength="55" name="user_email" class=" form-control inputcolor text_box_size" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => { setmail(e.target.value) }} />
              </div>





            </div>
            <div className='row mt-3'>
              <div className='col-2'> </div>
              <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                <label for="exampleInputEmail1" class="form-label ">Name</label>
                <input type="name" name="user_name" minlength="3" maxlength="25"
                  onKeyPress={(event) => {
                    if (!/[a-zA-Z]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }} class=" form-control inputcolor text_box_size" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => { setname(e.target.value) }} />
              </div>





            </div>

            <div className='row mt-3 hide_all_pc'>
              <div className='col-2'> </div>
              <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                <label for="exampleInputEmail1" class="form-label">Password*</label>
                <input type="password" name="message" class="  form-control inputcolor text_box_size" id="exampleInputEmail1" aria-describedby="emailHelp" value={password} onChange={(e) => { setpassword(e.target.value) }} />
              </div>




            </div>
            <div className='row mt-4'>





            </div>
            <div className='row mt-4'>
              <div className='col-2'> </div>

              <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 d-grid'>
                <button type="submit" class="btn btn-pri  ">ADD</button>
              </div>
              <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 d-grid'>
                <button onClick={() => goadh()} class="btn btn-secondary  ">back</button>
              </div>
            </div>

          </form>
        </div>
        <div className='col-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 bg-white'>
          <div className='row mt-4'>
            <p className='text-center fs-5 fw-bold grey'>User List</p>
          </div>
          <table class="table table-bordered mt-5">
            <table class="table mt-3">
              <thead>
                <tr>
                  <td scope="col" className='fs-6 fw-bold grey'> Name</td>

                  <td scope="col" className='fs-6 fw-bold mobsimplytext grey'>Email</td>
                  {/* <td scope="col" className='fs-6 fw-bold mobsimplytext grey'><small>CreatedOn</small></td> */}
                  <td scope="col" className='fs-6 fw-bold grey'>status </td>
                  <td scope="col" className='fs-6 fw-bold grey'><small>User-Type</small> </td>

                  <td scope="col" className='fs-6 fw-bold grey'>Edit </td>
                  <td scope="col" className='fs-6 fw-bold grey'>{t('delete')} </td>
                  <td scope="col" className='fs-6 fw-bold grey'>Password</td>

                </tr>
              </thead>
              <tbody>
                {loginemail.map((x, index) => {
                  return (
                    <tr key={index}>

                      <td>{x.name}</td>
                      <td className=''><small>{x.loginid}</small></td>

                      {/* <td className=''><small>{moment(x.created_on).format('DD-MM-YY')}</small></td> */}
                      <td>
                        {(() => {
                          if (x.status == true) {
                            return <p className='text-success'>Active</p>
                          }
                          if (x.status == false) {
                            return <p className='text-danger'>Disabled</p>
                          }
                        })
                          ()}</td>
                      <td>
                        {(() => {
                          if (x.primary == true) {
                            return <p>Primary</p>
                          }
                          if (x.primary == false) {
                            return <p>Secondary</p>
                          }
                        })()}</td>
                      <td>
                        {(() => {
                          if (x.primary == true) {
                            return
                          }
                          if (x.primary == false) {
                            return <svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-2 pointer_" viewBox="0 0 16 16">
                              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                            </svg>
                          }
                        })
                          ()}</td>

                      <td>
                        {(() => {
                          if (x.primary == true) {
                            return
                          }
                          if (x.primary == false) {
                            return <svg xmlns="http://www.w3.org/2000/svg" onClick={() => clickdelete(x.admin_id)} width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-2 ms-3 pointer_ " viewBox="0 0 16 16">

                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />

                            </svg>
                          }
                        })
                          ()}</td>
                      <td>
                        {(() => {
                          if (x.primary == true) {
                            return
                          }
                          if (x.primary == false) {
                            return <button className='btn btn-danger' onClick={() => ShowDetails(x, 'reset')}>Reset</button>
                          }
                        })
                          ()}</td>

                    </tr>

                  );
                })}
              </tbody>
            </table>
          </table>

          <div>{(() => {
            switch (user_type) {

              case "edit": return RenderEdit();
              case "reset": return Renderreset();
              default: return "";
            }
          })()}</div>
        </div>

      </div>

    </div>
  )
}

export default Superadminusers
