
import React, { useCallback, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { store } from '../../store/user';
// const SuccessPage = () => {
//     const { state } = useContext(store);
//     const globalState = useContext(store);
//     const { dispatch } = globalState;
//     useEffect(() => {
//     // Get the sessionId from the URL, assuming it's present as a query parameter
//     const urlParams = new URLSearchParams(window.location.search);
//     const sessionId = urlParams.get('sessionId');

//     if (sessionId && state.user) {
//       // Make a request to your server to get payment details
//       axios.put(`${process.env.REACT_APP_API_URL}/get_payment_details/${state?.user[0]?.user_id}`, { sessionId})
//         .then(response => {
//           const paymentDetails = response.data.paymentDetails;
//           console.log('Payment Details:', paymentDetails);
        
//             let bas = {
//                 loginmail: state?.user[0]?.loginmail,
//                 password: state?.user[0]?.password,
//             };
//             axios.post(`${process.env.REACT_APP_API_URL}/login_employee`, bas).then((res) => {
//                 console.log('valid User', res.data);
//                 //  Getloginuser () ;
//                 if (res.data.length > 0) {
    
//                     localStorage.setItem('user_details', JSON.stringify(res.data));
                  
//                     console.log(res.data[0].login_id, 'login_id')
//                     dispatch({ type: 'ADD_USER', payload: { user: res.data } });//context api updated. 
//                 }
    
    
//             }).catch(() => {
//                 //  alert("wrong password");
//             })
        
//         })
//         .catch(error => {
//           console.error('Error fetching payment details:', error);
//         });
//     }
//   }, [state]); // Empty dependency array to run the effect only once on mount

// let navigate = useNavigate();


// const gohome=()=>{
//   navigate("/billing");
// }
//   return (
//     <div className="success-container">
//       <div className="success-card card">
//         <div className="success-icon">&#10003;</div>
//         <h5>Payment Successful!</h5>
//         <p>Thank you for your payment.</p>
//         <button className='btn btn-primary' onClick={()=>gohome()}>home</button>
//       </div>
//     </div>
//   );
// };

// export default SuccessPage;

// import React, { useContext, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { store } from '../store'; // Make sure to import your store

const SuccessPage = () => {
  const { state, dispatch } = useContext(store);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('sessionId');

    if (sessionId && state.user) {
      axios.put(`${process.env.REACT_APP_API_URL}/get_payment_details/${state?.user[0]?.user_id}`, { sessionId })
        .then(response => {
          const paymentDetails = response.data.paymentDetails;
          console.log('Payment Details:', paymentDetails);

          let bas = {
            loginmail: state?.user[0]?.loginmail,
            password: state?.user[0]?.password,
          };

          axios.post(`${process.env.REACT_APP_API_URL}/login_employee`, bas)
            .then((res) => {
              console.log('valid User', res.data);

              if (res.data.length > 0) {
                localStorage.setItem('user_details', JSON.stringify(res.data));
                dispatch({ type: 'ADD_USER', payload: { user: res.data } });
                setLoading(false); // Set loading to false once the response is received
              }
            })
            .catch(() => {
              // Handle error
              setLoading(false); // Set loading to false even if there's an error
            });
        })
        .catch(error => {
          console.error('Error fetching payment details:', error);
          setLoading(false); // Set loading to false in case of an error
        });
    }
  }, [state, dispatch]);

  const gohome = () => {
    navigate("/billing");
  };

  return (
    <div className="success-container">
      {loading ? (
           <div className="loading-screen"></div>
      ) : (
        // Render success message once the response is received
        <div className="success-card card">
          <div className="success-icon ">&#10003;</div>
          <h5>Payment Successful!</h5>
          <p>Thank you for your payment.</p>
          <button className='btn btn-primary' onClick={gohome}>Home</button>
        </div>
      )}
    </div>
  );
};

export default SuccessPage;