import React, { useCallback, useState, useEffect, useContext } from 'react';
import './PersonalDetails.css';
import { useNavigate, useLocation } from "react-router-dom";
import AEHeader from '../Header/AEHeader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { store } from '../../store/user';
import CryptoJS from 'crypto-js';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const SalaryDetails = (props) => {
    const { state } = useContext(store);
    console.log('salarydtl', state.user);
    const [serviceList, setServiceList] = useState([{ head_name: "" }]);
    const [shift_details, setShiftDetails] = useState([]);
    const [basic_details, setbasicDetails] = useState([]);

    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceList];
        list[index][name] = value;
        setServiceList(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };

    const handleServiceAdd = () => {
        setServiceList([...serviceList, { head_name: "" }]);
    };
    let navigate = useNavigate();
    const employee = () => {
        let path = `/employeelist`;
        navigate(path);
    }
    const empper = (x) => {
        let path = `/personaldetails`;
        navigate(path, { state: { x: x } });
    }
    const location = useLocation();
    console.log('emp_id: ', location?.state?.x?.emp_id);

    const [emaildetails, setemailDetails] = useState([]);
    const [email, setemail] = useState([]);
    const [fname, setfname] = useState([]);
    const [password, setpassword] = useState([]);

       const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const Getemail = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/Emailsalself/${state?.user[0]?.company_id}/${location?.state?.x?.emp_id}`).then((res) => {
                console.log(res.data, 'succ_ess');
                setemailDetails(res.data);
                setpassword(res?.data[0].password);
                setfname(res?.data[0].full_name);
                setemail(res?.data[0].email);
                
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const [bind_user, setBindUser] = useState({});
    const GetShift = (emp_id) => {

        let emp = {
            emp_id: bind_user.emp_id,
        };
        axios.get(`${process.env.REACT_APP_API_URL}/Employee_salary`).then((res) => {
            console.log(res.data, 'success');
            setShiftDetails(res.data);

        }).catch(() => {

        })
    }
    const ShowDetails = (data, type) => {

        console.log('data', data);
        setBindUser(data);

    }
    const Getbasic = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/empbasic`).then((res) => {
                console.log(res.data, 'success');
                setbasicDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to save and continue',
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert('Canceled')
                }
            ]
        })
    }

    const aletcan = () => {
        confirmAlert({
            // title: 'Cancle',
            message: t('confirmCancel') ,
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => employee(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert('Canceled')
                }
            ]
        })
    }
    const save = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Data saved succesfully yes to continue',
            buttons: [
                {
                    label: 'Addmore',
                    onClick: () => Getsalcomp(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'Exit',
                    onClick: () => window.location = "/employeelist"
                }
            ]
        })
    }

    const { update, handleSubmit } = useForm();
    const [accno, setaccno] = useState("");
    const [accno_2, setaccno_2] = useState("");
    const [saltype, setsaltype] = useState([]);

    const checkifValidcomp = (component) => {
        console.log(component, 'salcomp');
        let isValid = true;
        salary_list.forEach(item => {
            console.log(salary_list, 'salcomp');
            if (item.salarycomponent.match(component)) {
                isValid = false;
            }
        });
        return isValid
    }
    const onInsert = (data, e) => {
        if (saltype != "") {
            if (checkifValidcomp(saltype)) {
                try {
                    let bnk = {
                        emp_id: location?.state?.x?.emp_id,
                        salarycomponent: saltype,
                        amount: accno,
                        createdby: 1,
                        salarycomponent_type: salary_details.filter(item => item?.head_name == saltype)[0]?.scope,
                        ispayrollcomponent: false,
                        payroll_id: 0,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/Employee_salary`, bnk).then((res) => {
                        console.log('user updated', res.data);
                        save();

                    })

                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Selected component already exists',
                    className: 'text-center',
                    buttons: [
                        {
                            label: t('close') ,
                            onClick: () => { },
                            style:{
                                backgroundColor:'#F36E35'
                               
                           }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        onClick: () => { },
                        style:{
                            backgroundColor:'#F36E35'
                           
                       }
                    },
                ]
            });
        }
    }

    // send employee email self service portal
    const sendEmail = (x) => {
        const emailData = {

            user_email: `${email}`,
            user_name: `${fname}`,
            message: `${password}`,
        };

        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailemp`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }




    const [wagetyp, setwagetyp] = useState("");
    const [accno1, setaccno1] = useState("");
    const [saltype1, setsaltype1] = useState("");
    const onInsertgross = (data, e) => {
        let amountbasic;
        if (wagetyp === "Annually") {
            amountbasic = accno1 / 12;
        } else {
            amountbasic = accno1;
        }
        let bnk = {
            emp_id: location?.state?.x?.emp_id,
            amountbasic: amountbasic,
            createdby: 1

        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employeebasicsalary`, bnk).then((res) => {
            console.log('user updated', res.data);
            onInsertsalhistory(amountbasic)

            onUpdatewage();
            alerts(location?.state?.x);
            Getsalc();

        }).catch(() => {

        })
    }
    const onUpdatewage = (job_id, e) => {

        let wage = {
            emp_id: location?.state?.x?.emp_id,
            wagetype: wagetyp,

        };

        axios.put(`${process.env.REACT_APP_API_URL}/updatewagetype/${location?.state?.x?.emp_id}`, wage).then((res) => {
            console.log('wage updated', res.data);
        }).catch(() => {

        })
    }
    // handle disabled submit
    const isValid = accno1

    const isValids = wagetyp !== "" && accno1

    // new insert to grosshistory tble on update of gross
    const onInsertsalhistory = (amountbasic) => {

        let history = {
            emp_id: location?.state?.x?.emp_id,
            grossamount: amountbasic,
            updateby: state?.user[0]?.first_name
        };


        axios.post(`${process.env.REACT_APP_API_URL}/grosshistory`, history).then((res) => {

            console.log('user updated', res.data);


        }).catch(() => {

        })
    }
    const [salary_details, setSalaryDetails] = useState([]);
    const [salarylist, setsallist] = useState([]);
    const [salary_list, setsal_list] = useState([]);
    const GetSalary = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/salary/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setSalaryDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const Getsalc = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/Employeebasicsal/${location?.state?.x?.emp_id}`).then((res) => {
                console.log(res.data, 'salbas');
                setsallist(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const Getsalcomp = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/Employee_salary/${location?.state?.x?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setsal_list(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const alerts = (x) => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Data saved succesfully yes to continue to personal details',
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => empper(x),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label: 'Save & Exit',
                    onClick: () => employee()
                }
            ]
        })
    }
    useEffect(() => {
        Getemail();
        Getsalc();
        GetSalary();
        GetShift();
        Getbasic();
    }, [state]);

    const clickdelete = (empsalid) => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmDelete'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Delete(empsalid),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToDelete'))
                }
            ]
        })
    }
    const Delete = (empsalid) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/Employeedelsalary/` + empsalid).then((res) => {
                console.log(res.data, 'success');
                //window.location = "/leavetype";
                if (res.data == true) {

                }

                Getsalcomp();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    return (
        <div className='container-fluid'>

            <div className='row mt-1'>
                <div className='col-2 col-xxl-2 col-sm-3 col-md-2 col-lg-2 col-xl-2 hid_tab'>

                </div>

                <div className='col-10  col-xxl-10 col-sm-9 col-md-10 col-lg-10 col-xl-10 mt-5 tabcl mob_bs'>
                    <div className='row mt-2'>
                        <div className='col-3'></div>

                    </div>
                    <div className='row mt-5 border-bottom scroll-containerbasic'>
                        <div className='col-1  col-xxl-1 col-sm-1 col-md-1 col-lg-1 col-xl-1'></div>
                        <div className='col-2  mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-3 col-xxl-3  col-md-3 col-lg-3 col-xl-2'><img className='' src={`Images/base.svg`} ></img></div>
                                <div className='col-7 col-xxl-7  col-md-7 col-lg-7 col-xl-8'><a class="nav-item nav-link  text-secondary text-tabcolor  " id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">{t('basicDetails')}</a>
                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #53B7B7" class="me-2 bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 baswid'>
                            <div className='row'>
                                <div className='col-3 col-xxl-3  col-md-3 col-lg-3 col-xl-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="gray" class="bi bi-coin  " viewBox="0 0 16 16">
                                    <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                </svg></div>
                                <div className='col-7 col-xxl-7 col-md-7 col-lg-7 col-xl-8'><a class="nav-item nav-link  text-tabcolor  border-bottom border-primary border-3 " id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">{t('salaryDetails')} </a>

                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 baswid'>
                            <div className='row'>
                                <div className='col-2  col-xxl-2  col-md-2 col-lg-2 col-xl-2'><img className='' src={`Images/personal.svg`} ></img></div>
                                <div className='col-8'><a class="nav-item nav-link  text-secondary   " id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">{t('personalDetails')}</a>

                                </div>
                                <div className='col-1 '>  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill  " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-2 col-xxl-2  col-md-2 col-lg-2 col-xl-1'></div>
                                <div className='col-3 col-xxl-2  col-md-2 col-lg-2 col-xl-2'> <img className='' src={`Images/tax.svg`} ></img></div>
                                <div className='col-4'> <a class="nav-item nav-link  text-tabcolor text-secondary" id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="" aria-selected="false">{t('tax')} </a>

                                </div>
                                <div className='col-2 me-3'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill me-5 " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                            </div>
                        </div>
                        <div className='col-2 mobsimplytext hide_tab'>
                            <div className='row'>
                                <div className='col-3'><img className='img-fluid' src={`Images/pay_1img.svg`} ></img></div>
                                <div className='col-5'><a class="nav-item nav-link  text-tabcolor   text-secondary " id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="" aria-selected="false">{t('payment')}  </a>
                                </div>
                                <div className='col-2'> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill=" #D9D9D9" class="bi bi-check-circle-fill " viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg></div>
                                <div className='col-2'></div>
                            </div>
                        </div>



                    </div>
                    



                    <div className='row bastextmob'>
                        <div className='col-3 col-xxl-3 col-sm-4 col-md-3 col-lg-3 col-xl-3 '></div>
                        <div className=' col-xxl-5 col-sm-5 col-md-7 col-lg-7 col-xl-5 ms-3 '>

                            <form className="App" autoComplete="off" onSubmit={handleSubmit(onInsertgross)} action="#" method="POST">
                                <div className='row mt-4'>
                                    <div className='col-11'>
                                        <div class="form-group">
                                            <label for="formGroupExampleInput" class="required ms-1">{t('wageType')}e</label>
                                            <select class="form-select inputcolor text-start text_box_size" Value={wagetyp} onChange={(e) => setwagetyp(e.target.value)} aria-label=" select Day">
                                                <option value="">{t('wageType')}e </option>
                                                <option value='Monthly'>{t('monthly')}</option>
                                                <option value='Annually'>{t('annually')}</option>

                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className='row '>
                                    <div className='col-6'>
                                        <small className='ms-1 required'>{t('wage')}</small>
                                        <input type="text" class=" form-control inputcolor text_box_size" Value="Wage" disabled="disabled" aria-label="Username" />

                                    </div>
                                    <div className='col-5'>
                                        <small className='ms-1 '></small>
                                        <input type="text" onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                            minlength="1" maxlength="30" class="  form-control inputcolor text_box_size " Value={accno1} onChange={(e) => setaccno1(e.target.value)} placeholder="Amount" aria-label="Username" />

                                    </div>
                                    <div className='col-1'>
                                        <small className='ms-1 '></small>
                                        {/* {salarylist.map((x, index) => {
                                            return (<div> */}
                                        {/* <button className='btn btn-pri  text_box_size' type='submit'>ADD</button> */}
                                        {/* {(() => {
                                            if (salarylist.length == 0) {
                                                return <button className='btn btn-pri text_box_size mt-4' type='submit' >ADD</button>


                                            }
                                            else {
                                                return <button className='btn btn-pri text_box_size mt-4' type='submit' disabled >ADD</button>

                                            }
                                        })()} */}


                                        {/* </div> */}
                                        {/* );
                                        })} */}
                                    </div>

                                </div>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <div class="form-check">
                                            <input class="form-check-input  " type="checkbox" value="" id="flexCheckDefault" onChange={sendEmail} />
                                            <label class="form-check-label  " for="flexCheckDefault">

                                                {t('sendEmployeeSSPortalAccess')}*
                                            </label>
                                        </div>
                                    </div>
                                    <input type="email" name="user_email" class="form-control inputcolor hide_all_pc " placeholder="Email" />
                                    <input type="address" className='hide_all_pc' name="message" />

                                </div>
                                <div className='row mt-4'>
                                    <div className='col-2 d-grid '>
                                        {/* <button class="btn btn-secondary text_box_size " type="button" onClick={aletcan}>{t('cancel')}</button> */}
                                    </div>
                                    <div className='col-6 d-grid'>
                                        {/* <form onSubmit={handleSubmit(onInsert)} className="d-grid" action="#" method="POST"> */}
                                        <button class="btn btn-pri text_box_size  " type="submit" disabled={!isValids} >{t('submit')}</button>

                                        {/* </form>  */}
                                    </div>

                                </div>

                            </form>

                            {/* <form className="App" autoComplete="off" onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                                <div className='row mt-5'>
                                    <div className='col-6'>

                                        <select name="head_name" class=" text-start text-secondary form-select inputcolor text_box_size  " id="service" aria-label=" select Day"

                                            onChange={(e) => setsaltype(e.target.value)}


                                            required>
                                            <option>Select Salary Component</option>
                                            {salary_details.map((x, index) => {

                                                return (

                                                    <option value={x.head_name}>{x.head_name}</option>
                                                );
                                            })}

                                        </select>

                                    </div>
                                    <div className='col-5  '>

                                        <input type="text" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                          event.preventDefault();
                                                        }
                                                      }} 
                                                      minlength="1" maxlength="30" class=" form-control inputcolor text_box_size  " Value={accno} onChange={(e) => setaccno(e.target.value)} placeholder="Amount" name='amount' aria-label="Username" />


                                    </div>
                                    <div className='col-1'>
                                        <button className='btn btn-pri  text_box_size' type='submit'>ADD</button>
                                    </div>
                                </div>
                                
                                <div className='row mt-3'>
                                    <div className='col-3'></div>
                                    <div className='col-6 d-grid'>

                                        <button class="btn btn-secondary  text_box_size  " type="button" onClick={employee} disabled={!isValid}>Complete</button>
                                    </div>
                                    
                                </div>




                            </form> */}

                        </div>
                        {/* <div className='row'>
                            <div className='col-3'></div>
                            <div className='col-6'>
                                <table class="table table-bordered mt-5">

                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <td scope="col" className='fs-6 fw-bold grey'>Salary Type</td>
                                                <td scope="col" className='fs-6 fw-bold grey' >Amount(VND)</td>

                                                <td scope="col" className='fs-6 fw-bold grey'>Delete</td>
                                                <td scope="col" ></td>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {salarylist.map((x, index) => {
                                                return (
                                                    <tr key={index}>

                                                        <td>Basic Salary</td>
                                                        <td>{Math.round (x.amountbasic)}</td>


                                                        <td></td>

                                                        <td></td>




                                                    </tr>

                                                );
                                            })}
                                            {salary_list.map((x, index) => {
                                                return (
                                                    <tr key={index}>

                                                        <td>{x.salarycomponent}</td>
                                                        <td>{x.amount}</td>


                                                        <td><svg onClick={() => clickdelete(x.empsalid)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3   me-5" viewBox="0 0 16 16">

                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />

                                                        </svg></td>

                                                        <td></td>

                                                        <td></td>




                                                    </tr>

                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </table>
                            </div>

                        </div> */}
                        <div className='row mt-3'>
                            <div className='col-4'></div>
                            <div className='col-4'>             <img className="  img-fluid hide_tab mobsimplytext" src="./Images/basebot.svg" />
                            </div>
                        </div>
                    </div>





                </div>
            </div>


            <AEHeader />
        </div>
    );
}
export default SalaryDetails;
