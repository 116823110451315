import React, { useCallback, useState, useContext, useEffect } from 'react';
import './LeaveManagement.css';
import { useNavigate, useParams } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Leavedetails from './Leavedetails';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from "react-paginate";
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const LeaveManagement = () => {
    const { state } = useContext(store);
    console.log('leave', state.user);
    let navigate = useNavigate();
    const leavedetails = () => {
        let path = `/leavedetails`;
        navigate(path);
    }
    const leave = () => {
        let path = `/leave`;
        navigate(path);
    }
    const { update, handleSubmit } = useForm();
    const [bind_user, setBindUser] = useState({});
    const [leave_details, setUserDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    // const Getleave = (x) => {
    //     if (state.user)
    //         axios.get(`${process.env.REACT_APP_API_URL}/applyleave/${state?.user[0]?.company_id}`).then((res) => {
    //             let datalist = res.data;
    //             console.log('before filter: ', datalist);
    //             if (state?.user[0]?.usertypename && state?.user[0]?.usertypename == 'Supervisor') {
    //                 datalist = datalist.filter(x => x?.manager == state?.user[0]?.emp_id)
    //             }
    //             console.log('after filter: ', datalist);
    //             setUserDetails(datalist);
    //         }).catch((error) => {
    //             console.log(error, 'success');
    //         });
    // }
    const Getleave = (x) => {
        if (state.user) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/applyleave/${state?.user[0]?.company_id}`)
                .then((res) => {
                    let datalist = res.data;
                    console.log('before filter: ', datalist);
                    if (state?.user[0]?.usertypename && state?.user[0]?.usertypename == 'Supervisor') {
                        datalist = datalist.filter(x => x?.manager == state?.user[0]?.emp_id);
                    }
                    console.log('after filter: ', datalist);

                    // Calculate date difference for each item
                    datalist.forEach(item => {
                        const fromDate = new Date(item.fromdate);
                        const toDate = new Date(item.todate);

                        // Check if the dates are the same
                        if (fromDate.toDateString() === toDate.toDateString()) {
                            item.dateDifference = 1;
                        } else {
                            const dateDifference = Math.ceil((toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24));
                            item.dateDifference = dateDifference;
                        }
                    });


                    setUserDetails(datalist);
                })
                .catch((error) => {
                    console.log(error, 'success');
                });
        }
    };

    useEffect(() => {
        Getleave();
    }, [state]);
    const [comnt, set_editcmnt] = useState("");
    const [paid, set_editpaid] = useState("true");
    const onUpdateA = (applyleave_id, e) => {
        let assigne;
        if (state?.user[0]?.emp_id === null) {
            assigne = state.user[0].user_id;
        } else {
            assigne = state.user[0].emp_id;
        }
        let upcur = {
            applyleave_id: bind_user.applyleave_id,
            approved: "true",
            paid: paid,
            comment: comnt,
            updatedby: assigne,

        };

        //console.log("user", user);



        axios.put(`${process.env.REACT_APP_API_URL}/apply_leave/${bind_user.applyleave_id}`, upcur).then((res) => {
            console.log('Response:', res);
            console.log('leave approved', res.data);
            if (res?.data[0]?.paid != false) {
                console.log(res?.data[0]?.paid, "paidtest")
                onInsertaddtime();
                onInsertaddleave();
                onUpdatelv();
            }


            //Update();



            // alert("Click Ok to approve");
            // leave();
        }).catch(() => {



        })

    }

    
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    // const onInsertaddtime = (data, e) => {
    //     const startDate = moment(bind_user.fromdate);
    //     const endDate = moment(bind_user.todate);
    //     let totalhr;
    //     let content;
    //     console.log('paid:', paid); // Add this line
    //     if (paid !== "false") { 
    //         totalhr = 8;
    //         content="Leave";
    //     } else {
    //         totalhr = 0;
    //         content="Unpaid Leave";
    //     }
    //     while (startDate <= endDate) {
    //         let time = {
    //             emp_id: bind_user.emp_id,
    //             date: startDate.format('YYYY-MM-DD'),
    //             totalhr: totalhr,
    //             content: content,

    //         };

    //         axios.post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
    //             .then((res) => {
    //                 console.log('user updated', res.data);
    //             })
    //             .catch(() => { });

    //         startDate.add(1, 'day');
    //     }
    // };




    // const onInsertaddtime = (data, e) => {
    //     const startDate = moment(bind_user.fromdate);
    //     const endDate = moment(bind_user.todate);
    //     let totalhr;
    //     let content;
    //     console.log('paid:', paid); // Add this line

    //     if (paid !== "false") {
    //         totalhr = 8;
    //         content = "Leave";
    //     } else {
    //         totalhr = 0;
    //         content = "Unpaid Leave";
    //     }

    //     let existingDate = null; // Track the existing date if found

    //     while (startDate <= endDate) {
    //         let currentDate = startDate.format('YYYY-MM-DD');
    //         let time = {
    //             emp_id: bind_user.emp_id,
    //             date: currentDate,
    //             totalhr: totalhr,
    //             content: content,
    //         };

    //         axios.get(`${process.env.REACT_APP_API_URL}/attgetdate/${bind_user.emp_id}/${startDate.format('YYYY-MM-DD')}/${endDate.format('YYYY-MM-DD')}`)
    //             .then((res) => {
    //                 if (res.data.length === 0) {
    //                     // Date not found in employee_attendance table, add a new entry
    //                     axios.post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
    //                         .then((res) => {
    //                             console.log('user updated', res.data);
    //                         })
    //                         .catch(() => { });
    //                 } else {
    //                     // Date found in employee_attendance table, update the existing entry
    //                     existingDate = currentDate; // Store the existing date
    //                     const existingAttendance = res.data[0];
    //                     const updatedAttendance = {
    //                         emp_id: bind_user.emp_id,
    //                         date: currentDate,
    //                         totalhr: totalhr,
    //                         createdby: 1,
    //                         contents: content
    //                     };
    //                     axios.put(`${process.env.REACT_APP_API_URL}/updateattendance/${existingAttendance.attendance_id}`, updatedAttendance)
    //                         .then((res) => {
    //                             console.log('attendance updated', res.data);
    //                         })
    //                         .catch(() => { });
    //                 }
    //             })
    //             .catch(() => { });

    //         startDate.add(1, 'day');
    //     }

    //     // Add the remaining date as a new entry if an existing date was found
    //     if (existingDate) {
    //         const remainingDate = existingDate === bind_user.fromdate ? bind_user.todate : bind_user.fromdate;
    //         const remainingTime = {
    //             emp_id: bind_user.emp_id,
    //             date: remainingDate,
    //             totalhr: totalhr,
    //             content: content,
    //         };
    //         axios.post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, remainingTime)
    //             .then((res) => {
    //                 console.log('user updated', res.data);
    //             })
    //             .catch(() => { });
    //     }
    // };



    // based on applied leave stores those many days in employee_attendance when leave is approved in {t('leaveManagement')}

    const onInsertaddtime = (data, e) => {
        const startDate = moment(bind_user.fromdate);
        const endDate = moment(bind_user.todate);
        let content;
        console.log('paid:', paid); // Add this line

        let existingDate = null; // Track the existing date if found

        const workSchedule = {}; // Store the employee's work schedule

        // Fetch the employee's work schedule
        axios.get(`${process.env.REACT_APP_API_URL}/get_work_schedule/${bind_user.emp_id}`)
            .then((res) => {
                if (res.data.length > 0) {
                    const schedule = res.data[0];
                    workSchedule.monday = schedule.monday;
                    workSchedule.tuesday = schedule.tuesday;
                    workSchedule.wednesday = schedule.wednesday;
                    workSchedule.thursday = schedule.thursday;
                    workSchedule.friday = schedule.friday;
                    workSchedule.saturday = schedule.saturday;
                    workSchedule.sunday = schedule.sunday;
                }

                while (startDate <= endDate) {
                    let currentDate = startDate.format('YYYY-MM-DD');
                    let totalhr;



                    if (paid !== "false") {
                        if (workSchedule[startDate.format('dddd').toLowerCase()] === 8) {
                            totalhr = 4; // Half working day
                            content = "Leave";
                        } else if (workSchedule[startDate.format('dddd').toLowerCase()] === 7) {
                            if (bind_user.session_1) {
                                totalhr = 4; //Half  working day
                                content = "Leave";
                            } else {
                                totalhr = 8; //Full  working day
                                content = "Leave";
                            }
                        }
                        else {
                            totalhr = 0; // weekend working day
                            content = "Leave";
                        }

                    } else {
                        totalhr = 0;
                        content = "Unpaid Leave";
                    }

                    let time = {
                        emp_id: bind_user.emp_id,
                        date: currentDate,
                        totalhr: totalhr,
                        content: content,
                    };

                    axios.get(`${process.env.REACT_APP_API_URL}/attgetdate/${bind_user.emp_id}/${startDate.format('YYYY-MM-DD')}/${endDate.format('YYYY-MM-DD')}`)
                        .then((res) => {
                            if (res.data.length === 0) {
                                // Date not found in employee_attendance table, add a new entry
                                axios.post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, time)
                                    .then((res) => {
                                        console.log('user updated', res.data);
                                    })
                                    .catch(() => { });
                            } else {
                                // Date found in employee_attendance table, update the existing entry
                                existingDate = currentDate; // Store the existing date
                                const existingAttendance = res.data[0];
                                const updatedAttendance = {
                                    emp_id: bind_user.emp_id,
                                    date: currentDate,
                                    totalhr: totalhr,
                                    createdby: 1,
                                    contents: content
                                };
                                axios.put(`${process.env.REACT_APP_API_URL}/updateattendance/${existingAttendance.attendance_id}`, updatedAttendance)
                                    .then((res) => {
                                        console.log('attendance updated', res.data);
                                    })
                                    .catch(() => { });
                            }
                        })
                        .catch(() => { });

                    startDate.add(1, 'day');
                }

                // Add the remaining date as a new entry if an existing date was found
                if (existingDate) {
                    const remainingDate = existingDate === bind_user.fromdate ? bind_user.todate : bind_user.fromdate;

                    // Adjust totalhr based on the work schedule for the remaining date
                    let totalhr;

                    if (workSchedule[remainingDate] === 8) {
                        totalhr = 4; // Half working day
                    } else if (workSchedule[remainingDate] === 7) {
                        totalhr = 8; // Full working day
                    } else {
                        totalhr = 0; // weekend working day
                    }

                    const remainingTime = {
                        emp_id: bind_user.emp_id,
                        date: remainingDate,
                        totalhr: totalhr,
                        content: content,
                    };
                    axios.post(`${process.env.REACT_APP_API_URL}/time_sheet_add`, remainingTime)
                        .then((res) => {
                            console.log('user updated', res.data);
                        })
                        .catch(() => { });
                }
            })
            .catch(() => { });
    };



    const onInsertaddleave = (data, e) => {
        const startDate = moment(bind_user.fromdate);
        const endDate = moment(bind_user.todate);

        while (startDate <= endDate) {
            let time = {
                emp_id: bind_user.emp_id,
                applyleave_id: bind_user.applyleave_id,
                date: startDate.format('YYYY-MM-DD'),
            };

            axios.post(`${process.env.REACT_APP_API_URL}/leavemanagement`, time)
                .then((res) => {
                    console.log('user updated', res.data);
                })
                .catch(() => { });

            startDate.add(1, 'day');
        }
    };
    const onUpdater = (applyleave_id, e) => {
        let assigne;
        if (state?.user[0]?.emp_id === null) {
            assigne = state.user[0].user_id;
        } else {
            assigne = state.user[0].emp_id;
        }
        let upcu = {
            applyleave_id: bind_user.applyleave_id,
            approved: "false",
            comment: comnt,
            updatedby: assigne,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/apply_leave/${bind_user.applyleave_id}`, upcu).then((res) => {

            console.log(' updated', res.data);

            ShowDetails('');
            Getleave();
        }).catch(() => {



        })

    }
    const onUpdatelv = (e) => {
        let upcu = {
            applyleave_id: bind_user.emp_id,

            leave_count: -bind_user.leavecount,
            leave_type_id: bind_user.leave_type

        };
        axios.put(`${process.env.REACT_APP_API_URL}/employeeleaveupdate/${bind_user.emp_id}`, upcu).then((res) => {

            console.log(' updated', res.data);
            ShowDetails('');
            Getleave();

        }).catch(() => {



        })

    }
    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to continue',
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Getleave(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('canceled'))
                }
            ]
        })
    }
    const [emleave, setemplev] = useState([]);
    const Getleave_emp = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/encashdetails/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'leaveemp');
                setemplev(res.data);
            }).catch(() => {

            })
        }


    }
    const [encshl, setencshl] = useState([]);
    const Getencshl = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/encashleavelist/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'encshl');
                setencshl(res.data);
            }).catch(() => {

            })
        }


    }
    const [query, SetQuery] = useState("");

    // page No
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 8;
    const pagesVisited = pageNumber * usersPerPage;


    const filteredUsers = leave_details.filter((x) => x.approved.toLowerCase().includes(query.toLowerCase()) || x.emp_name.toLowerCase().includes(query.toLowerCase()));

    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);


    const pageCount = Math.ceil(leave_details.length / usersPerPage);



    const [page_Number, setPage_Number] = useState(0);

    const usersPer_Page = 14;
    const pages_Visited = page_Number * usersPer_Page;

    const [que_ry, Set_Query] = useState("");

    const filtered_Users = emleave.filter((x) => x.leave_type.toLowerCase().includes(que_ry.toLowerCase()) || x.full_name.toLowerCase().includes(que_ry.toLowerCase()));

    const display_Users = filtered_Users.slice(pages_Visited, pages_Visited + usersPer_Page);


    const page_Count = Math.ceil(emleave.length / usersPer_Page);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const change_Page = ({ selected }) => {
        setPage_Number(selected);
    };

    let salaryId; // Declare the salaryId variable outside the GetSalary method

    const GetSalary = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/salary/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                const filteredSalary = res.data.filter(salary => salary.head_name === "Leave Encashment");
                if (filteredSalary.length > 0) {
                    salaryId = filteredSalary[0].head_name;
                    // Use the salaryId as needed
                    console.log("Salary ID:", salaryId);
                    // Call another method or perform operations that require salaryId
                    anotherMethod(salaryId);
                } else {
                    console.log("No salary found with head_name 'Leave Encashment'");
                }
            }).catch((error) => {
                console.log(error, 'error');
            });
        }
    }

    // Example method that uses salaryId
    const anotherMethod = (id) => {
        console.log("Using salaryId:", id);
        // Perform operations with salaryId
    }

    // Usage
    GetSalary();


    const RenderView = () => {

        return (
            <div className="bg-light container-fluid edit_ren">

                <div className="row">

                    <div className="col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2  hide_tab">

                    </div>
                    <div className="col-xxl-10 col-sm-10 col-md-12 col-lg-12 col-xl-10 bg-light mt-3 tblle">

                        <div className="card  mt-4 ms-sm-5  ">
                            <div className="card-body ">
                                <div className="row">

                                    <div className="col">
                                        <div className='row'>


                                            <div className='col'>
                                                <p className="fs-5 fnt-clr"> {t('leaveDetails')}</p>
                                                <div className="row">
                        <div className="ms-4 mt-2 onlytab">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                        </div>
                    </div>
                                                <table class="table table-bordered mt-4 year-buttonss">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>  <td scope="col"></td>

                                                                <td scope="col-sm" className='headfont  text-center text-uppercase grey'>{t('name')}</td>

                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('dat')}</td>
                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '>  {t('halfDay')} </td>
                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '> {t('fullDay')} </td>
                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '> {t('appliedDays')} </td>
                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('leaveType')} </td>
                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('leaveAvailable')}</td>
                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('department')}  </td>
                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('status')}</td>
                                                                <td scope="col-sm" className='headfont   text-center text-uppercase grey '>Un-Paid</td>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            <tr>
                                                                <th scope="row"><div className="ms-3">  </div></th>

                                                                <td className=''><p className='text-center greyh'>{bind_user.emp_name}</p></td>
                                                                <td className=' text-center greyh'><p>{moment(bind_user.fromdate).format('MM DD YY')} - {moment(bind_user.todate).format('MM DD YY')}</p></td>
                                                                {(() => {
                                                                    if (bind_user.session_2 == false && bind_user.session_1 == true) {
                                                                        return <td className='text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#53B7B7" class="bi bi-check-square-fill ms-3" viewBox="0 0 16 16">
                                                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                                                        </svg> </td>
                                                                    } else {
                                                                        return <td className='text-center'>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3" viewBox="0 0 16 16">
                                                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                        </svg></td>
                                                                    }
                                                                })()}

                                                                {(() => {
                                                                    if (bind_user.session_1 == false) {
                                                                        return <td className='text-center'>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#53B7B7" class="bi bi-check-square-fill ms-3" viewBox="0 0 16 16">
                                                                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                                                        </svg></td>
                                                                    } else {
                                                                        return <td className='text-center'>  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3" viewBox="0 0 16 16">
                                                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                        </svg></td>
                                                                    }
                                                                })()}
                                                                <td className=' text-center greyh'>{bind_user.leavecount}</td>
                                                                <td className=' text-center greyh'>{bind_user.leave_ty_pe}</td>
                                                                <td className=' text-center greyh'><p className='ms-5'> {bind_user.casualleave}</p></td>
                                                                <td className=' text-center greyh'>{bind_user.departments}</td>

                                                                {(() => {
                                                                    if (bind_user.approved == "true") {
                                                                        return <td className=' text-sucess text-center'><svg xmlns="http://www.w3.org/2000/svg" fill="green" width="16" height="16" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                                                                            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                                        </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3" viewBox="0 0 16 16">
                                                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                            </svg></td>
                                                                    } if (bind_user.approved == "false") {
                                                                        return <td className=' text-center greyh'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square " viewBox="0 0 16 16">
                                                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                        </svg><svg xmlns="http://www.w3.org/2000/svg" fill="red" width="16" height="16" class="bi bi-patch-check-fill ms-3" viewBox="0 0 16 16">
                                                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                                            </svg>
                                                                        </td>
                                                                    }
                                                                    else {
                                                                        return <td className=' text-center greyh'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square text-center" viewBox="0 0 16 16">
                                                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                        </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3" viewBox="0 0 16 16">
                                                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                            </svg>
                                                                        </td>


                                                                    }
                                                                })()}

                                                                {(() => {
                                                                    if (bind_user.paid == false) {
                                                                        return <td className=' text-center'>
                                                                            <input class="form-check-input mt-2  ms-3" type="checkbox" id="flexSwitchCheckChecked" Value={"false"} onChange={(e) => set_editpaid(e.target.value)} checked />

                                                                        </td>
                                                                    }
                                                                    else {
                                                                        return <td className=' text-center'>
                                                                            <input class="form-check-input mt-2  ms-3" type="checkbox" id="flexSwitchCheckChecked" Value={"false"} onChange={(e) => set_editpaid(e.target.value)} />

                                                                        </td>


                                                                    }
                                                                })()}


                                                            </tr>




                                                        </tbody>

                                                    </table>
                                                </table>
                                                <p className="fs-5 mt-3 fnt-clr">
                                                    {t('reason')}
                                                </p>

                                                <p className="fs-6">
                                                    {bind_user.reason}
                                                </p>

                                            </div>
                                            <div class="form-check form-switch ">

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-4'>

                                                <embed className='' style={{ width: 450, height: 250 }} src={bind_user.docpdf} />
                                            </div>
                                        </div>

                                        <div className="row ">

                                            {(() => {
                                                if (bind_user.approved == "true") {
                                                    return <div className='row'>
                                                        <div className='col-sm-3'> <form onSubmit={handleSubmit(onUpdateA)} action="#" method="POST">

                                                            <button class="btn btn-pri   mt-4 appr-mv" type="submit" disabled>{t('approve')}</button>
                                                        </form></div>
                                                        <div className='col-3'>
                                                            <form onSubmit={handleSubmit(onUpdater)} action="#" method="POST">

                                                                <button class="btn btn-danger btnh  mt-4 reje-mv" type="Submit" disabled>{t('reject')}</button>
                                                            </form></div>
                                                    </div>
                                                } if (bind_user.approved == "false") {
                                                    return <div className='row'><div className='col-sm-3'> <form onSubmit={handleSubmit(onUpdateA)} action="#" method="POST">

                                                        <button class="btn btn-pri   mt-4 appr-mv" type="submit" disabled>{t('approve')}</button>
                                                    </form></div><div className='col-3'>
                                                            <form onSubmit={handleSubmit(onUpdater)} action="#" method="POST">

                                                                <button class="btn btn-danger  btnh  mt-4 reje-mv" type="Submit" disabled>{t('reject')}</button>
                                                            </form></div>
                                                    </div>
                                                }
                                                else {
                                                    return <div>
                                                        <input class="form-control inputcolor task_textbox_2   mt-3" placeholder="Comments" id="exampleFormControlTextarea1" rows="3" Value={comnt} onChange={(e) => set_editcmnt(e.target.value)}></input>

                                                        <div className='row'>

                                                            <div className='col-sm-3'>
                                                                <form onSubmit={handleSubmit(onUpdateA)} action="#" method="POST">

                                                                    <button class="btn btn-pri   mt-4 appr-mv" type="submit" >{t('approve')}</button>

                                                                </form>
                                                            </div><div className='col-3'>
                                                                <form onSubmit={handleSubmit(onUpdater)} action="#" method="POST">

                                                                    <button class="btn btn-danger btnh  mt-4 reje-mv" type="Submit" >{t('reject')}</button>

                                                                </form>
                                                            </div>
                                                        </div></div>


                                                }
                                            })()}

                                            <div className="row">

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <button class="btn btn-secondary btnh mt-3 back-mv" type="button" onClick={() => ShowDetails('')}>{t('back')} </button>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <Lheader />
            </div>
        )
    }
    const [user_type, setUserType] = useState('');
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setUserType(type);
    }

    useEffect(() => {
        GetSalary();
        Getleave_emp();
        Getencshl();
    },
        [state]);
    const [tab_type, setTabType] = useState("");
    const TabChange = (type) => {
        console.log('type', tab_type)
        if (type == 'ot') {

            setTabType(type);
        }
        else {

            setTabType(type);
        }

    }
    const currentYear = new Date().getFullYear();
    const [paylist, setPay_list] = useState([])

    const saverunpay = () => {
        // let a = paylist.filter(item => item?.select === true);
        const a = emleave.filter(item => item.select);
        onInsertpayrollForMUser(a);
    }
    const onInsertpayrollForMUser = (users, e) => {
        let finallist = {
            inputList: users.map(user => {
                return {
                    emp_leave_id: user.emp_leave_id,
                    // Set the encashed status to a boolean value
                    salarycomponent: salaryId,
                    emp_id: user.empid,
                    salarycomponent_type: "Earning",

                };
            }),
        };

        axios
            .put(`${process.env.REACT_APP_API_URL}/update_encashment/${state?.user[0]?.company_id}`, finallist)
            .then(res => {
                console.log('Users updated:', res.data);
                Getleave_emp();
            })
            .catch(error => {
                console.error('Error updating users:', error);
            });

    };

    const areAnyCheckboxesChecked = emleave.some(item => item.select === true);
    const [finance, setfin] = useState([]);
    const Getfinancial = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/financialyear/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'fin');
                setfin(res.data);
                handleSelectChange(res.data);
                handleSelect_Change(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    useEffect(() => {
        Getfinancial();

    }, [state]);
    console.log(finance);
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const handleSelectChange = (x) => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // Get the current month as a number (January is 0)
        const selectedMonth = monthNames[currentMonth];

        console.log(selectedMonth, 'selectedMonth');

        console.log(x, x[0]?.startmonth, x[0]?.endmonth, 'selectedMonth');

        if (x[0]?.startmonth && x[0]?.endmonth) {
            const startMonthValue = new Date(x[0]?.startmonth).getMonth();
            const endMonthValue = new Date(x[0]?.endmonth).getMonth();

            console.log(monthNames[startMonthValue], monthNames[endMonthValue], 'selectedMonth');

            if (
                selectedMonth === monthNames[endMonthValue] ||
                selectedMonth === monthNames[startMonthValue] ||
                selectedMonth === monthNames[endMonthValue + 1]
            ) {
                setIsButtonEnabled(true);
                console.log('true');
            } else {
                setIsButtonEnabled(false);
            }
        }
    };
    const [monthsDiff, setMonthsDiff] = useState(0);
    const handleSelect_Change = (financialData) => {
        const currentDate = new Date();
        console.log('Number of months:', financialData[0]?.startmonth);
        const startMonth = new Date(financialData[0]?.startmonth).getMonth(); // Get the start month as a number (January is 0)
     // const startYear = new Date(financialData?.startmonth).getFullYear(); // Get the start year
        const currentMonth = currentDate.getMonth(); // Get the current month as a number (January is 0)
        const currentYear = currentDate.getFullYear(); // Get the current year
       
        
        const newMonthsDiff = (currentMonth - startMonth) ;
       
        setMonthsDiff(newMonthsDiff);
        console.log('Number of months:', monthsDiff);
        // Rest of your code
      };


    return (
        <div className='bg-light vh-100 container-fluid '>
            <div className='row mt-4'>
                <div className='col-sm-2 col-xxl-2  col-md-2 col-lg-2 col-xl-2 hid_tab '>

                </div>
                <div className='col-sm-10 col-xxl-10 col-md-12 col-lg-12 col-xl-10 bg-light  mt-4 tbl'>
                    <input type="text" class=" form-control grey mt-4 ms-sm-5 searchbox " placeholder={t('searchByEmployeeName')}
                        onChange={(event) => { SetQuery(event.target.value); Set_Query(event.target.value); }} id="" aria-label="Username" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>



                    {(() => {
                        if (tab_type == "ot") {
                            return <div class="card  ms-sm-5 shadow mb-5 p-2 bg-white rounded ">
                                 {(() => {
                                if (state.user?.[0]?.usertypename === 'Supervisor') {
                                    return 
                                } else {
                                    return<>  
                                    <ul class="nav nav-tabs nav-justified navmobv nav-att tab-nav ms-1 hide_all" role="tablist">
                                    <div class="slider hid_tab"></div>

                                    <li class="nav-item ms-2 nav-mob">
                                        <a class="nav-link  " style={{color:'#4EB4E6'}} onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('leaveManagement')}</a>

                                    </li>
                                    <li class="nav-item  ">
                                        {/* <a class="nav-link text-dark att-tab fnt-clr" onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('encashmentLeaveDetails')}</a> */}
                                        <a class="nav-link"  style={{color:'#4EB4E6'}}  onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">
    <i class="fas fa-home"></i>{t('encashmentLeaveDetails')}
</a>
                                    </li>

                                </ul>

                                <ul class="nav nav-tabs nav-justified navmobv nav-att tab-nav ms-1 onlytab hide_tab" role="tablist">

                                <li class="">
                                        <a class="  " style={{color:'#4EB4E6'}} onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('leaveManagement')}</a>

                                    </li>
                                    <li class="fntss ">
                                        {/* <a class="nav-link text-dark att-tab fnt-clr" onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('encashmentLeaveDetails')}</a> */}
                                        <a class="  "  style={{color:'#4EB4E6'}}  onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true">
    <i class=""></i>{t('encashmentLeaveDetails')}
</a>
                                    </li>
                                    </ul>

                                </>


                                }
                            })()}
                               

                                <div class="card-body">
                                    <div className='row mt-3'>
                                        <div className='col-sm-10'>
                                            <p className=' fs-5 fnt-clr '>{t('encashmentLeaveDetails')}</p>
                                        </div>
                                        <div className='col-sm-1 mobfilter pointer_ hide_all levapptab'>
                                            <form onSubmit={handleSubmit(saverunpay)} action="#" method="POST">
                                                <button className='btn btn-pri ms-2' type='submit' disabled={!areAnyCheckboxesChecked }>
                                                    {t('approve')}
                                                </button>
                                            </form>
                                        </div>
                                        <div className='col-sm-1 mobfilter pointer_ onlytab hide_tab'>
                                            <form onSubmit={handleSubmit(saverunpay)} action="#" method="POST">
                                                <button className='btn btn-pri ms-4' type='submit' disabled={!areAnyCheckboxesChecked }>
                                                    {t('approve')}
                                                </button>
                                            </form>
                                        </div>
                                        <div className='col-1 mobfilter pointer_ tablfil'>
                                            <svg data-bs-toggle="modal" data-bs-target="#exampleModal1" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-sm-3 " viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="row">
                        <div className="ms-4 mt-2  onlytab ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                        </div>
                    </div>
                                  <div className='row'>
                                  
                            
                                        <div className='col'>
                                            <table class="table table-bordered mt-4 year-buttonss ">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>

                                                            <td scope="col" className='pointer_'><input type="checkbox"
                                                                className='ms-3'
                                                                onChange={e => {
                                                                    const value = e.target.checked;
                                                                    setemplev(emleave => {
                                                                        return emleave.map(item => {
                                                                            if (
                                                                                item.encashed != true

                                                                            ) {
                                                                                item.select = value;
                                                                            } else {
                                                                                item.select = false; // Deselect if conditions are not met
                                                                            }
                                                                            console.log(item, 'prevListt');
                                                                            return item;
                                                                        });
                                                                    });
                                                                }} />

                                                            </td>
                                                            

                                                            <td scope="col-sm" className='headfont  text-center text-uppercase grey'>{t('leavePeriod')}</td>


                                                            <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('leaveType')}</td>
                                                            <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('employeeName')}</td>
                                                            <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('totalLeaves')}</td>
                                                            <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('leavesTaken')}</td>
                                                            <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('available')}</td>
                                                            <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('encashableLeave')}</td>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {display_Users.filter((y) => y.leave_type.toLowerCase().includes(que_ry.toLowerCase()) || y.full_name.toLowerCase().includes(que_ry.toLowerCase())).map((y, index) => {
                                                           const  leavedays =  (y.leavepermonth/12)* monthsDiff
                                                           return (



                                                                <tr>


                                                                    <td className=''> {(() => {
                                                                        console.log(y,'amb')
                                                                        if (y.encashed != true && ( y.amountbasic!=null ||y.amountbasic!=undefined) && (y.leave_count!=0)) {
                                                                            
                                                                            return <input
                                                                                type="checkbox" className='ms-3'
                                                                                checked={y.select}
                                                                                onChange={e => {
                                                                                    let value = e.target.checked;
                                                                                    setemplev(
                                                                                        emleave.map(sd => {
                                                                                            if (sd.emp_leave_id === y.emp_leave_id) {
                                                                                                sd.select = value;
                                                                                            }
                                                                                            return sd;
                                                                                        })
                                                                                    );
                                                                                }}
                                                                            />
                                                                        } else {
                                                                            return <input
                                                                                type="checkbox"
                                                                                className='ms-3'
                                                                                disabled

                                                                            />
                                                                        }
                                                                    })()}  </td>

                                                                    <td className=''><p className='text-center greyh'>{currentYear}</p></td>
                                                                    <td className=''><p className='text-center greyh'>{y.leave_type}</p></td>
                                                                    <td className=''><p className='text-center greyh'>{y.full_name}</p></td>
                                                                    <td className=''><p className='text-center greyh'>{y.leavepermonth}</p></td>
                                                                    {(()=>{
  if(y.earned==true){
return(
  <td className=''><p className='text-center'>{leavedays - y.leave_count}</p></td>
)
  }else{
    return(
      <td className=''><p className='text-center'>{y.leavepermonth - y.leave_count}</p></td>
    )
  }
})()}
                                                                    {/* <td className=''><p className='text-center'>{y.leavepermonth - y.leave_count}</p></td> */}
                                                                    <td className=''><p className='text-center greyh'>{y.leave_count}</p></td>
                                                                    {/* <td className=''><p className='text-center'>{y.encashdays}</p></td> */}
                                                                    <td className=''>
                                                                        {(() => {
                                                                            if (y.encashdays > y.leave_count) {
                                                                                return (
                                                                                    <p className='text-center greyh'>{y.leave_count}</p>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <p className='text-center greyh'>{y.encashdays}</p>
                                                                                )
                                                                            }
                                                                        })()}
                                                                    </td>



                                                                    <td className=''>
                                                                        {(() => {
                                                                            if (y.encashed == true) {
                                                                                return (
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check" viewBox="0 0 16 16">
                                                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                                                    </svg>
                                                                                )
                                                                            } else {
                                                                                return
                                                                            }
                                                                        })()}
                                                                    </td>


                                                                </tr>
                                                            );
                                                        })}




                                                    </tbody>

                                                </table>
                                            </table>
                                            <ReactPaginate
                                                previousLabel={t('previous')}
                                                nextLabel={"Next"}
                                                pageCount={page_Count}
                                                onPageChange={change_Page}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </div></div></div>
                            </div>
                        } else {
                            return <div class="card  ms-sm-5 shadow mb-5 p-2 bg-white rounded">
                                
                                {(() => {
                                if (state.user?.[0]?.usertypename === 'Supervisor') {
                                    return 
                                } else {
                                    return<> 
                                    <ul class="nav nav-tabs nav-justified navmobv nav-att tab-nav ms-1 hide_all" role="tablist">
                                    <div class="slider hid_tab"></div>
                                    <li class="nav-item ms-2 nav-mob ">
                                        <a class="nav-link "  style={{color:'#4EB4E6'}}  onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home "></i>{t('leaveManagement')}</a>

                                    </li>
                                    <li class="nav-item   ">
                                        <a class="nav-link"   style={{color:'#4EB4E6'}}  onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('encashmentLeaveDetails')}</a>

                                    </li>
                                    </ul>
                                    <ul class="nav nav-tabs nav-justified navmobv nav-att tab-nav ms-1 onlytab hide_tab" role="tablist">
                                    <li class="">
                                        <a class=""  style={{color:'#4EB4E6'}}  onClick={() => { TabChange("") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class=" "></i>{t('leaveManagement')}</a>

                                    </li>
                                    <li className="fntss">
                                        <a class="  att-tab "   style={{color:'#4EB4E6'}}  onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class=""></i>{t('encashmentLeaveDetails')}</a>

                                    </li>

                                </ul>
                                </>
                               
                               

                                }
                            })()}
                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-11'>
                                            <p className=' fnt-clr fs-5'> {t('leaveManagement')}</p>
                                        </div>
                                        
                                        <div className='col-1 mobfilter pointer_'>
                                            <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-sm-3" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                            </svg>
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                        <div className="ms-4 mt-2 onlytab hide_tab">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                            </svg>
                        </div>
                    </div>
                                    <div className='row '>
                                        <div className='col-sm'>
                                            <table class="table table-bordered mt-4 year-buttonss ">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td scope="col"></td>
                                                            <td scope="col" className='headfont  text-center text-uppercase fnt-clr '>{t('name')}</td>

                                                            <td scope="col" className='headfont    text-center text-uppercase  grey '>{t('dat')}</td>

                                                            <td scope="col-sm" className='headfont   text-center text-uppercase grey '>{t('leaveType')} </td>
                                                            <td scope="col" className='headfont   text-center text-uppercase grey'>{t('department')}  </td>
                                                            <td scope="col" className='headfont  text-center text-uppercase grey '>{t('approvedBy')}</td>
                                                            <td scope="col" className='headfont  text-center text-uppercase grey'>{t('approvalStatus')}</td>
                                                        </tr>
                                                    </thead>

                                                    <tbody>


                                                        {displayUsers.filter((x) => x.approved.toLowerCase().includes(query.toLowerCase()) || x.emp_name.toLowerCase().includes(query.toLowerCase())).map((x, index) => {
                                                            return (
                                                                <tr key={index} >


                                                                    <th scope="row"><div className="col-sm-3"> <img className='im_g ms-3' src={x.profileimg} style={{ height: 45, width: 45 }} ></img> </div></th>
                                                                    <td className='pointer_' style={{ paddingTop: '15px' }} onClick={() => ShowDetails(x, 'view')}><p className='text-center greyh'>{x.emp_name}</p></td>
                                                                    <td className='  text-center greyh sm ' style={{ paddingTop: '15px' }}>{moment(x.fromdate).format('MMM Do YYYY')} - {moment(x.todate).format('MMM Do YYYY')} </td>

                                                                    <td className=' text-center greyh' style={{ paddingTop: '15px' }}>{x.leave_ty_pe}</td>
                                                                    <td className=' text-center greyh' style={{ paddingTop: '15px' }}>{x.departments}</td>

                                                                    <td className=' text-center greyh' style={{ paddingTop: '15px' }}>
                                                                        {x.app_empname ? x.app_empname : x.admin_name} &nbsp;
                                                                        {x.updatedon ? '(' + moment(x.updatedon).format('DD/MM/YY') + ')' : "Approval Pending"}

                                                                    </td>

                                                                    {(() => {
                                                                        if (x.approved == "true") {
                                                                            return <td className=' text-sucess text-center' style={{ paddingTop: '15px' }}><svg xmlns="http://www.w3.org/2000/svg" fill="green" width="16" height="16" class="bi bi-patch-check-fill ms-3 " viewBox="0 0 16 16">
                                                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                                            </svg>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3 " viewBox="0 0 16 16">
                                                                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                                </svg></td>
                                                                        } if (x.approved == "false") {
                                                                            return <td className=' text-center greyh' style={{ paddingTop: '15px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3 " viewBox="0 0 16 16">
                                                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" fill="red" width="16" height="16" class="bi bi-patch-check-fill ms-3 " viewBox="0 0 16 16">
                                                                                    <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                                                                </svg>
                                                                            </td>
                                                                        }
                                                                        if (x.approved == "pending") {
                                                                            return <td className=' text-center greyh' style={{ paddingTop: '15px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3 " viewBox="0 0 16 16">
                                                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3 " viewBox="0 0 16 16">
                                                                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                                </svg>
                                                                            </td>
                                                                        }
                                                                        else {
                                                                            return <td className=' text-success' style={{ paddingTop: '15px' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3 " viewBox="0 0 16 16">
                                                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                            </svg><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C4C4C4" class="bi bi-x-square ms-3 " viewBox="0 0 16 16">
                                                                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                                </svg>
                                                                            </td>


                                                                        }
                                                                    })()}


                                                                </tr>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </table>
                                            <ReactPaginate
                                                previousLabel={t('previous')}
                                                nextLabel={"Next"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    })()}

                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable popupright tabfltwd">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <p class=" fs-6 ms-4 fw-bold">{t('leaveStatus')}</p>
                                        <div className='row'>
                                            <div className='col-6'>

                                                <select class="form-select inputcolor popupdrop ms-4" aria-label=" select Day" onChange={(e) => SetQuery(e.target.value)}>
                                                    <option value="">ALL</option>

                                                    <option value="true">{t('approved')}</option>
                                                    <option value="false">{t('rejected')}</option>
                                                    <option value="pending">{t('pending')}</option>

                                                </select>


                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div class="s">


                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable popupright tabfltwd">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <p class=" fs-6 ms-4 fw-bold">{t('leaveType')}</p>
                                        <div className='row'>
                                            <div className='col-6'>

                                                <select class="form-select inputcolor popupdrop ms-4" aria-label=" select Day" onChange={(e) => Set_Query(e.target.value)}>
                                                    <option value="">{t('all')}</option>
                                                    {encshl.map((x, index) => {
                                                        return (<option className="text-dark" value={x.leave_type} key={index}>
                                                            {x.leave_type}
                                                        </option>
                                                        )
                                                    })}
                                                </select>


                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div class="s">


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>{(() => {
                switch (user_type) {
                    case "view": return RenderView();

                    default: return "";
                }
            })()}</div>
            <div class="leaveml">
               
            </div>
            <Lheader />
        </div>
    );
}
export default LeaveManagement;