import React from 'react'
import Header from '../Header/Header';
import "./Addcompany.css"
import '../../../node_modules/open-sans-fonts/open-sans.less';
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Addcompany = () => {
    let navigate = useNavigate();
    const workshedule = () => {
        let path = `/workshedule`;
        navigate(path);
    }
    const { language } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const companyaddress = () => {
        let path = `/companyaddress`;
        navigate(path);
    }

    const companytax = () => {
        let path = `/companytax`;
        navigate(path);
    }
       
    
    return (

        <div className=' bg-light vh-100 container-fluid'>


            <div className='row mt-5'>

                <div className='col-2 hid_tab'>

                </div>

                <div className='col-10 bg-light vh-100 mt-3'>
                    <a class="nav-link text-primary fw-bold hide_all_pc text mobcm1" href="#"><img className="hide_all_pc" src="./Images/mobcompany.svg"></img>{t('workSchedule')}</a>

                    <p className=' fw-normal  fw-bold mt-2  _position_heading'> <a className=' ms-5' style={{ textDecoration: "none", color: " #0080FF" }}>Step:3</a>  Add the required details about your company </p>
                    <div className='border-bottom mobadd'>
                        <nav className='tabs-center '>
                            <div class="nav mt-3 " id="nav-tab" role="tablist">
                                <div class=" mt-1 mobaddalign"> <img className='img-fluid' src={`Images/add.svg`} ></img></div>
                                <a class="nav-item nav-link active  text-tabcolor border-bottom border-primary border-3 text-primary" style={{ color: " #0080FF" }} id="nav-home-tab" data-toggle="tab" href="#nav-address" role="tab" aria-controls="nav-home" aria-selected="true">Address</a>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                                <div onClick={companytax} class=" mt-1 taximg"> <img className='img-fluid' src={`Images/tax.svg`} ></img></div>
                                <a class="nav-item nav-link text-black text-tabcolor taxtext" id="nav-profile-tab" data-toggle="tab" href="#nav-tax" role="tab" aria-controls="nav-profile" aria-selected="false">{t('tax')} </a>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                                <div class=" mt-1 bankimg"> <img className='img-fluid ms-5' src={`Images/bank.svg`} ></img></div>
                                <a class="nav-item nav-link text-black text-tabcolor taxtext" id="nav-profile-tab" data-toggle="tab" href="#nav-bank" role="tab" aria-controls="nav-profile" aria-selected="false">Bank </a>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                            </div>
                        </nav>
                    </div>

                    <select class=" text-start ms-3 bankaccount_box" aria-label=" select Day">
                        <option selected>Symplefy ! </option>
                        <option value="1">Account 1</option>
                        <option value="2">Account 2</option>
                        <option value="3">Account 3</option>

                    </select>
                    <button class="btn btn-pri   addcopmanybutton" type="button" onClick={workshedule}>Continue to Schedule work</button>
                    <button class="btn btn-pri  addcopmanybutton_1" type="button" onClick={companyaddress}>Add New Company</button>

                </div>
                {/* <div className='col-2'>
               
                 </div> */}
                <p className='text_account'> Bank Account</p>
            </div>
            <Header />
            <img className=" hide_all footerimage2 img-fluid " src="./Images/footerimg2.png" />
            <img className=" hide_all footerimage1_1 img-fluid " src="./Images/footer1_1.png" />
            <img className=" hide_all footerimage1_2 img-fluid " src="./Images/footer1_2.png" />
            <img className=" hide_all footerimage1_3 img-fluid " src="./Images/footer1_3.png" />
            <img className="hide_all  footerimage1_4 img-fluid " src="./Images/footer1_4.png" />


        </div>
    );
}
export default Addcompany