import { React, useState, useEffect, useContext } from "react";
import "./Reports.css";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import ReactPaginate from "react-paginate";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Payrolltakehome = () => {
    const currentDate = new Date();
    const currentDateTime = currentDate.toLocaleString();
    // download 

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const generatePDF = (data) => {
        console.log(data, 'pdf');
        const doc = new jsPDF('p', 'mm', 'a4');

        const cmpname = data[0].company_name;

        // add company logo

        doc.setFontSize(12);
        doc.text('Employee Take Home Pay Report', 15, 16);
        doc.setFontSize(8);
        doc.text(`${query} to ${enddate}`, 15, 21);


        // add company name


        //company address 
        doc.setFontSize(9);
        doc.text(cmpname, 140, 16);

        // doc.text('Company Address', 140, 16);
        doc.setFontSize(11);
        doc.text(data[0].company_address1, 140, 21);
        doc.text(data[0].company_address2, 140, 26);
        const tableColumn = ['S.No', t('name'), t('earning'), t('employeeContributions'), t('personalIncomeTaxes'),t('reimbursements'), t('takeHome')];
        const tableRows = [];

        // push data to table rows
        data.forEach((item, index) => {
            const dataRow = [
                index + 1,
                item.full_name,
                Math.round(item.total_taxableamt).toLocaleString('en-US').replace(/,/g, '.'),
                Math.round(item.total_taxamt).toLocaleString('en-US').replace(/,/g, '.'),
                Math.round(item.total_empcontribution).toLocaleString('en-US').replace(/,/g, '.'),
                Math.round(item.total_reimb).toLocaleString('en-US').replace(/,/g, '.'),
                Math.round(item.total_takehomepay).toLocaleString('en-US').replace(/,/g, '.')
            ];
            tableRows.push(dataRow);
        });
        // total
        const totalTaxableAmt = data.reduce((acc, item) => acc + Math.round(item.total_taxableamt), 0).toLocaleString('en-US').replace(/,/g, '.');
        const totalTaxAmt = data.reduce((acc, item) => acc + Math.round(item.total_taxamt), 0).toLocaleString('en-US').replace(/,/g, '.');
        const totalEmpContribution = data.reduce((acc, item) => acc + Math.round(item.total_empcontribution), 0).toLocaleString('en-US').replace(/,/g, '.');
        const totalReimb = data.reduce((acc, item) => acc + Math.round(item.total_reimb), 0).toLocaleString('en-US').replace(/,/g, '.');
        const totalTakeHomePay = data.reduce((acc, item) => acc + Math.round(item.total_takehomepay), 0).toLocaleString('en-US').replace(/,/g, '.');

        // Add total row to table rows
        const totalRow = ['', 'Total', totalTaxableAmt, totalTaxAmt, totalEmpContribution, totalReimb, totalTakeHomePay];
        tableRows.push(totalRow);
        // add the table to the PDF
        const tableHeight = doc.autoTable.previous.finalY + 10;

        const downl = [`Downloaded On: ${currentDateTime}`];

        // define page content function
        const addPageContent = function (data) {
            // get current page number and total number of pages
            const pageCount = doc.internal.getNumberOfPages();
            const pageNumber = data.pageNumber;

            // set font size and style for download message
            doc.setFontSize(8);

            // add download message and page number to bottom of page
            doc.text(downl, data.settings.margin.left, doc.internal.pageSize.height - 10);
            doc.text(`Page ${pageNumber} of ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 5);
        };

        // generate PDF with autoTable
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            height: tableHeight,
            didParseCell: (data) => {
                const rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = 'bold';
                }
            },
            addPageContent: addPageContent // add page content to all pages
        });

        // save the PDF file
        // doc.save('report.pdf');
        doc.save(`report${query} to ${enddate}.pdf`);

    }
    const generateCSV = (data) => {
        const cmpname = data[0].company_name;

        // define the CSV column headers
        const headers = ['Employee Take Home Pay Report'];
        const dateRange = [`Report Range: ${query} to ${enddate}`];
        const companyName = [`Company Name: ${cmpname}`];
        const downl = [`Downloaded ON: ${currentDateTime}`];

        const fieldHeaders = ['Serial No.', t('name'), t('earning'), t('employeeContributions'), t('personalIncomeTaxes'),t('reimbursements'), t('takeHome')];

        // create a CSV string from the data
        let csv = '';
        [headers, dateRange, companyName, downl, fieldHeaders].forEach((row) => {
            csv += row.join(',') + '\n';
        });

        // iterate over data and add each row to CSV string

        let serialNo = 1;
        data.forEach(item => {
            const dataRow = [
                serialNo,
                item.full_name,
                Math.round(item.total_taxableamt),
                Math.round(item.total_taxamt),
                Math.round(item.total_empcontribution),
                Math.round(item.total_reimb),
                Math.round(item.total_takehomepay)
            ];
            csv += dataRow.join(',') + '\n';
            serialNo++;
        });

        // add total row to CSV string
        const totalTaxableAmt = data.reduce((acc, item) => acc + Math.round(item.total_taxableamt), 0);
        const totalTaxAmt = data.reduce((acc, item) => acc + Math.round(item.total_taxamt), 0);
        const totalEmpContribution = data.reduce((acc, item) => acc + Math.round(item.total_empcontribution), 0);
        const totalReimb = data.reduce((acc, item) => acc + Math.round(item.total_reimb), 0);
        const totalTakeHomePay = data.reduce((acc, item) => acc + Math.round(item.total_takehomepay), 0);

        const totalRow = ['', 'Total', totalTaxableAmt, totalTaxAmt, totalEmpContribution, totalReimb, totalTakeHomePay];
        csv += totalRow.join(',') + '\n';


        // initiate file download
        // const filename = 'report.csv';
        const filename = `report${query} to ${enddate}.csv`;
        const blob = new Blob([csv], { type: 'text/csv' });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    };


    const { state } = useContext(store);
    console.log('base', state.user);
    function handleKeyDown(event) {
        const maxDigits = 4; // Maximum number of digits allowed
        const inputLength = event.target.value.length + 1; // Length of input after this key is pressed
        if (inputLength > maxDigits && event.key !== 'Backspace' && event.key !== 'Delete') {
            // If the number of digits entered is greater than the maximum allowed
            event.preventDefault(); // Prevent the key from being entered
        }
    }


    let navigate = useNavigate();
    const payrolldash = () => {
        let path = `/reportsdashboard`;
        navigate(path);
    }
    const [query, SetQuery] = useState("");
    const [enddate, setendate] = useState("");
    const [demolist, setdemolist] = useState([])
    const [editac, seteditact] = useState("back");
    const [department, Setdept] = useState("");
    const [department_details, setdepartment_details] = useState([]);
    const [employee, setempno] = useState("");
    const showactive = (type) => {
        if (type == 'active') {
            seteditact(type);

        }
        if (type == 'back') {
            // seteditact(type);
            window.location.reload();
        }

    }
    const handleDepartmentChange = (event) => {
        const selectedDepartment = event.target.value;

        Setdept(selectedDepartment);
        Getemp(selectedDepartment);
    };

    // get departments
    const Getdepartment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/departments/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setdepartment_details(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const [empdetails, setempdetails] = useState([]);
    const Getemp = (x) => {

        axios.get(`${process.env.REACT_APP_API_URL}/employeetbasicdept/${state?.user[0]?.company_id}/${x}`).then((res) => {
            console.log(res.data, 'success');

            setempdetails(res.data);
        }).catch((error) => {
            console.log(error, 'success');
        });

    }
    useEffect(() => {
        Getdepartment();

    }, [state]);

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 9;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = demolist

        .slice(pagesVisited, pagesVisited + usersPerPage)

    // Calculate subtotals across all users

    const totaltaxableamt = demolist.reduce((acc, x) => acc + x.total_taxableamt, 0);

    const totalempcontribution = demolist.reduce((acc, x) => acc + x.total_empcontribution, 0);
    const totaltaxamt = demolist.reduce((acc, x) => acc + x.total_taxamt, 0);

    const totalreimb = demolist.reduce((acc, x) => acc + x.total_reimb, 0);

    const totaltakehomepay = demolist.reduce((acc, x) => acc + x.total_takehomepay, 0);

    const pageCount = Math.ceil(demolist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const [datalenght, setdatalenght] = useState();
    // get data for report
    const Getreporttakehome = () => {

        let bas = {
            company_id: state?.user[0]?.company_id,
            startdate: query,
            enddate: enddate,
            department: department,
            emp_id: employee,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/reporttakehome`, bas).then((res) => {
            console.log(res.data, 'admin');
            showactive("active");
            setdemolist(res.data);
            setdatalenght(res.data.length);
        }).catch(() => {

        })



    }
    // download 


    const isValid = query && enddate


    // alert  
    const alet = (x) => {
        confirmAlert({

            message: t('select_format_to_download_report'),
            buttons: [
                {
                    label: t('pdf'),
                    className: 'btn-pri btn',
                    onClick: () => generatePDF(x)
                },
                {
                    label: t('excel'),
                    className: 'btn-pri btn',
                    onClick: () => generateCSV(x)
                }
            ]
        })
    }
    return (
        <div className='bg-light vh-100 container-fluid '>

            {(() => {
                if (editac == "back") {
                    return <div className='row mt-4'>
                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-1 col-lg-2 col-xl-2 hid_tab'>

                        </div>   <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light mt-3col-10 mt-4  tbl'>
                            <p className=" fs-4 ms-sm-5 mt-3 fnt-clr" >{t('reports')}</p>
                            <div class="card card ms-sm-5 shadow mb-sm-5 p-2 bg-white rounded">

                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-sm-11'>
                                            <p className="fw-bold fs-5 ms-sm-3 text-secondary">{t('employeeTakeHomePay')} Summary</p>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-3">{t('startDate')}</small>
                                            <input
                                                type="date"
                                                className="form-control inputcolor txtaddr "
                                                onChange={(e) => {
                                                    SetQuery(e.target.value);
                                                }}
                                                id="month-input"
                                                aria-label="Month and Year"
                                                placeholder="Month and Year"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-2">{t('endDate')}</small>
                                            <input
                                                type="date"
                                                className="form-control inputcolor txtaddr "
                                                min={query}
                                                onChange={(e) => {

                                                    setendate(e.target.value);
                                                }}
                                                id="month-input"
                                                aria-label="Month and Year"
                                                placeholder="Month and Year"
                                            /></div>
                                    </div>
                                    <div className="row">

                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-3">{t('department')}</small>
                                            <select
                                                className="form-select inputcolor text-start text_box_size"
                                                value={department}
                                                onChange={handleDepartmentChange}><option value="">{t('all')} {t('department')}</option>
                                                {department_details.map((x) =>
                                                (<option value={x.id}>
                                                    {x.departments}</option>
                                                ))}
                                            </select> </div>
                                        <div className="col-sm-6">

                                            <small className="text-secondary ms-sm-2">{t('employee')}</small>
                                            <select
                                                className="form-select inputcolor text-start text_box_size "
                                                value={employee}
                                                onChange={(e) => setempno(e.target.value)}
                                            >
                                                <option value='0'>All Employee</option>
                                                {empdetails.map((x) => (
                                                    <option key={x.emp_id} value={x.emp_id}>{x.full_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-sm-6 d-grid">
                                            <button type="submit" className="btn btn-pri ms-sm-2" onClick={() => Getreporttakehome()} disabled={!isValid} >{t('generate')}</button>
                                        </div>
                                        <div className="col-sm-6 d-grid crdb">
                                            <button type="submit" className="btn btn-secondary" onClick={() => payrolldash()}  >{t('cancel')}</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                }
                if (editac == "active") {
                    return <div className='row mt-4'>
                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-1 col-lg-2 col-xl-2 hid_tab'>

                        </div>
                        <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light mt-3col-10 mt-4 tbl'>
                            <div className='row '>
                                <div className='col-sm-6 col-xxl-6 col-sm-7 col-md-7 col-lg-6 col-xl-6 '>
                                    <p className="fw-bold fs-4 ms-sm-5 mt-3 fnt-clr">{t('employeeTakeHomePay')}</p>

                                    <p className=" fs-6 ms-sm-5 mt-3">Report Dates {moment(query).format("DD/MM/YYYY")} to {moment(enddate).format("DD/MM/YYYY")}</p>
                                </div>
                                <div className='col-sm-5 col-xxl-5 col-sm-6 col-md-6 col-lg-5 col-xl-5  hid_tab '>

                                </div>
                            </div>

                            <div class="card card ms-sm-5 shadow mb-sm-5 p-2 bg-white rounded ">

                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-sm-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>

                                        </div>
                                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid  hid_tab '>
                                            <button type="submit" className="btn btn-secondary ms-sm-2 " onClick={() => showactive("back")}  >{t('back')}</button>

                                        </div>
                                        {(() => {
                                            if (datalenght === 0) {
                                                return (<div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid crdb downtop'>
                                                    <button type="submit" className="btn btn-pri ms-sm-2" disabled>{t('download')}</button>
                                                </div>
                                                );
                                            }
                                            else {
                                                return (<div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid crdb downtop'>
                                                    <button type="submit" className="btn btn-pri ms-sm-2 " onClick={() => alet(demolist)}>{t('download')}</button>
                                                </div>);
                                            }
                                        })()}
                                    </div>
                                    {(() => {
                                        if (datalenght == 0) {
                                            return <div className='mt-5'><h5> Date Range Does Not Exist !</h5>

                                            </div>
                                        } else {
                                            return<>
                                            <div className="row">
                                                    <div className="ms-4 mt-2 onlytab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                        </svg>
                                                    </div>
                                                </div> 
                                            <table class="table table-bordered mt-2 year-buttonss">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>  <td scope="col-sm"></td>


                                                            <td scope="col" className='text-center text-uppercase headfont '>{t('no')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont '>{t('name')}</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont '> {t('earning')}</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont '>{t('employeeContribution')}</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont  '>{t('personalIncomeTaxes')} </td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont  '>{t('reimbursements')}</td>
                                                            <td scope="col" className=' text-center  text-uppercase headfont   '>{t('netPay')}</td>

                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {displayUsers.map((x, index) => {
                                                            const actualIndex = pagesVisited + index;
                                                            const serialNumber = actualIndex + 1;
                                                            return (
                                                                <tr>
                                                                    <th scope="row"><div className="ms-sm-3 ">  </div></th>

                                                                    <td className='text-center'>{serialNumber}</td>
                                                                    <td className='text-center'  > {x.full_name}</td>
                                                                    <td className='text-center'>{Math.round(x.total_taxableamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.total_empcontribution).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.total_taxamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.total_reimb).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.total_takehomepay).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                                                </tr>

                                                            );
                                                        })}
                                                        <tr>



                                                            <td className='text-center fw-bold'></td>

                                                            <td className='text-center fw-bold'></td>
                                                            <td className='text-center fw-bold'>Sub Total</td>

                                                            <td className='text-center fw-bold'>{Math.round(totaltaxableamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totalempcontribution).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>

                                                            <td className='text-center fw-bold'>{Math.round(totaltaxamt).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totalreimb).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totaltakehomepay).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        </tr>




                                                    </tbody>

                                                </table>
                                            </table>
                                            </>
                                        }

                                    })()}
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                }
            })
                ()}



            <Lheader />

        </div>
    )
}
export default Payrolltakehome