
import React, { useCallback, useState, useEffect, useContext } from 'react'
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import { store } from '../../store/user';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const Adminpass = () => {
  const { state } = useContext(store);
  console.log('profile_cmp', state.user);
  const [password, setPassword] = useState("");
  const [newpassword, setnewPassword] = useState("");
  const { update, handleSubmit } = useForm();
  const [email, setemail] = useState("");
  const [newcon, setcon] = useState("");



  let navigate = useNavigate();

  const gohome = () => {
    navigate("/superadmindashboard");
  };

  const { language,  } = useLanguage();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);







  const onreset = (data, e) => {
    let bas = {

      loginid: state?.user[0]?.loginid,
      password_admin: newcon,
    };


    axios.put(`${process.env.REACT_APP_API_URL}/resetadminpassword`, bas).then((res) => {
      console.log('user updated', res.data);

      alert("Password saved succesfully");

      gohome();

    }).catch(() => {

    })
  }
  const onlog = (data, e) => {
    let bas = {
      loginid: state?.user[0]?.loginid,
      password_admin: password,
    };

    console.log(bas);
    axios.post(`${process.env.REACT_APP_API_URL}/forgetadminpass`, bas).then((res) => {
      console.log('valid User', res.data);

      //  Getloginuser () ;
      if (res.data.length > 0) {
        alert(" Click ok confirm ");
        onreset();
      } else {
        alert("Wrong data please enter a valid data");
      }
    }).catch(() => {
      //  alert("wrong password");
    })
  }
  const { register, watch, formState: { errors } } = useForm({
    mode: 'onTouched'
  });
  const fname = watch('fname')



  // handle disabled submit
  const isValid = fname && password
  const valuepass = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/i;


  return (
    <div className=''>

      <div className='row'>


        <div className='col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2'> </div>
        <div className='col-9 col-xxl-9 col-sm-9 col-md-9 col-lg-9 col-xl-9'>

          <div class="card task_card shadow mb-5 p-2 bg-white rounded">
            <p className='fs-5  fw-bold text-center'>Password Reset</p>
            <div class="card-body">
              <div className='row'>
                <div className='col-2'></div>
                <div className='col-4'>
                  <div className='row'>

                    <form onSubmit={handleSubmit(onlog)} action="#" method="POST">

                      <div className='col'>
                        <div className='row'>


                        </div>
                        <div className='row'>
                          <div className='col-8 mt-2'>
                            <label className='fw-bold'>Old Password</label>
                            <input type='Password' minlength="2" maxlength="15" name="password" className='pastxt' Value={password} onChange={(e) => setPassword(e.target.value)} />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-8 mt-2'>
                            <label className='fw-bold'> {t('newPassword')} </label>


                            <input

                              type='Password'
                              name="user_name"
                              minlength="8" maxlength="15"
                              Value={newpassword} onChange={(e) => setnewPassword(e.target.value)}
                              className={`pastxt  ${errors.fname &&
                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                              {...register("fname", {
                                required: {
                                  value: true,
                                  message: ' *Password is required'
                                },
                                minLength: {
                                  value: 8,
                                  message: "Please enter minimum 8 character",
                                },
                                maxLength: {
                                  value: 14,
                                  message:
                                    "Maximum allowed length is 15 characters ",
                                },
                                pattern: {
                                  value: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/i,
                                  message: <small> * Password Should Contain Capital letter ,Small letter, Numeric & special Characters</small>
                                },
                              })}
                            />
                            <div>
                              {errors.fname && <span className=' text-danger'>{errors.fname.message}</span>}
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-8 mt-2'>
                            <label className='fw-bold'>{t('confirmPassword')} </label>
                            <input type='Password' minlength="2" maxlength="15" name="message" className='pastxt' Value={newcon} onChange={(e) => setcon(e.target.value)} />
                          </div>
                        </div>
                        {/* {(() => {
                          if (newcon == fname && fname.match(valuepass)) {
                            return <div><input type="submit" className=' btn btn-pri  mt-3 ' value="Save Password" disabled={!isValid} />
                              {(() => {

                                if (state?.user[0]?.emp_id == null) {
                                  return (<input className=' btn btn-secondary  mt-3 btn_wid ms-3 ' onClick={gohome} value="Close" />

                                  );
                                } else {
                                  return (<input className=' btn btn-secondary  mt-3 btn_wid ms-3 ' value="Close" />

                                  );
                                }

                              })()}

                            </div>
                          }
                          else {

                            return <div>
                              <p className='text-danger'>Password Is not matching</p>
                              <input type="submit" className=' btn btn-pri  mt-3 ' value="Save Password" disabled />

                              <input className=' btn btn-secondary  mt-3 btn_wid ms-3 ' onClick={gohome} value="Close" />

                            </div>
                          }
                        })()} */}



                        {(() => {
                          if (newcon === fname && fname.match(valuepass)) {
                            // Passwords match, show Save Password and close buttons
                            return (
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-pri btn_wids mt-3"
                                  value="Save Password"
                                  disabled={!isValid}
                                >Save Password</button>
                                <button
                                  className="btn btn-secondary mt-3 btn_wids ms-3"
                                  onClick={gohome}
                                  style={{ fontSize: '12px' }}
                                  value="Close"
                                >Close</button>
                              </div>
                            );
                          } else if (newcon !== "" && newcon !== fname) {
                            // Passwords don't match, show error message, Save Password button, and close button
                            return (
                              <div className="">
                                <p className="text-danger">Password does not match</p>
                                <button
                                  type="submit"
                                  className="btn btn-pri btn_wids mt-3"
                                  value="Save Password"
                                  disabled
                                >Save Password</button>
                                <button
                                  className="btn btn-secondary mt-3 btn_wids ms-3"
                                  onClick={gohome}
                                  style={{ fontSize: '12px' }}
                                  value="Close"
                                >Close</button>
                              </div>
                            );
                          } else {
                            // Passwords haven't been entered yet, show empty div with disabled Save Password and close buttons
                            return (
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-pri btn_wids mt-3"
                                  value="Save Password"
                                  disabled
                                >Save Password</button>
                                <button
                                  className="btn btn-secondary mt-3 btn_wids ms-3"
                                  onClick={gohome}
                                  value="Close"
                                  style={{ fontSize: '12px' }}
                                >Close</button>
                              </div>
                            );
                          }
                        })()}

                      </div>
                    </form>

                  </div>
                </div>
                <div className='col-4'>
                  <img className='img-fluid imps ms-5' src={`Images/pass1.svg`}></img>
                  <p className=' ms-5 fw-bold'>Password Must  Includes </p>

                  <p className=' '> - Password Must contain 8 Characters </p>
                  <p className=''> - Password Must contain 1 Capital Letter</p>
                  <p className=''> - Password Must contain 1 Numeric Value</p>
                  <p className=' '> - Password Must contain 1 Special Characters</p>
                </div>
              </div>


            </div>
          </div>

        </div>
      </div>


    </div>
  )

};

export default Adminpass