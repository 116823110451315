import React, { useState, useContext, useEffect } from 'react';
import './ApplyLeave.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import CryptoJS from 'crypto-js';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const ApplyLeave = (props) => {
    const localizer = momentLocalizer(moment);
    const { state } = useContext(store);
    // console.log('applyleave', state.user);
    const { update, handleSubmit } = useForm();
    //const [from, setfrom] = useState("");
    //const [to, setto] = useState("");
    const [firstsession, setfirstsession] = useState("");
    const [secondsession, setsecondsession] = useState("");
    const [reason, setreason] = useState("");
    const [leavetype, setleavetype] = useState("");
    const [leavematch, setleavematch] = useState([]);
    const [supportpdf, setsupportpdf] = useState([]);

    const [leave_type_details, setUserDetails] = useState([]);
    const [time_details, setUser_Details] = useState([]);


    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    // Get the file size


    const Getleave_type = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/leaveemp/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'succ_ess');
                setUserDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    useEffect(() => {
        gettimesheet();
        Getleave_type();
    }, [state]);

    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: t('confirmApplyLeave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Getleave_emp(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('canceled'))
                }
            ]
        })
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    // handle submit

    // watch events
    const to = watch('to')
    const from = watch('from')


    // handle disabled submit
    const isValid = to && from && leavetype


    // const [session1, SetSession1] = useState("true");
    // const [session2, SetSession2] = useState("true");
    const [manager, setmanagerid] = useState('');
    const [email, setemail] = useState("");

    //apply leave post method
    const onInsert = (data, e) => {
        
       

        
        let applyleave = {

            leave_type: leavetype,
            emp_id: state.user[0].emp_id,
            fromdate: from,
            todate: to,
            session_1: session1,
            session_2: session2,
            reason: reason,
            approved: "pending",
            createdby: 1,
            docpdf: file,
            user_id: state.user[0].user_id,
            company_id: state?.user[0]?.company_id,
            manager: state?.user[0]?.manager,
        };


        axios.post(`${process.env.REACT_APP_API_URL}/applyleave`, applyleave).then((res) => {
            console.log('user updated', res.data);


            alert("Leave applied succesfully");
            send_Email();
            Getleavehistory();
        }).catch(() => {

        })
    }
       
    
    
    const [emp_data, setuserdata] = useState([]);

    const Getempdata = (x) => {
        if (state.user) {
          const managerId = state?.user[0]?.manager;
          const apiUrl = `${process.env.REACT_APP_API_URL}/managerdata/${managerId}`;
          
          if (managerId) {
            axios
              .get(apiUrl)
              .then((res) => {
                console.log(res.data, 'managerdata');
                setuserdata(res.data);
                setemail(res.data[0]?.email);
              })
              .catch((error) => {
                console.log(error, 'managerdata');
                
              });
          } else {
           
            console.log( '1admin_email');
          }
        }
      };
      
      const Gethrdata = () => {
        console.log('admin_email');
        if (state.user) {
          const emailData = {
            user_name: set_empname,
            message: from,
            todate: to,
          };
          const secretKey = 'mysecretkey';
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();
      
          axios
            .get(`${process.env.REACT_APP_API_URL}/hremail/${state?.user[0]?.company_id}`, {
              params: { data: encryptedData }, // Pass encryptedData as a parameter
              headers: { 'Content-Type': 'application/json' },
            })
            .then((res) => {
              console.log(res.data, 'hr emailemail');
            })
            .catch((error) => {
              console.log(error, 'call admin');
              Getadmindata();
            });
        }
      };
      
      
      const Getadmindata = () => {
        
        console.log( 'admin_email');
        if (state.user) {
          axios
            .get(`${process.env.REACT_APP_API_URL}/adminemail/${state?.user[0]?.company_id}`)
            .then((res) => {
              console.log(res.data, 'adminemail');
              setuserdata(res.data);
              setemail(res.data[0]?.email);
              send_Email();
            })
            .catch((error) => {
              console.log(error, 'adminemail');

            });
        }
      };
      

    useEffect(() => {
        Getempdata();
    },
        [state]);

    // send email to back end
    const send_Email = () => {
       
   const  emailData = {
        user_email: email,
        user_name: set_empname,
        message: from,
        manager:set_managername,
        todate:to,
    };

       
        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailleave`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        }).then(response => {
            if (response.status === 500) {
                Gethrdata(); // Call Gethrdata function here
            }
            return response.json();
        })
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }
    const [clockState, setClockState] = useState();

    let navigate = useNavigate();
    const Getleavehistory = () => {
        let path = `/leavehistory`;
        navigate(path);
    };

    const Getholidaycalendar = () => {
        let path = `/holidaycalendar`;
        navigate(path);
    };

    const Getapplyleave = () => {
        let path = `/applyleave`;
        navigate(path);
    };
    const [events, setEvents] = useState([]);
    const gettimesheet = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/emptimesheet_all/${state?.user[0]?.emp_id}`).then((res) => {
                setUser_Details(res.data);
                let aa = [];
                res.data.forEach(item => {
                    let a = {
                        id: item.time_id,
                        title: 'Present',
                        allDay: true,
                        start: new Date(item.intim),
                        end: new Date(item.out),
                    };
                    aa.push(a);
                });
                setEvents(aa);
            }).catch((error) => {
                console.log(error, 'success');
            });
    }

    useEffect(() => { }, [events])
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)
    const fileToBase64 = (file, cb) => {
        const files = file.size
        if (files > 5242880) {
            alert('File size is greater than 5mb');
            // Clear the file input value

        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }
    const onUploadFileChange = ({ target }) => {
        if (target.files < 1 || !target.validity.valid) {
            return
        }
        fileToBase64(target.files[0], (err, result) => {
            if (result) {
                setFile(result)
                setFileName(target.files[0])
            }
        })
    }
    const [set_empname, setempname] = useState([]);
    const [set_managername, setmanagername] = useState([]);

    const [set_empd, setempd] = useState([]);
    const Getleave = () => {
        if (state.user) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/emp_emp/${state?.user[0]?.emp_id}`)
                .then((res) => {
                    console.log(res.data, "emp_emp");
                    setempd(res.data);
                    setempname(res.data[0].full_name);
                    setmanagername(res.data[0].manager_name);
                })
                .catch((err) => {
                    console.error(err.message);

                });
        }
    };
    const [leavetype_details, setLeaveDetails] = useState([]);
    
    const [emleave, setemplev] = useState([]);
    const [emp_lev, setemp_lev] = useState([]);
   
    const [finance, setfin] = useState([]);
    const Getfinancial = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/financialyear/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'fin');
                setfin(res.data);
                handleSelectChange(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [monthsDiff, setMonthsDiff] = useState(0);
    const handleSelectChange = (financialData) => {
        const currentDate = new Date();
        console.log('Number of months:', financialData[0]?.startmonth);
        const startMonth = new Date(financialData[0]?.startmonth).getMonth(); // Get the start month as a number (January is 0)
        // const startYear = new Date(financialData?.startmonth).getFullYear(); // Get the start year
        const currentMonth = currentDate.getMonth(); // Get the current month as a number (January is 0)
        const currentYear = currentDate.getFullYear(); // Get the current year
       
        
         const newMonthsDiff = (currentMonth - startMonth) + 1;
       
        setMonthsDiff(newMonthsDiff);
        console.log('Number of months:', monthsDiff);
        // Rest of your code
      };
    useEffect(() => {
        Getfinancial();

    }, [state]);
     
      const Getleave_emp = () => {
       
        if (state.user) {
            console.log(from,to, 'date');
          axios.get(`${process.env.REACT_APP_API_URL}/leaveemp_type/${state?.user[0]?.emp_id}/${leavetype}`)
            .then((res) => {
              console.log(res.data, 'Getleaveemp');
              setemplev(res.data);
              const datediff = calculateDateDiff(from, to);
              console.log(datediff,from,to,res.data[0].leave_count, 'datediff');
           
            if(res.data[0].negativeleave){
                console.log(monthsDiff,(res.data[0].leavepermonth/12),'datediff')
                const  leavedays =  (res.data[0].leavepermonth/12)* monthsDiff
                if (datediff > res.data[0].leavepermonth) {
                    alert('Available leaves is not enough')
                  } else if(datediff <= res.data[0].leave_count){
                    onInsert();
                   
                    
                   
                  }else {
                    alert('Applied leave will affect as negative leave balance')
                    console.log( 'Entered');
                    
                    
                    console.log(leavedays,(res.data[0].leavepermonth - leavedays),res.data[0].leave_count, 'datiff');
                    if(datediff <= ( (res.data[0].leavepermonth - leavedays) + res.data[0].leave_count)){
                        console.log(leavedays, 'datiff');
                        onInsert();
                    }else{
                        alert('Available leaves is not enough')
                    }
                   
                   
                  }
            }else{
                if (datediff > res.data[0].leave_count) {
                    alert('Available leaves is not enough')
                  } else {
                     onInsert();
                   
                  }
            }
              
            })
            .catch(() => {
              // Handle error
            });
        }
      };
      const Getleaveempdetails = (x) => {
       
        if (state.user) {
           
          axios.get(`${process.env.REACT_APP_API_URL}/leaveemp_type/${state?.user[0]?.emp_id}/${x}`)
            .then((res) => {
              console.log(res.data, 'Getleave_emp');
              setemp_lev(res.data);
              
            })
            .catch(() => {
              // Handle error
            });
        }
      };
      const calculateDateDiff = (from, to) => {
        const fromDate = new Date(from);
        const toDate = new Date(to);
        const diffTime = Math.abs(toDate - fromDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays + 1;
      };
      
      
      
    useEffect(() => {
        Getleave();
         Getleavetype();
       
    },
        [state]);
      

        const Getleavetype = (x) => {
            if (state.user) {
                axios.get(`${process.env.REACT_APP_API_URL}/leavetypeall/${state?.user[0]?.company_id}`).then((res) => {
                    console.log(res.data, 'success');
                    setLeaveDetails(res.data);
                  
                }).catch((error) => {
                    console.log(error, 'success');
                });
            }
        }
        const isCheckboxDisabled = !(watch("from") && watch("to")) ;



        const [session1, setSession1] = useState(false);
        const [session2, setSession2] = useState(false);
        const handleSession1Change = (e) => {
            const checked = e.target.checked;
            setSession1(checked);
            setSession2(!checked);
          };
        
          const handleSession2Change = (e) => {
            const checked = e.target.checked;
            setSession2(checked);
            setSession1(!checked);
          };
    return (
        <div className="container-fluid">
            <div class="row mt-5">


                <div className="col-sm-2 hide_tab ">


                </div>
                <div className="col-sm-7 col-md-12 col-lg-2 col-xl-7 col-xxl-7   mt-3 ms-sm-4">

                    <ul class="nav nav-tabs nav-justified nav-inmview nav-normalview leavenavtab" role="tablist">
                        <div class="slider hid_tab"></div>
                        <li class="nav-item fw-bold  nav-fs">
                            <a class="nav-link fnt-clr ms-5 " id="home-tab" data-toggle="tab" role="tab" aria-controls="home" onClick={() => Getapplyleave()} aria-selected="true"><i class="fas fa-home "></i>{t('applyForLeave')} </a>
                        </li>
                        <li class="nav-item  ">
                            <a class="nav-link fnt-clr" id="profile-tab" data-toggle="tab" onClick={() => Getleavehistory()} role="tab" aria-controls="profile" aria-selected="false"><i class="leave-fs1 fas fa-id-card" ></i >{t('leaveHistory')} </a>
                        </li>
                        <li class="nav-item holiday-non">
                            <a class="nav-link fnt-clr" onClick={() => Getholidaycalendar()} id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false"><i class="fas fa-id-card" ></i>{t('holidayCalendar')} </a>
                        </li>
                        <li class="nav-item hideother holiday-mb">
                            <a class="nav-link fnt-clr" onClick={() => Getholidaycalendar()} id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false"><i class="fas fa-id-card"></i>{t('holidays')} </a>
                        </li>
                    </ul>
                    <div class="settb">

                        <p className="ms-5 mt-3 fw-bold  fnt-clr fs-5">{t('applyForLeave')} </p>

                        <div class="ms-5 mt-5 hide_all" style={{ height: '350pt', width: '500pt', alignItems: 'center' }}>

                            <Calendar
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                defaultDate={moment().toDate()}
                                localizer={localizer}
                            />

                        </div>
                        <div class="ms-4 mt-4 hide_for_pc" style={{ height: '150pt', width: '200pt', alignItems: 'center' }}>

<Calendar
    events={events}
    startAccessor="start"
    endAccessor="end"
    defaultDate={moment().toDate()}
    localizer={localizer}
/>

</div>


                    </div>


                </div>
                <div class=" col-sm-2  mt-4 hide_all col-md-8 col-lg-8 col-xl-2 col-xxl-2 calenright">

                    <div className='row mt-5'>

                    </div>
                    <div className='row'>

                        <div className='col'> {clockState}</div>
                    </div>

                    <div className='row mt-4'>
                        <div className='col'>
                            <select class="form-select inputcolor bg-light text_box_size" Value={leavetype} onChange={(e) => {setleavetype(e.target.value) ; Getleaveempdetails(e.target.value);}} aria-label=" select Day">

                                <option selected>{t('selectLeaveType')}  </option>
                              
                                {leave_type_details.map((x, index) => {
                                    const daysSinceHireArray = [];
    
    {set_empd.map((y, index) => {
        const currentDate = new Date();
        let hireDate = new Date(Date.parse(x.hiredate));
        const daysSinceHire = Math.floor((currentDate - hireDate) / (1000 * 60 * 60 * 24));
        daysSinceHireArray.push(daysSinceHire);
    }
    )}
    
// console.log(set_empd[0].hiredate,'hire')
    if (daysSinceHireArray > x.applicableafter && x.applicableafter !== null) {
        return (
            <option className="text-dark" value={x.leave_id} key={index}>
                {x.leave_type}
            </option>
        );
    } else {
        return null; // Skip rendering this option
    }
})}


                            </select>
                        </div>
                       
                    </div>
                    {emp_lev.map((x, index) => {
                          return(

                           <div class="row mt-2">

                            <div className='col-11 '>
                                <p class="  mt-2"> Available {x.leave_type} :{x.leave_count}</p>
                                 </div>
                        </div>
                          )
                           }
                           )}
                    <div className='row mt-3'>
                        <div className='col'>
                            <input
                                min={moment().format("YYYY-MM-DD")}
                                type='date'
                                placeholder='From Date'
                                className={`txtaddr form-control inputcolor   ${errors.from &&
                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                {...register("from", {
                                    required: {
                                        value: true,
                                        message: ' * from Date is required'
                                    },

                                })}
                            />
                            <div>
                                {errors.from && <span className=' text-danger'>{errors.from.message}</span>}
                            </div>
                        </div>

                    </div>
                    <div className='row mt-2'>
                        <div className='col'>
                            <p className='fw-bold text-center'>TO</p>
                        </div>

                    </div>
                    <div className='row '>
                        <div className='col'>
                            <input
                                min={moment().format("YYYY-MM-DD")}
                                type='date'
                                placeholder='To Date'
                                className={`txtaddr form-control inputcolor mt-3 ${errors.to &&
                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                {...register("to", {
                                    required: {
                                        value: true,
                                        message: ' * to Date is required'
                                    },

                                })}
                            />
                            <div>
                                {errors.to && <span className=' text-danger'>{errors.to.message}</span>}
                            </div>
                        </div>

                    </div>


{(()=>{
    if(from != to){

    }else{
        return<div className="row mt-2">
        <div className="col-6">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={session1}
              disabled={isCheckboxDisabled } 
              onChange={handleSession1Change}
              id="flexCheckChecked1"
            />
            <label className="form-check-label" htmlFor="flexCheckChecked1">
              {t('halfDay')} 
            </label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={session2}
              disabled={isCheckboxDisabled}
              
              onChange={handleSession2Change}
              id="flexCheckChecked2"
            />
            <label className="form-check-label" htmlFor="flexCheckChecked2">
              {t('fullDay')} 
            </label>
          </div>
        </div>
      </div>
    }

})()}

                    

                    <div class="row">
                        <div class="col-6">
                            <p class="text-secondary mt-1 fw-bold mt-3"> {t('Apply-To')}  </p>

                        </div>

                    </div>
                    {set_empd.map((x, index) => {

                        return (<div class="row">

                            <div className='col-9 '>
                                <p class=" fw-bold mt-2">{x.manager_name}</p>
                                <input value={x.manager} className='hide_all_pc' onChange={(e) => setmanagerid(e.target.value)} />
                            </div>
                        </div>

                        );
                    })}
                    {emp_data.map((x, index) => {

                        return (<div class="row">

                            <div className='col-9 '>
                                <p class=" fw-bold mt-2 hide_all_pc">{x.manager_name}</p>
                                <input Value={x.email} className='hide_all_pc' onChange={(e) => setemail(e.target.value)} />
                            </div>
                        </div>

                        );
                    })}

                    <div className='row'>
                        <div className='col'>
                            <textarea type="text " class="form-control inputcolor " id="inputPassword2" Value={reason} onChange={(e) => setreason(e.target.value)} placeholder="     Enter Reason" />

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p className='text-secondary fw-bold'>{t('SupportingDocument')} </p>
                            <input type="file" class=" mt-2 form-control inputcolor  " onChange={onUploadFileChange} placeholder="Profile Image" aria-label="Username" />

                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col d-grid'>
                            <form onSubmit={handleSubmit(alet)} className="d-grid" action="#" method="POST">
                                <button type="submit" class=" btn btn-pri" disabled={!isValid}>{t('apply')} </button>
                            </form>
                        </div>
                    </div>
                    <div class="form-group">

                    </div>



                </div>
                <div class=" col-sm-2  mt-2 onlytab hide_tab">

                    <div className='row mt-5'>

                    </div>
                    <div className='row'>

                        <div className='col'> {clockState}</div>
                    </div>

                    <div className='row mt-1'>
                        <div className='col'>
                            <select class="form-select inputcolor bg-light text_box_size" Value={leavetype} onChange={(e) => {setleavetype(e.target.value) ; Getleaveempdetails(e.target.value);}} aria-label=" select Day">

                                <option selected>{t('selectLeaveType')}  </option>
                              
                                {leave_type_details.map((x, index) => {
                                    const daysSinceHireArray = [];
    
    {set_empd.map((y, index) => {
        const currentDate = new Date();
        let hireDate = new Date(Date.parse(x.hiredate));
        const daysSinceHire = Math.floor((currentDate - hireDate) / (1000 * 60 * 60 * 24));
        daysSinceHireArray.push(daysSinceHire);
    }
    )}
    
// console.log(set_empd[0].hiredate,'hire')
    if (daysSinceHireArray > x.applicableafter && x.applicableafter !== null) {
        return (
            <option className="text-dark" value={x.leave_id} key={index}>
                {x.leave_type}
            </option>
        );
    } else {
        return null; // Skip rendering this option
    }
})}


                            </select>
                        </div>
                       
                    </div>
                    {emp_lev.map((x, index) => {
                          return(

                           <div class="row mt-2">

                            <div className='col-11 '>
                                <p class="  mt-2"> Available {x.leave_type} :{x.leave_count}</p>
                                 </div>
                        </div>
                          )
                           }
                           )}
                    <div className='row mt-3'>
                        <div className='col'>
                            <input
                                min={moment().format("YYYY-MM-DD")}
                                type='date'
                                placeholder='From Date'
                                className={`txtaddr form-control inputcolor   ${errors.from &&
                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                {...register("from", {
                                    required: {
                                        value: true,
                                        message: ' * from Date is required'
                                    },

                                })}
                            />
                            <div>
                                {errors.from && <span className=' text-danger'>{errors.from.message}</span>}
                            </div>
                        </div>

                    </div>
                    <div className='row mt-2'>
                        <div className='col'>
                            <p className='fw-bold text-center'>TO</p>
                        </div>

                    </div>
                    <div className='row '>
                        <div className='col'>
                            <input
                                min={moment().format("YYYY-MM-DD")}
                                type='date'
                                placeholder='To Date'
                                className={`txtaddr form-control inputcolor  ${errors.to &&
                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                {...register("to", {
                                    required: {
                                        value: true,
                                        message: ' * to Date is required'
                                    },

                                })}
                            />
                            <div>
                                {errors.to && <span className=' text-danger'>{errors.to.message}</span>}
                            </div>
                        </div>

                    </div>


{(()=>{
    if(from != to){

    }else{
        return<div className="row mt-2">
        <div className="col-6">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={session1}
              disabled={isCheckboxDisabled } 
              onChange={handleSession1Change}
              id="flexCheckChecked1"
            />
            <label className="form-check-label" htmlFor="flexCheckChecked1">
              {t('halfDay')} 
            </label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={session2}
              disabled={isCheckboxDisabled}
              
              onChange={handleSession2Change}
              id="flexCheckChecked2"
            />
            <label className="form-check-label" htmlFor="flexCheckChecked2">
              {t('fullDay')} 
            </label>
          </div>
        </div>
      </div>
    }

})()}

                    

                    <div class="row">
                        <div class="col-6">
                            <p class="text-secondary mt-1 fw-bold mt-3"> {t('Apply-To')}  </p>

                        </div>

                    </div>
                    {set_empd.map((x, index) => {

                        return (<div class="row">

                            <div className='col-9 '>
                                <p class=" fw-bold mt-2">{x.manager_name}</p>
                                <input value={x.manager} className='hide_all_pc' onChange={(e) => setmanagerid(e.target.value)} />
                            </div>
                        </div>

                        );
                    })}
                    {emp_data.map((x, index) => {

                        return (<div class="row">

                            <div className='col-9 '>
                                <p class=" fw-bold mt-2 hide_all_pc">{x.manager_name}</p>
                                <input Value={x.email} className='hide_all_pc' onChange={(e) => setemail(e.target.value)} />
                            </div>
                        </div>

                        );
                    })}

                    <div className='row'>
                        <div className='col'>
                            <textarea type="text " class="form-control inputcolor " id="inputPassword2" Value={reason} onChange={(e) => setreason(e.target.value)} placeholder="     Enter Reason" />

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p className='text-secondary fw-bold'> {t('SupportingDocument')}  </p>
                            <input type="file" class=" mt-2 form-control inputcolor  " onChange={onUploadFileChange} placeholder="Profile Image" aria-label="Username" />

                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col d-grid'>
                            <form onSubmit={handleSubmit(alet)} className="d-grid" action="#" method="POST">
                                <button type="submit" class=" btn btn-pri" disabled={!isValid}>{t('apply')} </button>
                            </form>
                        </div>
                    </div>
                    <div class="form-group">

                    </div>



                </div>
            </div>
            <div class="hidelh">

                
            </div>
            <Lheader />
        </div>
    )
}

export default ApplyLeave;