import moment from 'moment';
import "./Emp_profile_edit.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { React, useState, useEffect, useContext } from "react";
import Chart from "react-apexcharts";
import Lheader from '../Lheader/Lheader'
import { useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { store } from '../../store/user';
import { useForm } from 'react-hook-form';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Emp_Profileedit_admin = (props) => {
    const { state } = useContext(store);
    console.log('profile', state.user);
    const [edit_basic, setbasic] = useState('');
    const [edit_job, setjob] = useState('');
    const [edit_personal, setpersonal] = useState("back");
    const [edit_tax, settax] = useState("back");
    const [edit_acc, setacc] = useState("back");
    const [edit_sal, setsal] = useState("back");
    const [serviceList, setServiceList] = useState([{ service: "" }]);
    const { update, handleSubmit } = useForm();
    const [leave_details, setUserDetails] = useState([]);

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const Getleave = (emp_id) => {
        if (state.user) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/emp_emp/${state?.user[0]?.emp_id}`)
                .then((res) => {
                    console.log(res.data, "success");
                    setUserDetails(res.data);
                    setpass(res.data[0].pass_port);

                    setgen(res.data[0].gender);
                    setmarital(res.data[0].maritalstatus);
                    setadre(res.data[0].addres);
                    setcity(res.data[0].city_id);
                    setstat(res.data[0].state);
                    setloc(res.data[0].location);
                    setphn(res.data[0].phon);
                    setcoun(res.data[0].country);
                    setpin(res.data[0].postalcode);
                    setdbo(res.data[0].dateof_birth);
                    setbnk(res.data[0].bank);
                    setaccno(res.data[0].account_no);
                    setpitcode(res.data[0].pit_code);
                    setsibook(res.data[0].si_book);
                    setdepen(res.data[0].dependance);
                    setphcode(res.data[0].phncode);
                    Getemp_data();
                })
                .catch((err) => {
                    console.error(err.message);

                });
        }
    };

    useEffect(() => {
        Getleave();
    },
        [state]);

    const [activeTab, setActiveTab] = useState("basic");

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const [emp_data, setUserempdata] = useState([]);

    const Getemp_data = (emp_id) => {
        if (state.user) {
          axios
            .get(`${process.env.REACT_APP_API_URL}/emp_getdata/${state?.user[0]?.emp_id}`)
            .then((res) => {
              console.log(res.data, "succ_ess");
              setUserempdata(res.data);
      
              const nullColumns = res.data;
      
              
      
              if (
                nullColumns.includes("pass_port") ||
                !res.data[0]?.pass_port ||
                nullColumns.includes("maritalstatus") ||
                !res.data[0]?.maritalstatus ||
                nullColumns.includes("addres") ||
                !res.data[0]?.addres  ||
                nullColumns.includes("city") ||
                !res.data[0]?.city  ||
                nullColumns.includes("postalcode") ||
                !res.data[0]?.postalcode  ||
                nullColumns.includes("country") ||
                !res.data[0]?.country  ||
                nullColumns.includes("dateof_birth") ||
                !res.data[0]?.dateof_birth || // Check for null value
                // nullColumns.includes("phon") ||
                // !res.data[0]?.phon  ||
                nullColumns.includes("gender") ||
                !res.data[0]?.gender 
                
        
              ) {
                console.log("pass_port:",res.data[0]?.gender,res.data.phon, res.data.pass_port,res.data.maritalstatus,res.data.city,res.data.postalcode,res.data.country,res.data.dateof_birth );

                setActiveTab("personal");
                console.log("ess");
              }
              
             else if (
                nullColumns.includes("si_book") ||
                !res.data[0]?.si_book  ||
                nullColumns.includes("pit_code") ||
                !res.data[0]?.pit_code  ||
                nullColumns.includes("dependance")||
                !res.data[0]?.dependance  
              ) {
                setActiveTab("tax");
              }
      
            else  if (
                nullColumns.includes("bank") ||
                !res.data[0]?.bank  ||
                nullColumns.includes("account_no") ||
                !res.data[0]?.account_no  
              ) {
                setActiveTab("account");
              }else{
                setActiveTab("basic");
                console.log("basic")
              }
              
            })
            .catch((err) => {
              console.error(err.message);
            });
        }
      };
      
    useEffect(() => {
        Getemp_data();
    },
        [state]);
    const handleServiceChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...serviceList];
        list[index][name] = value;
        setServiceList(list);
    };

    const handleServiceRemove = (index) => {
        const list = [...serviceList];
        list.splice(index, 1);
        setServiceList(list);
    };

    const handleServiceAdd = () => {
        setServiceList([...serviceList, { service: "" }]);
    };

    const showbasic = (type) => {
        if (type == 'basic') {
            setbasic(type);
        }

    }
    const showjob = (type) => {
        if (type == 'job') {
            setjob(type);
        }

    }
    const showpersonal = (type) => {
        if (type == "personal") {
            console.log("data")
            setpersonal(type);
        }
        if (type == "back") {

            setpersonal(type);
        }

    }
    const showtax = (type) => {
        if (type == 'tax') {
            settax(type);
        }
        if (type == 'back') {
            settax(type);
        }

    }
    const showacc = (type) => {
        if (type == "acc") {
            setacc(type);
        }
        if (type == "back") {
            setacc(type);
        }

    }
    const showsal = (type) => {
        if (type == 'sal') {
            setsal(type);
        }

    }
    const alet = () => {
        confirmAlert({
            // title: 'Cancle',
            message: t('confirmCancel') ,
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => window.location.reload(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('canceled')),
                   
                }
            ]
        })
    }
    const ale_rt = () => {
        confirmAlert({
            // title: 'Cancle',
            message: 'Are you sure to update ',
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => window.location.reload(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    

                }
            ]
        })
    }
    const [stat_e, setstate] = useState({
        options: {
            chart: {
                height: 150,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            forecastDataPoints: {
                count: 0
            },
            stroke: {
                width: 5,
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: ['1/11/2000', '2/11/2000', '3/11/2000', '4/11/2000', '5/11/2000', '6/11/2000', '7/11/2000', '8/11/2000', '9/11/2000', '10/11/2000', '11/11/2000', '12/11/2000', '1/11/2001', '2/11/2001', '3/11/2001', '4/11/2001', '5/11/2001', '6/11/2001'],
                tickAmount: 10,
                labels: {
                    formatter: function (value, timestamp, opts) {
                        return opts.dateFormatter(new Date(timestamp), ' MMM')
                    }
                }
            },
            title: {
                text: t('monthlyAnalyser') ,
                align: 'left',
                style: {
                    fontSize: "16px",
                    color: '#666'
                }
            },
            fill: {
                //   type: 'gradient',
                //   gradient: {
                //     shade: 'dark',
                //     gradientToColors: [ '#FDD835'],
                //     shadeIntensity: 1,
                //     type: 'horizontal',
                //     opacityFrom: 1,
                //     opacityTo: 1,
                //     stops: [0, 100, 100, 100]
                //   },
                colors: ['#0080FF', 'transparent']
            },
            yaxis: {
                min: 0,
                max: 30
            }
        },
        series: [{
            name: 'Sales',
            data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 17, 1]
        }],

    })
    let navigate = useNavigate();
    const goemp = () => {
        navigate("/EmpProfileedit");

    };
    const empedit = () => {
        navigate("/EmpProfileedit");

    };
    // edit personal details
    const [pass, setpass] = useState("");
    const [gen, setgen] = useState("");
    const [marital, setmarital] = useState("");
    const [adre, setadre] = useState("");
    const [city, setcity] = useState("671");
    const [stat, setstat] = useState("");
    const [loc, setloc] = useState("");
    const [phn, setphn] = useState();
    const [con, setcoun] = useState("");
    const [pin, setpin] = useState("");
    const [dbo, setdbo] = useState("");

    const onInsertper = (emp_id, e) => {
        // if (!phn || phn.lenght > '6') {
        //     confirmAlert({
        //         message: 'Phone No is required. Please provide a valid data.',
        //         className: 'text-center',
        //         buttons: [
        //             {
        //                 label: t('close') ,
        //                 onClick: () => { },
        //                 style: {
        //                     backgroundColor: '#F36E35'

        //                 }
        //             },
        //         ]
        //     });
        //     return;
        // }
        let pers = {
            passport: pass,
            gender: gen,
            maritalstatus: marital,
            address: adre,
            city: city,
            state: stat,
            location: loc,
            phone: phn,
            country: con,
            pincode: pin,
            dateofbirth: dbo,
            phncode: phcod
        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/Employee_perso/${state?.user[0]?.emp_id}`, pers).then((res) => {
            console.log('user updated', res.data);
            showpersonal("back");
            Getleave();

        }).catch(() => {

        })
    }
    // New Personal data 
    // new personal
    const [phcod, setphcode] = useState("+84");
    const onInsertnewpersonal = (empId) => {

        let basic = {
            emp_id: state?.user[0]?.emp_id,
            passport: pass,
            gender: gen,
            maritalstatus: marital,
            address: adre,
            city: city,
            state: stat,
            location: loc,
            phone: phn,
            country: con,
            postalcode: pin,
            dateofbirth: dbo,
            createdby: state?.user[0]?.emp_id,
            phncode: phcod
        };


        axios.post(`${process.env.REACT_APP_API_URL}/Employee_personal`, basic).then((res) => {
            console.log('user updated', res.data);
            showpersonal("back");
            Getleave();
        }).catch(() => {

        })
    }
    // handle disabled submit
    const isValid = dbo && con && pass 

    useEffect(() => {
        Getvcitylist();
        Getcountry();
        Getvbanklist();
        Getcountryphonecode();

    }, []);
    const [countrphcode, setcountryDetailsphcode] = useState([]);


    const Getcountryphonecode = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country_phonecode`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetailsphcode(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const [vcitylist, setvcitylist] = useState([]);
    const Getvcitylist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vcity`).then((res) => {
                console.log(res.data, 'success');
                setvcitylist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }

    useEffect(() => {
        Getvprovincelist();
    }, [city]);
    const [vprovincelist, setvprovincelist] = useState([]);
    const Getvprovincelist = () => {
        if (city != null) {
            axios.get(`${process.env.REACT_APP_API_URL}/vprovince/${city}`).then((res) => {
                console.log(res.data, 'provi');
                setvprovincelist(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const [vbanklist, setvbanklist] = useState([]);

    const Getvbanklist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vbank`).then((res) => {
                console.log(res.data, 'success');
                setvbanklist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    const [countr, setcountryDetails] = useState([]);
    const Getcountry = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const onInsert_newtax = (empId) => {

        let tax = {
            emp_id: state?.user[0]?.emp_id,
            pitcode: pitcode,
            sibook: sibook,
            dependance: depen,
            createdby: state?.user[0]?.company_id

        };


        axios.post(`${process.env.REACT_APP_API_URL}/Employee_tax`, tax).then((res) => {
            console.log('user updated', res.data);
            showtax('back');
            Getleave();
        }).catch(() => {

        })
    }
    

    // new bank
    const onInsertnewbank = (empId) => {

        let bank = {
            emp_id: state?.user[0]?.emp_id,
            bank: bnk,
            accountno: accno,
            createdby: 1

        };

        axios.post(`${process.env.REACT_APP_API_URL}/Employee_bank`, bank).then((res) => {
            console.log('user updated', res.data);
            showacc("back");
            Getleave();


        }).catch(() => {

        })
    }

    const handle_Paste = (event) => {

        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };

    // update tax 
    const [pitcode, setpitcode] = useState("");
    const [sibook, setsibook] = useState("");
    const [depen, setdepen] = useState("");
    const onInserttax = (emp_id, e) => {
        if (!pitcode || !sibook || !depen) {
            confirmAlert({
                message: 'Pit code, Si book and Dependents is required. Please provide a valid data.',
                className: 'text-center',
                buttons: [
                    {
                        label: t('close') ,
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
            return;
        }
        let tax = {
            pitcode: pitcode,
            sibook: sibook,
            dependance: depen
        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/Employee_etax/${state?.user[0]?.emp_id}`, tax).then((res) => {

            console.log('user updated', res.data);
            //Update();
            showtax('back');
            Getleave();
        }).catch(() => {

        })
    }
    // update bank 
    const [bnk, setbnk] = useState("");
    const [accno, setaccno] = useState("");

    const onInsertbank = (emp_id, e) => {
        if (!accno) {
            confirmAlert({
                message: 'Account No. is required. Please provide a valid data.',
                className: 'text-center',
                buttons: [
                    {
                        label: t('close') ,
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
            return;
        }
        let bank = {
            bank: bnk,
            accountno: accno,

        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/Employee_Bank/${state?.user[0]?.emp_id}`, bank).then((res) => {

            console.log('user updated', res.data);
            showacc('back');
            Getleave();
        }).catch(() => {

        })
    }
    // get methods
    const [work_list, setWork_list] = useState([])
    const Getworklist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/workschedule/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setWork_list(res.data);

            }).catch(() => {

            })
    }
    const [managerlist, setManagerlist] = useState([])
    const Getmanagerlist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/employeereportingmanager/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setManagerlist(res.data);

            }).catch(() => {

            })
    }
    const [salary_cdetails, setSalarycDetails] = useState([]);
    const GetSalaryc = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/salary/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setSalarycDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const [salary_list, setsal_list] = useState([]);
    const [emid, setemid] = useState();
    const Getsalcomp = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/Employee_salary/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setsal_list(res.data);
                Getgrosshistory();
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const [grossdetails, setGrossDetails] = useState([]);
    const Getgrosshistory = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/grosshistory/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'grosshistory');
                setGrossDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    useEffect(() => {
        // GetNationality();
        Getworklist();
        GetSalaryc();
        Getmanagerlist();
        GetSalaryc();
        Getsalcomp();
    }, [state]);

    // handle disabled submit
    const isValids = pitcode && sibook && depen

    // handle disabled submit
    const isValide = bnk && accno
    return (

        <div className="container-fluid">
            {leave_details.map((x, index) => {
                return (
                    <div className="row  mt-5">
                        <div className="    col-sm-2 ms-5 hide_tab"></div>

                        <div className="ms-sm-md-5 col-sm-3 col-xxl-3 col-md-5 col-lg-4 col-xl-3  border-end">
                            <div className="row mt-5 ms-1 ">

                                <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6  ">

                                    <div className='row'>

                                        <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 '>

                                            <img className='img-fluid' src={x.profileimg} style={{ height: 65, width: 65 }} ></img>

                                        </div>
                                        <div className='col-8'>
                                            <p className=" mt-2">{x.full_name}
                                                <p> EMP ID :{x.employeeid}
                                                </p></p>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div className="row">
                                        <div className='col'>
                                            <p className="mt-2 ">Status</p>

                                            <select class="text-center text-white  rounded-pill  bg-success " disabled aria-label=" select Day">
                                                <option selected>Active </option>
                                                <option value="1">Inactive</option>
                                                <option value="2">Blocked</option>
                                                <option value="3"></option>
                                            </select>
                                        </div>

                                    </div>



                                </div>

                                <div className="row">
                                    <p className="text-primary fw-bold">Email</p>
                                    <p className='text-lowercase'>{x.email}</p>
                                    <p className="text-primary fw-bold" >{t('phone')} </p>
                                    <p>{x.phone_no}</p>
                                    <p className="text-primary fw-bold">{t('department')} </p>
                                    <p>{x.departments}</p>
                                    <p className="text-primary fw-bold">{t('jobTitle')} </p>
                                    <p>{x.job_title}</p>
                                    <p className="text-primary fw-bold">{t('reportingManager')} </p>
                                    <p>{x.manager_name} {x.lastmanager_name}</p>
                                </div>

                                <div className="row">

                                    <div className="col-7">
                                        <p className="text-primary  fw-bold" >{t('monthlyWage')} </p><small className="fw-bold">{Math.round(x.amountbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"} </small></div>
                                    <div className="col-5">    <p className="text-primary fw-bold ">{t('availableLeave')} </p><small className="fw-bold">{x.casualleave}</small></div>
                                </div>

                                <div className="row mt-2 chart_graph">

                                </div>

                            </div>

                        </div>

                        <div className="col-sm-6 ">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={handleTabChange}
                                id="uncontrolled-tab-example"
                                className="mb-3 tab_pos negw"
                            >
                                <Tab eventKey="basic" className="" title={t('basicProfileHeading')} >
                                    {(() => {
                                        if (edit_basic == "basic") {


                                            return <div className="row">
                                                <div className='col-2'>
                                                    <div class="base_pro"> <img className='img-fluid ms-5' src={`Images/basepro.png`} ></img>
                                                    </div>

                                                </div>
                                                <div className="col-10">
                                                    <div class="input-group ">
                                                        <input type="text" class="textboxbasic_1 " placeholder="" aria-label="Username" />
                                                    </div>
                                                    <p className='text_basic_1 text-center'> {t('jobTitle')} </p>
                                                    <div className="row">
                                                        <input type="text" class=" text-center text_basic_2  " placeholder={t('hireDate')}  aria-label="" />

                                                        <select class=" text-center ms-3 text_basic_3 " aria-label=" select Day">
                                                            <option selected> {t('nationality')} </option>
                                                            <option value="1">India</option>
                                                            <option value="2">America</option>
                                                            <option value="3">Poland</option>

                                                        </select>
                                                        <div class="form-check check_box">
                                                            <input class="form-check-input " type="checkbox" value="" id="flexCheckChecked" />
                                                            <label class="form-check-label" for="flexCheckChecked">
                                                                Email employee payslip? *
                                                            </label>
                                                        </div>
                                                        <input type="text" class="textboxbasic_4 mt-2" placeholder="Email" aria-label="Username" />
                                                        <input type="text" class="textboxbasic_5 mt-2" placeholder={t('taxResidentStatus')}  aria-label="Username" />
                                                        <select class="  textboxbasic_6  " aria-label=" select Day">
                                                            <option selected> Language</option>
                                                            <option value="1">English</option>

                                                        </select>

                                                    </div>

                                                    <div className='row mt-5'>
                                                        <div className="col-3">
                                                            <button class="btn btn-secondary btn_basic_updat" type="button" onClick={alet}>{t('cancel')}</button>
                                                        </div>
                                                        <div className="col-1"></div>
                                                        <div className="col-3">

                                                            <button class="btn btn-pri btn_basic_update " type="button" onClick={ale_rt}>{t('update')}</button>

                                                        </div>
                                                    </div>


                                                </div>
                                                <img className=' camera' src={`Images/camera.svg`} ></img>

                                            </div>

                                        }
                                        else {
                                            return <div className="row">
                                                <p className="text-secondary profiletext ms-2  ">{t('fullName')} </p>
                                                <p className=" ms-2">{x.full_name} {x.middle_name} {x.last_name}</p>
                                                <p className="text-secondary profiletext ms-2  " >{t('hireDate')} </p>
                                                <p className=" ms-2">{moment(x.hiredate).format('MMM Do YYYY')}</p>
                                                <p className="text-secondary profiletext ms-2 ">{t('nationality')} </p>
                                                <p className=" ms-2"> {x.nationality}
                                                </p>
                                                <p className="text-secondary profiletext ms-2 ">Email</p>
                                                <p className=" text-lowercase ms-2">{x.email}</p>
                                                <p className="text-secondary ms-2 profiletext">
                                                            {t('phoneNumberCompany')} </p>
                                                        <p className="ms-2">{x.phonecode} {x.phone_no}</p>
                                                <p className="text-secondary profiletext ms-2 ">{t('taxResidentStatus')} </p>
                                                <div>
                                                    {(() => {
                                                        if (x.residentalstatus == 1) {
                                                            return <p className='ms-2'>Resident</p>
                                                        }
                                                        if (x.residentalstatus == 2) {
                                                            return <p className='ms-2'>Non-Resident</p>
                                                        }
                                                        else {
                                                            return <p className='ms-2'>Not Defined</p>
                                                        }
                                                    })
                                                        ()}
                                                </div>


                                                <p className="text-secondary profiletext   ms-2">{t('languagePreference')} </p>
                                                <p className=" ms-2">{x.language}</p>

                                            </div>


                                        }
                                    })
                                        ()}
                                </Tab>
                                <Tab eventKey="jobprofile" title={t('jobProfileHeading')} >
                                    {(() => {
                                        if (edit_job == "job") {


                                            return <div className="row">
                                                <div className='col-2'>

                                                </div>
                                                <div className="col-10">

                                                    <input type="text" class="textboxjob_1 " placeholder="" aria-label="Username" />

                                                    <p className='text__job_1 text-center'> {t('jobTitle')} </p>
                                                    <div className="row">
                                                        <select class=" text-center  text_job_2  " aria-label=" select Day">
                                                            <option selected> Managers</option>
                                                            <option value="1">TL</option>
                                                            <option value="2">TEST</option>
                                                            <option value="3">TEST</option>

                                                        </select>
                                                        <input type="text" class=" text-center text_job_3  " placeholder={t('department')}  aria-label="" />

                                                        <input type="text" class=" text-center text_job_4  " placeholder=" Labor Contract" aria-label="" />

                                                        <input type="text" class=" text-center text_job_5  " placeholder=" Shift" aria-label="" />

                                                        <select class=" text-center  text_job_6 " aria-label=" select Day">
                                                            <option selected> {t('wageType')} </option>
                                                            <option value="1">Type 1</option>
                                                            <option value="2">Type 2</option>
                                                            <option value="3">Type 3</option>

                                                        </select>



                                                    </div>


                                                    <div className='row mt-5'>
                                                        <div className="col-3">
                                                            <button class="btn btn-secondary btn_basic_updat" type="button" onClick={alet}>{t('cancel')}</button>
                                                        </div>
                                                        <div className="col-1"></div>
                                                        <div className="col-3">

                                                            <button class="btn btn-pri btn_basic_update " type="button" onClick={ale_rt}>{t('update')}</button>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        }
                                        else {
                                            return <div className="row">
                                                <p className="text-secondary profiletext ms-2 ">{t('jobTitle')} </p>
                                                <p className=" ms-2">{x.job_title}</p>
                                                <p className="text-secondary profiletext  ms-2" >{t('manager')} </p>
                                                <p className="ms-2">{x.manager_name} {x.lastmanager_name}</p>
                                                <p className="text-secondary profiletext ms-2">{t('department')} </p>
                                                <p className="ms-2">{x.departments}
                                                </p>
                                                <p className="text-secondary profiletext ms-2">{t('labourContract')} </p>
                                                <p className="ms-2">{x.labourcontract}</p>
                                                <p className="text-secondary profiletext ms-2">{t('workSchedule')}</p>
                                                <p className="ms-2">{x.workschedule_name}</p>
                                                <p className="text-secondary profiletext ms-2">{t('workMode')} </p>
                                                {(() => {
                                                    if (x.wfo == true) {
                                                        return <p className='ms-2'>Work From Office</p>
                                                    }
                                                    if (x.wfo == false) {
                                                        return <p className='ms-2'>Work Form Home</p>
                                                    }

                                                    else {
                                                        return <p className='ms-2'>Not Defined</p>
                                                    }
                                                })()}

                                                <p className="text-secondary profiletext ms-2 ">Contract Expiry Date</p><div>
                                                    {(() => {
                                                        if (x.contractduedate == null) { return <p className='ms-2'></p> }
                                                        else { return <p className='ms-2'>{moment(x.contractduedate).format("DD/MM/YYYY")}</p> }
                                                    })()}
                                                </div>




                                                <div className="row">
                                                    <div className="col-5">

                                                    </div>

                                                    <div className="col-5">
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })
                                        ()}
                                </Tab>
                                <Tab eventKey="Salary" title={t('salary')}  >


                                    {(() => {
                                        if (edit_sal == "sal") {


                                            return <div>


                                            </div>
                                        }
                                        if (edit_sal == "back") {

                                            return <div className="row">
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <p className="text-secondary profiletext  ms-2">{t('wageType')} </p>

                                                        {(() => {
                                                            if (x.wagetype == "Monthly") {
                                                                return <p className='ms-2'>{t('monthly')} </p>
                                                            }
                                                            if (x.wagetype == "Annually") {
                                                                return <p className='ms-2'>Annually</p>
                                                            }

                                                            else {
                                                                return <p className='ms-2'>Not Defined</p>
                                                            }
                                                        })()}
                                                    </div>

                                                    <div className='col-4'>
                                                        <p className="ms-2 text-secondary profiletext">{t('monthlyWage')} </p>
                                                        {(() => {
                                                            if (x.amountbasic == null) {
                                                                return <p className="ms-2">0.00</p>
                                                            } else {
                                                                return <p className="ms-2 " >{Math.round(x.amountbasic).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p>
                                                            }
                                                        })()}
                                                    </div>
                                                    <div className='col-4'>
                                                        <p className="ms-2 text-secondary profiletext">{t('annualGrossPay')} </p>
                                                        {(() => {
                                                            if (x.amountbasic == null) {
                                                                return <p className="ms-2">0.00</p>
                                                            } else {
                                                                return <p className="ms-2 " >{Math.round(x.amountbasic * 12).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p>
                                                            }
                                                        })()}
                                                    </div>



                                                </div>

                                                <div className='row'>
                                                    {salary_list.map((x, index) => {
                                                        return (

                                                            <div className='col-3  '>

                                                                <p className="ms-2 text-secondary profiletext">{x.salarycomponent}</p>
                                                                <p className="ms-2 ">{Math.round(x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p>
                                                            </div>




                                                        );
                                                    })}
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-5">

                                                    </div>

                                                    <div className="col-5">
                                                    </div>

                                                </div>
                                                <div className='row mt-4'>
                                                    <p className='fw-bold '>{t('salaryHistory')} </p>

                                                    <div className='col'>
                                                        <table class="table table-bordered">

                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <td scope="col" className='fs-6 fw-bold grey'>Sl.No</td>
                                                                        <td scope="col" className='fs-6 fw-bold grey' >{t('monthlyWage')} </td>

                                                                        <td scope="col" className='fs-6 fw-bold grey'>{t('modifiedBy')} </td>
                                                                        <td scope="col" className='fs-6 fw-bold grey'>{t('modifiedDate')} </td>


                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {grossdetails.map((x, index) => {
                                                                        return (
                                                                            <tr key={index}>

                                                                                <td>{index + 1}</td>
                                                                                <td>{Math.round(x.grossamount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>


                                                                                <td className='text-capitalize'>{x.updateby}</td>

                                                                                <td>{moment(x.updatedon).format('DD /MM/ YYYY')}</td>




                                                                            </tr>

                                                                        );
                                                                    })}

                                                                </tbody>
                                                            </table>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })
                                        ()}
                                </Tab>
                                <Tab eventKey="personal" title={t('personal')}  >
                                    {(() => {   
                                        if (edit_personal == "personal") {


                                            return <div>
                                                {(() => {
                                                    if (x.emp_perid == null) {
                                                        return <form onSubmit={handleSubmit(onInsertnewpersonal)} action="#" method="POST" >

                                                            <div className='row'>
                                                                <div className='col-sm-2 hide_tab'>

                                                                </div>

                                                                <div className='col-sm-8 col-xxl-8 col-md-12 col-lg-12 col-xl-8'>


                                                                    <div className='row mt-3'>
                                                                        <div className='col-6'>
                                                                            <small className='text-secondary required'>{t('passportNationalID')} </small>
                                                                            <input type="text" class=" text-start  form-control inputcolor  text_box_size " Value={x.pass_port} onChange={(e) => setpass(e.target.value)} minlength="3" maxlength="15" placeholder=" Passport" aria-label="" />




                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <small className='text-secondary required'>{t('dateOfBirth')} </small>
                                                                            <input type="date" class=" text-start  form-control inputcolor  text_box_size " Value={x.dateof_birth} onChange={(e) => setdbo(e.target.value)} placeholder=" Date of Birth" aria-label="" />

                                                                        </div>
                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col-6'>
                                                                            <small className='text-secondary'>{t('gender')} </small>
                                                                            <select class=" text-start form-control inputcolor  text_box_size   " Value={x.gender} onChange={(e) => setgen(e.target.value)} aria-label=" select Day">
                                                                                <option selected>{t('gender')} </option>
                                                                                <option value="1">{t('male')} </option>
                                                                                <option value="2">{t('female')} </option>


                                                                            </select>


                                                                        </div>

                                                                        <div className='col-6'>
                                                                            <small className='text-secondary'>Marital status</small>
                                                                            <select class=" text-start form-control inputcolor  text_box_size   " Value={(x.maritalstatus)} onChange={(e) => setmarital(e.target.value)} aria-label=" select Day">
                                                                                <option selected>Marital status</option>
                                                                                <option value="1">Married</option>
                                                                                <option value="2">Single</option>


                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary'>{t('address')} </small>
                                                                            <input type="text" class="form-control inputcolor  text_box_size " placeholder={t('address')}  Value={x.addres} onChange={(e) => setadre(e.target.value)} aria-label="Username" />




                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary required'>{t('country')} </small>

                                                                            <select class=" text-start   form-control inputcolor  text_box_size  " aria-label=" select Day" Value={con} onChange={(e) => setcoun(e.target.value)}>
                                                                                <option >{t('country')} </option>
                                                                                {countr.map((x, index) => {
                                                                                    return (

                                                                                        <option value={x.country_name}>{x.country_name}</option>
                                                                                    );
                                                                                })}



                                                                            </select>



                                                                        </div>

                                                                        {/* <div className='col-6'>
                                                                            <small className='text-secondary required'>{t('phoneNumberCompany')} r</small>
                                                                            
                                                                            <div className='row'>
                                                                                <div className='col-4'>
                                                                                    <select class="text-start inputcolor text_box_size " aria-label=" select Day" Value={phcod} onChange={(e) => setphcode(e.target.value)}>
                                                                                        <option>+84 </option>
                                                                                        {countrphcode.map((x, index) => {

                                                                                            return (

                                                                                                <option value={x.country_phone_code}>{x.country_phone_code}</option>
                                                                                            );
                                                                                        })}

                                                                                    </select>
                                                                                </div>
                                                                                <div className='col-8'>
                                                                                    <input type="text" onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                        minlength="7" maxlength="12" class=" text-start form-control inputcolor  text_box_size" onPaste={handle_Paste} placeholder=" Phone" Value={x.phon} onChange={(e) => setphn(e.target.value)} aria-label="" />
                                                                                </div>
                                                                            </div>
                                                                        </div> */}

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col-4'>
                                                                            <small className='text-secondary'>{t('city')} </small>
                                                                            {(() => {

                                                                                if (con == "Vietnam ") {
                                                                                    return <div class="">

                                                                                        <div class="form-group">

                                                                                            <select class="form-control inputcolor text_box_size" Value={city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                                <option selected>City/Town </option>
                                                                                                {vcitylist.map((x, index) => {
                                                                                                    return (
                                                                                                        <option value={x.city_id}>{x.city}</option>
                                                                                                    );
                                                                                                })}
                                                                                            </select>
                                                                                        </div>

                                                                                    </div>
                                                                                } else {
                                                                                    return <div class="">

                                                                                        <div class="form-group">

                                                                                            <select class="form-control inputcolor text_box_size" Value={city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                                <option selected>City/Town </option>


                                                                                                <option value="671">Nil</option>

                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            })()}




                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <small className='text-secondary'>Province</small>
                                                                            {vprovincelist.map((x, index) => {
                                                                                {
                                                                                    return (<div>
                                                                                        {(() => {
                                                                                            if (city == 671) {
                                                                                                return <div class="form-group">

                                                                                                    <input type="text" class="form-control inputcolor text_box_size" Value={stat} onChange={(e) => setstat(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                                                                </div>
                                                                                            } else {
                                                                                                return <div class="form-group">

                                                                                                    <input type="text" class="form-control inputcolor text_box_size" value={x.province} Value={stat} onChange={(e) => setstat(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                                                                </div>
                                                                                            }
                                                                                        })()

                                                                                        }
                                                                                    </div>


                                                                                    )
                                                                                }
                                                                            })}

                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <small className='text-secondary'>Postal code</small>
                                                                            <input type="text" class=" text-start form-control inputcolor  text_box_size  " placeholder=" Postal code" Value={x.postalcode} minlength="3" maxlength="9" onChange={(e) => setpin(e.target.value)} aria-label="" />

                                                                        </div>

                                                                    </div>





                                                                    <div className='row mt-5'>
                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-secondary text_box_size " type="button" onClick={() => { showpersonal("back") }}>{t('cancel')}</button>
                                                                        </div>

                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-pri text_box_size " type="Submit" disabled={!isValid}>ADD</button>


                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>

                                                        </form>

                                                    }
                                                    else {
                                                        return <form onSubmit={handleSubmit(onInsertper)} action="#" method="POST" >

                                                            <div className='row'>
                                                                <div className='col-sm-2 hide_tab'>

                                                                </div>

                                                                <div className='col-sm-8 col-xxl-8 col-md-12 col-lg-12 col-xl-8'>


                                                                    <div className='row mt-3'>
                                                                        <div className='col-6'>
                                                                            <small className='text-secondary'>{t('passportNationalID')} </small>
                                                                            <input type="text" class=" text-start  form-control inputcolor  text_box_size " minlength="3" maxlength="15" Value={x.pass_port} onChange={(e) => setpass(e.target.value)} placeholder=" passport" aria-label="" />




                                                                        </div>
                                                                        <div className='col-6'>
                                                                            <small className='text-secondary'>{t('dateOfBirth')} </small>
                                                                            <input type="date" class=" text-start  form-control inputcolor  text_box_size " Value={x.dateof_birth} onChange={(e) => setdbo(e.target.value)} placeholder=" Date of Birth" aria-label="" />

                                                                        </div>
                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col-6'>
                                                                            <small className='text-secondary'>{t('gender')} </small>
                                                                            <select class=" text-start form-control inputcolor  text_box_size   " Value={x.gender} onChange={(e) => setgen(e.target.value)} aria-label=" select Day">
                                                                                {(() => {
                                                                                    if (x.gender == 1) {
                                                                                        return <option className="ms-2" selected disabled>{t('male')} </option>
                                                                                    } if (x.gender == 2) {
                                                                                        return <option className="ms-2" selected disabled>{t('female')} </option>
                                                                                    }

                                                                                })()}
                                                                                <option value="1">{t('male')} </option>
                                                                                <option value="2">{t('female')} </option>


                                                                            </select>


                                                                        </div>

                                                                        <div className='col-6'>
                                                                            <small className='text-secondary'>Marital status</small>
                                                                            <select class=" text-start form-control inputcolor  text_box_size   " Value={x.maritalstatus} onChange={(e) => setmarital(e.target.value)} aria-label=" select Day">
                                                                                {(() => {
                                                                                    if (x.maritalstatus == 1) {
                                                                                        return <option className="ms-2" selected disabled>Married</option>
                                                                                    } if (x.maritalstatus == 2) {
                                                                                        return <option className="ms-2" selected disabled>Single</option>
                                                                                    }

                                                                                })()}
                                                                                <option value="1">Married</option>
                                                                                <option value="2">Single</option>


                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary'>Address</small>
                                                                            <input type="text" class="form-control inputcolor  text_box_size " placeholder={t('address')} Value={x.addres} onChange={(e) => setadre(e.target.value)} aria-label="Username" />




                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary'>{t('country')} </small>

                                                                            <select class=" text-start   form-control inputcolor  text_box_size  " aria-label=" select Day" Value={x.country} onChange={(e) => setcoun(e.target.value)}>
                                                                                <option selected disabled>{x.country}</option>
                                                                                {countr.map((x, index) => {

                                                                                    return (

                                                                                        <option value={x.country_name}>{x.country_name}</option>
                                                                                    );
                                                                                })}



                                                                            </select>



                                                                        </div>

                                                                        {/* <div className='col-6'>
                                                                            <small className='text-secondary'>{t('phoneNumberCompany')} r</small>
                                                                            
                                                                            <div className='row'>
                                                                                <div className='col-4'>
                                                                                    <select class="text-start inputcolor text_box_size " aria-label=" select Day" onPaste={handle_Paste} Value={x.phncode} onChange={(e) => setphcode(e.target.value)}>
                                                                                        <option selected disabled>{x.phncode} </option>
                                                                                        {countrphcode.map((x, index) => {

                                                                                            return (

                                                                                                <option value={x.country_phone_code}>{x.country_phone_code}</option>
                                                                                            );
                                                                                        })}

                                                                                    </select>
                                                                                </div>

                                                                                <div className='col-8'>
                                                                                    <input type="text" onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                        minlength="7" maxlength="12" class=" text-start form-control inputcolor  text_box_size" placeholder={t('phone')}  Value={x.phon} onChange={(e) => setphn(e.target.value)} aria-label="" />

                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className='row mt-3'>
                                                                            <div className='col'>





                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className='row mt-1'>
                                                                        <div className='col-4'>
                                                                            <small className='text-secondary'>{t('city')} </small>

                                                                            {(() => {

                                                                                if (x.country == "Vietnam ") {
                                                                                    return <div class="">

                                                                                        <div class="form-group">

                                                                                            <select class="form-control inputcolor text_box_size" Value={x.city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                                <option selected disabled>{x.city} </option>
                                                                                                {vcitylist.map((x, index) => {
                                                                                                    return (
                                                                                                        <option value={x.city_id}>{x.city}</option>
                                                                                                    );
                                                                                                })}
                                                                                            </select>
                                                                                        </div>

                                                                                    </div>
                                                                                } else {
                                                                                    return <div class="">

                                                                                        <div class="form-group">

                                                                                            <select class="form-control inputcolor text_box_size" Value={x.city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                                                                <option selected>City/Town </option>


                                                                                                <option value="671">Nil</option>

                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            })()}




                                                                        </div>

                                                                        <div className='col-4'>
                                                                            <small className='text-secondary'>Province</small>

                                                                            {vprovincelist.map((x, index) => {
                                                                                {
                                                                                    return (<div>
                                                                                        {(() => {
                                                                                            if (city == 671) {
                                                                                                return <div class="form-group">

                                                                                                    <input type="text" class="form-control inputcolor text_box_size" Value={x.state} onChange={(e) => setstat(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                                                                </div>
                                                                                            } else {
                                                                                                return <div class="form-group">

                                                                                                    <input type="text" class="form-control inputcolor text_box_size" value={x.province} Value={x.state} onChange={(e) => setstat(e.target.value)} placeholder="Province" id="exampleFormControlInput1" />
                                                                                                </div>
                                                                                            }
                                                                                        })()

                                                                                        }
                                                                                    </div>


                                                                                    )
                                                                                }
                                                                            })}

                                                                        </div>
                                                                        <div className='col-4'>
                                                                            <small className='text-secondary'>{t('postalCode')} </small>
                                                                            <input type="text" class=" text-start form-control inputcolor  text_box_size  " placeholder={t('postalCode')}  minlength="3" maxlength="10" Value={x.postalcode} onChange={(e) => setpin(e.target.value)} aria-label="" />

                                                                        </div>

                                                                    </div>





                                                                    <div className='row mt-5'>
                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-secondary text_box_size " type="button" onClick={() => { showpersonal("back") }}>{t('cancel')}</button>
                                                                        </div>

                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-pri text_box_size" type="Submit" >{t('update')}</button>


                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>

                                                        </form>

                                                    }
                                                }
                                                )()}
                                            </div>

                                        }
                                        else {
                                            return <div className="row">
                                                <div className="row">
                                                    <div class="col-5">
                                                        <p className="text-secondary profiletext ms-2">  {t('passportNationalID')} </p>
                                                        <p className="ms-2">{x.pass_port}</p>
                                                        <p className="text-secondary profiletext ms-2" >{t('gender')} </p>
                                                        {(() => {
                                                            if (x.gender == 1) {
                                                                return <p className="ms-2">MALE</p>
                                                            } if (x.gender == 2) {
                                                                return <p className="ms-2">FEMALE</p>



                                                            }
                                                        })()}

                                                        <p className="ms-2 text-secondary profiletext">{t('maritalStatus')} </p>

                                                        {(() => {
                                                            if (x.maritalstatus == 1) {
                                                                return <p className="ms-2">Married</p>
                                                            } if (x.maritalstatus == 2) {
                                                                return <p className="ms-2">Single</p>
                                                            }

                                                        })()}

                                                        <p className="ms-2 text-secondary profiletext">{t('address')}  </p>
                                                        <p className="ms-2">{x.addres}</p>


                                                        <p className="ms-2 text-secondary profiletext">{t('city')} </p>
                                                        <p className="ms-2">{x.city}</p>

                                                    </div>
                                                    <div class="col-4">

                                                        <p className="ms-2 text-secondary profiletext">
                                                            {t('country')} </p>
                                                        <p className="ms-2">{x.country}</p>
                                                        <p className="ms-2 text-secondary profiletext">
                                                            {t('stateProvince')} </p>
                                                        <p className="ms-2">{x.province}</p>
                                                        {/* <p className="text-secondary ms-2 profiletext">
                                                            {t('phoneNumberCompany')} r</p>
                                                        <p className="ms-2">{x.phncode} {x.phon}</p> */}
                                                        <p className="text-secondary ms-2 profiletext">
                                                            {t('postalCode')} </p>
                                                        <p className="ms-2">{x.postalcode}</p>
                                                        <p className="ms-2 text-secondary profiletext">{t('dateOfBirth')} </p>
                                                        {(() => {
                                                            if (x.dateof_birth === null || x.dateof_birth === '') {
                                                                return <p className="ms-2"></p>
                                                            } else {
                                                                return <p className="ms-2">{moment(x.dateof_birth).format('MMM Do YYYY')}</p>
                                                            }

                                                        })()}
                                                    </div>
                                                </div>
                                                <div className="row">


                                                    <div className="col-sm-5">
                                                        <button class="btn btn-pri ms-2 button_edit " onClick={() => { showpersonal("personal") }} type="button">{t('edit')} </button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })
                                        ()}

                                </Tab>
                                <Tab eventKey="tax" title={t('tax')}  >
                                    {(() => {
                                        if (edit_tax == "tax") {


                                            return <div>
                                                {(() => {
                                                    if (x.emptax_id == null) {
                                                        return <form onSubmit={handleSubmit(onInsert_newtax)} action="#" method="POST" >
                                                            <div className='row'>
                                                                <div className='col-sm-2'>

                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary required'>{t('pitCode')}</small>
                                                                            <input type="text" class=" form-control inputcolor  text_box_size" placeholder={t('pitCode')} minlength="3" maxlength="15" onChange={(e) => setpitcode(e.target.value)} aria-label="Username" />
                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary required'>{t('siBook')}</small>
                                                                            <input type="text" class=" text-left  form-control inputcolor  text_box_size " onChange={(e) => setsibook(e.target.value)} minlength="3" maxlength="15" placeholder={t('siBook')} aria-label="" />
                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary required'>{t('numberOfDependents')} </small>
                                                                            <input type="number" class=" text-left form-control inputcolor  text_box_size  " onChange={(e) => setdepen(e.target.value)} minlength="0" maxlength="2" placeholder={t('numberOfDependents')}  aria-label="" />
                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-5'>
                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-secondary text_box_size " type="button" onClick={() => showtax("back")}>{t('cancel')}</button>
                                                                        </div>

                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-pri  text_box_size" type="submit" disabled={!isValids}>Add</button>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>



                                                        </form>
                                                    }
                                                    else {
                                                        return <form onSubmit={handleSubmit(onInserttax)} action="#" method="POST" >
                                                            <div className='row'>
                                                                <div className='col-sm-2'>

                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary '>Pit Code</small>
                                                                            <input type="text" class=" form-control inputcolor  text_box_size" placeholder="" Value={x.pit_code} onChange={(e) => setpitcode(e.target.value)} aria-label="Username" />
                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary'>{t('siBook')}</small>
                                                                            <input type="text" class=" text-left  form-control inputcolor  text_box_size " Value={x.si_book} onChange={(e) => setsibook(e.target.value)} placeholder={t('siBook')} aria-label="" />
                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary'>{t('numberOfDependents')} </small>
                                                                            <input type="number" class=" text-left form-control inputcolor  text_box_size  " Value={x.dependance} onChange={(e) => setdepen(e.target.value)} placeholder={t('numberOfDependents')}  aria-label="" />
                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-5'>
                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-secondary text_box_size " type="button" onClick={() => showtax("back")}>{t('cancel')}</button>
                                                                        </div>

                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-pri  text_box_size" type="submit" >{t('update')}</button>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>



                                                        </form>
                                                    }
                                                }
                                                )()}


                                            </div>

                                        }
                                        if (edit_tax == "back") {

                                            return <div className="row">
                                                <p className="text-secondary ms-2 profiletext">{t('pitCode')}</p>
                                                <p className="ms-2">{x.pit_code}</p>
                                                <p className="text-secondary ms-2 profiletext" >
                                                    {t('siBook')} </p>
                                                <p className="ms-2">{x.si_book}</p>
                                                <p className="ms-2 text-secondary profiletext">{t('numberOfDependents')} </p>
                                                <p className="ms-2">{x.dependance}
                                                </p>

                                                <div className="row">


                                                    <div className="col-sm-5">

                                                        <button class="btn btn-pri ms-2 button_edit " onClick={() => showtax("tax")} type="button">{t('edit')} </button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })
                                        ()}
                                </Tab>
                                <Tab eventKey="account" title={t('bank')}  >
                                    {(() => {
                                        if (edit_acc == "acc") {


                                            return <div>
                                                {(() => {
                                                    if (x.empbank_id == null) {
                                                        return <form onSubmit={handleSubmit(onInsertnewbank)} action="#" method="POST" >
                                                            <div className='row'>
                                                                <div className='col-sm-2'>

                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>

                                                                            <div class="form-group">
                                                                                <label for="formGroupExampleInput" class="required ms-1">{t('bankName')} </label>
                                                                                <select class=" form-select inputcolor text_box_size" Value={x.bank} onChange={(e) => setbnk(e.target.value)} aria-label=" select Day">
                                                                                    <option selected>{t('bankName')}  </option>
                                                                                    {vbanklist.map((x, index) => {
                                                                                        return (
                                                                                            <option value={x.vbankid}>{x.vbankname}</option>
                                                                                        );
                                                                                    })}
                                                                                </select>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary required'>Acccount No</small>
                                                                            <input type="text" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }} class=" text-left  form-control inputcolor  text_box_size " placeholder=" " Value={x.account_no} onChange={(e) => setaccno(e.target.value)} aria-label="" />
                                                                        </div>

                                                                    </div>

                                                                    <div className='row mt-5'>
                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-secondary text_box_size  " type="button" onClick={() => showacc("back")}>{t('cancel')}</button>
                                                                        </div>

                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-pri text_box_size " type="submit" disabled={!isValide}>ADD</button>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>




                                                        </form>
                                                    } else {
                                                        return <form onSubmit={handleSubmit(onInsertbank)} action="#" method="POST" >
                                                            <div className='row'>
                                                                <div className='col-sm-2'>

                                                                </div>
                                                                <div className='col-sm-8'>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <label for="formGroupExampleInput" class=" ms-1 text-secondary">{t('bankName')} </label>
                                                                            <select class="form-select inputcolor text-start  bankaccountbox" Value={x.bank} onChange={(e) => setbnk(e.target.value)} aria-label=" select Day">
                                                                                <option selected disabled>{x.vbankname}</option>
                                                                                {vbanklist.map((x, index) => {
                                                                                    return (
                                                                                        <option value={x.vbankid} onChange={(e) => setbnk(e.target.value)} >{x.vbankname}</option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </div>

                                                                    </div>
                                                                    <div className='row mt-3'>
                                                                        <div className='col'>
                                                                            <small className='text-secondary'>{t('accountNumber')} </small>
                                                                            <input type="text" onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }} class=" text-left  form-control inputcolor  text_box_size " placeholder=" " Value={x.account_no} onChange={(e) => setaccno(e.target.value)} aria-label="" />
                                                                        </div>

                                                                    </div>

                                                                    <div className='row mt-5'>
                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-secondary text_box_size  " type="button" onClick={() => showacc("back")}>{t('cancel')}</button>
                                                                        </div>

                                                                        <div className="col-6 d-grid">
                                                                            <button class="btn btn-pri text_box_size " type="button" onClick={onInsertbank}>{t('update')}</button>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>




                                                        </form>
                                                    }
                                                })()}
                                            </div>


                                        }
                                        if (edit_acc == "back") {

                                            return <div className="row">
                                                <p className="ms-2 text-secondary profiletext">{t('bank')} </p>
                                                <p className="ms-2">{x.vbankname}</p>
                                                <p className="ms-2 text-secondary profiletext" >
                                                    {t('accountNumber')} </p>
                                                <p className="ms-2">{x.account_no}</p>


                                                <div className="row">

                                                    <div className="col-sm-5 d-grid">
                                                        <button class="btn btn-pri ms-2 text_box_size " onClick={() => showacc("acc")} type="button">{t('edit')} </button>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    })
                                        ()}
                                </Tab>

                            </Tabs>
                        </div>
                    </div>
                );
            })}

            <Lheader />
        </div>

    )

}
export default Emp_Profileedit_admin