import React from 'react'
import { useCallback, useState, useContext, useEffect } from 'react';
import Lheader from '../Lheader/Lheader';
import "./Workshedule.css"
import "./Worksheduleupdate.css"
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useNavigate, useLocation } from "react-router-dom";
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';



const Workshedule = () => {
    const { state } = useContext(store);
    console.log('workschedule', state.user);
    const [work_list, setWork_list] = useState([])
    const [worktypes, setworktypes] = useState("");
    const [startwork, setstartwork] = useState("");
    const [endwork, setendwork] = useState("");
    const [bind_user, setBindUser] = useState({});
    const { update, handleSubmit } = useForm();
    const [user_type, setUserType] = useState('');
    const location = useLocation();

    //get work location
    const Getworklist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/workschedule/${location?.state?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setWork_list(res.data);

            }).catch(() => {

            })
    }


    useEffect(() => {
        Getworklist();
    }, [state]);

    const Delete = (workschedule_id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/workschedule/` + workschedule_id).then((res) => {
                console.log(res.data, 'success');

                if (res.data == true) {

                }
                Getworklist();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [mon, setmon] = useState(1);
    const [tue, settue] = useState(2);
    const [wed, setwed] = useState(3);
    const [thu, setthu] = useState(4);
    const [fri, setfri] = useState(5);
    const [sat, setsat] = useState(6);
    const [sun, setsun] = useState(0);
    const onUpdate = (workschedule_id, e) => {
        if (!worktypes) {
            alert('Workschedule name is required. Please provide a valid workschedule name.');
            return; // Do not proceed with the update if worktypes is null
        }
        let work = {
            workschedule_id: bind_user.workschedule_id,
            workschedule_name: worktypes,
            starttime: startwork,
            endtime: endwork,
            monday: mon,
            tuesday: tue,
            wednesday: wed,
            thursday: thu,
            friday: fri,
            saturday: sat,
            sunday: sun,

        };


        axios.put(`${process.env.REACT_APP_API_URL}/workschedule/${bind_user.workschedule_id}`, work).then((res) => {
            console.log('workschedule updated', res.data);
            ShowDetails('');
            Getworklist();
        }).catch(() => {

        })
    }

    const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className='row workup'>
            <div className='col-5'></div>
            <div className='col-5'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(save1)} action="#" method="POST">
                            <div className='row'>
                                <div className='col-8'>
                                    <div class="input-group">
                                        <input type="text" class=" form-control inputcolor" autocomplete="tax_type" Value={bind_user.workschedule_name} onChange={(e) => setworktypes(e.target.value)} placeholder="Workschedule Name" aria-label="Username" />
                                    </div>
                                </div>

                            </div>

                            <div className='row mt-2'>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.monday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('monday')}
                                                </label>
                                                <p>{bind_user.monstime}-{bind_user.monetime}</p>
                                            </div>
                                        } if (bind_user.monday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('monday')}
                                                </label>
                                                <p>{bind_user.monstime}-{bind_user.monetime}</p>
                                            </div>
                                        } else {

                                        }
                                    })()}
                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.tuesday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('tuesday')}
                                                </label>
                                                <p>{bind_user.tuestime}-{bind_user.tueetime}</p>
                                            </div>
                                        } if (bind_user.tuesday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('tuesday')}
                                                </label>
                                                <p>{bind_user.tuestime}-{bind_user.tueetime}</p>
                                            </div>
                                        } else {

                                        }
                                    })()}
                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.wednesday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('wednesday')}
                                                </label>
                                                <p>{bind_user.wedstime}-{bind_user.wedetime}</p>
                                            </div>
                                        } if (bind_user.wednesday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('wednesday')}
                                                </label>
                                                <p>{bind_user.wedstime}-{bind_user.wedetime}</p>
                                            </div>
                                        } else {

                                        }
                                    })()}
                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.thursday == 7) {
                                            return <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('thursday')}
                                                </label>
                                                <p>{bind_user.thustime}-{bind_user.thuetime}</p>
                                            </div>
                                        } if (bind_user.thursday == 8) {
                                            return <div class="form-check ms-3">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('thursday')}
                                                </label>
                                                <p>{bind_user.thustime}-{bind_user.thuetime}</p>
                                            </div>
                                        } else {

                                        }
                                    })()}
                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.friday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('friday')}
                                                </label>
                                                <p>{bind_user.fristime}-{bind_user.frietime}</p>
                                            </div>
                                        } if (bind_user.friday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('friday')}
                                                </label>
                                                <p>{bind_user.fristime}-{bind_user.frietime}</p>
                                            </div>
                                        } else {

                                        }
                                    })()}
                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.saturday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('saturday')}
                                                </label>
                                                <p>{bind_user.satstime}-{bind_user.satetime}</p>
                                            </div>
                                        } if (bind_user.saturday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('saturday')}
                                                </label>
                                                <p>{bind_user.satstime}-{bind_user.satetime}</p>
                                            </div>
                                        } else {

                                        }
                                    })()}
                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.sunday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('sunday')}
                                                </label>
                                                <p>{bind_user.sunstime}-{bind_user.sunetime}</p>
                                            </div>
                                        } if (bind_user.sunday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    {t('sunday')}
                                                </label>
                                                <p>{bind_user.sunstime}-{bind_user.sunetime}</p>
                                            </div>
                                        } else {

                                        }
                                    })()}
                                </div>

                            </div>
                            <div className='row'>
                                <button type='sumbit' onSubmit={handleSubmit(save1)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">{t('update')}</button>
                                <button type='sumbit' onClick={() => ShowDetails('')} class="btn btn-danger createbtn  mt-4 masteradd tabbtn">{t('cancel')}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }

    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setworktypes(data.workschedule_name);
        setstartwork(data.starttime);
        setendwork(data.endtime);
        setmon(data.monday);
        settue(data.tuesday);
        setwed(data.wednesday);
        setthu(data.thursday);
        setfri(data.friday);
        setsat(data.saturday);
        setsun(data.sunday);
        setUserType(type);
    }

    let navigate = useNavigate();
    const goloc = (id) => {
        let path = `/worklocation`;
        navigate(path, { state: { company_id: id } });
    }
    const gowrk = (id) => {
        let path = `/workshedule`;
        navigate(path, { state: { company_id: id } });
    }

    const save1 = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmUpdate'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onUpdate(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert( t('confirmUpdate'))
                }
            ]
        })
    }
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmSave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    // handle submit

    // watch events
    const workname = watch('workname')




    //const [workname, setworktitle] = useState("");
    const [workstart, setworkstart] = useState("");
    const [workend, setworkend] = useState("");

    const [monday, setMonday] = useState(1);
    const [tuesday, setTuesday] = useState(2);
    const [wednesday, setWednesday] = useState(3);
    const [thursday, setThursday] = useState(4);
    const [friday, setFriday] = useState(5);
    const [saturday, setsaturday] = useState(6);
    const [sunday, setsunday] = useState(0);
    const [halfstart, sethalfstart] = useState("");
    const [halfend, sethalfend] = useState("");
    const [isTopMondayChecked, setIsTopMondayChecked] = useState(false);
    const [isTopTuesdayChecked, setIsTopTuesdayChecked] = useState(false);
    const [isTopWednesdayChecked, setIsTopWednesdayChecked] = useState(false);
    const [isTopThursdayChecked, setIsTopThursdayChecked] = useState(false);
    const [isTopFridayChecked, setIsTopFridayChecked] = useState(false);
    const [isTopSaturdayChecked, setIsTopSaturdayChecked] = useState(false);
    const [isTopSundayChecked, setIsTopSundayChecked] = useState(false);
    const [dp, setdp] = useState('');
    const [monstime, setmonstime] = useState("");
    const [monetime, setmonetime] = useState("");
    const [tuestime, settuestime] = useState("");
    const [tueetime, settueetime] = useState("");
    const [wedstime, setwedstime] = useState("");
    const [wedetime, setwedetime] = useState("");
    const [thustime, setthustime] = useState("");
    const [thuetime, setthuetime] = useState("");
    const [fristime, setfristime] = useState("");
    const [frietime, setfrietime] = useState("");
    const [satstime, setsatstime] = useState("");
    const [satetime, setsatetime] = useState("");
    const [sunstime, setsunstime] = useState("");
    const [sunetime, setsunetime] = useState("");

    console.log(monstime, "testtime")
    console.log(workstart, "worktime")

    // handle disabled submit
    const isValid = workname && workstart && workend

    const checkifValidname = (workschedulename) => {

        let isValid = true;
        work_list.forEach(item => {

            if (item.workschedule_name.toLowerCase() === workschedulename.toLowerCase()) {
                isValid = false;
                console.log(item.workschedule_name, 'workname')
            }
        });
        return isValid
    }
    const onInsert = (data, e) => {
        if (workname != "") {
            if (checkifValidname(workname)) {
                try {
                    let workschedule = {
                        workschedule_name: workname,
                        starttime: workstart,
                        endtime: workend,
                        user_id: state.user[0].user_id,
                        company_id: location?.state?.company_id,
                        monday: monday,
                        monstime: monstime,
                        monetime: monetime,
                        tuesday: tuesday,
                        tuestime: tuestime,
                        tueetime: tueetime,
                        wednesday: wednesday,
                        wedstime: wedstime,
                        wedetime: wedetime,
                        thursday: thursday,
                        thustime: thustime,
                        thuetime: thuetime,
                        friday: friday,
                        fristime: fristime,
                        frietime: frietime,
                        saturday: saturday,
                        satstime: satstime,
                        satetime: satetime,
                        sunday: sunday,
                        sunstime: sunstime,
                        sunetime: sunetime,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/workschedule`, workschedule).then((res) => {
                        console.log('workschedule added', res.data);
                        Getworklist();
                        window.location = "/workshedule";
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {


                alert('Work schedule name already exists');
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        className: 'mx-auto',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }

    const on_Insert = (data, e) => {
        if (workname != "") {
            if (checkifValidname(workname)) {
                try {
                    let workschedule = {
                        workschedule_name: workname,
                        starttime: workstart,
                        endtime: workend,
                        user_id: state.user[0].user_id,
                        company_id: location?.state?.company_id,
                        monday: monday,
                        monstime: monstime,
                        monetime: monetime,
                        tuesday: tuesday,
                        tuestime: tuestime,
                        tueetime: tueetime,
                        wednesday: wednesday,
                        wedstime: wedstime,
                        wedetime: wedetime,
                        thursday: thursday,
                        thustime: thustime,
                        thuetime: thuetime,
                        friday: friday,
                        fristime: fristime,
                        frietime: frietime,
                        saturday: saturday,
                        satstime: satstime,
                        satetime: satetime,
                        sunday: sunday,
                        sunstime: sunstime,
                        sunetime: sunetime,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/workschedule`, workschedule).then((res) => {
                        console.log('workschedule added', res.data);
                        goloc(res?.data?.company_id);
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {

                alert('Work schedule name already exists');
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        className: 'mx-auto',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }


    const clickdelete = (workschedule_id) => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmDelete'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Delete(workschedule_id),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToDelete'))
                }
            ]
        })
    }

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);


    return (

        <div className='bg-light vh-100 container-fluid workupdatetab'>

            <div className='row'>


                <form onSubmit={handleSubmit(save)} action="#" method="POST">
                    <div className='row mt-5'>

                        <div className='col-4 hide_all hid_tab'></div>
                        <div className='col-sm-8 col-md-12 col-lg-12 col-xl-8 col-xxl-8  '>


                            <div className='row'>
                                <div className='col-1 col-md-3 col-lg-3 col-xl-1 col-xxl-1'></div>
                                <div className='col-sm-6'>
                                    <div className='row mt-4'>
                                        <div className='fs-5 fw-bold mt-3 text-center fnt-clr'>
                                        {t('workSchedule')}
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='text-center'>

                                            <div class="input-group">
                                                <input
                                                    minlength="2" maxlength="30"
                                                    type='text'
                                                    placeholder=   {t('workScheduleName')}
                                                    className={`form-control inputcolor txtaddr  mt-3 required ${errors.workname &&
                                                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                    {...register("workname", {
                                                        required: {
                                                            value: true,
                                                            message:t('workScheduleNameRequired')
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message:t('enterWorkScheduleName'),
                                                        },
                                                        maxLength: {
                                                            value: 30,
                                                            message:
                                                                "Maximum allowed length is 30 characters ",
                                                        },
                                                        pattern: {
                                                            value: /[a-zA-Z]+/,
                                                            message:t('onlyAlphabets') 
                                                        },

                                                    })}
                                                />
                                                <div>
                                                    {errors.workname && <span className='error_comwor text-danger'>{errors.workname.message}</span>}
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className=' mt-3'>

                                        <div className=''>

                                            <div class="input-group mb-3 ">
                                                <input type="time" class="form-control inputcolor text-center " Value={workstart} onChange={(e) => (setworkstart(e.target.value), setmonstime(e.target.value), settuestime(e.target.value), setwedstime(e.target.value), setthustime(e.target.value), setfristime(e.target.value), setsatstime(e.target.value), setsunstime(e.target.value))} placeholder=" Start Time *" aria-label="" />





                                                <input type="time" class="form-control inputcolor text-center ms-3 " Value={workend} onChange={(e) => (setworkend(e.target.value), setmonetime(e.target.value), settueetime(e.target.value), setwedetime(e.target.value), setthuetime(e.target.value), setfrietime(e.target.value), setsatetime(e.target.value), setsunetime(e.target.value))} placeholder="End Time*" aria-label="" />








                                            </div>

                                        </div>

                                    </div>
                                    <div className='row'>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setMonday(e.target.checked ? e.target.value : 1);
                                                    setIsTopMondayChecked(e.target.checked);
                                                }} id="topMondayCheckbox" disabled={dp === '1'} />
                                                <label class="form-check-label" for="">
                                                {t('monday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setTuesday(e.target.checked ? e.target.value : 2);
                                                    setIsTopTuesdayChecked(e.target.checked);
                                                }} id="topTuesdayCheckbox" disabled={dp === '2'} />
                                                <label class="form-check-label" for="">
                                                {t('tuesday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setWednesday(e.target.checked ? e.target.value : 3);
                                                    setIsTopWednesdayChecked(e.target.checked);
                                                }} id="topWednesdayCheckbox" disabled={dp === '3'} />
                                                <label class="form-check-label" for="">
                                                {t('wednesday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setThursday(e.target.checked ? e.target.value : 4);
                                                    setIsTopThursdayChecked(e.target.checked);
                                                }} id="topThursdayCheckbox" disabled={dp === '4'} />
                                                <label class="form-check-label" for="">
                                                {t('thursday')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setFriday(e.target.checked ? e.target.value : 5);
                                                    setIsTopFridayChecked(e.target.checked);
                                                }} id="topFridayCheckbox" disabled={dp === '5'} />
                                                <label class="form-check-label" for="topFridayCheckbox">
                                                {t('friday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setsaturday(e.target.checked ? e.target.value : 6);
                                                    setIsTopSaturdayChecked(e.target.checked);
                                                }} id="topSaturdayCheckbox" disabled={dp === '6'} />
                                                <label class="form-check-label" for="">
                                                {t('saturday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setsunday(e.target.checked ? e.target.value : 0);
                                                    setIsTopSundayChecked(e.target.checked);
                                                }} id="topSundayCheckbox" disabled={dp === '7'} />
                                                <label class="form-check-label" for="">
                                                {t('sunday')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col'>
                                            <select className='form-select inputcolor' value={dp} onChange={(e) => setdp(e.target.value)} >
                                                <option value='8'>{t('setHalfDay')}</option>
                                                <option value='1' disabled={isTopMondayChecked}>{t('monday')}</option>
                                                <option value='2' disabled={isTopTuesdayChecked}>{t('tuesday')}</option>
                                                <option value='3' disabled={isTopWednesdayChecked}>{t('wednesday')}</option>
                                                <option value='4' disabled={isTopThursdayChecked}>{t('thursday')}</option>
                                                <option value='5' disabled={isTopFridayChecked}>{t('friday')}</option>
                                                <option value='6' disabled={isTopSaturdayChecked}>{t('saturday')}</option>
                                                <option value='7' disabled={isTopSundayChecked}>{t('sunday')}</option>
                                            </select>
                                        </div>

                                        {(() => {
                                            if (dp == 1) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setmonstime(e.target.value), setMonday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setmonetime(e.target.value), setMonday(8))}></input>
                                                    </div>
                                                </div>
                                            }
                                            if (dp == 2) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (settuestime(e.target.value), setTuesday(8))} placeholder="Enter time"></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (settueetime(e.target.value), setTuesday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 3) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setwedstime(e.target.value), setWednesday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setwedetime(e.target.value), setWednesday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 4) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setthustime(e.target.value), setThursday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setthuetime(e.target.value), setThursday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 5) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setfristime(e.target.value), setFriday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setfrietime(e.target.value), setFriday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 6) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setsatstime(e.target.value), setsaturday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setsatetime(e.target.value), setsaturday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 7) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setsunstime(e.target.value), setsunday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setsunetime(e.target.value), setsunday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                        })()}
                                    </div>
                                    <div className='row mt-3'>

                                        <div className='col-6 d-grid'>
                                            <button class="btn btn-pri " onSubmit={handleSubmit(save)} type="submit" disabled={!isValid}>{t('addMoreWorkSchedules')}</button>
                                        </div>
                                        <div className='col-6 d-grid'>
                                            <button class="btn btn-pri " onClick={handleSubmit(on_Insert)} type="submit" disabled={!isValid} >{t('next')}</button>
                                        </div>


                                    </div>
                                </div>
                            </div>










                        </div>


                    </div>
                    <div className='row'>
                        <div className='col-3'></div>
                        <div className='col-8'>
                            <div className='row'>
                                <div className='col-1'></div>
                                <div className='col-10 pointer_'>
                                    <div className="row">
                                        <div className="ms-4 mt-2 onlytab">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <table class="table table-bordered mt-4 year-buttonss settb">
                                        <table class="table ">
                                            <thead>
                                                <tr>
                                                    <td scope="col"></td>
                                                    <td scope="col" className='fs-6 fw-bold grey'>{t('name')}</td>

                                                    <td scope="col" className='fs-6 fw-bold  grey'>{t('mon')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey'>{t('tue')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey'>{t('wed')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey'>{t('thu')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey'>{t('fri')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey'>{t('sat')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey'>{t('sun')}</td>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {work_list.map((x, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td scope="row"></td>
                                                            <td className=''>{x.workschedule_name}</td>
                                                            {(() => {
                                                                if (x.monday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } if (x.monday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.tuesday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } if (x.tuesday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.wednesday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } if (x.wednesday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.thursday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } if (x.thursday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.friday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } if (x.friday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.saturday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } if (x.saturday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.sunday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } if (x.sunday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            <td className='stendw'>{x.starttime}-{x.endtime}</td>
                                                            <td className='stendw'>{x.halfstarttime}-{x.halfendtime}</td>


                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className=" mt-2 imagework img-fluid hide_all hide_tab" src="./Images/workshedule.svg" />
                    <Lheader />
                </form>
            </div>
            <div>{(() => {
                switch (user_type) {
                    //case "view": return RenderView();
                    case "edit": return RenderEdit();
                    //case "delete": return RenderDelete();
                    default: return "";
                }
            })()}</div>
        </div>
    )

}

export default Workshedule