import React from 'react'
import "./Login/Login.css"
import { useEffect, useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { store } from '../store/user';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Login = () => {
  if (localStorage.getItem('user_details')) {
    localStorage.removeItem('user_details');
    window.location.reload();
  }
  localStorage.removeItem('subscriptionPopupSeen');
  const { state } = useContext(store);
  console.log('loginuser', state.user);
  const { update, handleSubmit } = useForm();
  const [loginuser, setloginuser] = useState('');
  const [user_details, setuserDetails] = useState([]);
  const [mail, setmail] = useState("");
  const [password, setpassword] = useState("");
  const [setd, setde] = useState([]);
  let navigate = useNavigate();
  const Gosignin = () => {
    //debugger;
    navigate("/signup");
  };

  const gohome = () => {
    //debugger;
    navigate("/admindashboard");
  };
  const gohomes = () => {
    //debugger;
    navigate("/supervisordashboard");
  };
  const goaddress = () => {
    //debugger;
    navigate("/quickguide");
  };
  
  const gobill = () => {
   
      navigate("/billing"); // Navigate to billing page
   
  };
  
  const gopasswordchange = () => {
    //debugger;
    navigate("/password");
  };
  const goemployee = () => {
    //debugger;
    navigate("/employeedashboard");
  };
  const goland = () => {
    //debugger;
    navigate("/");
  };

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordChange = (e) => {
    setpassword(e.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const goverify = (x) => {
    console.log(x, 'testo');
    let path = `/otpvalidation`;
    navigate(path, { state: { x: x } });
    // navigate( `/otpvalidation`);

  }
  const loginusertype = () => {
    if (state.user[0].admin == true && state.user[0].company == false) {
      goaddress();
    }
    if (state.user[0].admin == true && state.user[0].company == true) {
      gohome();
    }
    if (state.user[0].admin == false && state.user[0].company == true) {
      goemployee();
    }
  }

  const globalState = useContext(store);
  const { dispatch } = globalState;

  const Getloginuser = () => {
    // console.log(type, 'success');
    setuserDetails('');
    localStorage.setItem('user_details', JSON.stringify('symplefy'));
    dispatch({ type: 'ADD_USER', payload: { user: 'symplefy' } });//context api updated.  
    gohome();

  }



  useEffect(() => {
    const loggedInUser = localStorage.getItem('user_details');

    console.log('loggedInUser', loggedInUser);
    if (loggedInUser != null) {
      // gohome();
    }
  }, []);
  const loggedInUser = localStorage.getItem('user_details');
  const [ip, setIp] = React.useState("");


  console.log(ip, 'ipdata time');
  // Fetch IP when component mounts
  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then(res => res.json())
      .then(res => setIp(res.ip));
  }, []);
  const [user, setUserId] = useState([]);
  const [stdate, startdate] = useState('');
  const currentDate = new Date();
  

  // login method
  const onlogin = (data, e) => {
    e.preventDefault();
    let bas = {
      loginmail: mail,
      password: password,
      ip: ip,
    };
    axios.post(`${process.env.REACT_APP_API_URL}/login_employee`, bas).then((res) => {
      console.log('valid User', res.data);
      //  Getloginuser () ;

      if (res.data.length > 0) {
        if (res.data[0].active_status == true ) {


          startdate(res.data[0].createdon);
          const today = new Date();
          console.log(res.data[0].createdon, 'datest')
          const otherDate = new Date(res.data[0].createdon);
          const diffTime = Math.abs(today - otherDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          console.log(diffDays, 'datediff')
          const data = localStorage.getItem('username');
          console.log('logged', data);
          const paymentDate = new Date(res.data[0].payment_date);
 
          // Calculate the difference in days
          const daysDifference = Math.floor((currentDate - paymentDate) / (1000 * 60 * 60 * 24));
          
          if (data == res.data[0].loginmail) {
            if (res.data[0].trail == true && diffDays > 30) {


              onUpdateauto_null(res.data[0].user_id);


            } else {

              if (res.data[0].active == true || res.data[0].active == null || res.data[0].admin == true ) {
                if (res.data[0].trail == true || res.data[0].trail == false) {


                  // alert("success");
                  setuserDetails(res.data);
                  localStorage.setItem('user_details', JSON.stringify(res.data));
                  localStorage.setItem('company_id', JSON.stringify("null"));
                  localStorage.setItem('username', res.data[0].loginmail);
                  setUserId(res.data[0].login_id);
                  console.log(res.data[0].login_id, 'login_id')
                  dispatch({ type: 'ADD_USER', payload: { user: res.data } });//context api updated. 
                  if (res.data[0].passwordgen == true) {


                    gopasswordchange();
                  }
                  else {
                    if (res.data[0].admin == true && res.data[0].company == false) {

                      goaddress();
                    }
                    if (res.data[0].admin == true && res.data[0].company == true && res.data[0].payment_amount === 0 && daysDifference > 30) {
                      
                      gobill();
                      
                    }
                    if (res.data[0].admin == true && res.data[0].company == true && res.data[0].payment_amount != 0) {
                  
                      gohome();
                      
                    }
                    if (res.data[0].admin == true && res.data[0].company == true && res.data[0].payment_amount === 0 && daysDifference < 30) {
                  
                      gohome();
                      
                    }
                    if (res.data[0].admin == false && res.data[0].company == true && res.data[0].usertypename == 'HR Manager') {

                      gohome();
                    }
                    if (res.data[0].admin == false && res.data[0].company == true && res.data[0].usertypename == 'Admin') {

                      gohome();
                    }
                    if (res.data[0].admin == false && res.data[0].company == true && res.data[0].usertypename == 'Supervisor') {

                      gohomes();
                    }
                    if (res.data[0].admin == false && res.data[0].company == true && res.data[0].usertypename == 'Employee') {

                      goemployee();
                    }
                  }
                }
                else {
                  alert(t('Trail period completed contact admin'));
                }
              }
              else {
                alert(t('Account has been blocked contact admin'));
              }

            }
          } else {

            goverify(res.data);



          }
        } else {
          alert('account disabled contact admin');
        }

      } else {
        alert(t('Email ID is not registered with system (or) wrong password'));
      }

    }).catch((error) => {

      alert(t('Another user is already logged in with this account'));

    });
  }


  const [generatedOtp, setGeneratedOtp] = useState('');
  const [inputOtp, setInputOtp] = useState('');
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);

  // otp validation
  const [otpverified, setverified] = useState();
  const get_otp_verified = () => {


    const generateOtp = () => {
      setGeneratedOtp(Math.floor(100000 + Math.random() * 900000).toString());
    };

    const handleChange = (event) => {
      setInputOtp(event.target.value);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      if (inputOtp !== generatedOtp) {
        setError('OTP is incorrect');
        setIsValid(false);
      } else {
        setError('');
        setIsValid(true);
        setverified(true);
      }
    };

    return (
      <div>
        <button onClick={generateOtp}>Generate OTP</button>
        <p>Generated OTP: {generatedOtp}</p>
        <form onSubmit={handleSubmit}>
          <input type="text" value={inputOtp} onChange={handleChange} />
          {error && <p>{error}</p>}
          <button type="submit">Submit</button>
          {isValid && <p>OTP is valid</p>}
        </form>
      </div>
    );

  }
  // auto update trail period
  const onUpdateauto_null = (id) => {

    let demo = {
      user_id: id,
      trail: null,
    };
    axios.put(`${process.env.REACT_APP_API_URL}/trailstatus/${id}`, demo).then((res) => {

      console.log('trail period updated', res.data);
      alert("Trail period is completed");
      window.location = '/';
    }).catch(() => {



    })

  }
  const is_Valid = mail && password

  const { t, i18n } = useTranslation();

  const Language = () => {
    const newLanguage = i18n.language === 'en' ? 'vi' : 'en';
    i18n.changeLanguage(newLanguage);
  };
  
  // const handleLanguageChange = (newLanguage) => {
  //   i18n.changeLanguage(newLanguage);
  // };
  
  const handleLanguageChange = (newLanguage) => {
    
    // window.location.reload();
  
    i18n.changeLanguage(newLanguage);
    
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
   

  };  

  
  

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  return (

    <div className=' logallign '>

      <div className='row '>
        <div className=' col-xxl-7 col-sm-7 col-md-1 col-lg-1 col-xl-7  '>
          {/* <div className='row'>
            <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
              <div className="text-white fw-bold fs-1 mt-4 ms-4 "> <img className='img-fluid logo_size pointer_' onClick={goland} src={`Images/logoq.png`} ></img> <img className='img-fluid mt-5' src={`Images/Ellipse12.svg`} ></img></div>
            </div>
            <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7'>
              <div className="text-white fw-bold fs-1 text-center headn ms-5">Designed for a <p>global workforce.</p></div>


            </div>
            <div className='col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2'>
              <img className='img-fluid float-end headn mt-5 ms-5' src={`Images/Ellipse12.svg`} ></img>
            </div>

          </div>
          <div className='row'>

            <div className='col-12 col-xxl-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
              <img className='img-fluid  mt-5 loginimg' src={`Images/Frame.svg`} ></img>
              <img className='imgbor' src={`/Images/Vectu.png`}></img>
            </div>
          </div> */}




          <img className='img-fluid loginlang pointer_ tabimgcen ' onClick={goland} src={`/Images/sygl_img.png`} alt="Logo" />
          <img className='imgborallign mobsimplytext hide_tab' src={`/Images/sign_img.png`} alt="SY Image" />




        </div>
        <div className=' col-xxl-5 col-sm-5 col-md-11 col-lg-11 col-xl-5 bg-white inpucent'>
          <div className='row  '>
            <div className='col-2 col-xxl-2 col-sm-2 col-md-0 col-lg-0 col-xl-2 '></div>
            <div className=' col-xxl-8 col-sm-8 col-md-12 col-lg-12 col-xl-8 mt-4 logleft'>
              <div className='row mt-5'></div>
              <div className='row mt-5 '>
                
              <div className="cpel-switcher__lang">
  <div className="form-check form-switch  ">
    <input
      className="form-check-input"
      type="checkbox"
      role="switch"
      id="languageToggle"
      onClick={() => handleLanguageChange(i18n.language === 'en' ? 'vi' : 'en')}
      style={{ transform: 'scale(1.5)' }} 
    />
    <label htmlFor="languageToggle" className="form-check-label ms-2">
      {i18n.language === 'en' ? 'Tiếng Việt' : 'English'}
    </label>
  </div>
</div>

                {/* <button className='btn btn-primary' onClick={Language}>{t('changeLanguage')}</button> */}

 {/* English Radio Button */}
 {/* <label>
        <input
          type="radio"
          name="language"
          value="en"
          className='mt-3'
          checked={i18n.language === 'en'}
          onChange={() => handleLanguageChange('en')}
        />
        {t('english')}
      </label> */}

      {/* Vietnamese Radio Button */}
      {/* <label>
        <input
          type="radio"
          name="language"
          value="vi"
          checked={i18n.language === 'vi'}
          onChange={() => handleLanguageChange('vi')}
        />
        {t('vietnamese')}
      </label> */}
 {/* Language Toggle */}



              </div>
              <form onSubmit={handleSubmit(onlogin)} action="#" method="POST">
                <div className='row mt-5'>

                  <div className='col-10 col-xxl-10 col-sm-10 col-md-11 col-lg-10 col-xl-10'>
                    <label for="exampleInputEmail1" class="form-label ">{t('email')}*</label>
                    <input type="email" class=" form-control inputcolor text_box_size tabinpwid" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => { setmail(e.target.value) }} />
                  </div>




                </div>
                <div className='row mt-3'>

                  {/* <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                    <label for="exampleInputEmail1" class="form-label">Password*</label>
                    <input type="password" class="  form-control inputcolor text_box_size" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => { setpassword(e.target.value) }} />
                  </div> */}



                  <div className='col-10 col-xxl-10 col-sm-10 col-md-11 col-lg-10 col-xl-10 '>
                    <label htmlFor="password" className="form-label">
                    {t('password')}*
                    </label>
                    {/* <div className="input-group"> */}
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control inputcolor text_box_size tabinpwid"
                        id="password"
                        onChange={handlePasswordChange}
                        value={password}
                      />

                    {/* </div> */}
                  </div>
                </div>

                <div className='row mt-4'>

                  <div className='col-4 col-xxl-4 col-sm-4 col-md-5 col-lg-4 col-xl-4'>
                    <input type="checkbox" class="form-check-input " id="exampleCheck1" />
                    <label class="form-check-label ms-1" for="exampleCheck1">{t('remember')}</label>
                  </div>
                  <div className='col-1 col-xxl-1  col-md-0 col-lg-1 col-xl-1'></div>

                  <div className='col-6 col-xxl-6 col-md-6 col-lg-6 col-xl-6'>
                    <div class="form-check form-switch tablf">
                      <input class="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={() => setShowPassword(!showPassword)} />
                      <label htmlFor="exampleCheck1 " className="form-check-label">
                        {showPassword ? t('hide') : t('show')}
                      </label>
                      <label class="form-check-label ms-2" for="exampleCheck1">{t('password')}</label>
                    </div>

                
                  </div>
                  



                </div>
                <div className='row mt-4'>

                  <div className='col-10 col-xxl-10 col-sm-10 col-md-11 col-lg-10 col-xl-10 d-grid'>

                    <button type="submit" class="btn btn-pri tabinpwid" disabled={!is_Valid}>{t('login')}</button>


                    {/* <button type="submit" class="btn btn-pri  " disabled={!is_Valid}>Log In</button> */}
                  </div>

                </div>
                <div className='row mt-2'>
                  <div className='col-6'></div>
                  <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 '>
                    <a href="/forgetpassword" className="text-center fnt-clr forgrght">{t('forgotPassword')}</a>

                  </div>

                </div>
                <div className='row mt-5 '>
                  <div className='col topmar'>
                    <div className="ms-4 mt-4">{t('do')} <label className='signup pointer_ ms-1' onClick={Gosignin}>{t('signUpNow')}</label></div>
                  </div>
                </div>
              </form>
            </div>
            <div className='col-1'></div>
          </div>
          <div className='row'>
            <div className="headings">

            </div>
            <div className='div3 position-relative'>

              <div class="ml-5">

              </div>
              <br></br>
              <div class="mt-3">
              </div>
              <div class="mt-3">

              </div>


              <div class="loginbtn">


              </div>

            </div>

          </div>

        </div>
      </div>
      {/* <div className='row '>
        <div className='col-6 bg-primary'></div>
        <div className='col-6 bg-white'> </div>
      </div>
      <div className='hide_all_pc'>
        <div className='row bg-primary'>
          <div className="text-white sym  fw-bold fs-1 bg-primary ">Symplefy</div>

          <img className=' logbg bg-primary' src={`Images/logbg.svg`} ></img>
          <img className=' log' src={`Images/log.svg`} ></img>
        </div>
        <div className='row'>
          <div className=''>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-translate icn_lg" viewBox="0 0 16 16">  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" /></svg>
            <div className="headings"> <label className='headlanguage fnt-clr'>Change Language</label>
              <label className='headeng'>English</label>
            </div>
            <div className='div3 position-relative'>
              <form>
                <div class="ml-5">
                  <label for="exampleInputEmail1" class="form-label lbltext labelwidth">Email*</label>
                  <input type="email" class=" txtstyle" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <br></br>
                <div class="mt-3">
                  <label for="exampleInputEmail1" class="form-label lbltext labelwidth">Password*</label>
                  <input type="email" class="  txtstyle" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div class="mt-3">
                  <input type="checkbox" class="form-check-input chekb" id="exampleCheck1" />
                  <label class="form-check-label lblremeber" for="exampleCheck1">Remember</label>
                </div>
                <p></p>
                <div class=" login_btn">
                </div>
              </form>
            </div>
          </div>

        </div>
      </div> */}
    </div>
  )
}

export default Login
