import React, { useCallback, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Worklocation = () => {
    const { state } = useContext(store);
    console.log('worklocation', state.user);
    const { update, handleSubmit } = useForm();
    const [bname, setbank] = useState("");
    const [editbank, seteditbank] = useState("");
    const [bank_details, setUserDetails] = useState([]);
    const [cityloc, setcityloc] = useState("")
    const [bind_user, setBindUser] = useState({});

    const [user_type, setUserType] = useState('');
    const location = useLocation();
    // fetch existing company location
    const Getloc = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/companylocation/${location?.state?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    useEffect(() => {
        Getloc();
    }, [state]);

    // check existing location to prevent duplicate
    const checkifValidemail = (loginmail) => {

        let isValid = true;
        bank_details.forEach(item => {

            if (item.worklocation.toLowerCase() === loginmail.toLowerCase()) {
                isValid = false;
            }
        });
        return isValid
    }

    const [vcitylist, setvcitylist] = useState([]);
    const Getvcitylist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vcity`).then((res) => {
                console.log(res.data, 'success');
                setvcitylist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    useEffect(() => {
        Getvcitylist();

    }, []);
    let navigate = useNavigate();
    const gohm = () => {
        let path = `/admindashboard`;
        navigate(path);
    }
    // post method of location
    const onInsert = (data, e) => {
        if (wname != "") {
            if (checkifValidemail(wname)) {
                try {
                    let loc = {
                        worklocation: wname,
                        comp_location: bname,
                        comp_zone: 1,
                        company_id: location?.state?.company_id,
                        ip: ipdata,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/companylocation`, loc).then((res) => {
                        console.log('user updated', res.data);

                        // Getbank();
                        window.location = "/worklocation";
                        Getloc();
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Location name already exists',
                    className: 'text-center',
                    buttons: [
                        {
                            label: t('close') ,
                            className: 'mx-auto',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        className: 'mx-auto',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }
    const on_Insert = (data, e) => {
        if (wname != "") {
            if (checkifValidemail(wname)) {
                try {
                    let loc = {
                        worklocation: wname,
                        comp_location: bname,
                        comp_zone: 1,
                        company_id: location?.state?.company_id,
                        ip: ipdata,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/companylocation`, loc).then((res) => {
                        console.log('user updated', res.data);

                        onlogin();
                        gohm();
                        //save();
                        //Getbank();

                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Location name already exists',
                    className: 'text-center',
                    buttons: [
                        {
                            label: t('close') ,
                            className: 'mx-auto',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        className: 'mx-auto',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }

    // login
    const [user_details, setuserDetails] = useState([]);
    const [user, setUserId] = useState([]);
    const globalState = useContext(store);
    const { dispatch } = globalState;
    const onlogin = (data, e) => {
        let bas = {
            loginmail: state?.user[0]?.loginmail,
            password: state?.user[0]?.password,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/login_employee`, bas).then((res) => {
            console.log('valid User', res.data);
            //  Getloginuser () ;
            if (res.data.length > 0) {

                setuserDetails(res.data);
                localStorage.setItem('user_details', JSON.stringify(res.data));
                setUserId(res.data[0].login_id);
                console.log(res.data[0].login_id, 'login_id')
                dispatch({ type: 'ADD_USER', payload: { user: res.data } });//context api updated. 
            }


        }).catch(() => {
            //  alert("wrong password");
        })
    }

    const Delete = (location_id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/companylocation/` + location_id).then((res) => {
                console.log(res.data, 'success');
                //window.location = "/masterbank";
                if (res.data == true) {

                }
                Getloc();

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [ipdata, setipdata] = useState('');
    const onUpdate = (location_id, e) => {
        let loc = {
            location_id: bind_user.location_id,
            comp_location: cityloc,
            worklocation: editbank,
            comp_zone: 1,
            ip: ipdata,
        };

        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/companylocation/${bind_user.location_id}`, loc).then((res) => {

            console.log('companylocation updated', res.data);



            ShowDetails('');
            Getloc();
        }).catch(() => {



        })

    }
    const RenderEdit = () => {
        console.log('bind', bind_user);

        return <div className='row'>
            <div className='col-5'></div>
            <div className='col-5'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(updat)} action="#" method="POST">
                            <div class="input-group">
                                <input type="text" class=" form-control inputcolor" autocomplete="" Value={bind_user.worklocation} onChange={(e) => seteditbank(e.target.value)} placeholder="Work Location" aria-label="Username" />
                            </div>
                            <div>
                                <select class="form-select inputcolor text-start  mt-3" Value={bind_user.comp_location} onChange={(e) => setcityloc(e.target.value)} aria-label=" select Day">
                                    <option selected>{bind_user.city} </option>
                                    {vcitylist.map((x, index) => {
                                        return (
                                            <option value={x.city_id}>{x.city}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div >
                                <div class="input-group mt-4">
                                    <input type="text" class=" form-control inputcolor textbox-work " Value={ipdata} onChange={(e) => setipdata(e.target.value)} placeholder="IP" aria-label="Username" />
                                </div>
                            </div>
                            <div className='row'>
                                {/* <button class="btn btn-pri createbtn  mt-4 masteradd tabbtn" onClick={updateip}>Get Ip</button> */}

                                <button type='submit' onSubmit={handleSubmit(updat)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">{t('update')}</button>
                                <button type='submit' class="btn btn-danger createbtn  mt-4 masteradd tabbtn" onClick={() => ShowDetails('')}>{t('cancel')}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        seteditbank(data.worklocation);
        setcityloc(data.city_id)
        setipdata(data.ip);
        setUserType(type);
    }
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmSave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const clickdelete = (location_id) => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmDelete'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Delete(location_id),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToDelete'))
                }
            ]
        })
    }
    const updat = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmUpdate'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onUpdate(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    const wname = watch('wname')
    const isValid = wname && bname !== ""

    const [ip, setIp] = React.useState("");


    // Fetch IP when component mounts
    useEffect(() => {
        fetch("https://api.ipify.org?format=json")
            .then(res => res.json())
            .then(res => setIp(res.ip));
    }, []);
    const updateip = () => {
        setipdata(ip);
        console.log(ipdata, "iploc");
    }

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    return (
        <div className='bg-light vh-100 container-fluid'>


            <div className='row mt-5'>
                <div className='row mt-1'>
                    <div className='col-4 col-xxl-4 col-sm-4 col-md-2 col-lg-4 col-xl-4'></div>
                    <div className=' col-xxl-6 col-sm-6 col-md-9 col-lg-6 col-xl-6'>
                        <p className=' mt-5 fs-5 fw-bold text-center fnt-clr'>{t('workLocation')}</p>
                        <div className='row'>
                            <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                <div class="input-group">
                                    <input type="text" class=" form-control inputcolor textbox-work  mt-3 " Value={wname} onChange={(e) => setbank(e.target.value)} placeholder= {t('workLocation')} aria-label="Username"
                                        minlength="2" maxlength="30"

                                        name="user_email"
                                        className={` form-control inputcolor txtaddr ${errors.wname &&
                                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                        {...register("wname", {
                                            required: {
                                                value: true,
                                                message:t('dataRequired')
                                            },
                                            minLength: {
                                                value: 2,
                                                message:t('enterFullNameMinLength2'),
                                            },
                                            maxLength: {
                                                value: 30,
                                                message:
                                                    "Maximum allowed length is 30 characters ",
                                            },

                                        })} />
                                    <div>
                                        {errors.wname && <span className=' text-danger mt-5 ms-3'>{errors.wname.message}</span>}
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className='row'>
                            <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7'>
                                <div class="input-group mt-4">
                                    <input type="text" class=" form-control inputcolor txtaddr" Value={ipdata} onChange={(e) => setipdata(e.target.value)} placeholder="IP" aria-label="Username" />
                                </div>
                            </div>
                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                <button class="btn btn-pri w-100  setbtn mt-4" onClick={updateip}>Get Ip</button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-10 col-xxl-10 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                                <select class="form-select inputcolor text-start  txtaddr mt-3" Value={bname} onChange={(e) => setbank(e.target.value)} aria-label=" select Day">
                                    <option value="">{t('selectCity')} </option>
                                    {vcitylist.map((x, index) => {
                                        return (
                                            <option value={x.city_id}>{x.city}</option>
                                        );
                                    })}
                                </select>
                            </div>


                        </div>
                        <div className='row'>
                            <div className='col-5'>
                                <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                                    <button type='submit' class="btn btn-pri w-100 setbtn mt-3" disabled={!isValid} >{t('resetPassword')}</button>
                                </form>
                            </div>

                            <div className='col-5'>
                                <form onSubmit={handleSubmit(on_Insert)} action="#" method="POST">
                                    <button type='submit' class="btn btn-pri w-100 setbtn mt-3" disabled={!isValid} >{t('resetPassword')}</button>
                                </form>
                            </div>
                        </div>
                        <div className='col-10'>
                            <div className="row">
                                <div className="ms-4 mt-2 onlytab">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                    </svg>
                                </div>
                            </div>
                            <table class="table table-bordered mt-5 year-buttonss">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <td scope="col" className='fs-6 fw-bold grey'>{t('workLocation')}</td>
                                            <td scope="col" className='fs-6 fw-bold grey'>{t('city')}</td>
                                            <td scope="col" className='fs-6 fw-bold grey'>{t('zone')}</td>
                                            <td scope="col" className='fs-6 fw-bold grey'>IP</td>
                                            <td scope="col" className='fw-bold grey' >{t('createdDate')}</td>
                                            <td scope="col" ></td>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bank_details.map((x, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{x.worklocation}</td>
                                                    <td>{x.city}</td>
                                                    <td>{x.wageregion}</td>
                                                    <td>{x.ip}</td>
                                                    <td className='mobsimplytext'>{moment(x.createdon).format('MMM Do YYYY, h:mm:ss A')}</td>



                                                    <td></td>


                                                </tr>

                                            );
                                        })}
                                    </tbody>
                                </table>
                            </table>
                        </div>
                        <div className='col-2'></div>

                    </div>
                </div>
            </div>
            <div>{(() => {
                switch (user_type) {
                    case "edit": return RenderEdit();
                    default: return "";
                }
            })()}</div>
            <img className=" mt-2 imagework img-fluid hide_all hide_tab" src="./Images/workshedule.svg" />
            <Lheader />

        </div>
    )
}
export default Worklocation