import React, { useCallback, useState, useContext, useEffect } from 'react';
import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import ReactPaginate from "react-paginate";
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';


const Allocation = () => {
    const { state } = useContext(store);
    console.log('jobtitle', state.user);
    const [job_title, setJob_title] = useState([]);
    const { update, handleSubmit } = useForm();
    //const [jobtype, setjobtitle] = useState("");
    const [bind_user, setBindUser] = useState({});
    const [user_type, setUserType] = useState('');
    const [jobtypes, setjobtitles] = useState("");
    const [currentid, setCurrentId] = useState([]);

    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    // handle submit

    // watch events
    const jobtype = watch('jobtype')
    //const curname2 = watch('curname2')

    // handle disabled submit
    const isValid = jobtype


    const [bank_details, setUserDetails] = useState([]);
    const Getjobtitle = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/leave_type_closed/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    useEffect(() => {
        Getjobtitle();
    }, [state]);

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const onUpdate = () => {
        let upcur = {
            empid:empid,
            leave_type_id: bind_user.leave_id,
            leave_count: bind_user.leavepermonth

        };

        axios.post(`${process.env.REACT_APP_API_URL}/leavefremp`, upcur).then((res) => {

            console.log('department updated', res.data);
            alert('success');
            Getjobtitle();
            ShowDetails('');
        }).catch(() => {



        })

    }


    


   
    
 

  

    const [empid, setempid] = useState("");
    const [empdetails_details, setempdata] = useState([]);
    const [emplist_details, setemplist] = useState([]);

    const getleavelist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/closedleavemp/${x}`).then((res) => {
                console.log(res.data, 'success');
              
                

                setemplist(res.data);

            }).catch(() => {

            })
        }
    }
    const Getemplist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/leave_emp_list/${x}/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
              
                

                setempdata(res.data);

            }).catch(() => {

            })
        }
    }

    const Delete = (x) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/empleavedelete/${x}`).then((res) => {
                console.log(res.data, 'success');

                if (res.data == true) {

                }

                Getemplist();
                window.location.reload();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [query, SetQuery] = useState("");

    // page No
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 8;
    const pagesVisited = pageNumber * usersPerPage;


    const filteredUsers = emplist_details.filter((x) => x.full_name.toLowerCase().includes(query.toLowerCase()));

    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);


    const pageCount = Math.ceil(emplist_details.length / usersPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const Render_Edit = () => {
        console.log('bind', bind_user);
        return <div className='row mt-3  '>
            <div className='col-5'></div>
            <div className='col-sm-7  col-md-8   col-lg-6  col-xl-7 col-xxl-7'>
                <div class="card edit_sren edit_srene viewtop1">

                    <div class="card-body ">
                        <form onSubmit={handleSubmit(onUpdate)} action="#" method="POST">
                            <p for="formGroupExampleInput" class="">{bind_user.leave_type}</p>

                            
                                <label for="formGroupExampleInput" className="fw-bold ms-1">
                                Leave Allocatation
                                    </label>
                                <div class="input-group mt-3">
                                
                               
                                <select class="form-select inputcolor text-start text_box_size" onChange={(e) => setempid(e.target.value)}>
  <option value="">Select  Employee to Allocate Leave </option>
  {empdetails_details.map((x) => (
    <option key={x} value={x.emp_id}>
      {x.full_name}
    </option>
  ))}
</select>
<button type='submit' onSubmit={handleSubmit(onUpdate)} class="btn btn-pri createbtn   masteradd tabbtn">{t('add')}</button>
                                <button class="btn btn-danger createbtn  masteradd tabbtn" onClick={() => ShowDetails('')}>{t('cancel')}</button>
                            
                                  </div>
                            <div className='row'>
                            </div>
                        </form>

                    </div>
                    <input type="text" class=" form-control inputcolor mt-4  " placeholder={t('searchByEmployeeName')} 
                        onChange={(event) => { SetQuery(event.target.value); }} id="" aria-label="Username" />
                    <table class="table table-bordered mt-1">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'>{t('employeeName')} </td>
                                        <td scope="col" className='fs-6 fw-bold grey' >{t('daysPerYear')} </td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('delete')} </td>


                                    </tr>
                                </thead>
                                <tbody>
                                {displayUsers.filter((x) => x.full_name.toLowerCase().includes(query.toLowerCase())).map((x, index) => {
                                  return (
                                            <tr key={index}>

                                                <td>{x.full_name}</td>
                                                <td>{x.leave_count}</td>
                                                
                                                <td>
                                                                <svg
                                                                    onClick={() => Delete(x.emp_leave_id)}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="red"
                                                                    className="bi bi-trash3  ms-2 pointer_"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                                </svg>
                                                            </td>

                                             
                                            </tr>

                                        );
                                    })}
                                </tbody>
                            </table>
                        </table>
                        <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                </div>
            </div>
        </div>;
    }



    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setJob_title(data.job_title);
        setCurrentId(data.job_id);
        setUserType(type);
    }
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const allocation = () => {
        let path = `/allocation`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    const minwage = () => {
        let path = `/addminimumwage`;
        navigate(path);
    }
    return (
        <div className='bg-light vh-100 container-fluid'>
            <div class="row ">
                <div class="col-2">
                    <div>
                        <div className='setting_side hide_all hid_tab'>
                            <p className=" ms-3 fs-6  fw-bold mt-3">{t('settings')} </p>

                            <ul className='pointer_'>


                                <div className='mt-2' onClick={weekday}>{t('workSchedule')}</div>
                                <div className='mt-2 ' onClick={loc}>{t('workLocation')} </div>


                                <div className='mt-2' onClick={dept}>{t('department')} </div>





                                <div className='mt-2' onClick={job}>{t('jobTitles')}  </div>





                                <div className='mt-2' onClick={salary}>{t('salary')}  </div>


                                <div className='mt-2' onClick={holidays}>{t('holidays')}  </div>
                                <div className='mt-2' onClick={currency}>{t('currency')}  </div>

                                <div className='mt-2' onClick={financial}>{t('financialYear')}  </div>

                                <div className='mt-2' onClick={Leavetype}>{t('leaveTypes')}  </div>
                                <div className='mt-2 fw-bold' onClick={allocation}>{t('leaveAllocation')}  </div>
                                <div className='mt-2' onClick={overtype}>{t('overtimeRates')}  </div>
                                <div className='mt-2' onClick={ins}>{t('insurance')}  </div>

                                <div className='mt-2' onClick={minwage}>{t('minimumWage')}  </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
            
                <div className='row mt-5'>
                {(() => {
                        switch (user_type) {

                            
                            case "view": return Render_Edit();
                            default: return "";
                        }
                    })()}
                    <div className='col-5 col-xxl-5 col-sm-5 col-md-2 col-lg-5 col-xl-5'></div>
                    <div className='col-sm-6 col-xxl-6 col-sm-6 col-md-9 col-lg-6 col-xl-6'>
                    <div className='row'>
                            <div className='col-8'>
                        <p className=' mt-3 fs-5 fw-bold  grey'>{t('leaveAllocation')} </p>
                        </div>
                        <div className='col-3 onlytab'>
                                            {/* mobile setting sidebar */}
                                            <li class="item dropdown">
                                                <div className="float-start me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                                </svg> </div>
                                                <a class=" dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div></div>
                                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ marginLeft: -70,backgroundColor:"#E7E7E7" }}>
                                                    <p className="text-center fs-4 fw-bold mt-3 ">{t('settings')} </p>
                                                    <div className='mt-2  ms-2' onClick={weekday}>{t('workSchedule')} </div>
                                                    <div className='mt-2  ms-2' onClick={loc}>{t('workLocation')} </div>

                                                    <div className='mt-2 ms-2' onClick={dept}>{t('department')} </div>





                                                    <div className='mt-2 ms-2 ' onClick={job}>{t('jobTitles')}  </div>




                                                    <div className='mt-2 ms-2' onClick={salary}>{t('salary')}  </div>


                                                    <div className='mt-2 ms-2' onClick={holidays}>{t('holidays')}  </div>
                                                    <div className='mt-2 ms-2' onClick={currency}>{t('currency')}  </div>

                                                    <div className='mt-2 ms-2' onClick={financial}>{t('financialYear')}  </div>
                                                    <div className='mt-2 ms-2' onClick={Leavetype}>{t('leaveTypes')}  </div>
                                                    <div className='mt-2 ms-2 fw-bold' onClick={allocation}>{t('leaveAllocation')}  </div>
                                                    <div className='mt-2 ms-2' onClick={overtype}>{t('overtimeRates')}  </div>
                                                    <div className='mt-2 ms-2' onClick={ins}>{t('insurance')}  </div>
                                                    <div className='mt-2 ms-2' onClick={minwage}>{t('minimumWage')}  </div>

                                                </ul>
                                            </li>
                                        </div>
                        </div>
                        
                        <table class="table table-bordered mt-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'> {t('leaveType')} </td>
                                        <td scope="col" className='fs-6 fw-bold grey' >{t('daysPerYear')} </td>
                                        {/* <td scope="col" className='fs-6 fw-bold  grey'>Created Date</td> */}
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('edit')}  </td>
                                        


                                    </tr>
                                </thead>
                                <tbody>
                                    {bank_details.map((x, index) => {
                                        return (
                                            <tr key={index}>

                                                <td>{x.leave_type}</td>
                                                <td>{x.leavepermonth}</td>
                                                {/* <td className=''>{moment(x.created_on).format('MMM Do YYYY')}</td> */}






                                                <td><svg onClick={() => {ShowDetails(x, 'view');getleavelist(x.leave_id);Getemplist(x.leave_id)}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil pointer_" viewBox="0 0 16 16">

                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />

                                                </svg></td>

                                               
                                            </tr>

                                        );
                                    })}
                                </tbody>
                            </table>
                        </table>
                    </div>
                </div>



            </div>
            <div></div>
            <Lheader />
        </div>
    )
}
export default Allocation