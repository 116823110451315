import React, { useCallback, useState, useContext } from 'react';

import './Payrolllist.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';



const Report = () => {
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
return(
    <div className='bg-light vh-100 container-fluid'>
           
            <div className='row mt-5'>
                <div className='col-2 hid_tab'>

                </div>
                <div className='col-10 mt-1'>
                    <input type="text" class="form-control inputcolor mt-4 searchbox" placeholder="Search by Account, Date, Category or Amount" id="" aria-label="Username" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                   <p className='fw-bold text-center'>
                   Report Days
                   </p>
                   <div className="row">
                    <div className='col-3'></div>
                                                                                    <div className="col-3 ms-5">
                                                                                        <input type="date" class="form-control inputcolor  date_pay  mt-1 " placeholder=" Date of Birth" aria-label="" />
                                                                                        <input class="form-check-input mt-4 " type="checkbox" value="" id="flexCheckChecked" />
                                                    <label class="form-check-label ms-2 mt-4" for="flexCheckChecked">
                                                    Bonos Payrolls
                                                    </label>
                                                                                        <div className=' '>
                                                                                       
                                                                                        <button  class="btn btn-secondary  mt-1 date_btn " type="button">{t('cancel')}</button>
                                                                                    </div>
                                                                                  
                                                                                    </div>
                                                                                 
                                                                                    <div className="col-3">
                                                                                        <input type="date" class="form-control inputcolor   date_pay mt-1 " placeholder=" Date of Birth" aria-label="" />
                                                                                       <div className=''>
                                                                                        <button class="btn btn-pri mt-5 date_btn" type="button">{t('save')}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                </div>

            </div>
            <Lheader />
        </div>
)
}
export default Report