import React from 'react'
import { useCallback, useState, useContext, useEffect } from 'react';
import Lheader from '../Lheader/Lheader';
import "./Workshedule.css"
import "./Worksheduleupdate.css"
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useNavigate } from "react-router-dom";
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const Worksheduleupdate = () => {
    const { state } = useContext(store);
    console.log('workschedule', state.user);
    const [work_list, setWork_list] = useState([])
    const [worktypes, setworktypes] = useState("");
    const [startwork, setstartwork] = useState("");
    const [endwork, setendwork] = useState("");
    const [bind_user, setBindUser] = useState({});
    const { update, handleSubmit } = useForm();
    const [user_type, setUserType] = useState('');


    const Getworklist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/workschedule/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setWork_list(res.data);

            }).catch(() => {

            })
    }


    useEffect(() => {
        Getworklist();
    }, [state]);

    
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const Delete = (workschedule_id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/workschedule/` + workschedule_id).then((res) => {
                console.log(res.data, 'success');

                if (res.data == true) {

                }
                Getworklist();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [mon, setmon] = useState(1);
    const [tue, settue] = useState(2);
    const [wed, setwed] = useState(3);
    const [thu, setthu] = useState(4);
    const [fri, setfri] = useState(5);
    const [sat, setsat] = useState(6);
    const [sun, setsun] = useState(0);
    const onUpdate = (workschedule_id, e) => {
        if (!worktypes) {
            alert('Workschedule name is required. Please provide a valid workschedule name.');
            return; // Do not proceed with the update if worktypes is null
        }

        let work = {
            workschedule_id: bind_user.workschedule_id,
            workschedule_name: worktypes,
            starttime: startwork,
            endtime: endwork,
            monday: mon,
            tuesday: tue,
            wednesday: wed,
            thursday: thu,
            friday: fri,
            saturday: sat,
            sunday: sun,
            monstime: monstime,
            monetime: monetime,
            tuestime: tuestime,
            tueetime: tueetime,
            wedstime: wedstime,
            wedetime: wedetime,
            thustime: thustime,
            thuetime: thuetime,
            fristime: fristime,
            frietime: frietime,
            satstime: satstime,
            satetime: satetime,
            sunstime: sunstime,
            sunetime: sunetime,

        };


        axios.put(`${process.env.REACT_APP_API_URL}/workschedule/${bind_user.workschedule_id}`, work).then((res) => {
            console.log('workschedule updated', res.data);
            ShowDetails('');
            Getworklist();
        }).catch(() => {

        })
    }

    const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className='row workup'>
            <div className='col-4'></div>
            <div className='col-7'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(save1)} action="#" method="POST">
                            <div className='row'>
                                <div className='col-sm-8'>
                                    <div class="input-group">
                                        <input minlength="2" maxlength="30" type="text" class=" form-control inputcolor" autocomplete="tax_type" Value={bind_user.workschedule_name} onChange={(e) => setworktypes(e.target.value)} placeholder="Workschedule Name" aria-label="Username" />
                                    </div>
                                </div>

                            </div>

                            <div className='row mt-2'>
                                <small className='fw-bold grey'>Full Day</small>
                                <div className='col-sm-6'>
                                    {(() => {
                                        if (bind_user.monday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={1} onChange={(e) => setmon(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('monday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.monstime} onChange={(e) => setmonstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.monetime} onChange={(e) => setmonetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        } if (bind_user.monday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('monday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={7} onChange={(e) => setmon(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('monday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setmonstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setmonetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.tuesday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={2} onChange={(e) => settue(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('tuesday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.tuestime} onChange={(e) => settuestime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.tueetime} onChange={(e) => settueetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.tuesday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('tuesday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={7} onChange={(e) => settue(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('tuesday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => settuestime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => settueetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.wednesday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={3} onChange={(e) => setwed(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('wednesday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.wedstime} onChange={(e) => setwedstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.wedetime} onChange={(e) => setwedetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.wednesday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('wednesday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={7} onChange={(e) => setwed(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('wednesday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setwedstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setwedetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.thursday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={4} onChange={(e) => setthu(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('thursday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.thustime} onChange={(e) => setthustime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.thuetime} onChange={(e) => setthuetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.thursday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('thursday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={7} onChange={(e) => setthu(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('thursday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setthustime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setthuetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.friday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={5} onChange={(e) => setfri(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('friday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.fristime} onChange={(e) => setfristime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.frietime} onChange={(e) => setfrietime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.friday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('friday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={7} onChange={(e) => setfri(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('friday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setfristime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setfrietime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.saturday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={6} onChange={(e) => setsat(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('saturday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.satstime} onChange={(e) => setsatstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.satetime} onChange={(e) => setsatetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.saturday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('saturday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={7} onChange={(e) => setsat(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('saturday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setsatstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setsatetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.sunday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={0} onChange={(e) => setsun(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('sunday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.sunstime} onChange={(e) => setsunstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.sunetime} onChange={(e) => setsunetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.sunday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('sunday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" Value={7} type="checkbox" onChange={(e) => setsun(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('sunday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setsunstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setsunetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className='row'>
                                <button type='sumbit' onSubmit={handleSubmit(save1)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">{t('update')}</button>
                                <button type='sumbit' onClick={() => ShowDetails('')} class="btn btn-danger createbtn  mt-4 masteradd tabbtn">{t('cancel')}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }

    const RenderEditHalfDay = () => {
        console.log('bind', bind_user);
        return <div className='row workup'>
            <div className='col-4'></div>
            <div className='col-7'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(save1)} action="#" method="POST">
                            <div className='row'>
                                <div className='col-sm-8'>
                                    <div class="input-group">
                                        <input minlength="2" maxlength="30" type="text" class=" form-control inputcolor" autocomplete="tax_type" Value={bind_user.workschedule_name} onChange={(e) => setworktypes(e.target.value)} placeholder="Workschedule Name" aria-label="Username" />
                                    </div>
                                </div>

                            </div>

                            <div className='row mt-2'>
                                <small className='fw-bold grey'>Half Day</small>
                                <div className='col-sm-6'>
                                    {(() => {
                                        if (bind_user.monday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={1} onChange={(e) => setmon(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('monday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.monstime} onChange={(e) => setmonstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.monetime} onChange={(e) => setmonetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        } if (bind_user.monday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('monday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={8} onChange={(e) => setmon(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('monday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setmonstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setmonetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.tuesday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={2} onChange={(e) => settue(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('tuesday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.tuestime} onChange={(e) => settuestime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.tueetime} onChange={(e) => settueetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.tuesday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('tuesday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={8} onChange={(e) => settue(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('tuesday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => settuestime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => settueetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.wednesday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={3} onChange={(e) => setwed(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('wednesday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.wedstime} onChange={(e) => setwedstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.wedetime} onChange={(e) => setwedetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.wednesday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('wednesday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={8} onChange={(e) => setwed(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('wednesday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setwedstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setwedetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.thursday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={4} onChange={(e) => setthu(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('thursday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.thustime} onChange={(e) => setthustime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.thuetime} onChange={(e) => setthuetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.thursday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('thursday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={8} onChange={(e) => setthu(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('thursday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setthustime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setthuetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.friday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={5} onChange={(e) => setfri(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('friday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.fristime} onChange={(e) => setfristime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.frietime} onChange={(e) => setfrietime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.friday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('friday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={8} onChange={(e) => setfri(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('friday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setfristime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setfrietime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.saturday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={6} onChange={(e) => setsat(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('saturday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.satstime} onChange={(e) => setsatstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.satetime} onChange={(e) => setsatetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.saturday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('saturday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" Value={8} onChange={(e) => setsat(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('saturday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setsatstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setsatetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                                <div className='col-6'>
                                    {(() => {
                                        if (bind_user.sunday == 8) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" defaultChecked={true} value={0} onChange={(e) => setsun(e.target.value)} />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('sunday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.sunstime} onChange={(e) => setsunstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" Value={bind_user.sunetime} onChange={(e) => setsunetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        } if (bind_user.sunday == 7) {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" type="checkbox" id="flexCheckChecked" disabled />
                                                <label class="form-check-label ms-2" for="flexCheckChecked">
                                                    {t('sunday')}
                                                </label>

                                            </div>
                                        } else {
                                            return <div class="form-check">
                                                <input class="form-check-input ms-2" Value={8} type="checkbox" onChange={(e) => setsun(e.target.value)} id="" />
                                                <label class="form-check-label ms-2" for="flexCheckDefault">
                                                    {t('sunday')}
                                                </label>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setsunstime(e.target.value)} placeholder="Start Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div class="input-group">
                                                            <input type="time" class=" form-control inputcolor " autocomplete="tax_percentage" onChange={(e) => setsunetime(e.target.value)} placeholder="End Time" aria-label="Username" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })()}
                                </div>
                            </div>
                            <div className='row'>
                                <button type='sumbit' onSubmit={handleSubmit(save1)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">{t('update')}</button>
                                <button type='sumbit' onClick={() => ShowDetails('')} class="btn btn-danger createbtn  mt-4 masteradd tabbtn">{t('cancel')}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }

    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setworktypes(data.workschedule_name);
        setstartwork(data.starttime);
        setendwork(data.endtime);
        setmon(data.monday);
        settue(data.tuesday);
        setwed(data.wednesday);
        setthu(data.thursday);
        setfri(data.friday);
        setsat(data.saturday);
        setsun(data.sunday);
        setmonstime(data.monstime);
        setmonetime(data.monetime);
        settuestime(data.tuestime);
        settueetime(data.tueetime);
        setwedstime(data.wedstime);
        setwedetime(data.wedetime);
        setthustime(data.thustime);
        setthuetime(data.thuetime);
        setfristime(data.fristime);
        setfrietime(data.frietime);
        setsatstime(data.satstime);
        setsatetime(data.satetime);
        setsunstime(data.sunstime);
        setsunetime(data.sunetime);
        setUserType(type);
    }
    let navigate = useNavigate();
    const cmplst = () => {
        let path = `/companylist`;
        navigate(path);
    }
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    const minwage = () => {
        let path = `/addminimumwage`;
        navigate(path);
    }
    const allocation = () => {
        let path = `/allocation`;
        navigate(path);
    }

    const save1 = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmUpdate'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onUpdate(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert( t('confirmUpdate'))
                }
            ]
        })
    }
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmSave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    // handle submit

    // watch events
    const workname = watch('workname')
    //const workstart = watch('workstart')
    //const workend = watch('workend')




    //const [workname, setworktitle] = useState("");
    const [workstart, setworkstart] = useState("");
    const [workend, setworkend] = useState("");

    const [monday, setMonday] = useState(1);
    const [tuesday, setTuesday] = useState(2);
    const [wednesday, setWednesday] = useState(3);
    const [thursday, setThursday] = useState(4);
    const [friday, setFriday] = useState(5);
    const [saturday, setsaturday] = useState(6);
    const [sunday, setsunday] = useState(0);
    const [halfstart, sethalfstart] = useState("");
    const [halfend, sethalfend] = useState("");
    const [isTopMondayChecked, setIsTopMondayChecked] = useState(false);
    const [isTopTuesdayChecked, setIsTopTuesdayChecked] = useState(false);
    const [isTopWednesdayChecked, setIsTopWednesdayChecked] = useState(false);
    const [isTopThursdayChecked, setIsTopThursdayChecked] = useState(false);
    const [isTopFridayChecked, setIsTopFridayChecked] = useState(false);
    const [isTopSaturdayChecked, setIsTopSaturdayChecked] = useState(false);
    const [isTopSundayChecked, setIsTopSundayChecked] = useState(false);
    const [dp, setdp] = useState('');
    const [monstime, setmonstime] = useState("");
    const [monetime, setmonetime] = useState("");
    const [tuestime, settuestime] = useState("");
    const [tueetime, settueetime] = useState("");
    const [wedstime, setwedstime] = useState("");
    const [wedetime, setwedetime] = useState("");
    const [thustime, setthustime] = useState("");
    const [thuetime, setthuetime] = useState("");
    const [fristime, setfristime] = useState("");
    const [frietime, setfrietime] = useState("");
    const [satstime, setsatstime] = useState("");
    const [satetime, setsatetime] = useState("");
    const [sunstime, setsunstime] = useState("");
    const [sunetime, setsunetime] = useState("");

    console.log(monstime, "testtime")
    console.log(workstart, "worktime")

    // handle disabled submit
    const isValid = workname && workstart && workend

    const checkifValidname = (workschedulename) => {

        let isValid = true;
        work_list.forEach(item => {

            if (item.workschedule_name.toLowerCase() === workschedulename.toLowerCase()) {
                isValid = false;
                console.log(item.workschedule_name, 'workname')
            }
        });
        return isValid
    }
    const onInsert = (data, e) => {
        if (workname != "") {
            if (checkifValidname(workname)) {
                try {
                    let workschedule = {
                        workschedule_name: workname,
                        starttime: workstart,
                        endtime: workend,
                        user_id: state.user[0].user_id,
                        company_id: state?.user[0]?.company_id,
                        monday: monday,
                        monstime: monstime,
                        monetime: monetime,
                        tuesday: tuesday,
                        tuestime: tuestime,
                        tueetime: tueetime,
                        wednesday: wednesday,
                        wedstime: wedstime,
                        wedetime: wedetime,
                        thursday: thursday,
                        thustime: thustime,
                        thuetime: thuetime,
                        friday: friday,
                        fristime: fristime,
                        frietime: frietime,
                        saturday: saturday,
                        satstime: satstime,
                        satetime: satetime,
                        sunday: sunday,
                        sunstime: sunstime,
                        sunetime: sunetime,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/workschedule`, workschedule).then((res) => {
                        console.log('workschedule added', res.data);
                        //Getworklist();
                        window.location = "/worksheduleupdate";
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {

                alert('Work schedule name already exists');
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        className: 'mx-auto',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }




    const clickdelete = (workschedule_id) => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmDelete'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Delete(workschedule_id),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToDelete'))
                }
            ]
        })
    }


    return (

        <div className=' vh-100 container-fluid workupdatetab'>
            <div class="row ">
                <div class="col-2">
                    <div >
                        <div className='setting_side hide_all hid_tab'>
                            <p className=" ms-3 fs-6 fw-bold mt-3  ">{t('settings')}</p>

                            <ul className='pointer_'>

                                {/* <div className='' onClick={bank}>Bank </div> */}
                                <div className='mt-2 fw-bold ' onClick={weekday}>{t('workSchedule')} </div>
                                <div className='mt-2  ' onClick={loc}>{t('workLocation')} </div>

                                <div className='mt-2 ' onClick={dept}>{t('department')} </div>





                                <div className='mt-2' onClick={job}>{t('jobTitles')} </div>




                                <div className='mt-2' onClick={salary}>{t('salary')} </div>


                                <div className='mt-2' onClick={holidays}>{t('holidays')} </div>
                                <div className='mt-2' onClick={currency}>{t('currency')} </div>

                                <div className='mt-2' onClick={financial}>{t('financialYear')} </div>
                                <div className='mt-2' onClick={Leavetype}>{t('leaveTypes')}</div>
                                <div className='mt-2' onClick={allocation}>{t('leaveAllocation')} </div>
                                <div className='mt-2' onClick={overtype}>{t('overtimeRates')} </div>
                                <div className='mt-2' onClick={ins}>{t('insurance')} </div>
                                <div className='mt-2' onClick={minwage}>{t('minimumWage')} </div>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>
            <div className='row'>


                <form onSubmit={handleSubmit(save)} action="#" method="POST">
                    <div className='row mt-5'>

                        <div className='col-4 hide_all hid_tab'></div>
                        <div className='col-sm-8  col-md-12 col-lg-12 col-xl-8 col-xxl-8  '>
                            {/* <a class="nav-link text-primary fw-bold hide_all_pc " aria-current="page" href="#"><img className="hide_all_pc" src="./Images/Cpyinfo.png"></img>Company Information</a>
                            <a class="nav-link text-primary fw-bold hide_all_pc text" href="#"><img className="hide_all_pc" src="./Images/Work.png"></img>Work Schedules</a> */}

                            <div className='row'>
                                <div className='col-1  col-md-3 col-lg-3 col-xl-1 col-xxl-1 '></div>
                                <div className='col-sm-6'>
                                    <div className='row mt-4'>
                                        <div className='col-8'>
                                            <div className='fs-5 fw-bold mt-3 grey'>
                                                {t('workSchedule')}
                                            </div>
                                        </div>
                                        <div className='col-3 onlytab'>
                                            {/* mobile setting sidebar */}
                                            <li class="item dropdown">
                                                <div className="float-start me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                                </svg> </div>
                                                <a class=" dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                </a>
                                                <div></div>
                                                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ marginLeft: -70,backgroundColor:"#E7E7E7" }}>
                                                    <p className="text-center fs-4 fw-bold mt-3 ">{t('settings')}</p>
                                                    <div className='mt-2 fw-bold ms-2' onClick={weekday}>{t('workSchedule')} </div>
                                                    <div className='mt-2  ms-2' onClick={loc}>{t('workLocation')} </div>

                                                    <div className='mt-2 ms-2' onClick={dept}>{t('department')} </div>





                                                    <div className='mt-2 ms-2' onClick={job}>{t('jobTitles')} </div>




                                                    <div className='mt-2 ms-2' onClick={salary}>{t('salary')} </div>


                                                    <div className='mt-2 ms-2' onClick={holidays}>{t('holidays')} </div>
                                                    <div className='mt-2 ms-2' onClick={currency}>{t('currency')} </div>

                                                    <div className='mt-2 ms-2' onClick={financial}>{t('financialYear')} </div>
                                                    <div className='mt-2 ms-2' onClick={Leavetype}>{t('leaveTypes')}</div>
                                                    <div className='mt-2 ms-2' onClick={allocation}>{t('leaveAllocation')} </div>
                                                    <div className='mt-2 ms-2' onClick={overtype}>{t('overtimeRates')} </div>
                                                    <div className='mt-2 ms-2' onClick={ins}>{t('insurance')} </div>
                                                    <div className='mt-2 ms-2' onClick={minwage}>{t('minimumWage')} </div>

                                                </ul>
                                            </li>
                                        </div>



                                    </div>
                                    <div className=''>
                                        <div className='text-center'>

                                            <div class="input-group">
                                                <input
                                                    className={`form-control inputcolor txtaddr  mt-3 required ${errors.workname &&
                                                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}

                                                    minlength="2" maxlength="30"
                                                    type='text'
                                                    placeholder={t('workSchedule')} Name

                                                    {...register("workname", {
                                                        required: {
                                                            value: true,
                                                            message:t('workScheduleNameRequired')
                                                        },
                                                        minLength: {
                                                            value: 2,
                                                            message:t('enterWorkScheduleName'),
                                                        },
                                                        maxLength: {
                                                            value: 39,
                                                            message:
                                                               t('maxAllowedLengthh'),
                                                        },
                                                        pattern: {
                                                            value: /[a-zA-Z]+/,
                                                            message:t('onlyAlphabets') 
                                                        },

                                                    })}
                                                />
                                                <div>
                                                    {errors.workname && <span className='error_comwor text-danger'>{errors.workname.message}</span>}
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className=' mt-3'>

                                        <div className=''>

                                            <div class="input-group mb-3 ">
                                                <input type="time" class="form-control inputcolor text-center  " Value={workstart} onChange={(e) => (setworkstart(e.target.value), setmonstime(e.target.value), settuestime(e.target.value), setwedstime(e.target.value), setthustime(e.target.value), setfristime(e.target.value), setsatstime(e.target.value), setsunstime(e.target.value))} placeholder=" Start Time *" aria-label="" />





                                                <input type="time" class="form-control inputcolor text-center ms-3 " Value={workend} onChange={(e) => (setworkend(e.target.value), setmonetime(e.target.value), settueetime(e.target.value), setwedetime(e.target.value), setthuetime(e.target.value), setfrietime(e.target.value), setsatetime(e.target.value), setsunetime(e.target.value))} placeholder="End Time*" aria-label="" />








                                            </div>

                                        </div>

                                    </div>
                                    <div className='row'>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setMonday(e.target.checked ? e.target.value : 1);
                                                    setIsTopMondayChecked(e.target.checked);
                                                }} id="topMondayCheckbox" disabled={dp === '1'} />
                                                <label class="form-check-label" for="">
                                                    {t('monday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setTuesday(e.target.checked ? e.target.value : 2);
                                                    setIsTopTuesdayChecked(e.target.checked);
                                                }} id="topTuesdayCheckbox" disabled={dp === '2'} />
                                                <label class="form-check-label" for="">
                                                    {t('tuesday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setWednesday(e.target.checked ? e.target.value : 3);
                                                    setIsTopWednesdayChecked(e.target.checked);
                                                }} id="topWednesdayCheckbox" disabled={dp === '3'} />
                                                <label class="form-check-label" for="">
                                                    {t('wednesday')}
                                                </label>
                                            </div>
                                        </div>


                                        <div className='col-3 '>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setThursday(e.target.checked ? e.target.value : 4);
                                                    setIsTopThursdayChecked(e.target.checked);
                                                }} id="topThursdayCheckbox" disabled={dp === '4'} />
                                                <label class="form-check-label" for="">
                                                    {t('thursday')}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setFriday(e.target.checked ? e.target.value : 5);
                                                    setIsTopFridayChecked(e.target.checked);
                                                }} id="topFridayCheckbox" disabled={dp === '5'} />
                                                <label class="form-check-label" for="">
                                                    {t('friday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setsaturday(e.target.checked ? e.target.value : 6);
                                                    setIsTopSaturdayChecked(e.target.checked);
                                                }} id="topSaturdayCheckbox" disabled={dp === '6'} />
                                                <label class="form-check-label" for="">
                                                    {t('saturday')}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='col-3'>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value={7} onChange={(e) => {
                                                    setsunday(e.target.checked ? e.target.value : 0);
                                                    setIsTopSundayChecked(e.target.checked);
                                                }} id="topSundayCheckbox" disabled={dp === '7'} />
                                                <label class="form-check-label" for="">
                                                    {t('sunday')}
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className='col'>
                                            <select className='form-select inputcolor' value={dp} onChange={(e) => setdp(e.target.value)} >
                                                <option value='8'>Set Half Day</option>
                                                <option value='1' disabled={isTopMondayChecked}>{t('monday')}</option>
                                                <option value='2' disabled={isTopTuesdayChecked}>{t('tuesday')}</option>
                                                <option value='3' disabled={isTopWednesdayChecked}>{t('wednesday')}</option>
                                                <option value='4' disabled={isTopThursdayChecked}>{t('thursday')}</option>
                                                <option value='5' disabled={isTopFridayChecked}>{t('friday')}</option>
                                                <option value='6' disabled={isTopSaturdayChecked}>{t('saturday')}</option>
                                                <option value='7' disabled={isTopSundayChecked}>{t('sunday')}</option>
                                            </select>
                                        </div>

                                        {(() => {
                                            if (dp == 1) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setmonstime(e.target.value), setMonday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setmonetime(e.target.value), setMonday(8))}></input>
                                                    </div>
                                                </div>
                                            }
                                            if (dp == 2) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (settuestime(e.target.value), setTuesday(8))} placeholder="Enter time"></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (settueetime(e.target.value), setTuesday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 3) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setwedstime(e.target.value), setWednesday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setwedetime(e.target.value), setWednesday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 4) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setthustime(e.target.value), setThursday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setthuetime(e.target.value), setThursday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 5) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setfristime(e.target.value), setFriday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setfrietime(e.target.value), setFriday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 6) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setsatstime(e.target.value), setsaturday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setsatetime(e.target.value), setsaturday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                            if (dp == 7) {

                                                return <div className='row mt-2'>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setsunstime(e.target.value), setsunday(8))}></input>
                                                    </div>
                                                    <div className='col-6'>
                                                        <input className='form-control inputcolor' type='time' onChange={(e) => (setsunetime(e.target.value), setsunday(8))}></input>
                                                    </div>
                                                </div>

                                            }
                                        })()}
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-6 d-grid'>
                                            <button class="btn btn-pri " onSubmit={handleSubmit(save)} type="submit" disabled={!isValid}>{t('save')}</button>
                                        </div>
                                        <div className='col-6 d-grid'>

                                        </div>

                                    </div>
                                </div>
                            </div>






                            <div className='row'>
                                <div className='col-1'></div>
                                <div className='col-10 pointer_'>
                                    <div className="row">
                                        <div className="ms-4 mt-2 onlytab">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <table class="table table-bordered mt-4 year-buttonss">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <td scope="col"></td>
                                                    <td scope="col" className='fs-6 fw-bold grey'>{t('name')} </td>

                                                    <td scope="col" className='fs-6 fw-bold  grey text-center'>{t('mon')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey text-center'>{t('tue')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey text-center'>{t('wed')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey text-center'>{t('thu')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey text-center'>{t('fri')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey text-center'>{t('sat')}</td>
                                                    <td scope="col" className='fs-6 fw-bold  grey text-center'>{t('sun')}</td>
                                                    <td scope="col" className='fs-6   fw-bold grey'> {t('fullDayEdit')}   </td>
                                                    <td scope="col" className='fs-6    fw-bold grey'> {t('halfDayEdit')}   </td>
                                                    <td scope="col" className='fs-6   fw-bold grey'>{t('delete')}  </td>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {work_list.map((x, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td scope="row"></td>
                                                            <td className=''>{x.workschedule_name}</td>
                                                            {(() => {
                                                                if (x.monday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.monstime}-{x.monetime}</p></td>
                                                                } if (x.monday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.monstime}-{x.monetime}</p></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.tuesday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.tuestime}-{x.tueetime}</p></td>
                                                                } if (x.tuesday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.tuestime}-{x.tueetime}</p></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.wednesday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.wedstime}-{x.wedetime}</p></td>
                                                                } if (x.wednesday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.wedstime}-{x.wedetime}</p></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.thursday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.thustime}-{x.thuetime}</p></td>
                                                                } if (x.thursday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.thustime}-{x.thuetime}</p></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.friday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.fristime}-{x.frietime}</p></td>
                                                                } if (x.friday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.fristime}-{x.frietime}</p></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.saturday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.satstime}-{x.satetime}</p></td>
                                                                } if (x.saturday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.satstime}-{x.satetime}</p></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            {(() => {
                                                                if (x.sunday == 7) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.sunstime}-{x.sunetime}</p></td>
                                                                } if (x.sunday == 8) {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                                    </svg><p>{x.sunstime}-{x.sunetime}</p></td>
                                                                } else {
                                                                    return <td className='stendw text-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x" viewBox="0 0 16 16">
                                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                                    </svg></td>
                                                                }

                                                            })()}
                                                            <td className='stendw'><svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-pencil" viewBox="0 0 16 16">
                                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                            </svg></td>
                                                            <td className='stendw'><svg onClick={() => ShowDetails(x, 'edithalfday')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-pencil" viewBox="0 0 16 16">
                                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                            </svg></td>
                                                            <td><svg onClick={() => clickdelete(x.workschedule_id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash3  " viewBox="0 0 16 16">
                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                            </svg></td>

                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </table>
                                </div>
                            </div>


                            <a class="nav-link fw-bold add_com_ text-secondary hide_all_pc addemp-tab" href="#"><img className="hide_all_pc " src="./Images/Account.png"></img>Add Employes</a>

                        </div>


                    </div>

                    <Lheader />
                </form>
            </div>
            <div>{(() => {
                switch (user_type) {
                    //case "view": return RenderView();
                    case "edit": return RenderEdit();
                    case "edithalfday": return RenderEditHalfDay();
                    default: return "";
                }
            })()}</div>
        </div>
    )

}

export default Worksheduleupdate