import React, { useCallback, useState, useContext, useEffect ,useRef} from 'react';

import { LoadScript, Autocomplete, GoogleMap, Marker, Circle } from '@react-google-maps/api';
import axios from 'axios';
import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';


const Companylocation = () => {


    const { state } = useContext(store);
    console.log('bank', state.user);
    const { update, handleSubmit } = useForm();
    const [bname, setbank] = useState("");
    const [editbank, seteditbank] = useState("");
    const [bank_details, setUserDetails] = useState([]);
    const [cityloc, setcityloc] = useState("")
    const [bind_user, setBindUser] = useState({});

    const [user_type, setUserType] = useState('');



    // geo fence


    const api = 'AIzaSyDnLeChdkHjdcB8cwxMLj61U_6j2KOZUwY';
    const [selectedPlace, setSelectedPlace] = useState('');
    const [selectedPlaceCoordinates, setSelectedPlaceCoordinates] = useState(null);
    const [radius, setRadius] = useState(100); // Default radius in meters
    const [recordedLocations, setRecordedLocations] = useState([]);
    const [message, setMessage] = useState('');
  
    const autocompleteRef = useRef(null);
  
    const onAutocompleteLoad = (autocomplete) => {
      autocompleteRef.current = autocomplete;
    };
  
    const onPlaceChanged = () => {
      const place = autocompleteRef.current.getPlace();
  
      if (place.geometry) {
        setSelectedPlace(place.formatted_address);
        setSelectedPlaceCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      }
    };
  
    const onMarkerDragEnd = (e) => {
      setSelectedPlaceCoordinates({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    };
    const center = selectedPlaceCoordinates || { lat: 0, lng: 0 };
     
    //end
  
  
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }
    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    const minwage = () => {
        let path = `/addminimumwage`;
        navigate(path);
    }
    const allocation = () => {
        let path = `/allocation`;
        navigate(path);
    }


    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const Getloc = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/companylocation/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    useEffect(() => {
        Getloc();
    }, [state]);


    const checkifValidemail = (loginmail) => {

        let isValid = true;
        bank_details.forEach(item => {

            if (item.worklocation.toLowerCase() === loginmail.toLowerCase()) {
                isValid = false;
            }
        });
        return isValid
    }

    const [vcitylist, setvcitylist] = useState([]);
    const Getvcitylist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vcity`).then((res) => {
                console.log(res.data, 'success');
                setvcitylist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    useEffect(() => {
        Getvcitylist();

    }, []);
    const onInsert = (data, e) => {
        if (wname != "") {
            if (checkifValidemail(wname)) {
                try {
                    let loc = {
                        worklocation: wname,
                        comp_location: bname,
                        comp_zone: 1,
                        company_id: state?.user[0]?.company_id,
                        ip: ipdata,
                        place:selectedPlace,
                        laltitude:selectedPlaceCoordinates.lat,
                        longitude:selectedPlaceCoordinates.lng,
                        radius:radius,
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/companylocation`, loc).then((res) => {
                        console.log('user updated', res.data);
                        //save();
                        //Getbank();
                        window.location = "/companylocation";
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: 'Location name already exists',
                    className: 'text-center',
                    buttons: [
                        {
                            label: t('close') ,
                            className: 'mx-auto',
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        className: 'mx-auto',
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }
    const Delete = (location_id) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/companylocation/` + location_id).then((res) => {
                console.log(res.data, 'success');

                if (res.data == true) {

                }
                Getloc();

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const [ipdata, setipdata] = useState('');

    const onUpdate = (location_id, e) => {
        if (!editbank || !ipdata) {
            alert(t('workLocationNameAndIPRequired'));
            return;
        }
        let loc = {
            location_id: bind_user.location_id,
            comp_location: cityloc,
            worklocation: editbank,
            comp_zone: 1,
            ip: ipdata,
        };



        axios.put(`${process.env.REACT_APP_API_URL}/companylocation/${bind_user.location_id}`, loc).then((res) => {

            console.log('companylocation updated', res.data);



            ShowDetails('');
            Getloc();
        }).catch(() => {



        })

    }
    const RenderEdit = () => {
        console.log('bind', bind_user);

        return <div className='row'>
            <div className='col-5'></div>
            <div className='col-5'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(updat)} action="#" method="POST">
                            <div class="input-group">
                                <input minlength="2" maxlength="40" type="text" class=" form-control inputcolor" autocomplete="" Value={bind_user.worklocation} onChange={(e) => seteditbank(e.target.value)} placeholder={t('workLocation')} aria-label="Username" />
                            </div>
                            <div>
                                <select class="form-select inputcolor text-start  mt-3" Value={bind_user.comp_location} onChange={(e) => setcityloc(e.target.value)} aria-label=" select Day">
                                    <option selected>{bind_user.city} </option>
                                    {vcitylist.map((x, index) => {
                                        return (
                                            <option value={x.city_id}>{x.city}</option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div >
                                <div class="input-group mt-4 ">
                                    <input type="text" class=" form-control inputcolor  textbox-work " Value={ipdata} onChange={(e) => setipdata(e.target.value)} placeholder="IP" aria-label="Username" />
                                    <a class="btn btn-pri createbtn   masteradd hide_all hide_tab" onClick={updateip}><p className='mt-2'>Get Ip</p></a>

                                </div>

                            </div>
                            <div className='row'>
                                <a class="btn btn-pri createbtn     mt-2 onlytab " onClick={updateip}><p className='mt-2'>Get Ip</p></a>

                                <button type='submit' onSubmit={handleSubmit(updat)} class="btn btn-pri createbtn  mt-2  ms-5 onlytab  masteradd tabbtn">{t('update')}</button>
                                <button type='submit' class="btn btn-danger createbtn  mt-4  ms-5 masteradd tabbtn onlytab hide_tab" onClick={() => ShowDetails('')}>{t('cancel')}</button>
                                <button type='submit' onSubmit={handleSubmit(updat)} class="btn btn-pri createbtn  mt-4   masteradd tabbtn hide_tab  hide_all">{t('update')}</button>
                                <button type='submit' class="btn btn-danger createbtn  mt-4   masteradd tabbtn canleft hide_all" onClick={() => ShowDetails('')}>{t('cancel')}</button>

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        seteditbank(data.worklocation);
        setcityloc(data.city_id)
        setipdata(data.ip);
        setUserType(type);
    }
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmSave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const clickdelete = (location_id) => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmDelete'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Delete(location_id),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToDelete'))
                }
            ]
        })
    }
    const updat = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmUpdate'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onUpdate(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    //const bname = watch('fname')
    const wname = watch('wname')
    const isValid = wname && bname !== ""

    const [ip, setIp] = React.useState("");
    //  setipdata(ip);
    // Fetch IP when component mounts
    useEffect(() => {
        fetch("https://api.ipify.org?format=json")
            .then(res => res.json())
            .then(res => setIp(res.ip));
    }, []);
    const updateip = () => {
        fetch("https://api.ipify.org?format=json")
            .then(res => res.json())
            .then(res => setipdata(res.ip));
        // setipdata(res.ip);
        console.log(ipdata, "iploc");
    }

    return (
        <div className='bg-light vh-100 container-fluid'>
            <div class="row">
                <div class="col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div >
                        <div className='setting_side hide_all hid_tab'>
                            <p className=" ms-3 fs-6   fw-bold mt-3">{t('settings')}</p>

                            <ul className='pointer_'>


                                <div className='mt-2' onClick={weekday}>{t('workSchedule')} </div>
                                <div className='mt-2 fw-bold' onClick={loc}>{t('workLocation')} </div>

                                <div className='mt-2' onClick={dept}>{t('department')} </div>





                                <div className='mt-2' onClick={job}>{t('jobTitles')} </div>


                                <div className='mt-2' onClick={salary}>{t('salary')} </div>


                                <div className='mt-2' onClick={holidays}>{t('holidays')} </div>
                                <div className='mt-2' onClick={currency}>{t('currency')} </div>

                                <div className='mt-2' onClick={financial}>{t('financialYear')} </div>

                                <div className='mt-2' onClick={Leavetype}>{t('leaveTypes')}</div>
                                <div className='mt-2' onClick={allocation}>{t('leaveAllocation')} </div>
                                <div className='mt-2' onClick={overtype}>{t('overtimeRates')} </div>
                                <div className='mt-2' onClick={ins}>{t('insurance')} </div>

                                <div className='mt-2' onClick={minwage}>{t('minimumWage')} </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-5'>
                <div className='row mt-3'>
                    <div className='col-sm-5 col-xxl-5 col-sm-5 col-md-2 col-lg-3 col-xl-5'></div>
                    <div className='col-sm-6 col-xxl-6 col-sm-6 col-md-9 col-lg-9 col-xl-6'>
                        <div className='row'>
                            <div className='col-8'>
                                <p className=' mt-3 fs-5 fw-bold grey   '> {t('workLocation')}</p>

                            </div>
                            <div className='col-3 onlytab'>
                                {/* mobile setting sidebar */}
                                <li class="item dropdown">
                                    <div className="float-start me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                    </svg> </div>
                                    <a class=" dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    </a>
                                    <div></div>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ marginLeft: -70, backgroundColor: "#E7E7E7" }}>
                                        <p className="text-center fs-4 fw-bold mt-3 ">{t('settings')}</p>
                                        <div className='mt-2  ms-2' onClick={weekday}>{t('workSchedule')} </div>
                                        <div className='mt-2  fw-bold ms-2' onClick={loc}>{t('workLocation')} </div>

                                        <div className='mt-2 ms-2' onClick={dept}>{t('department')} </div>





                                        <div className='mt-2 ms-2' onClick={job}>{t('jobTitles')} </div>




                                        <div className='mt-2 ms-2' onClick={salary}>Salary </div>


                                        <div className='mt-2 ms-2' onClick={holidays}>{t('holidays')} </div>
                                        <div className='mt-2 ms-2' onClick={currency}>{t('currency')} </div>

                                        <div className='mt-2 ms-2' onClick={financial}>{t('financialYear')} </div>
                                        <div className='mt-2 ms-2' onClick={Leavetype}>{t('leaveTypes')}</div>
                                        <div className='mt-2 ms-2' onClick={allocation}>{t('leaveAllocation')} </div>
                                        <div className='mt-2 ms-2' onClick={overtype}>{t('overtimeRates')} </div>
                                        <div className='mt-2 ms-2' onClick={ins}>{t('insurance')} </div>
                                        <div className='mt-2 ms-2' onClick={minwage}>{t('minimumWage')} </div>

                                    </ul>
                                </li>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                <div class="input-group">
                                    <input type="text" class=" form-control inputcolor textbox-work  mt-3 " Value={wname} onChange={(e) => setbank(e.target.value)} placeholder={t('workLocation')} aria-label="Username"
                                        minlength="2" maxlength="40"

                                        name="user_email"
                                        className={` form-control inputcolor txtaddr ${errors.wname &&
                                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                        {...register("wname", {
                                            required: {
                                                value: true,
                                                message:t('dataRequired')
                                            },
                                            minLength: {
                                                value: 3,
                                                message:t('enterFullNameMinLength2'),
                                            },
                                            maxLength: {
                                                value: 39,
                                                message:
                                                   t('maxAllowedLengthh'),
                                            },

                                        })} />
                                    <div>
                                        {errors.wname && <span className='error_f text-danger mt-5 ms-3'>{errors.wname.message}</span>}
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className='row'>
                            <div className='col-sm-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                <div class="input-group mt-4">
                                    <input type="text" class=" form-control inputcolor txtaddr" Value={ipdata} onChange={(e) => setipdata(e.target.value)} placeholder="IP" aria-label="Username" />
                                </div>
                            </div>
                            <div className='col-sm-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                <button class="btn btn-pri w-75 ms-5 setbtn mt-4" onClick={updateip}>Get Ip</button>
                            </div>
                        </div>
                        <div className="row">
          
          <div className="col-sm-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
            <LoadScript googleMapsApiKey={api} libraries={['places']} loading="async" className="mt-4">
              <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                <input
                  type="text"
                  className="form-control mt-3  inputcolor txtaddr"
                  value={selectedPlace}
                  onChange={(e) => setSelectedPlace(e.target.value)}
                  placeholder="Search for a place"
                />
              </Autocomplete>
            </LoadScript>
          
            <input
              className="form-control mt-3  inputcolor txtaddr"
              disabled
              value={selectedPlaceCoordinates ? `Lat: ${selectedPlaceCoordinates.lat}, Lng: ${selectedPlaceCoordinates.lng}` : ''}
            ></input>

            <select
              className="form-select mt-3 inputcolor txtaddr"
              value={radius}
              onChange={(e) => setRadius(Number(e.target.value))}
            >
              <option value={50}>50 meter</option>
              <option value={100}>100 meter</option>
              <option value={200}>200 meter</option>
            </select>
         
 
          </div>
          <div className="col-4">
            <LoadScript googleMapsApiKey={api} libraries={['places']} loading="async"  className="mt-4">
                <br></br>
              <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={{ width: '100%', height: '200px' }}
                className="mt-5"
              >
                {selectedPlaceCoordinates && (
                  <>
                    <Marker
                      position={selectedPlaceCoordinates}
                      draggable={true}
                      onDragEnd={onMarkerDragEnd}
                    />
                    <Circle
                      center={selectedPlaceCoordinates}
                      radius={radius}
                      options={{
                        fillColor: '#007BFF',
                        fillOpacity: 0.2,
                        strokeColor: '#007BFF',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        clickable: false,
                        draggable: false,
                        editable: false,
                        visible: true,
                      }}
                    />
                  </>
                )}
              </GoogleMap>
            </LoadScript>
          </div>
        </div>

                        <div className='row'>
                            <div className='col-sm-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>
                                <select class="form-select inputcolor text-start  txtaddr mt-3" Value={bname} onChange={(e) => setbank(e.target.value)} aria-label=" select Day">
                                    <option value="">{t('selectCity')} </option>
                                    {vcitylist.map((x, index) => {
                                        return (
                                            <option value={x.city_id}>{x.city}</option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className='col-sm-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">
                                    <button type='submit' class="btn btn-pri w-75 ms-5 setbtn mt-3" disabled={!isValid} >{t('add')}</button>
                                </form>
                            </div>
                        </div>
                        <div className="row">
                                <div className="ms-4 mt-2 onlytab hide_tab">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                    </svg>
                                </div>
                            </div>
                        <table class="table table-bordered mt-3 year-buttonss settb">
                           
                          
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'>{t('workLocation')}</td>
                                        <td scope="col" className='fs-6 fw-bold grey'>{t('city')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('zone')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('ip')}</td>
                                        <td scope="col" className='fs-6 fw-bold  grey'>{t('Details')}</td>
                                        <td scope="col" className='fw-bold grey' >{t('createdDate')}</td>
                                        <td scope="col" ></td>
                                        <td scope="col" className='fw-bold grey'>{t('edit')} </td>
                                        <td scope="col" className='fw-bold grey'>{t('delete')} </td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {bank_details.map((x, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{x.worklocation}</td>
                                                <td>{x.city}</td>
                                                <td>{x.wageregion}</td>
                                                <td>{x.ip}</td>
                                                <td>{x.place}</td>
                                                <td className=''>{moment(x.createdon).format('MMM Do YYYY')}</td>



                                                <td></td>

                                                <td><svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3 pointer_" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></td>

                                                <td><svg onClick={() => clickdelete(x.location_id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash3  mt-3 float-end me-5 pointer_" viewBox="0 0 16 16">

                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />

                                                </svg></td>
                                            </tr>

                                        );
                                    })}
                                </tbody>
                            </table>
                        </table>
                    </div>
                </div>
            </div>
            <div>{(() => {
                switch (user_type) {
                    case "edit": return RenderEdit();
                    default: return "";
                }
            })()}</div>
            <Lheader />

        </div>
    )
}
export default Companylocation