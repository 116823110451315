import React, { useCallback, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './LeaveHistory.css';
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { store } from '../../store/user';
import { useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import ReactPaginate from "react-paginate";
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const LeaveHistory = (props) => {
    const { state } = useContext(store);
    console.log('leavehistory', state.user);
    const [leavelist, setLeave_list] = useState([])

    let navigate = useNavigate();
    const Getholidaycalendar = () => {
        let path = `/holidaycalendar`;
        navigate(path);
    };

    const Getapplyleave = () => {
        let path = `/applyleave`;
        navigate(path);
    };

    const { emp_id } = useParams();
    const [events, setEvents] = useState([]);

    const Getleavelist = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/leave_emp/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setLeave_list(res.data);
                let aa = [];

                res.data.forEach(item => {
                    if (item.approved === 'true') {
                        let fromdate = new Date(item.fromdate);
                        let todate = new Date(item.todate);
                
                        // Check if fromdate and todate have the same date
                        if (fromdate.toDateString() === todate.toDateString()) {
                            // Same date, no need to increment todate
                        } else {
                            // Different dates, increment todate by one day
                            todate.setDate(todate.getDate() + 1);
                        }
                
                        let a = {
                            id: item.applyleave_id,
                            title: 'Leave',
                            allDay: true,
                            start: fromdate,
                            end: todate,
                        };
                        aa.push(a);
                    }
                });                setEvents(aa);
            }).catch(() => {

            })
        }
    }

    useEffect(() => {
        Getleavelist();
    }, [state]);
    const localizer = momentLocalizer(moment);

    const [empget, setemp] = useState([]);

    const Getemp = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/leaveemp/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'succ_ess');
                setemp(res.data);
            }).catch(() => {

            })
        }
        // var id = this.props.match.params.emp_id;

    }
    useEffect(() => {
        Getemp();
        Getleave();
    },
        [state]);

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = leavelist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCount = Math.ceil(leavelist.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const [set_empd, setempd] = useState([]);
    const Getleave = () => {
        if (state.user) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/emp_emp/${state?.user[0]?.emp_id}`)
                .then((res) => {
                    console.log(res.data, "emp_emp");
                    setempd(res.data);
                   
                })
                .catch((err) => {
                    console.error(err.message);

                });
        }
    };
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    return (
        <div className="container-fluid">
            <div class="row mt-5 ">

                <div className="col-sm-2  hid_tab ">


                </div>
                <div className="col-sm-7 col-md-12 col-lg-2 col-xl-7 col-xxl-7  mt-4 ms-sm-4">

                    <ul class="nav nav-tabs nav-justified nav-inmview nav-normalview leavenavtab" role="tablist">
                        <div class="slider hid_tab"></div>
                        <li class="nav-item   ">
                            <a class="nav-link fnt-clr ms-5 " id="home-tab" data-toggle="tab" role="tab" aria-controls="home" onClick={() => Getapplyleave()} aria-selected="false"><i class="fas fa-home"></i>{t('applyForLeave')} </a>
                        </li>
                        <li class="nav-item  fw-bold">
                            <a class="nav-link fnt-clr " id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="true"><i class="leave-fs1 fas fa-id-card" ></i >{t('leaveHistory')} </a>
                        </li>
                        <li class="nav-item holiday-non">
                            <a class="nav-link fnt-clr" onClick={() => Getholidaycalendar()} id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false"><i class="fas fa-id-card" ></i>{t('holidayCalendar')} </a>
                        </li>
                        <li class="nav-item hideother holiday-mb">
                            <a class="nav-link fnt-clr" onClick={() => Getholidaycalendar()} id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false"><i class="fas fa-id-card"></i>{t('holidays')} </a>
                        </li>
                    </ul>
                    <div class="settb">

                        <p className="ms-5 mt-3 fw-bold fnt-clr fs-5">{t('leaveHistory')} </p>

                        <div class="ms-5 mt-2 hide_all" style={{ height: '350pt', width: '500pt', alignItems: 'center' }}>

                            <Calendar
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                defaultDate={moment().toDate()}
                                localizer={localizer}
                            />

                        </div>
                        <div class="ms-4 mt-4 hide_for_pc" style={{ height: '160pt', width: '200pt', alignItems: 'center' }}>

<Calendar
    events={events}
    startAccessor="start"
    endAccessor="end"
    defaultDate={moment().toDate()}
    localizer={localizer}
/>

</div>



                    </div>


                </div>


                <div class="col-sm-2 mt-3  ">
                    <p className="mt-5  fw-bold">{t('leaveHistory')}  </p>
                    {/* {empget.map((y, index) => {
                        const daysSinceHireArray = [];
    
                        {set_empd.map((x, index) => {
                            const currentDate = new Date();
                            let hireDate = new Date(Date.parse(x.hiredate));
                            const daysSinceHire = Math.floor((currentDate - hireDate) / (1000 * 60 * 60 * 24));
                            daysSinceHireArray.push(daysSinceHire);
                        }
                        )}
                        console.log(daysSinceHireArray,'dt')
                        if (daysSinceHireArray > y.applicableafter && y.applicableafter !== null) {
                            return (<p className="">Available {y.leave_type} :{y.leave_count}</p>
                                
                            );
                        } else {
                            return null; // Skip rendering this option
                        }
                       
                    })} */}

                    {displayUsers.map((x, index) => {
                        return (
                            <div key={index} class="frist-tab">
                                <div class="row mt-5">
                                    <div class=" fw-bold fs-6">{moment(x.fromdate).format('DD MMM,dddd')} - {moment(x.todate).format('DD MMM,dddd')}</div>

                                </div>
                                <div class="row">

                                    {(() => {
                                        if ((x.session_2 == true) || (x.session_1 == false && x.session_2 == false)) {
                                            return <td className='hidecoltab mobsimplytext '>{t('fullDay')} </td>
                                        } 
                                        if (x.session_1 == true) {
                                            return <td className='hidecoltab mobsimplytext '>{t('halfDay')} </td>
                                        }
                                    })()}
                                </div>
                                <div class="row">
                                    {(() => {
                                        if (x.approved == "true") {
                                            return <td className='hidecoltab mobsimplytext text-success'>{t('leaveApproved')} </td>
                                        }
                                        if (x.approved == "false") {
                                            return <td className='hidecoltab mobsimplytext text-danger'>{t('leaveRejected')} </td>
                                        } else {
                                            return <td className='hidecoltab mobsimplytext text-danger'>{t('pending')} </td>
                                        }

                                    })()}
                                </div>
                            </div>
                        );
                    })}
                    <div className="clndright ">
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />
</div>
                </div>

            </div>
            <div class="leavelh">
                
            </div>
            <Lheader />
        </div>
    )
}
export default LeaveHistory;