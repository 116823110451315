import { React, useState, useEffect, useContext } from "react";
import "./Reports.css";
import Lheader from '../Lheader/Lheader';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { store } from '../../store/user';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from "react-paginate";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Insurancedetails = () => {

    const { state } = useContext(store);
    console.log('base', state.user);

    let navigate = useNavigate();
    const reportsdashboard = () => {
        let path = `/reportsdashboard`;
        navigate(path);
    }

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    function handleKeyDown(event) {
        const maxDigits = 4; // Maximum number of digits allowed
        const inputLength = event.target.value.length + 1; // Length of input after this key is pressed
        if (inputLength > maxDigits && event.key !== 'Backspace' && event.key !== 'Delete') {
            // If the number of digits entered is greater than the maximum allowed            event.preventDefault(); // Prevent the key from being entered
        }
    }

    const [query, SetQuery] = useState("");
    const [demolist, setdemolist] = useState([])
    const [editac, seteditact] = useState("back");
    const [department, Setdept] = useState("");
    const [department_details, setdepartment_details] = useState([]);
    const [employee, setempno] = useState("");

    const showactive = (type) => {

        if (type == 'active') {
            seteditact(type);
        }
        if (type == 'back') {
            //seteditact(type);
            window.location.reload();
        }
    }


    const currentDate = new Date();
    const currentDateTime = currentDate.toLocaleString();


    const generatePDF = (data) => {
        console.log(data, 'pdf');
        const doc = new jsPDF('p', 'mm', 'a4');
        const cmpname = data[0].company_name;
        doc.setFontSize(12);

        doc.text('Social,Health & Unemployment Insurance Report', 15, 16);

        doc.setFontSize(8);

        doc.text(`${startdate} to ${endate}`, 15, 21);
        doc.setFontSize(9);
        doc.text(cmpname, 140, 16);
        doc.setFontSize(11);
        doc.text(data[0].company_address1, 140, 21);
        doc.text(data[0].company_address2, 140, 26);

        // First Table: Social Insurance Contributions
        doc.text('Social Insurance Contributions', 15, 28);
        const tableColumn = [t('no'), t('name'), 'SI #', t('employeeContributions'), t('employerContributions'), t('totalContributions')];
        const tableRows = [];
        // Push data to table rows
        data.forEach((item, index) => {
            const dataRow = [
                index + 1,
                item.full_name,
                item.si_book,
                Math.round(item.socialins).toLocaleString('en').replace(/,/g, '.'),
                Math.round(item.compsocialins).toLocaleString('en').replace(/,/g, '.'),
                Math.round(item.total_socialcontribution).toLocaleString('en').replace(/,/g, '.')
            ];

            tableRows.push(dataRow);
        });

        // Total
        const totalsocialins = data.reduce((acc, item) => acc + Math.round(item.socialins), 0).toLocaleString('en').replace(/,/g, '.');
        const totalCompsocialins = data.reduce((acc, item) => acc + Math.round(item.compsocialins), 0).toLocaleString('en').replace(/,/g, '.');
        const totalSocialContribution = data.reduce((acc, item) => acc + Math.round(item.total_socialcontribution), 0).toLocaleString('en').replace(/,/g, '.');
        // Add total row to table rows
        const totalRow = ['', 'Total', '', totalsocialins, totalCompsocialins, totalSocialContribution];
        tableRows.push(totalRow);

        const addPageContent = function (data) {
            const pageCount = doc.internal.getNumberOfPages();
            const pageNumber = data.pageNumber;
            doc.setFontSize(8);
            doc.text(`Downloaded On: ${currentDateTime}`, data.settings.margin.left, doc.internal.pageSize.height - 10);
            doc.text(`Page ${pageNumber} of ${pageCount}`, data.settings.margin.left, doc.internal.pageSize.height - 5);
        };

        const tableHeight = doc.autoTable.previous.finalY + 10;
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            height: tableHeight,
            didParseCell: (data) => {
                const rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = 'bold';
                }
            },
            addPageContent: addPageContent
        });

        // Second Table: Health Insurance Contributions
        doc.addPage();
        doc.text('Health Insurance Contributions', 15, 28);
        const table_Column = [t('no'), t('name'), 'SI #', t('employeeContributions'), t('employerContributions'), t('totalContributions')];
        const table_Rows = [];
        data.forEach((item, index) => {
            const dataRow = [
                index + 1,
                item.full_name,
                item.si_book,
                Math.round(item.healthins).toLocaleString('en').replace(/,/g, '.'),
                Math.round(item.comphealthins).toLocaleString('en').replace(/,/g, '.'),
                Math.round(item.total_healthcontribution).toLocaleString('en').replace(/,/g, '.')
            ];

            table_Rows.push(dataRow);
        });

        // Total
        const totalhealthins = data.reduce((acc, item) => acc + Math.round(item.healthins), 0).toLocaleString('en').replace(/,/g, '.');
        const totalComphealthins = data.reduce((acc, item) => acc + Math.round(item.comphealthins), 0).toLocaleString('en').replace(/,/g, '.');
        const totalHealthContribution = data.reduce((acc, item) => acc + Math.round(item.total_healthcontribution), 0).toLocaleString('en').replace(/,/g, '.');

        // Add total row to table rows
        const total_Row = ['', 'Total', '', totalhealthins, totalComphealthins, totalHealthContribution];
        table_Rows.push(total_Row);

        doc.autoTable({
            head: [table_Column],
            body: table_Rows,
            startY: 30,
            height: tableHeight,
            didParseCell: (data) => {
                const rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = 'bold';
                }
            },
            addPageContent: addPageContent
        });

        // Third Table: Unemployment Insurance Contributions
        doc.addPage();
        doc.text('Unemployment Insurance Contributions', 15, 28);
        const tables_Column = [t('no'), t('name'), 'SI #', t('employeeContributions'), t('employerContributions'), t('totalContributions')];
        const tables_Rows = [];
        data.forEach((item, index) => {
            const dataRow = [
                index + 1,
                item.full_name,
                item.si_book,
                Math.round(item.unempins).toLocaleString('en').replace(/,/g, '.'),
                Math.round(item.compunempins).toLocaleString('en').replace(/,/g, '.'),
                Math.round(item.total_unempinscontribution).toLocaleString('en').replace(/,/g, '.')
            ];

            tables_Rows.push(dataRow);
        });

        // Total
        const totalunempins = data.reduce((acc, item) => acc + Math.round(item.unempins), 0).toLocaleString('en').replace(/,/g, '.');
        const totalCompunempins = data.reduce((acc, item) => acc + Math.round(item.compunempins), 0).toLocaleString('en').replace(/,/g, '.');
        const totalUnempContribution = data.reduce((acc, item) => acc + Math.round(item.total_unempinscontribution), 0).toLocaleString('en').replace(/,/g, '.');

        // Add total row to table rows
        const totals_Row = ['', 'Total', '', totalunempins, totalCompunempins, totalUnempContribution];
        tables_Rows.push(totals_Row);

        doc.autoTable({
            head: [tables_Column],
            body: tables_Rows,
            startY: 30,
            height: tableHeight,
            didParseCell: (data) => {
                const rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    data.cell.styles.fontStyle = 'bold';
                }
            },
            addPageContent: addPageContent
        });

        // Save the PDF file
        doc.save(`report${startdate} to ${endate}.pdf`);
    }



    const generateCSV = (data) => {
        const headers = ['Social,Health & Unemployment Insurance Report'];
        const dateRange = [`Report Range: ${startdate} to ${endate}`];
        const cmpname = data[0].company_name;
        const companyName = [`Company Name: ${cmpname}`];
        const downl = [`Downloaded ON: ${currentDateTime}`];

        // Define the CSV column headers for each table
        const table1Headers = ['Social Insurance Contributions'];
        const table1FieldHeaders = [t('no'), t('name'), 'SI #', t('employeeContributions'), t('employerContributions'), t('totalContributions')];
        const table2Headers = ['Health Insurance Contributions'];
        const table2FieldHeaders = [t('no'), t('name'), 'SI #', t('employeeContributions'), t('employerContributions'), t('totalContributions')];
        const table3Headers = ['Unemployment Insurance Contributions'];
        const table3FieldHeaders = [t('no'), t('name'), 'SI #', t('employeeContributions'), t('employerContributions'), t('totalContributions')];

        // Create a CSV string for each table
        let csvTable1 = '';
        let csvTable2 = '';
        let csvTable3 = '';

        // Add headers and other information for each table

        [headers, dateRange, companyName, downl, table1Headers, table1FieldHeaders].forEach((row) => {
            csvTable1 += row.join(',') + '\n';
        });

        [table2Headers, table2FieldHeaders].forEach((row) => {
            csvTable2 += row.join(',') + '\n';
        });

        [table3Headers, table3FieldHeaders].forEach((row) => {
            csvTable3 += row.join(',') + '\n';
        });

        // Iterate over data for each table and add rows to CSV string
        let serialNo = 1;

        // Table 1: Social Insurance Contributions
        data.forEach((item) => {
            const dataRow = [
                serialNo,
                item.full_name,
                item.si_book,
                Math.round(item.socialins),
                item.compsocialins,
                Math.round(item.total_socialcontribution)
            ];
            csvTable1 += dataRow.join(',') + '\n';
            serialNo++;
        });

        // Add total row for Table 1
        const totalSocialIns = data.reduce((acc, item) => acc + Math.round(item.socialins), 0);
        const totalCompSocialIns = data.reduce((acc, item) => acc + Math.round(item.compsocialins), 0);
        const totalSocialContribution = data.reduce((acc, item) => acc + Math.round(item.total_socialcontribution), 0);
        const totalRowTable1 = ['', 'Total', '', totalSocialIns, totalCompSocialIns, totalSocialContribution];
        csvTable1 += totalRowTable1.join(',') + '\n';

        // Reset serial number for Table 2
        serialNo = 1;

        // Table 2: Health Insurance Contributions
        data.forEach((item) => {
            const dataRow = [
                serialNo,
                item.full_name,
                item.si_book,
                Math.round(item.healthins),
                item.comphealthins,
                Math.round(item.total_healthcontribution)
            ];
            csvTable2 += dataRow.join(',') + '\n';
            serialNo++;
        });

        // Add total row for Table 2
        const totalHealthIns = data.reduce((acc, item) => acc + Math.round(item.healthins), 0);
        const totalCompHealthIns = data.reduce((acc, item) => acc + Math.round(item.comphealthins), 0);
        const totalHealthContribution = data.reduce((acc, item) => acc + Math.round(item.total_healthcontribution), 0);
        const totalRowTable2 = ['', 'Total', '', totalHealthIns, totalCompHealthIns, totalHealthContribution];
        csvTable2 += totalRowTable2.join(',') + '\n';

        // Reset serial number for Table 3
        serialNo = 1;

        // Table 3: Unemployment Insurance Contributions
        data.forEach((item) => {
            const dataRow = [
                serialNo,
                item.full_name,
                item.si_book,
                Math.round(item.unempins),
                item.compunempins,
                Math.round(item.total_unempinscontribution)
            ];
            csvTable3 += dataRow.join(',') + '\n';
            serialNo++;
        });

        // Add total row for Table 3
        const totalUnempIns = data.reduce((acc, item) => acc + Math.round(item.unempins), 0);
        const totalCompUnempIns = data.reduce((acc, item) => acc + Math.round(item.compunempins), 0);
        const totalUnempContribution = data.reduce((acc, item) => acc + Math.round(item.total_unempinscontribution), 0);
        const totalRowTable3 = ['', 'Total', '', totalUnempIns, totalCompUnempIns, totalUnempContribution];
        csvTable3 += totalRowTable3.join(',') + '\n';

        // Combine CSV strings for all tables
        const csv = `${csvTable1}\n\n${csvTable2}\n\n${csvTable3}`;

        // Initiate file download
        const filename = `report${startdate} to ${endate}.csv`;
        const blob = new Blob([csv], { type: 'text/csv' });
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        }
    };


    // alert  

    const alet = (x) => {
        confirmAlert({

            message: t('select_format_to_download_report'),
            buttons: [
                {
                    label: t('pdf'),
                    className: 'btn-pri btn',
                    onClick: () => generatePDF(x)
                },
                {
                    label: t('excel'),
                    className: 'btn-pri btn',
                    onClick: () => generateCSV(x)
                }
            ]
        })
    }

    const handleDepartmentChange = (event) => {
        const selectedDepartment = event.target.value;
        Setdept(selectedDepartment);
        Getemp(selectedDepartment)
    };

    // get departments
    const Getdepartment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/departments/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setdepartment_details(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }


    const [empdetails, setempdetails] = useState([]);

    const Getemp = (x) => {
        axios.get(`${process.env.REACT_APP_API_URL}/employeetbasicdept/${state?.user[0]?.company_id}/${x}`).then((res) => {
            console.log(res.data, 'success');
            setempdetails(res.data);
        }).catch((error) => {
            console.log(error, 'success');
        });
    }

    useEffect(() => {
        Getdepartment();
    }, [state]);



    const [startdate, setstartdate] = useState("");
    const [endate, setendate] = useState("");
    const [datalenght, setdatalenght] = useState();

    const GetInurancedetails = () => {

        let tax = {
            company_id: state?.user[0]?.company_id,
            startdate: startdate,
            enddate: endate,
            department: department,
            emp_id: employee,

        };
        axios.post(`${process.env.REACT_APP_API_URL}/reportinsurancedetails`, tax).then((res) => {
            console.log(res.data, 'admin');
            setdemolist(res.data);
            setdatalenght(res.data.length);

        }).catch(() => {

        })
    }

    // handle disabled submit
    const isValids = startdate && endate

    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 5;
    const pageCount = Math.ceil(demolist.length / usersPerPage);

    // Calculate subtotals across all users
    const totalsocialins = demolist.reduce((acc, x) => acc + x.socialins, 0);
    const totalCompsocialins = demolist.reduce((acc, x) => acc + x.compsocialins, 0);
    const totalSocialContribution = demolist.reduce((acc, x) => acc + x.total_socialcontribution, 0);
    const totalhealthins = demolist.reduce((acc, x) => acc + x.healthins, 0);
    const totalComphealthins = demolist.reduce((acc, x) => acc + x.comphealthins, 0);
    const totalHealthContribution = demolist.reduce((acc, x) => acc + x.total_healthcontribution, 0);
    const totalunempins = demolist.reduce((acc, x) => acc + x.unempins, 0);
    const totalCompunempins = demolist.reduce((acc, x) => acc + x.compunempins, 0);
    const totalUnempContribution = demolist.reduce((acc, x) => acc + x.total_unempinscontribution, 0);

    // Slice the users for the current page
    const pagesVisited = pageNumber * usersPerPage;
    const displayUsers = demolist.slice(pagesVisited, pagesVisited + usersPerPage);
    const changePage = ({ selected }) => {

        setPageNumber(selected);

    };



    return (
        <div className='bg-light vh-100 container-fluid'>
            {(() => {
                if (editac == "back") {
                    return <div className='row mt-4'>
                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab'>
                        </div>   <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light mt-3col-10 mt-4 tbl'>
                            <p className=" fs-4 ms-sm-5 mt-3 fnt-clr">{t('reports')}</p>
                            <div class="card card ms-sm-5 shadow mb-sm-5 p-2 bg-white rounded">
                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-sm-11'>
                                            <p className="fw-bold fs-5 ms-sm-1 text-secondary">Social,Health & Unemployment Insurance Details</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-2">{t('startDate')}</small>
                                            <input
                                                type="date"
                                                className="form-control inputcolor txtaddr "
                                                onChange={(e) => { console.log(e.target.value, 'end'); setstartdate(e.target.value) }}
                                                id="month-input"
                                                aria-label="Month and Year"
                                                placeholder="Month and Year"
                                            />
                                        </div>

                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-2">{t('endDate')}</small>
                                            <input
                                                type="date"
                                                className="form-control inputcolor txtaddr "
                                                onChange={(e) => { console.log(e.target.value, 'end'); setendate(e.target.value) }}
                                                min={startdate}
                                                id="month-input"
                                                aria-label="Month and Year"
                                                placeholder="Month and Year"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-2">{t('department')}</small>
                                            <select
                                                className="form-select inputcolor text-start  text_box_size"
                                                value={department}
                                                onChange={handleDepartmentChange}><option value="">{t('all')} {t('department')}</option>
                                                {department_details.map((x) =>
                                                (<option value={x.id}>
                                                    {x.departments}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-6">
                                            <small className="text-secondary ms-sm-2">{t('employee')}</small>
                                            <select
                                                className="form-select inputcolor text-start  text_box_size "
                                                value={employee}
                                                onChange={(e) => setempno(e.target.value)}
                                            >
                                                <option value='0'>{t('all')} {t('employee')}</option>
                                                {empdetails.map((x) => (
                                                    <option key={x.emp_id} value={x.emp_id}>{x.full_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-sm-6 d-grid">
                                            <button type="submit" className="btn btn-pri ms-sm-2" onClick={() => { showactive("active"); GetInurancedetails(); }} disabled={!isValids} >{t('generate')}</button>
                                        </div>

                                        <div className="col-sm-6 d-grid crdb">
                                            <button type="submit" className="btn btn-secondary" onClick={reportsdashboard}>{t('cancel')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                if (editac == "active") {
                    return <div className='row mt-4'>
                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab'>
                        </div>
                        <div className='col-sm-10  col-xxl-10 col-sm-9 col-md-12 col-lg-10 col-xl-10 bg-light mt-3col-10 mt-4 tbl'>
                            <div className='row mt-4'>
                                <div className='col-sm-6 col-xxl-6 col-sm-7 col-md-7 col-lg-6 col-xl-6 '>
                                    <p className="fw-bold fs-4 ms-sm-5  fnt-clr">Social,Health & Unemployment</p>
                                    <p className=" fs-6 ms-sm-5 mt-3">Report Dates {moment(startdate).format("DD/MM/YYYY")} to {moment(endate).format("DD/MM/YYYY")}</p>
                                </div>

                                {/* <div className='col-5 col-xxl-5 col-sm-6 col-md-6 col-lg-5 col-xl-5  hid_tab border'>
                                    <p className=" fs-4 ms-5 mt-3">Company Address</p>
                                    <p>
                                        company adddress 1
                                    </p>
                                    <p>
                                        company adddress 1
                                    </p>
                                </div> */}
                            </div>
                            <div class="card card ms-sm-5 shadow mb-sm-5 p-2 bg-white rounded ">
                                <div class="card-body">
                                    <div className='row'>
                                        <div className='col-sm-8'>
                                        </div>
                                        <div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid'>
                                            <button type="submit" className="btn btn-secondary ms-sm-2" onClick={() => showactive("back")}  >{t('back')}</button>
                                        </div>
                                        {(() => {
                                            if (datalenght === 0) {
                                                return (<div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid crdb downtop'>
                                                    <button type="submit" className="btn btn-pri ms-sm-2" disabled>{t('download')}</button>
                                                </div>
                                                );
                                            }
                                            else {
                                                return (<div className='col-sm-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 d-grid crdb downtop'>
                                                    <button type="submit" className="btn btn-pri ms-sm-2 " onClick={() => alet(demolist)}>{t('download')}</button>
                                                </div>);
                                            }
                                        })()}
                                    </div>
                                    {(() => {
                                        if (datalenght == 0) {
                                            return <div className='mt-5'><h5> Date Range Does Not Exist !</h5>

                                            </div>
                                        } else {
                                            return<>
                                            <div className="row">
                                                    <div className="ms-4 mt-2 onlytab hide_tab">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            <div className=" year-buttonss">
                                                
                                            <table class="table table-bordered mt-2  ">

                                                <small className="fw-bold fs-6 ">{t('socialInsuranceContributions')}</small>
                                                <table class="table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" className='text-center text-uppercase headfont '>{t('no')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont '>{t('name')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('siNumber')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '> {t('employeeContributions')}  <small></small></td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('employerContributions')}  <small></small></td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('totalContributions')}<small></small></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>



                                                        {displayUsers.map((x, index) => {
                                                            const actualIndex = pagesVisited + index;
                                                            const serialNumber = actualIndex + 1;
                                                            return (
                                                                <tr>
                                                                    <td className='text-center'>{serialNumber}</td>
                                                                    <td className='text-center'>{x.full_name}</td>
                                                                    <td className='text-center'>{x.si_book}</td>
                                                                    <td className='text-center'>{Math.round(x.socialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.compsocialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.total_socialcontribution).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                </tr>
                                                            );
                                                        })}

                                                        <tr>
                                                            <td className=''></td>
                                                            <td className='text-center fw-bold'>Sub Total</td>
                                                            <td className=''></td>
                                                            <td className='text-center fw-bold'>{Math.round(totalsocialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totalCompsocialins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totalSocialContribution).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                
                                                 <small className="fw-bold fs-6">Health Insurance Contributions</small> 
                                                <table class="table table-striped ">

                                              
                                                
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" className='text-center text-uppercase headfont '>{t('no')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont '>{t('name')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('siNumber')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('employeeContributions')} <small></small></td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('employerContributions')} <small></small></td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('totalContributions')}<small></small></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>



                                                        {displayUsers.map((x, index) => {
                                                            const actualIndex = pagesVisited + index;
                                                            const serialNumber = actualIndex + 1;
                                                            return (
                                                                <tr>
                                                                    <td className='text-center'>{serialNumber}</td>
                                                                    <td className='text-center'>{x.full_name}</td>
                                                                    <td className='text-center'>{x.si_book}</td>
                                                                    <td className='text-center'>{Math.round(x.healthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.comphealthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.total_healthcontribution).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                </tr>
                                                            );
                                                        })}

                                                        <tr>
                                                            <td className=''></td>
                                                            <td className='text-center fw-bold'>Sub Total</td>
                                                            <td className=''></td>
                                                            <td className='text-center fw-bold'>{Math.round(totalhealthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totalComphealthins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totalHealthContribution).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                               
                                                <small className="fw-bold fs-6">{t('unemploymentInsuranceContributions')}</small>
                                                <table class="table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" className='text-center text-uppercase headfont '>{t('no')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont '>{t('name')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('siNumber')}</td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '> {t('employeeContributions')} <small></small></td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('employerContributions')}<small></small></td>
                                                            <td scope="col" className='text-center text-uppercase headfont  '>{t('totalContributions')}<small></small></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>



                                                        {displayUsers.map((x, index) => {
                                                            const actualIndex = pagesVisited + index;
                                                            const serialNumber = actualIndex + 1;
                                                            return (
                                                                <tr>
                                                                    <td className='text-center'>{serialNumber}</td>
                                                                    <td className='text-center'>{x.full_name}</td>
                                                                    <td className='text-center'>{x.si_book}</td>
                                                                    <td className='text-center'>{Math.round(x.unempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.compunempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                    <td className='text-center'>{Math.round(x.total_unempinscontribution).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                                </tr>
                                                            );
                                                        })}

                                                        <tr>
                                                            <td className=''></td>
                                                            <td className='text-center fw-bold'>Sub Total</td>
                                                            <td className=''></td>
                                                            <td className='text-center fw-bold'>{Math.round(totalunempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totalCompunempins).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                            <td className='text-center fw-bold'>{Math.round(totalUnempContribution).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </table>
                                            </div>
                                            </>
                                        }

                                    })()}
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            })
                ()}
            <Lheader />
        </div>

    )
}
export default Insurancedetails