import React, { useCallback, useState, useContext, useEffect } from 'react';

import './Master.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { bind } from 'store-js/src/util';
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Usertype = () => {
    const { state } = useContext(store);
    console.log('usertype', state.user);
    const [usertypedetails, setusertypeDetails] = useState([]);
    const { update, handleSubmit } = useForm();
    const [usertype, setusertype] = useState("");

    const [bind_user, setBindUser] = useState({});
    const [user_type, setUserType] = useState('');
    const [userstype, setuserstype] = useState("");
    const [rread, setRead] = useState("false");
    const [writee, setWrite] = useState("false");
    const [menuu, setMenu] = useState("false");
    const [read, setReadd] = useState("false");
    const [write, setWritee] = useState("false");
    const [menu, setMenuu] = useState("false");

 
    const Getusertype = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/usertype/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setusertypeDetails(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const onInsert = (data, e) => {
        try {
            let user = {
                usertype: usertype,
                read: rread,
                write: writee,
                menu: menuu,
                user_id: state?.user[0]?.user_id,
                company_id: state?.user[0]?.company_id,
            };

            axios.post(`${process.env.REACT_APP_API_URL}/usertype`, user).then((res) => {
                console.log('usertype added', res.data);
                Getusertype();
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        Getusertype();
    }, [state]);

    const Delete = (usertypeid) => {
        try {

            axios.delete(`${process.env.REACT_APP_API_URL}/usertype/` + usertypeid).then((res) => {
                console.log(res.data, 'success');
                //window.location = "/addshift";
                if (res.data == true) {

                }
                Getusertype();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const onUpdate = () => {

        let usertype = {
            usertypeid: bind_user.usertypeid,
            usertype: userstype,
            read: read,
            write: write,
            menu: menu,
        };

        axios.put(`${process.env.REACT_APP_API_URL}/usertype/${bind_user.usertypeid}`, usertype).then((res) => {
            console.log('usertype updated', res.data);
            ShowDetails('');
            Getusertype();
        }).catch(() => {

        })
    }

    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmSave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const clickdelete = (usertypeid) => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmDelete'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => Delete(usertypeid),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToDelete'))
                }
            ]
        })
    }
    const clickupdate = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmUpdate'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onUpdate(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert( t('confirmUpdate'))
                }
            ]
        })
    }

    const RenderEdit = () => {
        console.log('bind', bind_user);
        return <div className='row'>
            <div className='col-5'></div>
            <div className='col-5'>
                <div class="card tabeditcard" >

                    <div class="card-body">
                        <form onSubmit={handleSubmit(clickupdate)} action="#" method="POST">
                            <div className='row'>
                                <div className='col-3'>
                                    <div class="input-group">
                                        <input type="text" class=" form-control inputcolor" autocomplete="userstype" Value={bind_user.usertype} onChange={(e) => setuserstype(e.target.value)} placeholder="User Type" aria-label="Username" />
                                    </div>
                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.read == true) {
                                            return <div className='row'><div className='col-3'><svg xmlns="http://www.w3.org/2000/svg" fill="green" width="16" height="16" class="bi bi-patch-check-fill ms-3" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            </div>
                                                <div className='col-6'>
                                                    <p>Read</p>
                                                </div>
                                            </div>
                                        } else {
                                            return <div className='row'><div className='col-3'><svg xmlns="http://www.w3.org/2000/svg" fill="red" width="16" height="16" class="bi bi-patch-check-fill ms-3" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            </div>
                                                <div className='col-6'>
                                                    <p>Read</p>
                                                </div>
                                            </div>
                                        }
                                    })()}

                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.write == true) {
                                            return <div className='row'><div className='col-3'><svg xmlns="http://www.w3.org/2000/svg" fill="green" width="16" height="16" class="bi bi-patch-check-fill ms-3" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            </div>
                                                <div className='col-6'>
                                                    <p>Write</p>
                                                </div>
                                            </div>
                                        } else {
                                            return <div className='row'><div className='col-3'><svg xmlns="http://www.w3.org/2000/svg" fill="red" width="16" height="16" class="bi bi-patch-check-fill ms-3" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            </div>
                                                <div className='col-6'>
                                                    <p>Write</p>
                                                </div>
                                            </div>
                                        }
                                    })()}

                                </div>
                                <div className='col-3'>
                                    {(() => {
                                        if (bind_user.menu == true) {
                                            return <div className='row'><div className='col-3'><svg xmlns="http://www.w3.org/2000/svg" fill="green" width="16" height="16" class="bi bi-patch-check-fill ms-3" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            </div>
                                                <div className='col-6'>
                                                    <p>Menu</p>
                                                </div>
                                            </div>
                                        } else {
                                            return <div className='row'><div className='col-3'><svg xmlns="http://www.w3.org/2000/svg" fill="red" width="16" height="16" class="bi bi-patch-check-fill ms-3" viewBox="0 0 16 16">
                                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                            </svg>
                                            </div>
                                                <div className='col-6'>
                                                    <p>Menu</p>
                                                </div>
                                            </div>
                                        }
                                    })()}

                                </div>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" Value={"true"} onChange={(e) => setReadd(e.target.value)} id="" />
                                <label class="form-check-label" for="">
                                    Read
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" Value={"true"} onChange={(e) => setWritee(e.target.value)} id="" />
                                <label class="form-check-label" for="">
                                    Write
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" Value={"true"} onChange={(e) => setMenuu(e.target.value)} id="" />
                                <label class="form-check-label" for="">
                                    Menu
                                </label>
                            </div>
                            <div className='row'>
                                <button type='submit' onSubmit={handleSubmit(clickupdate)} class="btn btn-pri createbtn  mt-4 masteradd tabbtn">{t('update')}</button>
                                <button type='submit' onClick={() => ShowDetails('')} class="btn btn-danger createbtn  mt-4 masteradd tabbtn">{t('cancel')}</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>;
    }

    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setuserstype(data.usertype);
        setRead(data.read);
        setWrite(data.write);
        setMenu(data.menu);
        setUserType(type);
    }
    let navigate = useNavigate();
    const bank = () => {
        let path = `/masterbank`;
        navigate(path);
    }
    const job = () => {
        let path = `/addjobtitle`;
        navigate(path);
    }
    const dept = () => {
        let path = `/adddepartment`;
        navigate(path);
    }
    const lang = () => {
        let path = `/`;
        navigate(path);
    }
    const national = () => {
        let path = `/addnationality`;
        navigate(path);
    }
    const tax = () => {
        let path = `/tabletax`;
        navigate(path);
    }
    const salary = () => {
        let path = `/addsalary`;
        navigate(path);
    }
    const holidays = () => {
        let path = `/addholidays`;
        navigate(path);
    }
    const shift = () => {
        let path = `/addshift`;
        navigate(path);
    }
    const currency = () => {
        let path = `/currency`;
        navigate(path);
    }
    const weekday = () => {
        let path = `/weekdays`;
        navigate(path);
    }
    const financial = () => {
        let path = `/financial`;
        navigate(path);
    }
    const Leavetype = () => {
        let path = `/leavetype`;
        navigate(path);
    }
    const overtype = () => {
        let path = `/overtimerate`;
        navigate(path);
    }

    const ins = () => {
        let path = `/insurance`;
        navigate(path);
    }
    const usrtyp = () => {
        let path = `/usertype`;
        navigate(path);
    }
    const ipp = () => {
        let path = `/ip`;
        navigate(path);
    }
    const loc = () => {
        let path = `/Companylocation`;
        navigate(path);
    }
    return (
        <div className='bg-light vh-100 container-fluid'>

            <div class="row">
                <div class="col-2">
                    <div >
                        <div className='setting_side'>
                            <p className="ms-5 fs-4 fw-bold mt-3">Settings</p>

                            <ul className='pointer_'>

                                {/* <div className='' onClick={bank}>Bank </div> */}
                                <div className='mt-2 ' onClick={loc}>Work Location </div>


                                <div className='mt-2' onClick={dept}>Department </div>





                                <div className='mt-2' onClick={job}>Job Title </div>


                                {/* <div className='mt-2' onClick={tax}>Tax </div> */}


                                {/* <div className='mt-2' onClick={national}>Nationality </div> */}


                                <div className='mt-2' onClick={salary}>Salary </div>


                                <div className='mt-2' onClick={holidays}>Holidays </div>
                                <div className='mt-2' onClick={currency}>Currency </div>
                                <div className='mt-2' onClick={weekday}>WeekDays </div>
                                <div className='mt-2' onClick={financial}>Financial year </div>
                                <div className='mt-2' onClick={shift}>Shift </div>
                                <div className='mt-2' onClick={Leavetype}>LeaveType </div>
                                <div className='mt-2' onClick={overtype}>Overtime Rate </div>
                                <div className='mt-2' onClick={ins}>Insurance </div>
                                <div className='mt-2 fw-bold' onClick={usrtyp}>User Type </div>
                                <div className='mt-2' onClick={ipp}>IP White Listing </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='row mt-5'>
                    <div className='col-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5'></div>
                    <div className='col-6 col-xxl-6 col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                        <p className=' mt-5 fs-5 fw-bold '> User Type</p>
                        <div className='row'>

                            <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8'>


                                <div class="input-group">
                                    <input type="text" class=" form-control inputcolor textbox-work  mt-3" Value={usertype} onChange={(e) => setusertype(e.target.value)} placeholder="UserType" aria-label="Username" />
                                </div>


                                <div class="form-check mt-4">
                                    <input class="form-check-input" type="checkbox" Value={"true"} onChange={(e) => setRead(e.target.value)} id="" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Read
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" Value={"true"} onChange={(e) => setWrite(e.target.value)} id="" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Write
                                    </label>
                                </div>
                                <div class="form-check ">
                                    <input class="form-check-input" type="checkbox" Value={"true"} onChange={(e) => setMenu(e.target.value)} id="" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Menu
                                    </label>
                                </div>


                            </div>
                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                <form onSubmit={handleSubmit(save)} action="#" method="POST">
                                    <button type='submit' class="btn btn-pri w-75 ms-5 setbtn mt-3">Add</button>
                                </form>
                            </div>
                        </div>
                        <table class="table table-bordered mt-5">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td scope="col" className='fs-6 fw-bold grey'> User Type</td>
                                        <td scope="col" className='fs-6 fw-bold grey'> Options</td>

                                        <td scope="col" className='fs-6 fw-bold mobsimplytext grey' >Created Date</td>
                                        <td scope="col" ></td>
                                        <td scope="col" className='fs-6 fw-bold grey'>Edit </td>
                                        <td scope="col" className='fs-6 fw-bold grey'>DELETE </td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {usertypedetails.map((x, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{x.usertype}</td>
                                                {(() => {
                                                    if (x.read == true && x.write == true && x.menu == true) {
                                                        return <td className=''>Read,Write,menu</td>
                                                    }
                                                    if (x.read == false && x.write == false && x.menu == false) {
                                                        return <td className=''>-----</td>
                                                    }
                                                    if (x.read == true && x.write == true && x.menu == false) {
                                                        return <td className=''>Read,Write</td>
                                                    }
                                                    if (x.read == true && x.write == false && x.menu == true) {
                                                        return <td className=''>Read,Menu</td>
                                                    }
                                                    if (x.read == false && x.write == true && x.menu == true) {
                                                        return <td className=''>Write,Menu</td>
                                                    }
                                                    if (x.read == false && x.write == false && x.menu == true) {
                                                        return <td className=''>Menu</td>
                                                    }
                                                    if (x.read == false && x.write == true && x.menu == false) {
                                                        return <td className=''>Write</td>
                                                    }
                                                    if (x.read == true && x.write == false && x.menu == false) {
                                                        return <td className=''>Read</td>
                                                    }
                                                })()}

                                                <td className='mobsimplytext'>{moment(x.createdon).format('MMM Do YYYY, h:mm:ss a')}</td>
                                                <td></td>

                                                <td><svg onClick={() => ShowDetails(x, 'edit')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil mt-3" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg></td>
                                                <td><svg onClick={() => clickdelete(x.usertypeid)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3  mt-3" viewBox="0 0 16 16">
                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                </svg></td>
                                            </tr>
                                        );
                                    })}


                                </tbody>
                            </table>
                        </table>
                    </div>
                </div>



                
            </div>
            <div>{(() => {
                switch (user_type) {
                    //case "view": return RenderView();
                    case "edit": return RenderEdit();
                    //case "delete": return RenderDelete();
                    default: return "";
                }
            })()}</div>
            <Lheader />
        </div>
    )
}
export default Usertype