import React, { useCallback, useState, useContext, useEffect } from 'react';
import './EmployeeList.css';
import { useNavigate } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { store } from '../../store/user';
import Chart from "react-apexcharts";
import Empleave from './Empleave';
import ReactPaginate from "react-paginate";
import Worklocation from '../Workshedule/Worklocation';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const MyTeams = () => {
    const { state } = useContext(store);
    console.log('myteams list', state.user);

    const [teamdetails, setUserDetails] = useState([]);
    const [query, SetQuery] = useState({ location: '', schedule: '' });
    const [attendanceStatus, SetAttendanceStatus] = useState("");

    function getDayName(dayIndex) {
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        return daysOfWeek[dayIndex];
    }


    const GetMyTeam = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/myteams/${state?.user[0]?.company_id}`).then((res) => {
                let datalist = res.data;
                console.log('before filter: ', datalist);
                if (state?.user[0]?.usertypename && state?.user[0]?.usertypename == 'Supervisor') {
                    datalist = datalist.filter(x => x?.manager == state?.user[0]?.emp_id)
                }
                console.log('after filter: ', datalist);
                setUserDetails(datalist);
            }).catch(() => {

            })
        }
    }
    useEffect(() => {
        GetMyTeam();
        Getworklist();
        Getloc();
    }, [state]);


    // Page no 
    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 8;
    const pagesVisited = pageNumber * usersPerPage;
    const today = new Date();
    const todayFormatted = moment(today).format('YYYY-MM-DD');
    console.log(todayFormatted, 'dateformat')

    const filteredUsers = teamdetails.filter((x) => {
        const nameMatch = !query.name || x.full_name.toLowerCase().includes(query.name.toLowerCase());
        const locationMatch = !query.location || x.worklocation.toLowerCase().includes(query.location.toLowerCase());
        const scheduleMatch = !query.schedule || x.workschedule_name.toLowerCase().includes(query.schedule.toLowerCase());
        const attendanceStatusMatch = !attendanceStatus || (x.intim !== null || x.contents == 'Present' && attendanceStatus === 'Present') || (x.contents === 'Leave' || x.contents === 'Paid Leave' || x.contents === 'Unpaid Leave') && attendanceStatus === 'Leave' || (x.monday == 1 && today.getDay() === 1) && attendanceStatus === 'Weekoff' || (x.tuesday == 2 && today.getDay() === 2) && attendanceStatus === 'Weekoff' || (x.wednesday == 3 && today.getDay() === 3) && attendanceStatus === 'Weekoff' || (x.thursday == 4 && today.getDay() === 4) && attendanceStatus === 'Weekoff' || (x.friday == 5 && today.getDay() === 5) && attendanceStatus === 'Weekoff' || (x.saturday == 6 && today.getDay() === 6) && attendanceStatus === 'Weekoff' || (x.sunday == 0 && today.getDay() === 0) && attendanceStatus === 'Weekoff' || (x.intim === null && (x.holidaydate == null && moment(x.holidaydate).format("YYYY-MM-DD") != todayFormatted) && (x.contents !== 'Present' && x.contents !== 'Leave' && x.contents !== 'Paid Leave' && x.contents !== 'Unpaid Leave') && (x.monday != 1 || today.getDay() !== 1) && (x.tuesday != 2 || today.getDay() !== 2) && (x.wednesday != 3 || today.getDay() !== 3) && (x.thursday != 4 || today.getDay() !== 4) && (x.friday != 5 || today.getDay() !== 5) && (x.saturday != 6 || today.getDay() !== 6) && (x.sunday != 0 || today.getDay() !== 0)) && attendanceStatus === 'Absent';
        return nameMatch && locationMatch && scheduleMatch && attendanceStatusMatch;
    });

    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);


    const pageCount = Math.ceil(teamdetails.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const [work_list, setWork_list] = useState([])
    const Getworklist = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/workschedule/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setWork_list(res.data);

            }).catch(() => {

            })
    }
    const [complocation, setcomploc] = useState([])
    const Getloc = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/companylocation/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setcomploc(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }


    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    return (
        <>

            <div className='bg-light vh-100 container-fluid'>

                <div className='row mt-4'>
                    <div className='col-2 col-xxl-2 col-sm-3 col-md-2 col-lg-2 col-xl-2  hid_tab '>

                    </div>
                    <div className='  col-xxl-10 col-sm-10 col-md-12 col-lg-12 col-xl-10 bg-light mt-4 tbl'>
                        <input type="text" class=" form-control grey mt-4 ms-sm-5 searchbox" onChange={(e) => SetQuery({ ...query, name: e.target.value })} placeholder={t('searchByEmployeeName')} id="" aria-label="Username" />

                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon" viewBox="0 0 20 20">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                        <div class="card  ms-sm-5 shadow mb-5 p-2 bg-white rounded">

                            <div class="card-body">
                                <div className='row'>
                                    <div className='col-11 '>
                                        <p className=' fs-4 fnt-clr'>{t('myTeam')}</p>
                                    </div>
                                    <div className='col-1 mobfilter pointer_'>
                                        <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-3 " viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12 '>

                                        <div className="row">
                                            <div className="ms-4 mt-2 onlytab hide_tab">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#4EB4E6" class="bi bi-arrow-left-right iconpos" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <table class="table table-bordered year-buttonss   mt-4">
                                            <table class="table table-striped ">
                                                <thead className=''>
                                                    <tr>
                                                        <td scope="col"></td>
                                                        <td scope="col" className=' text-center text-uppercase headfont grey ' >{t('name')}</td>
                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>{t('designation')}</td>
                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>{t('workLocation')}</td>
                                                        <td scope="col" className='  text-center  text-uppercase headfont grey'>{t('workSchedule')} </td>
                                                        <td scope="col" className=' text-center  text-uppercase headfont grey'>{t('todaysStatus')}</td>

                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {displayUsers.filter((x) => {
                                                        const nameMatch = !query.name || x.full_name.toLowerCase().includes(query.name.toLowerCase());
                                                        const locationMatch = !query.location || x.worklocation.toLowerCase().includes(query.location.toLowerCase());
                                                        const scheduleMatch = !query.schedule || x.workschedule_name.toLowerCase().includes(query.schedule.toLowerCase());
                                                        const attendanceStatusMatch = !attendanceStatus || (x.intim !== null || x.contents == 'Present' && attendanceStatus === 'Present') || (x.contents === 'Leave' || x.contents === 'Paid Leave' || x.contents === 'Unpaid Leave') && attendanceStatus === 'Leave' || (x.monday == 1 && today.getDay() === 1) && attendanceStatus === 'Weekoff' || (x.tuesday == 2 && today.getDay() === 2) && attendanceStatus === 'Weekoff' || (x.wednesday == 3 && today.getDay() === 3) && attendanceStatus === 'Weekoff' || (x.thursday == 4 && today.getDay() === 4) && attendanceStatus === 'Weekoff' || (x.friday == 5 && today.getDay() === 5) && attendanceStatus === 'Weekoff' || (x.saturday == 6 && today.getDay() === 6) && attendanceStatus === 'Weekoff' || (x.sunday == 0 && today.getDay() === 0) && attendanceStatus === 'Weekoff' || (x.intim === null && (x.holidaydate == null && moment(x.holidaydate).format("YYYY-MM-DD") != todayFormatted) && (x.contents !== 'Present' && x.contents !== 'Leave' && x.contents !== 'Paid Leave' && x.contents !== 'Unpaid Leave') && (x.monday != 1 || today.getDay() !== 1) && (x.tuesday != 2 || today.getDay() !== 2) && (x.wednesday != 3 || today.getDay() !== 3) && (x.thursday != 4 || today.getDay() !== 4) && (x.friday != 5 || today.getDay() !== 5) && (x.saturday != 6 || today.getDay() !== 6) && (x.sunday != 0 || today.getDay() !== 0)) && attendanceStatus === 'Absent';
                                                        return nameMatch && locationMatch && scheduleMatch && attendanceStatusMatch;
                                                    }).map((x, index) => {
                                                        return (


                                                            <tr key={index} className=''>
                                                                {(() => {
                                                                    if (x.profileimg == null || x.profileimg == '') {
                                                                        return <td scope="row"><div className="ms-3" > <img className='img-fluid tabprofimg1 hide_tab' src={`Images/grycir.svg`} style={{ height: 45, width: 45 }} ></img> </div></td>


                                                                    } else {
                                                                        return <td scope="row"><div className="ms-3"> <img className='img-fluid im_g tabprofimg hide_tab' src={x.profileimg} style={{ height: 45, width: 45 }}></img> </div></td>

                                                                    }
                                                                })()}
                                                                <td className='text-center text-capitalize greyh' style={{ paddingTop: '20px' }}>{x.full_name}</td>
                                                                <td className='text-center text-capitalize greyh' style={{ paddingTop: '20px' }}>{x.job_title}</td>
                                                                <td className='text-center text-capitalize greyh' style={{ paddingTop: '20px' }}>{x.worklocation}</td>
                                                                <td className='text-center text-capitalize  greyh' style={{ paddingTop: '20px' }}>
                                                                    {x.workschedule_name}&nbsp;
                                                                    <small>({(() => {
                                                                        let ranges = [];
                                                                        let start = null;
                                                                        let end = null;
                                                                        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                                                                        for (let i = 0; i < 7; i++) {
                                                                            if (x[dayNames[i].toLowerCase()] === 7 || x[dayNames[i].toLowerCase()] === 8) {
                                                                                if (start === null) {
                                                                                    start = i;
                                                                                }
                                                                                end = i;
                                                                            } else {
                                                                                if (start !== null) {
                                                                                    if (start === end) {
                                                                                        ranges.push(dayNames[start]);
                                                                                    } else {
                                                                                        ranges.push(`${dayNames[start]}-${dayNames[end]}`);
                                                                                    }
                                                                                    start = null;
                                                                                    end = null;
                                                                                }
                                                                            }
                                                                        }
                                                                        if (start !== null) {
                                                                            if (start === end) {
                                                                                ranges.push(dayNames[start]);
                                                                            } else {
                                                                                ranges.push(`${dayNames[start]}-${dayNames[end]}`);
                                                                            }
                                                                        }
                                                                        return <>{ranges.join(', ')}</>;
                                                                    })()})</small>

                                                                </td>



                                                                {(() => {
                                                                    if (x.intim !== null || x.contents == 'Present') {
                                                                        return <td className=' text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Present</td>
                                                                    }
                                                                    if (x.contents == 'Leave' || x.contents == 'Paid Leave') {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Leave</td>
                                                                    } if (x.contents == 'Unpaid Leave') {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Leave</td>
                                                                    }
                                                                    if (x.monday == 1 && today.getDay() === 1) {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Weekoff</td>
                                                                    }
                                                                    if (x.tuesday == 2 && today.getDay() === 2) {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Weekoff</td>
                                                                    }
                                                                    if (x.wednesday == 3 && today.getDay() === 3) {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Weekoff</td>
                                                                    }
                                                                    if (x.thursday == 4 && today.getDay() === 4) {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Weekoff</td>
                                                                    }
                                                                    if (x.friday == 5 && today.getDay() === 5) {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Weekoff</td>
                                                                    }
                                                                    if (x.saturday == 6 && today.getDay() === 6) {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Weekoff</td>
                                                                    }
                                                                    if (x.sunday == 0 && today.getDay() === 0) {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Weekoff</td>
                                                                    }
                                                                    if (x.holidaydate != null && moment(x.holidaydate).format("YYYY-MM-DD") === todayFormatted) {
                                                                        return <td className='text-center text-primary text-capitalize greyh' style={{ paddingTop: '20px' }}>Holiday</td>
                                                                    } else {
                                                                        return <td className='  text-center  text-capitalize greyh' style={{ paddingTop: '20px' }}>Absent</td>
                                                                    }


                                                                })()}



                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </table>
                                        {/* <div className='row'>
                                            <div className='col-2'></div>
                        <div className='col-sm-8'> */}

                                        <ReactPaginate
                                            previousLabel={"Previous"}
                                            nextLabel={"Next"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            containerClassName={"paginationBttns"}
                                            previousLinkClassName={"previousBttn"}
                                            nextLinkClassName={"nextBttn"}
                                            disabledClassName={"paginationDisabled"}
                                            activeClassName={"paginationActive"}
                                        />
                                        {/* </div>
                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable popupright tabfltwd">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h7 class="modal-title fw-bold" id="exampleModalLabel">{t('filterBy')}</h7>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">


                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            <p class="fs-6 ms-4 fw-bold">{t('attendanceStatus')}</p>
                                            <select class="form-select inputcolor popupdrop ms-4" onChange={(e) => SetAttendanceStatus(e.target.value)} aria-label="select attendance status">
                                                <option value="">{t('all')}</option>
                                                <option value="Present">Present</option>
                                                <option value="Absent">Absent</option>
                                                <option value="Weekoff">Weekoff</option>
                                                <option value="Leave">Leave</option>
                                            </select>
                                        </div>

                                        <div className='col-6'>
                                            <p class=" fs-6 ms-4 fw-bold">{t('workLocation')}</p>
                                            <select class="form-select inputcolor popupdrop ms-4" onChange={(e) => SetQuery({ ...query, location: e.target.value })} aria-label=" select Day">
                                                <option value="">{t('select')}</option>

                                                {complocation.map((x, index) => {
                                                    return (<option value={x.worklocation}>{x.worklocation}</option>);
                                                })}

                                            </select>


                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-6'>
                                            <p class=" fs-6 ms-4 fw-bold">{t('workSchedule')}</p>
                                            <select class="form-select inputcolor popupdrop ms-4" onChange={(e) => SetQuery({ ...query, schedule: e.target.value })} aria-label=" select Day">
                                                <option value="">{t('select')}</option>
                                                {work_list.map((x, index) => {
                                                    return (<option value={x.workschedule_name}>{x.workschedule_name}</option>);
                                                })}

                                            </select>


                                        </div>

                                    </div>

                                </div>
                                <div class="s">


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Lheader />
            </div>
        </>
    );
}
export default MyTeams;