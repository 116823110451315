import React, { useCallback, useState, useRef, useContext, useEffect } from 'react';
import './TimeSheet.css';
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import TimeSheetheader from '../Employee_work/Timeheader';
import Lheader from '../Lheader/Lheader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useForm } from 'react-hook-form';
import { store } from '../../store/user';
import { tuple } from 'antd/es/_util/type';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
const TimeSheet = () => {
    const { state } = useContext(store);
    console.log('profile_cmp', state.user);

    const { update, handleSubmit } = useForm();
    const [buttonText, setButtonText] = useState('Pause');

    const [islogged, setLogged] = useState(true);
    const [User, setUser] = useState(false);

    const logOut = () => {

        if (islogged == true) {
            localStorage.removeItem('user_details');
            setUser(false);
            goLogin();
        }
        else {
            localStorage.setItem('user_details');
        }
    }
    let navigate = useNavigate();
    const goLogin = () => {
        let path = `/`;
        navigate(path);
    }

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const Ref = useRef(null);

    const currTime = new Date().toLocaleTimeString();
    // const timestamp = Date.now(); // This would be the timestamp you want to format

    // console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(timestamp));
    //  console.log("time",currTime);

    const [intime, setintime] = useState("");
    const [outtime, setouttime] = useState("");
    const [wrkhr, setwrkhr] = useState("");
    const gettime = () => {
        setintime(currTime);
        //   localStorage.setItem('intime', renderedStreamDuration);

        // console.log(intime,"intime");
        save();
    }
    const gettime3 = () => {

        localStorage.setItem('intime', renderedStreamDuration);
        const data = localStorage.getItem('intime');
        console.log(data, 'local');
    }
    const data = localStorage.getItem('intime');

    const gettime2 = () => {

        setouttime(currTime);
        // setwrkhr(renderedStreamDuration);
        save2();
    }
    const clearTimer = (e) => {


        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }




    const onClickReset = () => {
        startTimer(clearTimer());

    }
    function handleClick() {
        setButtonText('Resume');
        gettime();
        // onClickReset();
    }

    // new timer
    const [renderedStreamDuration, setRenderedStreamDuration] = useState('00:00:00'),
        streamDuration = useRef(0),
        previousTime = useRef(0),
        requestAnimationFrameId = useRef(null),
        [isStartTimer, setIsStartTimer] = useState(false),
        [isStopTimer, setIsStopTimer] = useState(false),
        [isPauseTimer, setIsPauseTimer] = useState(false),
        [isResumeTimer, setIsResumeTimer] = useState(false),
        isStartBtnDisabled = isPauseTimer || isResumeTimer || isStartTimer,
        isStopBtnDisabled = !(isPauseTimer || isResumeTimer || isStartTimer),
        isPauseBtnDisabled = !(isStartTimer || (!isStartTimer && isResumeTimer)),
        isResumeBtnDisabled = !isPauseTimer;

    const updateTimer = useCallback(() => {
        let now = performance.now();
        let dt = now - previousTime.current;

        if (dt >= 1000) {
            streamDuration.current = streamDuration.current + Math.round(dt / 1000);
            const formattedStreamDuration = new Date(streamDuration.current * 1000)
                .toISOString()
                .substr(11, 8);
            setRenderedStreamDuration(formattedStreamDuration);
            previousTime.current = now;
        }
        requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
    }, []);

    const startTimer = useCallback(() => {
        previousTime.current = performance.now();
        requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
    }, [updateTimer]);

    useEffect(() => {
        gettimesheet();
    }, [state]);

    useEffect(() => {
        get_timesheet();
    }, [state]);
    useEffect(() => {
        gettimesheet_hr();
    }, [state]);
    useEffect(() => {


        if (isStartTimer && !isStopTimer) {
            startTimer();
        }
        if (isStopTimer && !isStartTimer) {
            streamDuration.current = 0;
            cancelAnimationFrame(requestAnimationFrameId.current);
            setRenderedStreamDuration('00:00:00');
        }
    }, [isStartTimer, isStopTimer, startTimer]);

    const startHandler = () => {
        setIsStartTimer(true);
        setIsStopTimer(false);
        gettime();

    };

    const stopHandler = () => {
        setIsStopTimer(true);
        setIsStartTimer(false);
        setIsPauseTimer(false);
        setIsResumeTimer(false);
        gettime2();

    };

    const pauseHandler = () => {
        setIsPauseTimer(true);
        setIsStartTimer(false);
        setIsResumeTimer(false);
        cancelAnimationFrame(requestAnimationFrameId.current);
        gettime2();
    };

    const resumeHandler = () => {
        setIsResumeTimer(true);
        setIsPauseTimer(false);
        gettime();
    };


    const [timecomment, setTimecomment] = useState("");

    //insert intime
    const onInsert = (data, e) => {
        try {
            let pay = {
                emp_id: state?.user[0]?.emp_id,
                intime: intime,
                // outtime:outtime,
                time_comment: wrkhr,
                user_id: state?.user[0]?.user_id,
                company_id: state?.user[0]?.company_id,
                manager: state?.user[0]?.manager
                // wrk:wrkhr
            };

            axios.post(`${process.env.REACT_APP_API_URL}/emptimesheet`, pay).then((res) => {
                console.log('time sheeet updated added', res.data);
                gettimesheet();
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const getclock = () => {
        let path = `/yourwork`;
        navigate(path);
    }
    // ot insert 
    const [ot_hr, setothr] = useState("");

    const [holiday_list, setHoliday_list] = useState([]);
    const [overtime_list, setOvertime_list] = useState([]);

    const Getholidaylist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/holidays/${state?.user[0]?.company_id}`).then((res) => {
                setHoliday_list(res.data);
                console.log(res.data, 'holi');
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const GetOvertime = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/overtimerate/${state?.user[0]?.company_id}`).then((res) => {
                setOvertime_list(res.data);
                console.log(res.data, 'ort');
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    useEffect(() => {
        Getholidaylist();
        GetOvertime();
    }, [state]);

    const checkIfHoliday = (inputDate) => {
        let temp = holiday_list.filter(item => moment(item?.holidaydate).format('DD-MM-YYYY') === moment(inputDate).format('DD-MM-YYYY'))

        console.log(temp, 'templeng');
        console.log(inputDate, 'temp_leng');
        return temp.length > 0 ? true : false
    }

    // const checkIfWeekend = (inputDate) => {
    //     let temp = moment(inputDate).day();
    //     return (temp == 0 || temp == 6) ? true : false;
    // }





    // compare holiday date

    const hdate = new Date()
    const h_date = (moment(hdate).format('YYYY-MM-DD'));
    const checkIfValidEmail = (inputMail) => {
        let isValid = true;
        console.log(h_date, 'hd');
        holiday_list.forEach(item => {
            if (moment(item?.holidaydate).format('DD-MM-YYYY') === moment(inputMail).format('DD-MM-YYYY')) {
                console.log(moment(item?.holidaydate).format('DD-MM-YYYY'), 'dtl');
                isValid = false;
            }
        });
        console.log(moment(inputMail).format('DD-MM-YYYY'), 'leng');
        console.log(holiday_list.holidaydate, 'leng');
        console.log(isValid, 'leng');
        return isValid;
    }

    // insert Overtime
    const onInsertot = (data, e) => {
        if (h_date != "") {
            if (checkIfValidEmail(h_date)) {
                // Perform the necessary actions for a valid email
                checkIfWeekend(h_date)
                    .then((isWeekend) => {
                        let overtimehr = bind_user.totaldayhr;
                        let overtimetype;
                        if (isWeekend) {

                            overtimetype = overtime_list.filter(item => item?.overtime == 'Rest Day/Weekend Overtime')[0]?.rate_id;
                        } else {
                            overtimehr = bind_user.totaldayhr - 8;

                            overtimetype = getOvertimeType(h_date);
                        }


                        let pay = {
                            emp_id: state?.user[0]?.emp_id,
                            overtimehr: overtimehr,
                            status: "Pending",
                            user_id: state?.user[0]?.user_id,
                            company_id: state?.user[0]?.company_id,
                            manager: state?.user[0]?.manager,
                            overtimetype: overtimetype,
                        };

                        axios.post(`${process.env.REACT_APP_API_URL}/over_time_req`, pay)
                            .then((res) => {
                                console.log('Sent For Approval', res.data);
                                const overtimehr = res.data.overtimehr;
                                const overtimetype = res.data.overtimetype;
                                const date = res.data.date;
                                onupot();
                                console.log('overtimehr:', overtimehr, 'overtimetype:', overtimetype);
                                //onupdateotnight({ overtimehr, overtimetype, date });
                                if (isWeekend) {

                                    onupdateattendance(res.data.date);
                                } else {

                                }

                            })
                            .catch((err) => {
                                console.log(err.message);
                            });
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            } else {
                try {
                    let pay = {
                        emp_id: state?.user[0]?.emp_id,
                        overtimehr: bind_user.totaldayhr,
                        // date:date,
                        status: "Pending",
                        user_id: state?.user[0]?.user_id,
                        company_id: state?.user[0]?.company_id,
                        manager: state?.user[0]?.manager,
                        overtimetype: getOvertimeType(h_date),
                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/over_time_req`, pay).then((res) => {
                        console.log('Sent For Approval holiday', res.data);
                        const overtimehr = res.data.overtimehr;
                        const overtimetype = res.data.overtimetype;
                        const date = res.data.date;
                        onupdateattendance(res.data.date);
                        onupot();
                        console.log('overtimehr:', overtimehr, 'overtimetype:', overtimetype);
                        //onupdateotnight({ overtimehr, overtimetype, date });
                    })
                } catch (err) {
                    console.log(err.message);
                }
            }
        }
    }

    const getOvertimeType = (date) => {
        if (date) {
            console.log(date, 'ind');
            console.log('inside 0');
            if (checkIfHoliday(date)) {
                console.log('inside 1');
                const holidayOvertime = overtime_list.filter(item => item?.overtime == 'Holiday Overtime Rate')[0];
                console.log(holidayOvertime, 'holidayOvertime');
                return holidayOvertime?.rate_id;
            } else {
                console.log('inside 3');
                const regularOvertime = overtime_list.filter(item => item?.overtime == 'Regular Overtime')[0];
                console.log(regularOvertime, 'regularOvertime');
                return regularOvertime?.rate_id;
            }
        } else {
            console.log('outside');
            const defaultOvertime = overtime_list.filter(item => item?.overtime == 'Regular Overtime')[0];
            console.log(defaultOvertime, 'defaultOvertime');
            return defaultOvertime?.rate_id;
        }
    };


    const checkIfWeekend = (date) => {
        return new Promise((resolve, reject) => {
            let workSchedule = {};
            let temp = new Date(date).getDay();

            if (state.user) {
                axios
                    .get(`${process.env.REACT_APP_API_URL}/get_work_schedule/${state?.user[0]?.emp_id}`)
                    .then((res) => {
                        if (res.data.length > 0) {
                            const schedule = res.data[0];
                            workSchedule[1] = parseInt(schedule.monday);
                            workSchedule[2] = parseInt(schedule.tuesday);
                            workSchedule[3] = parseInt(schedule.wednesday);
                            workSchedule[4] = parseInt(schedule.thursday);
                            workSchedule[5] = parseInt(schedule.friday);
                            workSchedule[6] = parseInt(schedule.saturday);
                            workSchedule[0] = parseInt(schedule.sunday);

                            console.log(workSchedule, 'work');
                            console.log(temp, "works");

                            if (workSchedule.hasOwnProperty(temp) && [0, 1, 2, 3, 4, 5, 6].includes(workSchedule[temp])) {
                                console.log('trueeee');
                                resolve(true);
                            } else {
                                console.log('feeee');
                                resolve(false);
                            }
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    };



    // update attendance
    const onupdateattendance = (dat) => {
        let totalhr;
        let applyleave = {
            date: dat,
            totalhr: 0,
        };
        axios.put(`${process.env.REACT_APP_API_URL}/time_sheet_up/${state?.user[0]?.emp_id}`, applyleave).then((res) => {
            console.log('Timesheet updated', res.data);
            //Update();        
            //alert("timesheet  added succesfully");              
        }).catch(() => {

        })
    }
    const onupot = (data, e) => {
        try {
            let pay = {
                otstatus: "Pending",
            };

            axios.put(`${process.env.REACT_APP_API_URL}/emptimesheet_ot`, pay).then((res) => {
                console.log('time sheeet updated added', res.data);
                send_Email();
                gettimesheet_hr();
                window.location = '/yourwork';
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setUserType(type);
    }
    // clock out update 
    const onupclockout = (data, e) => {
        try {
            let pay = {

                out: outtime,
                time_comment: wrkhr,
            };

            axios.put(`${process.env.REACT_APP_API_URL}/emptimesheet/${state?.user[0]?.emp_id}`, pay).then((res) => {
                console.log('outupdate', res.data);
                // save();
                gettimesheet();
                get_timesheet();
                getnighttimesheet();
                gettimesheet_hr();
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('clockIn') ,
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const save2 = () => {
        confirmAlert({
            //title: 'Cancle',
            message: t('clockOut'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onupclockout(),
                    style:{
                        backgroundColor:'#F36E35'
                       
                   }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }

    const current = new Date();

    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
    const [time_details, setUserDetails] = useState([]);
    const [time_1details, set_UserDetails] = useState([]);
    const [bind_user, setBindUser] = useState({});

    const [user_type, setUserType] = useState('');
    const [datalenght, setdatalenght] = useState();
    const gettimesheet = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/emptimesheet/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setUserDetails(res.data);
                setdatalenght(res.data.length);
            }).catch((error) => {
                console.log(error, 'success');
            });

    }
    const get_timesheet = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/emp_timesheet/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'clock btn');
                set_UserDetails(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });

    }
    const [nightdetails, setnightDetails] = useState([]);
    const getnighttimesheet = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/emp_timesheet/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'timeanddate');
                setnightDetails(res.data);
                Nighttimehrs(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });

    }
    const [time_hr, set_hr] = useState([]);
    const gettimesheet_hr = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/emptimesheet_hr/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                set_hr(res.data);

            }).catch((error) => {
                console.log(error, 'success');
            });

    }
    const RenderEdit = () => {
        console.log('bind', bind_user);

        confirmAlert({
            // title: 'Leave Details',

            message: <div className='row'>


                <div class="modal-content">

                    <p className='text-black'> Proceed for overtime approval on {date} total overtime hours :{(bind_user.totaldayhr.toFixed(2))}</p>



                </div>


            </div>,
            buttons: [
                {
                    className: 'btn btn-pri',
                    label: 'Submit',
                    onClick: () => onInsertot()
                },
                {
                    label: t('close') 
                },


            ]
        })

    }



    // clockout with ip
    // State to store IP
    const [ip, setIp] = React.useState("");
    const [nip, nsetIp] = React.useState([]);

    console.log(ip, 'ipdata time');
    // Fetch IP when component mounts
    useEffect(() => {
        fetch("https://api.ipify.org?format=json")
            .then(res => res.json())
            .then(res => setIp(res.ip));
    }, []);
    useEffect(() => {
        // Make axios request to fetch data
        //     axios.get(`${process.env.REACT_APP_API_URL}/getip/${state?.user[0]?.company_id}`)
        //       .then(response => nsetIp(response.data))
        //       .catch(error => console.log(error));
        // 
        GetIp();
    }, [state]);



    const GetIp = (x) => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/company_location/${state?.user[0]?.worklocation}`).then((res) => {
                console.log(res.data, 'ip data');
                nsetIp(res.data);
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].ip != ip) {
                        setShowText(true);

                    } else {
                        setShowText(false);
                    }
                }

            }).catch((error) => {
                console.log(error, 'success');
            });
    }
    // useEffect(() => {
    //     GetIp();
    // },
    //     []);
    const [showText, setShowText] = useState(true);
    console.log(showText, 'show');
    const [employee, setempdetails] = useState([]);

    const Getleave = (emp_id) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/emp_emp/${state?.user[0]?.emp_id}`).then((res) => {
                console.log(res.data, 'success');
                setempname(res.data[0].full_name);
                setempdetails(res.data);
            }).catch(() => {

            })
        }


    }
    useEffect(() => {
        Getleave();
    },
        [state]);



        const Nighttimehrs = (x) => {
            const clockInTime = new Date(x[0].intim);
            const clockOutTime = new Date(x[0].out);
            const startHour = 22; // 10pm
            const endHour = 6; // 6am 
            const clockInHour = clockInTime.getHours();
            const clockOutHour = clockOutTime.getHours();
            let hoursInRange = 0;
            let minutesInRange = 0;
        
            if (clockOutHour >= startHour || clockOutHour < endHour ||clockOutHour < clockInHour) {
                const diff = clockOutTime.getTime() - clockInTime.getTime();
                hoursInRange = Math.floor(diff / (1000 * 60 * 60));
                minutesInRange = Math.floor((diff / (1000 * 60)) % 60);
            
                if (clockOutHour < clockInHour) {
                  hoursInRange = Math.abs(hoursInRange); // convert negative value to positive
                  minutesInRange = 60 - minutesInRange; // adjust minutes
                }
            
                if (clockOutHour < startHour && clockOutHour >= endHour) {
                  // If clock out happens before startHour but on the next day, reset the duration to 0
                  hoursInRange = 0;
                  minutesInRange = 0;
                }
            
                // Limit the duration to the range between startHour and endHour
                if (clockOutHour >= startHour) {
                  hoursInRange = Math.min(hoursInRange, clockOutHour - startHour);
                  minutesInRange = Math.min(minutesInRange, 60);
                }
            
                if (clockOutHour < endHour) {
                  hoursInRange = Math.min(hoursInRange, 24 - startHour);
                  minutesInRange = Math.min(minutesInRange, 60);
                }
                if (clockOutHour < clockInHour){
                    let hoursWorkedFirstDay = 0
                    let hoursWorkedSecondDay = 0
                   
                    if(clockInHour > startHour){
                        hoursWorkedFirstDay = 24 - clockInHour;
                   }else{
                    hoursWorkedFirstDay = 24 - startHour;
                   
                   }
    if(clockOutHour >endHour){
         hoursWorkedSecondDay = endHour;
    
    }else{
         hoursWorkedSecondDay = clockOutHour;
    }
                    hoursInRange = hoursWorkedFirstDay+hoursWorkedSecondDay
                    console.log(hoursInRange,'nextday')
                }
              }
             
            
            console.log(`${hoursInRange} hours and ${minutesInRange} minutes`);
            try {
              let pay = {
                emp_id: state?.user[0]?.emp_id,
                totalnighthr: hoursInRange + (minutesInRange / 100),
                nightmin: minutesInRange,
                date: moment(x[0].intim).format('YYYY MM DD'),
                clockin: x[0].intim,
                clockout: x[0].out,
                nightstatus: 'Approved',
                ottype: 1,
              };
          
              axios.post(`${process.env.REACT_APP_API_URL}/nightshift`, pay).then((res) => {
                console.log('nighthrs added', res.data);
                window.location = '/timesheet';
              });
            } catch (err) {
              console.log(err.message);
            }
          };

    //  const Nighttimehrs = (x) => {
    //   const clockInTime = new Date(x[0].intim);
    //   console.log(clockInTime, "intime")
    //   const clockOutTime = new Date(x[0].out);
    //   console.log(clockOutTime, "outtime")
    //   const startHour = 22; // 10pm
    //   const endHour = 6; // 6am 
    //   const clockInHour = clockInTime.getHours();
    //   const clockOutHour = clockOutTime.getHours();
    //   let hoursInRange = 0;
    //   let minutesInRange = 0;
    //   if (clockOutHour >= startHour || clockOutHour < endHour) {
    //     // const diff = clockOutTime.getTime() - clockInTime.getTime();
    //     // minutesInRange = Math.floor((diff / 1000 / 60) % 60);
    //     // hoursInRange = Math.floor(diff / 1000 / 60 / 60);
    //     const diff = clockOutTime.getTime() - clockInTime.getTime();
    //     hoursInRange = Math.floor(diff / 1000 / 60 / 60);
    //     minutesInRange = (Math.floor(diff / 1000 / 60) - (hoursInRange * 60))/100;
    //     if (clockOutHour < clockInHour) {
    //       const hoursBeforeMidnight = 24 - clockInHour;
    //       const hoursAfterMidnight = clockOutHour;
    //       hoursInRange = hoursBeforeMidnight + hoursAfterMidnight;
    //     } else {
    //       hoursInRange = clockOutHour - startHour;
    //     }
    //   }
    //   console.log(`${hoursInRange} hours and ${minutesInRange} minutes`);
    //   try {
    //     let pay = {
    //         emp_id: state?.user[0]?.emp_id,
    //         totalnighthr:hoursInRange+minutesInRange,
    //         nightmin:minutesInRange,
    //         date:moment(x[0].intim).format('YYYY MM DD'),
    //         clockin:x[0].intim,
    //         clockout:x[0].out,
    //         nightstatus:'Approved',
    //         ottype:1,
    //     };

    //     axios.post(`${process.env.REACT_APP_API_URL}/nightshift`, pay).then((res) => {
    //         console.log('nighthrs added', res.data);
    //     })
    // } catch (err) {
    //     console.log(err.message);
    // }
    // }; 



    // const onupdateotnight = (x) => {
    //     console.log('updateot in nightshift')
    //     try {
    //         let pay = {
    //             emp_id: state?.user[0]?.emp_id,
    //             othrs: x.overtimehr,
    //             ottype: x.overtimetype,
    //             date: moment(x.date).format('YYYY MM DD'),
    //             nightstatus: 'Pending',
    //         };

    //         axios.put(`${process.env.REACT_APP_API_URL}/nightshift/${state?.user[0]?.emp_id}`, pay).then((res) => {
    //             console.log('outupdate', res.data);
    //         })
    //     } catch (err) {
    //         console.log(err.message);
    //     }
    // }


    // send email
    const [set_empname, setempname] = useState([]);

    const [email, setemail] = useState("");
    const send_Email = () => {

        const emailData = {
            user_email: email,
            user_name: set_empname,
            message: date,
        };
        const secretKey = 'mysecretkey';
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(emailData), secretKey).toString();

        fetch(`${process.env.REACT_APP_API_URL}/sendemailovertime`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: encryptedData }),
            // body: JSON.stringify(emailData),
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.error(error));
    }

    const [emp_data, setuserdata] = useState([]);

    const Getempdata = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/managerdata/${state?.user[0]?.manager}`).then((res) => {
                console.log(res.data, 'managerdata');

                setuserdata(res.data);
                setemail(res.data[0].email);
            }).catch((error) => {
                console.log(error, 'managerdata');
            });
        }
    }
    // console.log(email,'email');
    useEffect(() => {
        Getempdata();
    },
        [state]);


    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-2  col-md-2 col-lg-2 col-xl-2 col-xxl-2">

                </div>
                <div className="col-10 timesheet-tab    ">

                    <ul class=" headeralignment headerposition your-workmv timesheetnone">


                    </ul>
                    <div className='row'>

                        <div className='col-3 mt-4  '>

                        </div>

                    </div>

                    <div className='row silghtleft'>
                        <div className='col-sm-4   '>

                            {(() => {
                                if (datalenght == 0) {
                                    return <div><h2>{t('noRecordsFound')}  !</h2>

                                    </div>
                                } else {
                                    return <>
                                        {employee.map((x, index) => {
                                            return (<p className='fw-bold Depamob'>{t('department')} :  {x.departments}</p>
                                            );

                                        })}
                                    </>
                                }

                            })()}



                        </div>

                        <div className='col-2 hide_tab '>


                        </div>

                        <div className='col-6  col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
                            <div className='row'>


                                {time_hr.map((y, index) => {

                                    return (<div>
                                        {time_1details.map((x, index) => {
                                            return (
                                                <div className="row">
                                                    <div className='col-sm-1 ms-sm-5'></div>
                                                    <div className='col-sm-3 Clockt1'>
                                                        {employee.map((w, index) => {
                                                            return (
                                                                <div>
                                                                    {(() => {
                                                                        if (w.wfo == true) {
                                                                            return <div>  {nip.map((c, index) => {
                                                                                return (
                                                                                    <div>
                                                                                        {(() => {


                                                                                            if (c.ip != ip) {

                                                                                                return <div> {showText && <p className='text-center time_sheet_txt text-danger'>Wrong Location</p>} </div>


                                                                                            } else {

                                                                                                return <div>
                                                                                                    {(() => {
                                                                                                        if (x.out == null) {
                                                                                                            return <button
                                                                                                                onClick={startHandler}

                                                                                                                className={`timer-controller-btn btn-pri btn pausebtn `}
                                                                                                                disabled >
                                                                                                               {t('clockIn')} 
                                                                                                            </button>
                                                                                                        }

                                                                                                        else {
                                                                                                            return <button
                                                                                                                onClick={startHandler}
                                                                                                                disabled={isStartBtnDisabled}
                                                                                                                className={`timer-controller-btn btn-pri btn pausebtn ${isStartBtnDisabled ? 'disabled' : ''}`}
                                                                                                            >
                                                                                                               {t('clockIn')} 
                                                                                                            </button>


                                                                                                        }
                                                                                                    })()}
                                                                                                </div>
                                                                                            }




                                                                                        })()}
                                                                                    </div>);
                                                                            })}

                                                                            </div>
                                                                        }
                                                                        else {

                                                                            return <div>
                                                                                {(() => {
                                                                                    if (x.out == null) {
                                                                                        return <button
                                                                                            onClick={startHandler}

                                                                                            className={`timer-controller-btn btn-pri btn pausebtn `}
                                                                                            disabled >
                                                                                           {t('clockIn')} 
                                                                                        </button>
                                                                                    }

                                                                                    else {
                                                                                        return <button
                                                                                            onClick={startHandler}
                                                                                            disabled={isStartBtnDisabled}
                                                                                            className={`timer-controller-btn btn-pri btn pausebtn ${isStartBtnDisabled ? 'disabled' : ''}`}
                                                                                        >
                                                                                           {t('clockIn')} 
                                                                                        </button>


                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        }
                                                                    })()}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    <div className='col-sm-3 Clockt'>
                                                        {employee.map((w, index) => {
                                                            return (
                                                                <div>
                                                                    {(() => {
                                                                        if (w.wfo == true) {
                                                                            return <div>  {nip.map((c, index) => {
                                                                                return (
                                                                                    <div>
                                                                                        {(() => {


                                                                                            if (c.ip != ip) {

                                                                                                return <div> {showText && <p className='text-center time_sheet_txt text-danger'>Wrong Location</p>} </div>


                                                                                            } else {

                                                                                                return <div>
                                                                                                    {(() => {
                                                                                                        if (x.out == null) {
                                                                                                            return <button
                                                                                                                className={`timer-controller-btn btn-danger btn pausebtn  ms-sm-1`}

                                                                                                                onClick={stopHandler}
                                                                                                            >
                                                                                                               {t('clockOut')} 
                                                                                                            </button>
                                                                                                        }

                                                                                                        else {
                                                                                                            return <button
                                                                                                                className={`timer-controller-btn btn-danger btn pausebtn ms-1  ${isStopBtnDisabled ? 'disabled' : ''}`}
                                                                                                                disabled={isStopBtnDisabled}
                                                                                                                onClick={stopHandler}
                                                                                                            >
                                                                                                               {t('clockOut')} 
                                                                                                            </button>


                                                                                                        }
                                                                                                    })()}
                                                                                                </div>
                                                                                            }




                                                                                        })()}
                                                                                    </div>);
                                                                            })}

                                                                            </div>
                                                                        }
                                                                        else {

                                                                            return <div>
                                                                                {(() => {
                                                                                    if (x.out == null) {
                                                                                        return <button
                                                                                            className={`timer-controller-btn btn-danger btn pausebtn  ms-sm-1  `}

                                                                                            onClick={stopHandler}
                                                                                        >
                                                                                           {t('clockOut')} 
                                                                                        </button>
                                                                                    }

                                                                                    else {
                                                                                        return <button
                                                                                            className={`timer-controller-btn btn-danger btn pausebtn ms-sm-1  ${isStopBtnDisabled ? 'disabled' : ''}`}
                                                                                            disabled={isStopBtnDisabled}
                                                                                            onClick={stopHandler}
                                                                                        >
                                                                                           {t('clockOut')} 
                                                                                        </button>


                                                                                    }
                                                                                })()}
                                                                            </div>
                                                                        }
                                                                    })()}
                                                                </div>
                                                            );
                                                        })}

                                                    </div>
                                                    <div className='col-sm-3 Clockt'>
                                                        {(() => {
                                                            if (x.otstatus == null) {
                                                                return <>
                                                                    {(() => {
                                                                        if (y.totaldayhr > 8) {
                                                                            return <button className='btn btn-pri pausebtn ms-sm-2' onClick={() => ShowDetails(y, 'edit')} >  {t('RequestOvertime')}   </button>

                                                                        }

                                                                        else {
                                                                            return <button className='btn btn-pri pausebtn ms-sm-2' disabled>  {t('RequestOvertime')}   </button>



                                                                        }
                                                                    })()}
                                                                </>
                                                            }

                                                            else {
                                                                return <button className='btn btn-pri pausebtn ms-sm-3' disabled onClick={() => ShowDetails(y, 'edit')}> {t('RequestOvertime')}   </button>



                                                            }
                                                        })()}
                                                    </div>









                                                </div>
                                            );
                                        })}
                                    </div>
                                    );

                                })}

                            </div>
                        </div>
                        <div className='col-2 pause-tab'>
                        </div>
                        <div className='col-2 pause-tab'>
                        </div>
                    </div>
                    <div className='row ms-sm-2 mt-4'>
                        <div className='col-sm-5 col-md-6 col-lg-2 col-xl-5 col-xxl-5'> {time_hr.map((x, index) => {
                            return (
                                <div className='col-sm-10 ms-sm-4 Clockt1 col-md-12 col-lg-12 col-xl-10 col-xxl-10'>
                                    {(() => {
                                        if (x.totaldayhr != null) {
                                            return <p className='fw-bold ms-1'> {t('TotalWorkedHoursOn')} {date} :{x.totaldayhr.toFixed(2)} Hours</p>

                                        }

                                        else {
                                            return <p className='fw-bold ms-1'> {t('TotalWorkedHoursOn')} {date} : 0 Hours</p>



                                        }
                                    })()}
                                </div>
                            );
                        })}</div>

                        <div className='col-sm-6 Clockt2'>
                            {time_hr.map((x, index) => {
                                return (
                                    <div className='col-sm-12 ms-sm-5'>
                                        {(() => {
                                            if (x.totaldayhr > 8 && x.totaldayhr != null) {
                                                return <p className='fw-bold text-end'> {t('TotalOver-TimeHoursOn')}  {date} : {((x.totaldayhr - 8).toLocaleString(undefined, { maximumFractionDigits: 2 }))} Hours</p>
                                            }

                                            else {
                                                return <p className='fw-bold text-end'> {t('TotalOver-TimeHoursOn')}  {date} : 0 Hours</p>



                                            }
                                        })()}</div>

                                );
                            })}</div>
                    </div>
                    <div className='row mt-2 card-2 hide_all'>

                        {time_details.map((x, index) => {
                            return (
                                <div className='col-11 mt-1 ms-5 col-md-12 col-lg-12 col-xl-11 col-xxl-11'><div class="card timecard " >
                                    <div className='row '>
                                        <div className='col-2 text-none des-tab1 '>
                                            <p className='mt-4  ms-4'>{moment(x.date).format(' MMM Do YYYY')}</p>
                                        </div>

                                        <div className='col-1 indate-mv1 indate1-tab col-md-1 col-lg-1 col-xl-1 col-xxl-1'>
                                            <input type="text" class=" form-control inputcolor timesize mt-3  " placeholder="" value={moment(x.intim).format('h:mm:ss A')} id="exampleFormControlInput1" disabled />

                                        </div>
                                        <div className='col-1 restext-mv1 tomob-tab '>
                                            <p className='mt-4 text-end'>to</p>
                                        </div>
                                        <div className='col-2 indate-mv2 indate2-tab col-md-2 col-lg-1 col-xl-2 col-xxl-2'>
                                            {(() => {
                                                if (x.out == null) {
                                                    return <input type="text" class=" form-control inputcolor timesize mt-3  me-5" placeholder="" value="Pending" id="exampleFormControlInput1" disabled />
                                                }

                                                else {
                                                    return <input type="text" class=" form-control inputcolor timesize mt-3  me-5" placeholder="" value={moment(x.out).format('h:mm:ss A')} id="exampleFormControlInput1" disabled />



                                                }
                                            })()}   </div>


                                        <div className='col-2 timer-mv1 timer-tab col-md-4 col-lg-3 col-xl-2 col-xxl-2 test'>
                                            <div className='row'>
                                                <div className='col-7'>  <p className=' mt-4 '>Clocked-In</p>
                                                </div>

                                                <div className='col-5'>
                                                    <p className='mt-4'>{x.totalhr}Hr</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-2 text-none ms-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                            <input type="text" class="form-control inputcolor  mt-3  test2  addcomm-tab" placeholder= {t('AddComment')}  value={x.time_comment} id="exampleFormControlInput1" />

                                        </div>
                                        <div className='col-sm-2 timer-mv1 timer-tab'>
                                            {/* <p className='fw-normal mt-4 text-start'>8</p> */}

                                        </div>

                                    </div>

                                </div></div>
                                

                            );
                        })}
                    </div>
                    {/* //mobilebiew */}
                    <div className='row mt-2 card-2 '>

{time_details.map((x, index) => {
    return (
        <div className='col-11  ms-5 onlytab hide_tab'><div class="card Clockt5" >
            <div className='row '>
                <div className='col-sm-2 '>
                    <p className='mt-2  ms-4'>{moment(x.date).format(' MMM Do YYYY')}</p>
                </div>

                <div className='col-sm-1 Clockt4'>
                    <input type="text" class=" form-control inputcolor timesize   " placeholder="" value={moment(x.intim).format('h:mm:ss A')} id="exampleFormControlInput1" disabled />

                </div>
                <div className='col-sm-1 Clockt3'>
                    <p className='mt-1 text-end'>to</p>
                </div>
                <div className='col-sm-2 Clockt4 '>
                    {(() => {
                        if (x.out == null) {
                            return <input type="text" class=" form-control inputcolor timesize   me-5" placeholder="" value="Pending" id="exampleFormControlInput1" disabled />
                        }

                        else {
                            return <input type="text" class=" form-control inputcolor timesize   me-5" placeholder="" value={moment(x.out).format('h:mm:ss A')} id="exampleFormControlInput1" disabled />



                        }
                    })()}   </div>


                <div className='col-sm-2 Clockt6'>
                    <div className='row'>
                        <div className='col-sm-7'>  <p className=' mt-1  '>Clocked-In</p>
                        </div>

                        <div className='col-sm-5 hcl'>
                            <p className=''>{x.totalhr}Hr</p>
                        </div>
                    </div>

                </div>
                <div className='col-sm-2  hcl'>
                    <input type="text" class="form-control inputcolor     addcomm-tab" placeholder= {t('AddComment')}  value={x.time_comment} id="exampleFormControlInput1" />

                </div>
                <div className='col-2 timer-mv1 timer-tab'>
                    {/* <p className='fw-normal mt-4 text-start'>8</p> */}

                </div>

            </div>

        </div></div>
        

    );
})}
</div>

                </div>

            </div>
            <div>{(() => {
                switch (user_type) {
                    //case "view": return RenderView();
                    case "edit": return RenderEdit();
                    //case "delete": return RenderDelete();
                    default: return "";
                }
            })()}</div>
            {/* <div class="dotmv lheaderdis">
                <Lheader />
            </div> */}

            <TimeSheetheader />


        </div>
    );
}


export default TimeSheet;