import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import AppWrapper from './App';
import { StateProvider } from './store/user'
import callInterceptor from './interceptors/token-interceptor';
import resInterceptor from './interceptors/refresh-interceptor';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51JImRzFjKCFWFCO2ZI1FFh5ntr8KN0wNYuh7myshFsB9XHHVGrGyfZcTKvy35WjDFbUGnufgsNuSkTbk0TeKgzvF007j6867rU');

const root = ReactDOM.createRoot(document.getElementById('root'));
const options = {
  // passing the client secret obtained from the server
  clientSecret: '{{sk_test_51JImRzFjKCFWFCO2Z7uUFAD19a2b5m6tAco0pvvPaFuuXJ3Unttp4dalfJSOWYLBbbBzvLbJwgrrJtpoQyDeSCco00T18UdhKv}}',
};
root.render(
  <React.StrictMode>
  <StateProvider>
  <Elements stripe={stripePromise} >
    <AppWrapper />
    </Elements>
  </StateProvider>   
</React.StrictMode>
);

callInterceptor();
resInterceptor();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
