import React, { useCallback, useState, useContext, useEffect } from 'react';
import { BsX } from 'react-icons/bs';
import moment from 'moment';
import Modal from 'react-modal';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const StripeButton = ({ isOpen, onRequestClose, handlePayment }) => {
  const { state } = useContext(store);
  console.log('leave', state.user);
  const stripePromise = loadStripe('pk_test_51JImRzFjKCFWFCO2ZI1FFh5ntr8KN0wNYuh7myshFsB9XHHVGrGyfZcTKvy35WjDFbUGnufgsNuSkTbk0TeKgzvF007j6867rU');



  const [pending, setPending] = useState([]);
  const { language,  } = useLanguage();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  const getPendingPayment = () => {
    if (state.user) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/getpenpaymentofuser/${state?.user[0]?.user_id}`)
        .then((res) => {
          console.log(res.data, 'pay');
          setPending(res.data);
        })
        .catch(() => {});
    }
  };
  const [paymentAmount, setPaymentAmount] = useState(108);

  // const calculateDueAmount = () => {
  //   // Assuming the due amount is constant
  //   const dueAmount = 2500000;

  //   // Count the number of rows with amount: 0
  //   const zeroAmountRows = pending.filter((row) => row.amount === 0);

  //   // Calculate the total due amount without tax
  //   const totalDueAmountWithoutTax = zeroAmountRows.length * dueAmount;

  //   // Calculate the tax amount (10% of the total due amount without tax)
  //   const taxAmount = (10 / 100) * totalDueAmountWithoutTax;

  //   // Calculate the total due amount including tax
  //   const totalDueAmountWithTax = totalDueAmountWithoutTax + taxAmount;

  //   // Set the payment amount in state
  //   // setPaymentAmount(totalDueAmountWithTax);

  //   return totalDueAmountWithTax;
  // };
  // const handlePaymentClick = async () => {
  //   try {
  //     const stripe = await stripePromise;
  
  //     // Fetch the payment intent from your server, including the amount
  //     const response = await axios.post(`${process.env.REACT_APP_API_URL}/payment_intent`, {
  //       amount: paymentAmount,
  //       email:state.user[0].loginmail,
  //       userid:state.user[0].user_id
  //     });
      
  //     const { sessionId } = response.data;
  
  //     // Redirect to the checkout page
  //     const { error } = await stripe.redirectToCheckout({
  //       sessionId,
  //     });
  
  //     if (error) {
  //       console.error(error);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  
  const [exchangeRate, setExchangeRate] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get('https://v6.exchangerate-api.com/v6/ce73b5da476a3bd7c0f030ac/latest/USD');
  //       if (response.status === 200) {
  //         const rate = response.data.conversion_rates.VND;
  //         setExchangeRate(rate);
  //       } else {
  //         console.error(`Error fetching exchange rate: ${response.statusText}`);
  //       }
  //     } catch (error) {
  //       console.error(`Error fetching exchange rate: ${error.message}`);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const calculateDueAmount = () => {
    const dueAmount = 2500000;
    const zeroAmountRows = pending.filter((row) => row.amount === 0);
    const totalDueAmountWithoutTax = zeroAmountRows.length * dueAmount;
    const taxAmount = (10 / 100) * totalDueAmountWithoutTax;
    
    // Check if exchangeRate is available before calculating in VND
    
      const totalDueAmountWithTax = totalDueAmountWithoutTax + taxAmount;
      const totalDueAmountInVND = totalDueAmountWithTax 
  
      // Round off the totalDueAmountInVND to the nearest whole number
      const roundedTotalDueAmountInVND = Math.round(totalDueAmountInVND);
  
      // You can use roundedTotalDueAmountInVND as needed
      return roundedTotalDueAmountInVND;
    
  };
  
  const totalDueAmountInVND = calculateDueAmount();
 
  const handlePaymentClick = async () => {
    try {
        const stripe = await stripePromise;

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/payment_intent`, {
            amount: totalDueAmountInVND,
            email: state.user[0].loginmail,
            userid: state.user[0].user_id,
        });

        const { sessionId, paymentIntentId } = response.data;

        // Redirect to the checkout page
        const { error } = await stripe.redirectToCheckout({
            sessionId,
        });

        if (error) {
            console.error(error);
        }
    } catch (error) {
        console.error(error);
    }
};

  

  useEffect(() => {
    getPendingPayment();
  }, [state]);
  
  const [adminprofile, setUserDetails] = useState([]);
  const Getadmin = (emp_id) => {
    if (state.user) {
        axios.get(`${process.env.REACT_APP_API_URL}/get_admin/${state?.user[0]?.user_id}`).then((res) => {
            console.log(res.data, 'success');
            setUserDetails(res.data);
        }).catch(() => {

        })
    }
    // var id = this.props.match.params.emp_id;

}
useEffect(() => {
    Getadmin();
},
    [state]);
  const today = new Date();
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Payment Modal" className="payment-modal">
  <div className='row mt-3 mb-3'>
    <div className='col-12'>
    <button className='btn btn-link float-end' onClick={onRequestClose}>
        <BsX size={24} />
      </button>
    </div>
  </div>
  <div className='row'>
    <div className='col-2'></div>
    <div className='col-8'>
      <h3 className='text-center fw-bold'>{t('paymentDetails')}</h3>
 
      {adminprofile.map((x, index) => {
                        return (<div>
     <p className='mt-2'>{t('name')}</p>
      <input className='form-control' disabled value={x.first_name}></input>
      <p className='mt-2'>Email</p>
      <input className='form-control' disabled value={x.email}></input>
      <p className='mt-2'>{t('companyName')}</p>
      <input className='form-control' disabled value={x.company_name} ></input>
                        </div>
                        )
      })}
      <p className='mt-2'>{t('paymentDate')}</p>
      <input className='form-control' disabled value={moment(today).format('DD-MM-YYYY')}></input>
      <p className='mt-2'>{t('totalDueAmount')}</p>
      <small>(Including Tax đ)</small>
      <input className='form-control' disabled value={calculateDueAmount()} onChange={(e)=>setPaymentAmount(e.target.value)}></input>
      <button className='btn btn-primary mx-auto d-flex mt-5 mb-4' onClick={handlePaymentClick}>{t('payNow')}</button>
      
    </div>
    <div className='col-2'></div>
  </div>
</Modal>

  );
};

export default StripeButton;
