import React, { useCallback, useState, useContext, useEffect } from 'react';

import { useNavigate, useParams } from "react-router-dom";
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from "react-paginate";
import { store } from '../../store/user';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import StripeButton from './Stripebutton';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Billing = () => {
    const [query, SetQuery] = useState("");
    const { state } = useContext(store);
    console.log('leave', state.user);
    let navigate = useNavigate();
    const leavedetails = () => {
        let path = `/leavedetails`;
        navigate(path);
    }
    const leave = () => {
        let path = `/leave`;
        navigate(path);
    }

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
    useEffect(() => {
        // Check if the popup should be displayed
        
        const data = localStorage.getItem('subscriptionPopupSeen');
       
         console.log(data,'gub')
      
    
        if (state.user&&state?.user[0]?.admin && state?.user[0]?.company && state?.user[0]?.payment_amount === 0 && !data) {
          confirmAlert({
            message: "Attention: Your subscription grace period has ended. Act now to renew and regain access to our premium services. Don't miss out on the benefits – renew today!",
            buttons: [
              {
                label: "Pay Now",
                onClick: () => {
                  localStorage.setItem('subscriptionPopupSeen', 'true');
                 
                  
                },
                style: {
                  backgroundColor: '#F36E35'
                }
              },
              {
                label: "Logout",
                onClick: () => {
                    logOut()
                }
              }
            ]
          });
        }
      }, [state]);
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const [payment, setpayment] = useState([]);
    const [company_list, setCompany_list] = useState([]);
    const Getcompanylist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/billingpay/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'success');
                setCompany_list(res.data);

            }).catch(() => {

            })
        }
    }
    const GetPayment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getallpaymentofuser/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'pay');
                setpayment(res.data);

            }).catch(() => {

            })
        }
    }
    const [pending, setpending] = useState([]);
    const Getpenpayment = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/getpenpaymentofuser/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'pay');
                setpending(res.data);

            }).catch(() => {

            })
        }
    }
    const [latest, setlatest] = useState([]);
    const Getsetlatest = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/latestpay/${state?.user[0]?.user_id}`).then((res) => {
                console.log(res.data, 'success');
                setlatest(res.data);

            }).catch(() => {

            })
        }
    }

    useEffect(() => {
        Getcompanylist();
        Get_companylist();
        GetPayment();
        Getpenpayment();
        Getsetlatest();
    }, [state]);




    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 5;
    const pagesVisited = pageNumber * usersPerPage;

    const filteredUsers = payment.filter((x) => {
        const paymentDate = moment(x.payment_date).format('YYYY-MM');
        if (typeof paymentDate === "string" && typeof query === "string") {
            return paymentDate.toLowerCase().includes(query.toLowerCase());
        }
        return false;
    });



    const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);



    const pageCount = Math.ceil(payment.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };


    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const differenceInTime = today.getTime() - firstDayOfMonth.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    const handlePayment = async () => {
      // Your payment logic using stripe, elements, etc.
      // Close the modal after payment is processed
      closeModal();
    };
  
  
   
    // const handleDownload = (invoiceData, filename) => {
    //     const pdf = new jsPDF();
      
    //     // Add content to the PDF
    //     pdf.text('Your Company Name', 20, 10);
    //     pdf.text('Invoice', 20, 20);
      
    //     // Add invoice details
    //     pdf.text(`Invoice ID: ${invoiceData.payment_id}`, 20, 30);
    //     pdf.text(`Date: ${formatDate(invoiceData.payment_date)}`, 20, 40);
      
    //     // Add customer details
    //     pdf.text(`Customer: ${invoiceData.userid}`, 150, 30);
      
    //     // Add table header and borders
    //     pdf.line(20, 50, 190, 50); // Horizontal line under the table header
    //     pdf.line(20, 50, 20, 80);  // Vertical line at the left
    //     pdf.line(150, 50, 150, 80);  // Vertical line at the right
    //     pdf.line(20, 80, 190, 80); // Horizontal line at the bottom
      
    //     // Add table header
    //     pdf.text('Description', 30, 60);
    //     pdf.text('Amount', 160, 60);
      
    //     // Add table rows (example with a single row) and borders
    //     pdf.line(20, 80, 190, 80); // Horizontal line above the row
    //     pdf.line(30, 50, 30, 80); // Vertical line at the left of the first column
    //     pdf.line(160, 50, 160, 80); // Vertical line at the left of the second column
    //     pdf.text('Item Description', 30, 70);
    //     pdf.text(`$${invoiceData.amount}`, 160, 70);
      
    //     // Add total amount and borders
    //     pdf.line(20, 90, 190, 90); // Horizontal line above the total amount
    //     pdf.line(160, 90, 160, 110); // Vertical line at the right
    //     pdf.text(`Total: $${invoiceData.amount}`, 160, 100);
      
    //     // Add notes or additional information
    //     pdf.text('Notes:', 20, 120);
    //     pdf.text('Thank you for your business!', 20, 130);
      
    //     // Save the PDF with the specified filename
    //     pdf.save(`${filename}.pdf`);
    //   };
      
      
    
      // Helper function to format date
     
    const [companylist, setCompanylist] = useState([])
      const Get_companylist = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/company_address/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'cmp');
                setCompanylist(res.data);

            }).catch(() => {

            })
        }
    }
      const handleDownload = (invoiceData, filename) => {
        // const companyListHTML = companylist.map((company, index) => `<li key=${index}>${company.company_name}</li>`).join('');
        const companyListHTML = companylist.map((company, index) => `<p key=${index}>${company.company_name}</p>,<small> ${company.company_address1}</small>,<Small>${company.company_address2}</small>`).join('');

        const content = `
        <img
            className='img-fluid'
            src='Images/logsyp.PNG'
            alt='Company Logo'
          /> 
        <div style="font-family: Arial; margin: 20px;">
           
            
          <h3>Invoice</h3>

            <p>Invoice ID: ${('SY0000' + invoiceData.payment_id).slice(-4)}</p>
            <p>Date: ${formatDate(invoiceData.paymentdate)}</p>
            <ul style="list-style: none; margin: 0; padding: 0;">${companyListHTML}</ul>
            <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
              <tr style="border-bottom: 1px solid #000;">
                <th style="text-align: left; padding: 8px;">Description</th>
                <th style="text-align: left; padding: 8px;">Due Month</th>
                <th style="text-align: right; padding: 8px;">Amount</th>
                <th style="text-align: right; padding: 8px;">Tax</th>
              </tr>
              <tr>
                <td style="padding: 8px;">Subscription</td>
                <td style="padding: 8px;">${formatDate(invoiceData.payment_date)}</td>
                <td style="text-align: right; padding: 8px;">${invoiceData.amount.toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
                <td style="text-align: right; padding: 8px;">${invoiceData.tax.toLocaleString('en-US').replace(/,/g, '.') + "đ"}</td>
              </tr>
            </table>
            <p style="margin-top: 20px; border-top: 1px solid #000; padding-top: 10px; text-align: right;">Total: ${(invoiceData.amount + invoiceData.tax).toLocaleString('en-US').replace(/,/g, '.') + 'đ'}</p>
            <p style="margin-top: 20px;">Notes: This is a system-generated signature not required</p>
          </div>
        `;
        
      
        const pdfOptions = { margin: 10, filename: `${filename}.pdf`, image: { type: 'jpeg', quality: 0.98 } };
      
        html2pdf().from(content).set(pdfOptions).save();
      }; 
      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      };
      const [islogged, setLogged] = useState(true);
      const [User, setUser] = useState(false);
      const logOut = () => {


        if (state) {
          axios.put(`${process.env.REACT_APP_API_URL}/updateloginstatus/${state.user[0].login_id}`).then((res) => {
            log_Out();
    
          }).catch(() => {
    
    
    
          })
        }
      }
      const log_Out = () => {

        if (islogged == true) {
          localStorage.removeItem('user_details');
          localStorage.setItem("hireAlertFlag", "true");
          setUser(false);
          // goLogin();
          window.location = '/login';
        }
        else {
          localStorage.setItem('user_details');
        }
      }
    return (
        <div className='bg-light vh-100 container-fluid '>
            <div className='row mt-4'>
                <div className='col-sm-2 hid_tab bg-primary'>

                <img className='img-fluid mt-5 hide_all' src={`Images/logoq.png`} ></img>
                </div>
                <div className='col-sm-10  bg-light  mt-4  col-xxl-10  col-md-12 col-lg-12 col-xl-10 tbl'>
                    <input
                        type="date"
                        className="form-control inputcolor mt-4 ms-sm-5 searchbox"
                        onChange={(event) => {
                            let formattedDate = "";
                            if (event.target.value) {
                                const selectedDate = new Date(event.target.value);
                                const year = selectedDate.getFullYear();
                                const month = selectedDate.getMonth() + 1;
                                formattedDate = `${year}-${month.toString().padStart(2, '0')}`;
                            }
                            SetQuery(formattedDate);
                        }}
                        id="month-input"
                        aria-label="Month and Year"
                        placeholder="Month and Year"
                    />





                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-search searchicon searchiconbiling searchic" viewBox="0 0 20 20">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    <div class="card card  ms-sm-5 shadow mb-5 p-2 bg-white rounded">

                        <div class="card-body">
                            <div className='row'>
                                <div className='col-sm'>
                                    <p className=' fs-4 fnt-clr '> {t('billingDetails')}</p>


                                </div><div className='col-sm-2'>
                                <div>
      
    </div>
                                </div>

                            </div>
                            <div className='row pointer_'>
                                <div className='col-sm'>
                                    <div className='row'>
                                        <div className='col-sm-6'>
                                            {company_list.map((x, index) => {
                                                return (

                                                    <div className='row'>
                                                        <div className='col-sm-2'>
                                                            {(() => {
                                                                if (x.user_image == null || x.user_image == '') {
                                                                    return <div className="" > <img className='img-fluid' src={`Images/complogo.jpg`} style={{ height: 65, width: 65 }}></img> </div>
                                                                } else {
                                                                    return <div className=""  > <img className='img-fluid im_g' src={x.user_image} style={{ height: 48, width: 65 }} ></img> </div>
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <p className='mt-4 greyh'>{x.first_name}</p>
                                                        </div>
                                                    </div>




                                                );
                                            })}
                                        </div>
                                        <div className='col-6'>

                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-sm-6'>
                                            {company_list.map((x, index) => {
                                                return (

                                                    <div className='row'>
                                                        <div className='col-sm-4 '>
                                                            <small className='text-secondary grey '>{t('billingAddress')}</small><br></br>
                                                            <small className='greyh'>{x.company_address1}</small>
                                                            <small className='greyh'>{x.company_address2}</small>
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <small className='text-secondary grey'>{t('planType')} </small><br></br>

                                                            {(() => {
                                                                if (x.trail == false) {
                                                                    return <p className="text greyh">Subscribed</p>
                                                                } if (x.trail == true) {
                                                                    return <p className="text-warning" >Trial Period</p>
                                                                } if (x.trail == null) {
                                                                    return <p className="text-danger">In-Active</p>
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className='col-sm-4 '>
                                                            <small className='text-secondary grey'>{t('billingCycle')} </small><br></br>
                                                            <p className='greyh'>Monthly</p>
                                                        </div>
                                                    </div>



                                                );
                                            })}


                                        </div>
                                        {latest.map((y, index) => {
                                            return (
                                                <div className='row mt-3'>
                                                    <div className='col-4 border' style={{ border: "1px solid black" }}>
                                                        <small className='text-secondary grey'>
                                                            {t('start_date')}

                                                        </small>
                                                        <p className='greyh'>{moment(y.createdon).format('DD-MMM-YYYY')}</p>
                                                    </div>

                                                    <div className='col-4 border' style={{ border: "1px solid black" }}>
                                                        <small className='text-secondary grey'>
                                                            {t('dueDate')}
                                                        </small>
                                                        <p><p className='greyh'>{moment(y.payment_date).add(1, 'month').startOf('month').format('DD-MMM-YYYY')}</p></p>
                                                    </div>

                                                    <div className='col-4 border' style={{ border: "1px solid black" }}>
                                                        <small className='text-secondary grey'>
                                                            {t('lastPaidDate')}
                                                        </small>
                                                        <p className='greyh'>{moment(y.paymentdate).format('DD-MMM-YYYY')}</p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                       
                                        {(()=>{
if(pending.length==0){
return(<h4 className=' fw-bold grey'>{t('noDues')} !</h4>)
}
else{
    return(
        <div className='row year-buttonss'>
           
            
        <table class=" mt-2">
            <table class="table year-buttonss" style={{ border: "none", borderColor: "white" }}>
                <thead>
                    <tr>
                        <td scope="col" className=' headfont fs-6  text-secondary grey mobsimplytext'>{t('pendingMonth')}</td>

                        <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>{t('pendingAmount')}</td>

                        <td scope="col" className=' headfont fs-6  text-secondary grey mobsimplytext'>{t('tax')}</td>
                        <td className='ms-2'></td>
                        <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>{t('total')}</td>

                        <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>{t('dueDate')} </td>

                        <td scope="col" className='headfont fs-6  text-secondary grey mobsimplytext'>{t('dueDays')}</td>






                    </tr>
                </thead>
                <tbody>
                    
                    {pending.map((x, index) => {
                        return (
                            <tr>
                                <td className='text-start greyh'>
                                    {(() => {
                                        if (x.amount != null) {
                                            return <p>{moment(x.payment_date).format('MMM Do YY')}</p>
                                        } else {
                                            return <p> {moment(firstDayOfMonth).format('MMM Do YY')}</p>
                                        }
                                    })()}
                                </td>


                                <td className='text-start greyh'><p>{(2500000 - x.amount).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>

                                <td className='text-start greyh'><p>{(250000 - x.tax).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                <td className='ms-2'></td>
                                <td className='text-start greyh'><p>{((250000 - x.tax) + (2500000 - x.amount)).toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>
                                <td className='text-start greyh'>{moment(x.payment_date).format('MMM Do YY')}</td>
                                <td className='text-danger text-start greyh '>
                                    {(() => {
                                        const paymentDate = new Date(x.payment_date);
                                        const differenceIn_Time = new Date().getTime() - paymentDate.getTime();
                                        const difference_InDays = Math.floor(differenceIn_Time / (1000 * 3600 * 24));

                                        if (x.amount != null) {
                                            return <p>{difference_InDays} Days</p>;
                                        } else {
                                            return <p className="">{differenceInDays} Days</p>;
                                        }
                                    })()}


                                </td>


                            </tr>
                        )
                    })}
                </tbody>

            </table>
        </table>
        <div className='col-9 '></div>
            <div className='col-3'>
            <button className='btn btn-primary mt-2' onClick={openModal}>{t('payNow')}</button>
      <StripeButton
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        handlePayment={handlePayment}
      />
            </div>
    </div>
    )

}
                                                        })()}
                                        <div className='row mt-4'>
                                            <h5 className='text-secondary grey fw-bold'>{t('paidHistory')}</h5>
                                            <table class="table table-bordered mt-2 year-buttonss">
                                                <table class="table" style={{ border: "none", borderColor: "white" }}>
                                                    <thead>
                                                        <tr>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'>{t('year')}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>

                                                            <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'>{t('month')}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey '>{t('paymentId')}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey '>{t('paidDate')}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td scope="col" className='headfont fs-6  text-secondary grey '>{t('amountPaid')} </td>
<td scope="col" className='headfont fs-6  text-secondary grey '>{t('downloadInvoice')} </td>







                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {displayUsers.filter((x) => { console.log(moment(x.payment_date).format('YYYY-MM-DD'), query); return (moment(x.payment_date).format('YYYY-MM-DD')).includes(query) }).map((x, index) => {

                                                            return (
                                                                <tr>
                                                                    <td className=''><p className="text-start greyh">{moment(x.payment_date).format('YYYY')}</p></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>

                                                                    <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                                    <td className=''><p className="text-start greyh">{moment(x.payment_date).format('MMM')}</p></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                                    <td className=''><p className="text-start greyh">{('0000' + x.payment_id).slice(-4)}</p></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td scope="col" className='headfont fs-6  text-secondary grey'></td>                                                                    <td className=''>{(() => {
                                                                        if (x.paymentdate != null) {
                                                                            return <p className="text-start greyh">
                                                                                {moment(x.paymentdate).format('MMM-DD-YYYY')}</p>

                                                                        } else {
                                                                            return <p className="text-start greyh">
                                                                                {moment(x.payment_date).format('MMM-DD-YYYY')}</p>
                                                                        }
                                                                    })()}
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                   
                                                                    <td scope="col" className='headfont fs-6  text-secondary grey'></td>
                                                                    <td className=''><p className="text-start greyh">{x.amount.toLocaleString('en-US').replace(/,/g, '.') + "đ"}</p></td>


                                                                    <td><svg onClick={()=>handleDownload(x,'PaymentInvoice')} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" class="bi bi-file-earmark-arrow-down mt-1 ms-4" viewBox="0 0 16 16">
  <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
</svg></td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>

                                                </table>
                                            </table>

                                        </div>

                                    </div>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable popupright">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <p class=" fs-6 ms-4 fw-bold">Leave status</p>
                                        <div className='row'>
                                            <div className='col-6'>

                                                <select class="form-select inputcolor popupdrop ms-4" aria-label=" select Day" onChange={(e) => SetQuery(e.target.value)}>
                                                    <option value="">ALL</option>

                                                    <option value="true">Approved</option>
                                                    <option value="false">Rejected</option>
                                                    <option value="pending">Pending</option>

                                                </select>


                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                            <div class="s">


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
            <div class="leaveml">
                
            </div>
            <Lheader />
        </div>
    );
}
export default Billing;