
import React, { useCallback, useState, useEffect, useContext } from 'react'
import Lheader from '../Lheader/Lheader'
import axios from 'axios';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import { store } from '../../store/user';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';
const Passwordchange = () => {
  const { state } = useContext(store);
  console.log('profile_cmp', state.user);
  const [password, setPassword] = useState("");
  const [newpassword, setnewPassword] = useState("");
  const { update, handleSubmit } = useForm();
  const [email, setemail] = useState("");
  const [newcon, setcon] = useState("");
  let navigate = useNavigate();

  const gohome = () => {
    navigate("/admindashboard");
  };

  const gohomes = () => {
    //debugger;
    navigate("/supervisordashboard");
  };
  const gohomee = () => {
    navigate("/employeedashboard");
  };
  const goaddress = () => {
    //debugger;
    navigate("/quickguide");
  };

  const onlogin = (data, e) => {

    let bas = {
      loginmail: email,
      password: password,
    };


    axios.post(`${process.env.REACT_APP_API_URL}/login`, bas).then((res) => {
      console.log('user updated', res.data);

    }).catch(() => {

    })
  }
  const alet = () => {
    confirmAlert({
      // title: 'Cancle',
      message: 'Confirm To send password',
      buttons: [
        {
          label:  t('yes'),
          onClick: () => onlogin(),
          style: {
            backgroundColor: '#F36E35'

          }
        },
        {
          label:  t('no'),

        }
      ]
    })
  }
  const { language,  } = useLanguage();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hrquipa', 'template_jyjlb0k', form.current, 'CG6sgr1nRYDufVqn2')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };
  const form = useRef();

  const onreset = (data, e) => {
    let bas = {

      loginmail: state?.user[0]?.loginmail,
      password: newcon,
    };


    axios.put(`${process.env.REACT_APP_API_URL}/resetmypassword`, bas).then((res) => {
      console.log('user updated', res.data);

      alert("password saved succesfully");
      //   if (state?.user[0]?.emp_id == null) {
      //     gohome();
      //   }
      //   else {
      //     gohomee();
      //   }
      if (state?.user[0]?.admin == true && state?.user[0]?.company == false) {

        goaddress();
      }
      if (state?.user[0]?.emp_id == null) {

        gohome();
      }
      if (state?.user[0]?.usertypename == 'HR Manager') {

        gohome();
      }
      if (state?.user[0]?.usertypename == 'Admin') {

        gohome();
      }
      if (state?.user[0]?.usertypename == 'Supervisor') {

        gohomes();
      }
      if (state?.user[0]?.usertypename == 'Employee') {

        gohomee();
      }


    }).catch(() => {

    })
  }
  // console.log(state?.user[0]?.loginmail);
  const onlog = (data, e) => {
    let bas = {
      loginmail: state?.user[0]?.loginmail,
      password: password,
    };

    console.log(bas);
    axios.post(`${process.env.REACT_APP_API_URL}/forgetmypass`, bas).then((res) => {
      console.log('valid User', res.data);

      //  Getloginuser () ;
      if (res.data.length > 0) {
        alert(" Click ok to confirm ");
        onreset();
      } else {
        alert("Wrong data please enter a valid data");
      }
    }).catch(() => {
      //  alert("wrong password");
    })
  }
  const { register, watch, formState: { errors } } = useForm({
    mode: 'onTouched'
  });
  const fname = watch('fname')



  // handle disabled submit
  const isValid = fname && password
  const valuepass = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/i;


  return (

    <div className='logscr'>
      <div className='row'>
        <div className=' col-xxl-7 col-sm-7 col-md-1 col-lg-1 col-xl-7  '>
          <div className='row'>
            <div className='col '>
              <img className='img-fluid loginimg tabimgcent' src={`Images/sygl_img.png`} alt="Logo" />

              <img className='imgbor mobsimplytext hide_tab' src={`/Images/sign_img.png`} alt="SY Image" />
            </div>


          </div>

        </div>
        <div className=' col-xxl-5 col-sm-5 col-md-11 col-lg-11 col-xl-5 bg-white inpucent'>
          <div className='row mt-5'></div>
          <div className='row mt-5'></div>
          <div className='row mt-5'></div>
          <div className='row'>
            <div className='row'>
              <div className='col-2 hide_tab'></div>
              <div className='col-sm-6 forleft hide_all '> <p className='fw-bold fs-3 text-start'>{t('ChangeYourPassword')}  !<p className=''>  </p> </p></div>
              <div className='col-sm-6 forleft onlytab hide_tab'> <p className='fw-bold fs-5 mt-5
               text-start'>Change Your Password !</p> </div>
              <div className='col-3'></div>
            </div>

            <div className='col-3'></div>

          </div>
          <div className='row'>
            <div className='col-2'></div>
            <div className='col-sm-6 col-xxl-6 col-md-12 col-lg-12 col-xl-6'>
              <div className='row'>

                <form onSubmit={handleSubmit(onlog)} action="#" method="POST">

                  <div className='col'>
                    <div className='row'>


                    </div>
                    <div className='row'>
                      <div className='col-8 mt-2 chnge'>
                        <label className=''>{t('currentPassword')} </label>
                        <input type='Password' minlength="2" maxlength="15" name="Password" className='form-control inputcolor pastxt1' Value={password} onChange={(e) => setPassword(e.target.value)} />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-8 mt-2 chnge'>
                        <label className=''> {t('newPassword')} </label>


                        <input

                          type='Password'
                          name="user_name"
                          minlength="8" maxlength="15"
                          Value={newpassword} onChange={(e) => setnewPassword(e.target.value)}
                          className={`form-control inputcolor pastxt1  ${errors.fname &&
                            " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                          {...register("fname", {
                            required: {
                              value: true,
                              message: ' *Password is required'
                            },
                            minLength: {
                              value: 8,
                              message: "Please enter minimum 8 character",
                            },
                            maxLength: {
                              value: 15,
                              message:
                                "Maximum allowed length is 15 characters ",
                            },
                            pattern: {
                              value: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-z A-Z 0-9!@#$%^&*]{8,64}$/i,
                              message: <small> * Password Should Contain Capital letter ,Small letter, Numeric & special Characters</small>
                            },
                          })}
                        />
                        <div>
                          {errors.fname && <span className=' text-danger'>{errors.fname.message}</span>}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-8 mt-2 chnge'>
                        <label className=''>{t('confirmPassword')} </label>
                        <input type='Password' minlength="2" maxlength="15" name="message" className='form-control inputcolor pastxt1' Value={newcon} onChange={(e) => setcon(e.target.value)} />
                      </div>
                    </div>


                    {(() => {
                      if (newcon === fname && fname.match(valuepass)) {
                        // Passwords match, show reset and close buttons
                        return (
                          <div className='row '>

                            <div className='col-2 hide_tab'> </div>
                            <div className='col-7 d-grid'> <button
                              type="submit"
                              className="btn d-grid btn-pri  mt-3"
                              value="Reset"
                              disabled={!isValid}
                            >{t('reset')} </button> </div>
                            <div className='col-1'> </div>

                            <div className='col-5 d-grid'>

                            </div>


                          </div>
                        );
                      } else if (newcon !== "" && newcon !== fname) {
                        // Passwords don't match, show error message, reset button, and close button
                        return (
                          <div className=" row ">
                            <p className="text-danger ">Password does not match</p>

                            <div className='col-2 hide_tab'> </div>
                            <div className='col-7 d-grid'>   <button
                              type="submit"
                              className="btn btn-pri d-grid  mt-3"
                              value="Reset"
                              disabled
                            >{t('reset')} </button></div>
                            <div className='col-1'> </div>

                            <div className='col-5 d-grid'>

                            </div>




                          </div>
                        );
                      } else {
                        // Passwords haven't been entered yet, show empty div with disabled reset and close buttons
                        return (
                          <div className='row '>

                            <div className='col-2 hide_tab'> </div>
                            <div className='col-7 d-grid'>
                              <button
                                type="submit "
                                className="btn btn-pri d-grid  mt-3"
                                value="Reset"
                                disabled
                              >{t('reset')} </button>
                            </div>
                            <div className='col-1'> </div>
                            <div className='col-5'>

                            </div>



                          </div>
                        );
                      }
                    })()}

                  </div>
                </form>

              </div>
            </div>
          </div>




        </div>
      </div>

    </div>
  )

};

export default Passwordchange