
import ReactPaginate from "react-paginate";
import React, { useState, useContext, useEffect } from 'react';
import './Attantance.css';
import moment from 'moment';
import axios from 'axios';
import Lheader from '../Lheader/Lheader';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { store } from '../../store/user';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';


import 'react-big-calendar/lib/css/react-big-calendar.css';


const Attantance = (props) => {
  const localizer = momentLocalizer(moment);
  const { state } = useContext(store);
  console.log('profile_cmp', state.user);

  const [tab_type, setTabType] = useState('Attantance');
  const [query, SetQuery] = useState("");
  const TabChange = (type) => {
    console.log('type', tab_type)
    if (type == 'Attantance') {
      console.log('Attantance', type);
      setTabType(type);
    } else if (type == 'ot') {
      console.log('My Regularization', type);
      setTabType(type);
    }
    else {

      setTabType(type);
    }

  }

  const { language,  } = useLanguage();
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    // Store the selected language in localStorage
    localStorage.setItem('selectedLanguage', newLanguage);
  };

  useEffect(() => {
    // Retrieve the selected language from localStorage on page load
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  // timesheet 
  const [time_details, setUserDetails] = useState([]);
  const [events, setEvents] = useState([]);

  const gettimesheet = (x) => {
    if (state.user)
      axios.get(`${process.env.REACT_APP_API_URL}/emptimesheet_all/${state?.user[0]?.emp_id}`).then((res) => {
        setUserDetails(res.data);
        let aa = [];
        res.data.forEach(item => {
          let a = {
            id: item.attendance_id,
            title: item.contents,
            allDay: true,
            // style:item.content === 'Absent' ?'background:#db153d' : 'background:#0080FF',
            // className: item.content === 'Absent' ? 'rbc-event_1' : 'rbc-event_p',
            start: new Date(item.date),
            end: new Date(item.date),
          };
          aa.push(a);
        });
        setEvents(aa);
      }).catch((error) => {
        console.log(error, 'success');
      });
  }
  const [data, setData] = useState([]);
  const gettimesheet_avg = (x) => {
    if (state.user)
      axios.get(`${process.env.REACT_APP_API_URL}/emptimesheet_all/${state?.user[0]?.emp_id}`).then((res) => {
        setData(res.data);
        console.log(res.data, 'avg')
      })

        .catch((error) => {
          console.log(error, 'success');
        });
  }
  const [time_1details, set_UserDetails] = useState([]);
  const get_timesheet = (x) => {
    if (state.user) axios.get(`${process.env.REACT_APP_API_URL}/emp_timesheet/${state?.user[0]?.emp_id}`).then((res) => {
      console.log(res.data, 'clock btn');
      set_UserDetails(res.data);
    }).catch((error) => {
      console.log(error, 'success');
    });
  }
  useEffect(() => { }, [events])

  //ot
  const [ot_details, setot] = useState([]);
  const getovertime = (x) => {
    if (state.user)
      axios.get(`${process.env.REACT_APP_API_URL}/overtime_emp/${state?.user[0]?.emp_id}`).then((res) => {
        console.log(res.data, 'succe_ss');
        setot(res.data);

      }).catch((error) => {
        console.log(error, 'success');
      });

  }
  useEffect(() => {
    gettimesheet_avg();
    gettimesheet();
    getovertime();
    get_timesheet();
  }, [state]);
  // page no
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;


  const filteredUsers = ot_details.filter((x) => x.approvalstatus.toLowerCase().includes(query));

  const displayUsers = filteredUsers.slice(pagesVisited, pagesVisited + usersPerPage);




  const pageCount = Math.ceil(time_details.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [month, setMonth] = useState(1); // example month
  const averageHours = data
    .filter(item => new Date(item.date).getMonth() === month - 1) // -1 because getMonth() returns 0-indexed month
    .reduce((total, item) => total + item.totalhr, 0) / (data.length);

  return (
    <div className='container-fluid'>


      {(() => {
        if (tab_type == "Attantance") {
          return <div class="row">

            <div class="col-2  col-xxl-2 col-sm-3 col-md-1 col-lg-1 col-xl-2 hide_tab">

            </div>
            <div class="col-sm-6 col-xxl-6 col-md-6 col-lg-6 col-xl-6 ms-5 mt-5 hide_all">
              <ul class="nav nav-tabs nav-justified navmobv attcaltb hide_all" role="tablist">
                <div class="slider hid_tab"></div>
                <li class="nav-item fw-bold nav-mob">
                  <a class="nav-link fnt-clr " onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('attendance')} </a>
                </li>
                <li class="nav-item  ">
                  <a class="nav-link fnt-clr" onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('overtime')} </a>
                </li>

              </ul>

              <ul class="nav nav-tabs onlytab hide_tab" role="tablist">
                <div class="slider hid_tab"></div>
                <li class="nav-item fw-bold nav-mob">
                  <a class="nav-link fnt-clr att-tab" onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('attendance')} </a>
                </li>
                <li class="nav-item  ">
                  <a class="nav-link fnt-clr" onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('overtime')} </a>
                </li>

              </ul>

              <div class="ms-4 mt-5" style={{ height: '450pt', width: '500pt', alignItems: 'center' }}>

                <Calendar
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  defaultDate={moment().toDate()}
                  localizer={localizer}
                />

              </div>

         
            </div>
            <div class="col-sm-6 onlytab hide_tab">
              <ul class="nav nav-tabs nav-justified navmobv nav-att tab-nav hide_all" role="tablist">
                <div class="slider hid_tab"></div>
                <li class="nav-item fw-bold nav-mob">
                  <a class="nav-link fnt-clr att-tab" onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('attendance')} </a>
                </li>
                <li class="nav-item  ">
                  <a class="nav-link fnt-clr" onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('overtime')} </a>
                </li>

              </ul>

              <ul class="nav nav-tabs onlytab mt-5" role="tablist">
                <div class="slider hid_tab"></div>
                <li class="nav-item fw-bold nav-mob">
                  <a class="nav-link fnt-clr att-tab" onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('attendance')} </a>
                </li>
                <li class="nav-item  ">
                  <a class="nav-link fnt-clr" onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('overtime')} </a>
                </li>

              </ul>

              

              <div class="ms-5 mt-5  " style={{ height: '169pt', width: '200pt', alignItems: 'center' }}>

<Calendar
  events={events}
  startAccessor="start"
  endAccessor="end"
  defaultDate={moment().toDate()}
  localizer={localizer}
/>

</div>
            </div>
            <div className="col-sm-3 col-xxl-3 col-md-8 col-lg-8 col-xl-3 mt-5 ms-sm-5 hide_all ">
              {time_1details.map((x, index) => {
                return (
                  <div className="ms-sm-3">
                    <div class="col fw-bold   mt-5 timri">{moment(x.date).format(' MMM Do YYYY')}</div>

                    <p class="fw-bold mt-5"></p>
                    <div class="row">
                      <div class="col-4 text-secondary timri">{t('Time-In')}</div>
                      <div class="col-6 fw-bold timri">{moment(x.intim).format('h:mm:ss A')}</div>
                      <div></div>

                      <div class="col-4 text-secondary mt-3 timri">{t('Time-Out')} </div>
                      <div class="col-6 fw-bold mt-3 timri">{moment(x.out).format('h:mm:ss A')}</div>
                    </div>
                  </div>)
              })}

              <div class="row mt-5 ms-2">

                <div className="row">
                  <p className="timri">{t('AvgWorkingHours')}</p>
                  <p className="timri">{averageHours.toFixed(1)}</p>
                </div>
                {/* <div className="row">
                  <p>Absent Days</p>
                  <p>00</p>
                </div>
                <div className="row">
                  <p>Late In</p>
                  <p>00</p>
                </div>
                <div className="row">
                  <p>EarlyOut</p>
                  <p>00</p>
                </div> */}




              </div>
            </div>
            <div className="col-sm-3  ms-sm-5 onlytab hide_tab">
              {time_1details.map((x, index) => {
                return (
                  <div className="ms-sm-3">
                    <div class="col fw-bold   mt-5 ">{moment(x.date).format(' MMM Do YYYY')}</div>

                    <p class="fw-bold mt-3  "></p>
                    <div class="row">
                      <div class="col-4 text-secondary ">{t('Time-In')}</div>
                      <div class="col-6 fw-bold">{moment(x.intim).format('h:mm:ss A')}</div>
                      <div></div>

                      <div class="col-4 text-secondary mt-3">{t('Time-Out')} </div>
                      <div class="col-6 fw-bold mt-3">{moment(x.out).format('h:mm:ss A')}</div>
                    </div>
                  </div>)
              })}

              <div class="row mt-2 ">

                <div className="row">
                  <p className="">{t('AvgWorkingHours')}</p>
                  <p>{averageHours.toFixed(1)}</p>
                </div>
                {/* <div className="row">
                  <p>Absent Days</p>
                  <p>00</p>
                </div>
                <div className="row">
                  <p>Late In</p>
                  <p>00</p>
                </div>
                <div className="row">
                  <p>EarlyOut</p>
                  <p>00</p>
                </div> */}




              </div>
            </div>


          </div>

        } else if (tab_type == "ot") {
          return <div class="">

            <div>

              <div class="row mt-5 ">

                <div class="col-3  ">

                </div>
                <div class="col-sm-9 ">
                  <ul class="nav nav-tabs nav-justified nav-reg navregtab " role="tablist">
                    <div class="slider hid_tab"></div>
                    <li class="nav-item  ">
                      <a class="nav-link fnt-clr " onClick={() => { TabChange("Attantance") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('attendance')} </a>
                    </li>
                    <li class="nav-item fw-bold ">
                      <a class="nav-link fnt-clr " onClick={() => { TabChange("ot") }} id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-home"></i>{t('overtime')} </a>
                    </li>

                  </ul>




                </div>
                <div className="row">
                  <div className="col-3 hide_tab"></div>
                  <div className="col-3"><p className='ms-5 fnt-clr mt-4'> {t('overtime')}  </p></div>
                  <div className="col-4 col-xxl-4 col-md-7 col-lg-7 col-xl-4"></div>
                  <div className="col-1">
                    <svg data-bs-toggle="modal" data-bs-target="#exampleModal" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#727272" class="bi bi-sliders ms-3 mt-3 " viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                    </svg></div>
                </div>

                {displayUsers.filter((x) => x.approvalstatus.toLowerCase().includes(query)).map((x, index) => {
                  return (

                    <div className='row'>
                      <div className='col-3 hide_tab'></div>
                      <div className='col-8 col-xxl-8 col-md-12 col-lg-12 col-xl-8 ms-4  mt-1 hide_all'><div class="card timecard" >
                        <div className='row '>
                          <div className='col-2 text-none'>
                            <p className='mt-4  ms-4'>{moment(x.date).format(' MMM Do YYYY')}</p>
                          </div>

                          <div className='col-1 indate-mv1'>
                            <input type="text" class=" form-control inputcolor timesize mt-3  " placeholder="" value={x.full_name} id="exampleFormControlInput1" disabled />

                          </div>
                          <div className='col-1 restext-mv1 tomob-tab'>
                            <p className='mt-4 text-end'></p>
                          </div>
                          <div className='col-2 indate-mv2 indate2-tab'>

                            <p className='mt-4'> OT Hours : {(x.overtimehr.toFixed(2))}</p>



                          </div>


                          <div className='col-2 timer-mv1 '>
                            <div className='row'>
                              <div className='col-8 col-xxl-8 col-md-9 col-lg-9 col-xl-8'>  <p className=' mt-4 '>{(() => {
                                if (x.approvalstatus == "Rejected") {
                                  return <p className="text-danger mt-3">{x.approvalstatus}</p>
                                }
                                if (x.approvalstatus == "Approved") {
                                  return <p className="text-success mt-3">{x.approvalstatus}</p>
                                }

                                else {
                                  return <p className="text-warning mt-3">{x.approvalstatus}</p>


                                }
                              })()} </p>
                              </div>

                              <div className='col-2'>

                              </div>
                            </div>

                          </div>
                          <div className='col-2 col-xxl-2 col-md-1 col-lg-1 col-xl-2 text-none ms-4'>
                            <input type="text" class="form-control inputcolor  mt-3   addcomm-tab" placeholder={t('NoComment')} value={x.otcmnt} id="exampleFormControlInput1" disabled />

                          </div>
                          <div className='col-2 timer-mv1 timer-tab'>


                          </div>

                        </div>

                      </div></div>
                      <div className='col-8  ms-2   onlytab hide_tab'><div class="card Clockt2  mt-1" >
                        <div className='row '>
                          <div className='col-sm-2  '>
                            <p className='mt-2  ms-4'>{moment(x.date).format(' MMM Do YYYY')}</p>
                          </div>

                          <div className='col-sm-1 Clockt6 '>
                            <input type="text" class=" form-control inputcolor timesize   " placeholder="" value={x.full_name} id="exampleFormControlInput1" disabled />

                          </div>
                          <div className='col-1 restext-mv1 tomob-tab'>
                            <p className='mt-2 text-end'></p>
                          </div>
                          <div className='col-sm-2 Clockt6'>

                            <p className=''> OT Hours : {(x.overtimehr.toFixed(2))}</p>



                          </div>


                          <div className='col-sm-2 Clockt6 '>
                            <div className='row'>
                              <div className='col-8'>  <p className='  '>{(() => {
                                if (x.approvalstatus == "Rejected") {
                                  return <p className="text-danger ">{x.approvalstatus}</p>
                                }
                                if (x.approvalstatus == "Approved") {
                                  return <p className="text-success ">{x.approvalstatus}</p>
                                }

                                else {
                                  return <p className="text-warning ">{x.approvalstatus}</p>


                                }
                              })()} </p>
                              </div>

                              <div className='col-2'>

                              </div>
                            </div>

                          </div>
                          <div className='col-2 text-none ms-4'>
                            <input type="text" class="form-control inputcolor  mt-3   addcomm-tab" placeholder={t('NoComment')} value={x.otcmnt} id="exampleFormControlInput1" disabled />

                          </div>
                          <div className='col-2 timer-mv1 timer-tab'>


                          </div>

                        </div>

                      </div></div>
                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-scrollable popupright tabfltwd">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h7 class="modal-title fw-bold" id="exampleModalLabel">Filter By</h7>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <div className='row'>
                                <div className='col-sm-6'>
                                  <p class=" fs-6 ms-4 fw-bold">{t('approvalStatus')} </p>
                                  <div className='row'>
                                    <div className='col-6'>

                                      <select class="form-select inputcolor popupdrop ms-4" aria-label=" select Day" onChange={(e) => SetQuery(e.target.value)}>
                                        <option value="">ALL</option>

                                        <option value="approved">{t('approved')} </option>
                                        <option value="rejected">{t('rejected')} </option>
                                        <option value="pending">{t('pending')} </option>

                                      </select>


                                    </div>
                                  </div>


                                </div>

                              </div>
                            </div>
                            <div class="s">


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  );
                })}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />

              </div>


            </div>

          </div>
        }

      })()}
      <div class="attlh tabheader">
      
      </div>
      <Lheader /> 
    </div>

  )

}
export default Attantance;