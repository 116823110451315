import React, { useState } from 'react';
import { useContext, useEffect } from 'react';
import './CompanyAddress.css';
import { useNavigate } from "react-router-dom";
import EHeader from '../Header/EHeader'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { store } from '../../store/user';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const CompanyAddress = (props) => {
    const { state } = useContext(store);
    console.log('companylogin', state.user);
    const { update, handleSubmit } = useForm();
    const [image64, setImage64] = useState("");
    const [selectedfile, setSelectedfile] = useState([]);
    //const [companyname, setcompanytitle] = useState("");
    //const [companyadd1, setcompanyadd1] = useState("");
    const [companyadd2, setcompanyadd2] = useState("");
    const [city, setcity] = useState("671");
    //const [pincode, setpincode] = useState("");
    const [country, setcountry] = useState("");
    const [phone_code, setPhoneCode] = useState("+84");
    const [faxcode, setFaxCode] = useState(0);
    const [province, setProvince] = useState("");
    //const [phone_number, setphonenumber] = useState("");
    const [fax, setfax] = useState("");


    const [countr, setcountryDetails] = useState([]);


    const Getcountry = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetails(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const [countrphcode, setcountryDetailsphcode] = useState([]);


    const Getcountryphonecode = (x) => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/country_phonecode`).then((res) => {
                console.log(res.data, 'success');
                setcountryDetailsphcode(res.data);

            })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        Getcountry();
        Getvcitylist();
        Getcountryphonecode();
    }, []);
    useEffect(() => {
        Getvprovincelist();
    }, [city]);
    const [vcitylist, setvcitylist] = useState([]);
    const Getvcitylist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vcity`).then((res) => {
                console.log(res.data, 'success');
                setvcitylist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    const [vprovincelist, setvprovincelist] = useState([]);
    const Getvprovincelist = () => {
        if (city != null) {
            axios.get(`${process.env.REACT_APP_API_URL}/vprovince/${city}`).then((res) => {
                console.log(res.data, 'provi');
                setvprovincelist(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }

    const onUpdatelogin = (cmpid) => {

        let login = {
            login_id: state.user[0].login_id,
            company: 'true',
            company_id: cmpid

        };
        //console.log("user", user);

        axios.put(`${process.env.REACT_APP_API_URL}/logincompany/${state.user[0].login_id}`, login).then((res) => {
            console.log('login_emp updated', res.data);
        }).catch(() => {

        })
    }

    const onUpdateUser = (cmpid) => {

        let cmp_id = {

            company_id: cmpid

        };
        console.log("user", cmp_id);

        axios.put(`${process.env.REACT_APP_API_URL}/update_companyid/${state.user[0].user_id}`, cmp_id).then((res) => {
            console.log('company updated', res.data);
        }).catch(() => {

        })
    }

    const image = (e) => {

        setSelectedfile(e.target.files);
        console.log(e.target.files[0]);
        console.log(e.target.files[0].name);
        console.log(e.target.files[0].size);
        console.log(e.target.files[0].type);
    };

    const ConvertImageToBase64 = (event) => {
        // console.log('event', event.target.files[0]);
        let file = event.target.files[0];
        var reader = new FileReader();
        console.log('file upload');
        let base64;
        reader.readAsDataURL(file);
        reader.onload = () => {
            base64 = reader.result;
            console.log('base64', base64);
            setImage64(base64);
        };

        reader.onerror = (error) => {
            console.log('error :', error);
        };

    };
    const alert = (id) => {
        confirmAlert({
            // title: 'Cancle',
            message:t('dataSavedSuccess') ,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => companytax(id),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },

            ]
        })
    }
    const availableCities = vcitylist.find((c) => c.city === city);
    const availableProvince = availableCities?.vcitylist?.find(
        (s) => s.city === availableCities
    );

    const { register, watch, formState: { errors } } = useForm({
        mode: 'onTouched'
    });
    // handle submit

    // watch events
    const companyname = watch('companyname')
    const companyadd1 = watch('companyadd1')
    //const companyadd2 = watch('companyadd2')
    //const city = watch('city')
    const pincode = watch('pincode')
    //const country = watch('country')
    //const fax = watch('fax')
    const phone_number = watch('phone_number')
    const phonecode = watch('phone_code')
    //const worklocation = watch('worklocation')

    // handle disabled submit
    const isValid = companyname && companyadd1 && city && phone_number.length > '6'

    const handle_Paste = (event) => {

        const pastedText = event.clipboardData.getData('text');

        // Check if pasted text contains only numeric characters
        if (!/^[0-9]+$/.test(pastedText)) {
            event.preventDefault();
        }
    };

    const [companynm, setcompanynm] = useState("");
    // companyname validator
    const Getcompanynm = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/usercompany`).then((res) => {

                setcompanynm(res.data);
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const [companyph, setcompanyph] = useState("");
    // companyphone validator
    const Getcompanyph = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/usercompphone`).then((res) => {

                setcompanyph(res.data);
            })
        } catch (err) {
            console.log(err.message);
        }
    }

    useEffect(() => {
        Getcompanynm();
        Getcompanyph();
    }, []);
    const checkifValidcompany = (inputmail) => {
        let isValid = true;
        companynm.forEach(item => {
            if (item.company_name.match(inputmail)) {
                isValid = false;
            }
        });
        return isValid
    }
    const checkifValidcompanyph = (inputmail) => {
        let isValid = true;
        companyph.forEach(item => {
            if (item.phone_number.match(inputmail)) {
                isValid = false;
            }
        });
        return isValid
    }

    const onInsert = (data, e) => {
        if (companyname != "") {
            if (checkifValidcompany(companyname) && checkifValidcompanyph(phone_number)) {
                try {
                    let address = {

                        status: "true",
                        company_name: companyname,
                        company_address1: companyadd1,
                        company_address2: companyadd2,
                        city: city,
                        pincode: pincode,
                        country: country,
                        phone_number: phone_number,
                        company_fax_address: fax,
                        company_logo: image64,
                        user_id: state.user[0].user_id,
                        phonecode: phonecode,
                        faxcode: faxcode,
                        province: province,

                    };

                    axios.post(`${process.env.REACT_APP_API_URL}/company_address`, address).then((res) => {
                        console.log('address added', res.data);

                        // Storing the company_id as JSON object in local storage
                        localStorage.setItem('company_id', JSON.stringify({ company_id: res?.data?.company_id }));

                        companytax(res?.data?.company_id, res?.data?.company_name)
                        //onUpdatelogin(res?.data?.company_id);
                        //onUpdateUser(res?.data?.company_id);

                        //window.location = "/companytax";
                    })
                } catch (err) {
                    console.log(err.message);
                }
            } else {
                confirmAlert({
                    message: ' CompanyName/PhoneNo already exists , existing companyname/phoneno cannot be used',
                    buttons: [
                        {
                            label: t('close') ,
                            onClick: () => { },
                            style: {
                                backgroundColor: '#F36E35'

                            }
                        },
                    ]
                });
            }

        } else {
            confirmAlert({
                message: t('fillAllData') ,
                buttons: [
                    {
                        label: t('close') ,
                        onClick: () => { },
                        style: {
                            backgroundColor: '#F36E35'

                        }
                    },
                ]
            });
        }
    }


    let navigate = useNavigate();
    const companytax = (id, companyname) => {
        let path = `/companytax`;
        navigate(path, { state: { company_id: id, company_name: companyname } });
    }
    return (
        <div className=' vh-100 container-fluid'>

            <div className='row mt-5'>
                <div className='col-2 col-xxl-2 col-sm-3 col-md-1 col-lg-2 col-xl-2'>

                </div>
                <div className='col-xxl-10 col-sm-10 col-md-9 col-lg-10 col-xl-10 mt-3'>
                    {/* <a class="nav-link text-primary fw-bold mobhide mobcompy" aria-current="page"><img className=" mobhide" src="./Images/mobcompany.svg"></img>Company Information</a> */}

                    <div className=' fw-normal  text-center fw-bold mt-4 '> <a className='fs-6' style={{ textDecoration: "none", color: " #0080FF" }}>Step:1</a>   {t('addCompanyDetails')}  </div>
                    <div className='border-bottom'>

                        <nav className='tabs-center'>
                            <div class="nav mt-3 " id="nav-tab" role="tablist">
                                <div className='row scroll-containercomp'>
                                    <div className='col-4 col-xxl-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                <div class=" mt-1 addlefmob"> <img className='img-fluid ms-4' src={`Images/add.svg`} ></img></div>
                                            </div>
                                            <div className='col-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5'>
                                                <a class="nav-item nav-link active  text-tabcolor border-bottom border-primary border-3 text-primary" style={{ color: " #0080FF" }} id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true"><div className='row'>
                                                    <div className='col me-5 addlefmob'> {t('address')}</div></div></a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 col-xxl-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                <div class=" mt-1 taxlefmob"> <img className='img-fluid ms-4' src={`Images/tax.svg`} ></img></div>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <a class="nav-item nav-link text-black text-tabcolor taxlefmob" id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">{t('tax')} </a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 col-xxl-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <div class=" mt-1 banklefmob"> <img className=' ms-5' src={`Images/bank.svg`} ></img></div>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <a class="nav-item nav-link text-black text-tabcolor banklefmob1" id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">{t('bank')} </a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div className='row'>
                        <div className='col-3 col-xxl-3 col-sm-3 col-md-1 col-lg-3 col-xl-3'>
                        </div>
                        <div className=' col-xxl-5 col-sm-5 col-md-9 col-lg-5 col-xl-5 addtextmob'>
                            <div className=''>
                                <div class="form-group mt-3">
                                    <label for="formGroupExampleInput" class="required ms-5">{t('companyName')}</label>
                                    <div class="input-group ms-5 ">
                                        {/* <input type="text" class=" textbox-work mt-3 " Value={companyname} onChange={(e) => setcompanytitle(e.target.value)} placeholder="Joseph George" aria-label="Username" /> */}
                                        <input
                                            minlength="3" maxlength="50"
                                            type='text'
                                            placeholder={t('companyName')}
                                            className={`textbox-work form-control inputcolor required ${errors.companyname &&
                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                            {...register("companyname", {
                                                required: {
                                                    value: true,
                                                    message: t('companyNameRequiredd') 
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: t('companyNameMinLength') ,
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message:
                                                      t('companyNameMaxLength') ,
                                                },
                                                pattern: {
                                                    value: /[a-zA-Z]+/,
                                                    message:t('onlyAlphabets') 
                                                },
                                            })}
                                        />
                                        <div>
                                            {errors.companyname && <span className='error_comp text-danger'>{errors.companyname.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>

                                <div className="col-2 col-xxl-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <img className='img-fluid ms-5' src={image64} ></img>
                                </div>
                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                    <label for="formFile" class="form-label ms-5">{t('companyLogo')}</label>
                                </div>
                                <div className='col-7 col-xxl-7 col-sm-7 col-md-7 col-lg-7 col-xl-7'>
                                    <div class="">
                                        <input class="form-control inputcolor ms-5" type="file" id="formFile" onChange={ConvertImageToBase64} />
                                        <span className='ms-5 text-danger text_box_size textsml'>{t('lessThan620x420Pixel')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className=''>
                                    <div class="mt-2 ">
                                        <div class="form-group mt-3">
                                            <label for="formGroupExampleInput" class="required ms-5">{t('address1')}</label>
                                            {/* <input type="address" class="form-control inputcolor  txtaddr " Value={companyadd1} onChange={(e) => setcompanyadd1(e.target.value)} placeholder="Address 1" id="exampleFormControlInput1" /> */}
                                            <input
                                                minlength="3" maxlength="100"
                                                type='text'
                                                placeholder={t('address1')}
                                                className={` form-control inputcolor ms-5  txtaddr required ${errors.companyadd1 &&
                                                    " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                {...register("companyadd1", {
                                                    required: {
                                                        value: true,
                                                        message: t('address1Required') 
                                                    },
                                                    minLength: {
                                                        value: 3,
                                                        message:t('address1MinLength') ,
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message:
                                                          t('address1MaxLength') ,
                                                    },
                                                    pattern: {
                                                        value: /[a-zA-Z0-9]+/,

                                                    },
                                                })}
                                            />
                                        </div>
                                        <div>
                                            {errors.companyadd1 && <span className='ms-5 text-danger'>{errors.companyadd1.message}</span>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='mt-3 '>
                                    <div class="">
                                        {/* <input type="address" class="form-control inputcolor ms-5 txtaddr " Value={companyadd2} onChange={(e) => setcompanyadd2(e.target.value)} placeholder="Address 2" id="exampleFormControlInput1" /> */}
                                        <input
                                            minlength="3" maxlength="100"
                                            type='text'
                                            placeholder={t('address2')}
                                            onChange={(e) => setcompanyadd2(e.target.value)}
                                            className={` form-control inputcolor ms-5 txtaddr ${errors.companyadd2 &&
                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                            {...register("companyadd2", {
                                                required: {
                                                    value: true,
                                                    // message: ' * Address field is required'
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: t('address2MinLength') ,
                                                },
                                                maxLength: {
                                                    value: 100,
                                                    message:
                                                      t('address1MaxLength') ,
                                                },
                                                pattern: {
                                                    value: /[a-zA-Z0-9]+/,

                                                },
                                            })}
                                        />
                                        <div>
                                            {errors.companyadd2 && <span className='error_ad text-danger'>{errors.companyadd2.message}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>

                                <div className='col-12 mt-3 '>
                                    <div class="">
                                        {/* <input type="address" class="form-control inputcolor txtaddr " Value={pincode} onChange={(e) => setpincode(e.target.value)} placeholder="Pincode" id="exampleFormControlInput1" /> */}
                                        <input

                                            type='text'

                                            minlength="3" maxlength="10"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            placeholder={t('postalCode')}
                                            className={` form-control inputcolor ms-5 txtaddr ${errors.pincode &&
                                                " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                            {...register("pincode", {
                                                required: {
                                                    value: true,

                                                },
                                                minLength: {
                                                    value: 3,
                                                    message:t('postCodeRequired') ,
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message:
                                                       t('postCodeMaxLength') ,
                                                },
                                                pattern: {
                                                    value: /[0-9]+/,
                                                    message: t('postCodeNumbersOnly') 
                                                },
                                            })}
                                        />
                                        <div>
                                            {errors.pincode && <span className='error_pcode text-danger'>{errors.pincode.message}</span>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='mt-3'>
                                    <div class="">

                                        <div class="form-group mt-3">
                                            <label for="formGroupExampleInput" class="required ms-5">{t('country')}</label>
                                            <select class="form-select inputcolor ms-5 txtaddr " Value={country} onChange={(e) => setcountry(e.target.value)} aria-label=" select Day" >

                                                <option  >{t('selectCountry')}</option>

                                                {countr.map((x, index) => {
                                                    return (
                                                        <option value={x.country_name} >{x.country_name}</option>
                                                    );
                                                })}

                                            </select>
                                        </div>
                                        <div>
                                            {errors.country && <span className='error_ad text-danger'>{errors.country.message}</span>}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 mt-3'>
                                    {(() => {

                                        if (country == "Vietnam ") {
                                            return <div class="">

                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-5">{t('cityTown')}</label>
                                                    <select class="form-control inputcolor text-start ms-5 txtaddr" Value={city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                        <option selected>{t('cityTown')} </option>
                                                        {vcitylist.map((x, index) => {
                                                            return (
                                                                <option value={x.city_id}>{x.city}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        } else {
                                            return <div class="">

                                                <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-5">{t('cityTown')}</label>
                                                    <select class="form-control inputcolor text-start ms-5 txtaddr" Value={city} onChange={(e) => setcity(e.target.value)} aria-label=" select Day">
                                                        <option selected>{t('cityTown')} </option>


                                                        <option value="671">Nil</option>

                                                    </select>
                                                </div>
                                            </div>
                                        }
                                    })()}

                                </div>
                                <div className='col-6 mt-3'>

                                    {vprovincelist.map((x, index) => {
                                        {
                                            return (<div>
                                                {(() => {
                                                    if (city == 671) {
                                                        return <div class="form-group">
                                                            <label for="formGroupExampleInput" class=" ms-5">{t('province')}</label>
                                                            <input type="text" class="form-control inputcolor txtaddr  ms-5" Value={province} onChange={(e) => setProvince(e.target.value)} placeholder={t('province')} id="exampleFormControlInput1" />
                                                        </div>
                                                    } else {
                                                        return <div class="form-group">
                                                            <label for="formGroupExampleInput" class=" ms-5">{t('province')}</label>
                                                            <input type="text" class="form-control inputcolor txtaddr  ms-5" value={x.province} Value={province} onChange={(e) => setProvince(e.target.value)} placeholder={t('province')} id="exampleFormControlInput1" />
                                                        </div>
                                                    }
                                                })()

                                                }
                                            </div>


                                            )
                                        }
                                    })}
                                </div>
                            </div>


                            <div className=''>
                                <div className='col-sm mt-3 '>
                                    <div class="">
                                        <div class="form-group">
                                            <label for="formGroupExampleInput" class="required ms-5">{t('phoneNumber')}</label>
                                            <div class="input-group ms-5 border bg-white phonedrop txtaddr ">


                                                <select Value={phone_code} onChange={(e) => setPhoneCode(e.target.value)} aria-label="  select Day"
                                                    type='tel'
                                                    placeholder={t('phoneNumber')}
                                                    className={` text-start inputcolor phonedrop ${errors.phonecode &&
                                                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                    {...register("phone_code", {
                                                        required: {
                                                            value: true,
                                                            message: t('phoneCodeRequired') 
                                                        }, pattern: {
                                                            value: /[0-9]+/,
                                                            message:t('numbersOnly') 
                                                        },

                                                    })} >
                                                    <option selected>+84 </option>
                                                    {countrphcode.map((x, index) => {
                                                        return (
                                                            <option value={x.country_phone_code} >{x.country_phone_code}</option>
                                                        );
                                                    })}

                                                </select>
                                                <div>
                                                    {errors.phonecode && <span className='error_ph text-danger'>{errors.phonecode.message}</span>}
                                                </div>

                                                <input

                                                    type='tel'
                                                    placeholder={t('phoneNumber')}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onPaste={handle_Paste}
                                                    minlength="7" maxlength="12"
                                                    className={` form-control inputcolor border-top-0 border-bottom-0 ${errors.phone_number &&
                                                        " focus:border-red-500 focus:ring-red-500 border-red-500"}`}
                                                    {...register("phone_number", {
                                                        required: {
                                                            value: true,
                                                            message:t('phoneNumberRequired') 
                                                        },
                                                        minLength: {
                                                            value: 7,
                                                            message: t('phoneNumberMinDigits') ,
                                                        },
                                                        maxLength: {
                                                            value: 12,
                                                            message:
                                                               t('phoneNumberMaxLength') ,
                                                        },
                                                        pattern: {
                                                            value: /[0-9]+/,
                                                            message:t('numbersOnly') 
                                                        },
                                                    })}
                                                />
                                                <div>
                                                    {errors.phone_number && <span className='error_ph text-danger'>{errors.phone_number.message}</span>}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='col-sm mt-3 '>
                                    <div class="">
                                        <div class="input-group ms-5 border bg-white phonedrop txtaddr ">


                                            <select class="text-start inputcolor phonedrop" Value={faxcode} onChange={(e) => setFaxCode(e.target.value)} aria-label=" select Day">
                                                <option selected>00 </option>
                                                {countr.map((x, index) => {
                                                    return (
                                                        <option value={x.country_phone_code} >{x.country_phone_code}</option>
                                                    );
                                                })}
                                            </select>


                                            <input type="address" class="form-control inputcolor border-top-0 border-bottom-0" Value={fax} onChange={(e) => setfax(e.target.value)}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                minlength="7" maxlength="12"
                                                placeholder={t('fax')} id="exampleFormControlInput1" />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'></div>
                                <div className='col-8 col-xxl-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 mt-4'>
                                    <form onSubmit={handleSubmit(onInsert)} action="#" method="POST">

                                        <input
                                            type='submit'
                                            value={t('next')}
                                            className={"btn btn-pri ms-5 w-75"}
                                            disabled={!isValid}
                                        />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>



                    &nbsp;
                    {/* <a class="nav-link text-secondary fw-bold mobhide text  mobcompy1 " ><img className="mobhide" src="./Images/mobwork.svg"></img>Work Schedules</a>
                    <a class="nav-link fw-bold  text-secondary mobhide mobcompy" ><img className="mobhide" src="./Images/Account.png"></img>Add Employes</a> */}
                </div>
            </div>
            <div className='row'>
                <div className='col-2 ms-5'></div>
                <div className='col-3 float-end mt-5 mobsimplytext hide_tab'><img className="img-fluid  float-end " src="./Images/compleftimg.png" /></div>
                <div className='col-4'></div>
                <div className='col-2 mt-4 mobsimplytext hide_tab'><img className="img-fluid " src="./Images/right.svg" /></div>
            </div>

            <EHeader />
        </div>
    );
}
export default CompanyAddress;
