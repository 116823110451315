import React from 'react'
import { useState, useContext, useEffect } from 'react';
import EHeader from '../Header/EHeader'
import "./Companyaccount.css"
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Companyaccount = () => {
    const { state } = useContext(store);
    console.log('cmpbank', state.user);
    const [user_details, setuserDetails] = useState([]);
    const [user, setUserId] = useState([]);
    const { update, handleSubmit } = useForm();
    const [companybankname, setcompanytitle] = useState("");
    const [bankname, setbankname] = useState({});
    const [bind_user, setBindUser] = useState("");
    const [emplist, setEmpList] = useState([]);
    const [vbanklist, setvbanklist] = useState([]);
    const location = useLocation();
    console.log('company_id: ', location?.state?.company_id);
    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);

    const onInsert = (data, e) => {
        try {
            let bank = {
                company_id: location?.state?.company_id,
                bank_id: companybankname,


            };

            axios.post(`${process.env.REACT_APP_API_URL}/company_bank`, bank).then((res) => {
                console.log('tax details added', res.data);

                onUpdatelogin(res?.data?.company_id);
                onUpdateUser(res?.data?.company_id);

            })
        } catch (err) {
            console.log(err.message);
        }
    }

    const onInsert2 = (data, e) => {
        try {
            let bank = {
                company_id: location?.state?.company_id,
                bank_id: companybankname,


            };

            axios.post(`${process.env.REACT_APP_API_URL}/company_bank`, bank).then((res) => {
                console.log('tax details added', res.data);

                onUpdatelogin(res?.data?.company_id);
                onUpdateUser(res?.data?.company_id);
                newworkshedule(res?.data?.company_id);
                localStorage.removeItem('company_id');
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const onUpdatelogin = (cmpid) => {

        let login = {
            login_id: state.user[0].login_id,
            company: 'true',
            company_id: cmpid

        };


        axios.put(`${process.env.REACT_APP_API_URL}/logincompany/${state.user[0].login_id}`, login).then((res) => {
            console.log('login_emp updated', res.data);
        }).catch(() => {

        })
    }
    const onUpdateUser = (cmpid) => {

        let cmp_id = {

            company_id: cmpid

        };
        console.log("user", cmp_id);

        axios.put(`${process.env.REACT_APP_API_URL}/update_companyid/${state.user[0].user_id}`, cmp_id).then((res) => {
            console.log('company updated', res.data);
        }).catch(() => {

        })
    }
    const globalState = useContext(store);
    const { dispatch } = globalState;
    const onlogin = (data, e) => {
        let bas = {
            loginmail: state?.user[0]?.loginmail,
            password: state?.user[0]?.password,
        };
        axios.post(`${process.env.REACT_APP_API_URL}/login_employee`, bas).then((res) => {
            console.log('valid User', res.data);
            //  Getloginuser () ;
            if (res.data.length > 0) {

                setuserDetails(res.data);
                localStorage.setItem('user_details', JSON.stringify(res.data));
                setUserId(res.data[0].login_id);
                console.log(res.data[0].login_id, 'login_id')
                dispatch({ type: 'ADD_USER', payload: { user: res.data } });//context api updated. 
            }


        }).catch(() => {
            //  alert("wrong password");
        })
    }
    const [company_list, setCompany_list] = useState([])
    const Getcompanyaddress = (x) => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/companyaddressvbank/${location?.state?.company_id}`).then((res) => {
                console.log(res.data, 'compbank');
                setCompany_list(res.data);

            }).catch(() => {

            })
        }
    }


    let navigate = useNavigate();
    const workshedule = () => {
        let path = `/worksheduleupdate`;
        navigate(path);
    }
    const newworkshedule = (id) => {
        let path = `/workshedule`;
        navigate(path, { state: { company_id: id } });
    }
    const cmplist = () => {
        let path = `/companylist`;
        navigate(path);
    }
    const Getbanklist = () => {
        if (state.user) {
            axios.get(`${process.env.REACT_APP_API_URL}/bank/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setEmpList(res.data);
            }).catch((error) => {
                console.log(error, 'success');
            });
        }
    }
    const Getvbanklist = () => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/vbank`).then((res) => {
                console.log(res.data, 'success');
                setvbanklist(res.data);
            })
        }
        catch (error) {
            console.log(error, 'success');
        }
    }
    useEffect(() => {
        Getvbanklist();
        Getbanklist();
        Getcompanyaddress();
    }, [state]);

// handle disabled submit
const isValid = companybankname

    return (

        <div className='container-fluid'>

            <div className='row mt-5'>

                <div className='col-2 col-xxl-2 col-sm-3 col-md-1 col-lg-2 col-xl-2'>

                </div>

                <div className='col-xxl-10 col-sm-9 col-md-9 col-lg-10 col-xl-10 mt-3'>
                    {/* <a class="nav-link text-primary fw-bold mobhide mobcompy" aria-current="page" ><img className=" mobhide" src="./Images/mobcompany.svg"></img>Company Information</a> */}
                    <div className=' fw-normal  text-center fw-bold mt-4 '> <a className='fs-6' style={{ textDecoration: "none", color: " #0080FF" }}>Step:1</a>   {t('addCompanyDetails')}  </div>

                    <div className='border-bottom'>
                        <nav className='tabs-center'>
                            <div class="nav mt-3 " id="nav-tab" role="tablist">
                                <div className='row scroll-containercomp'>
                                    <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                <div class=" mt-1 addlefmob"> <img className='img-fluid ms-4' src={`Images/add.svg`} ></img></div>
                                            </div>
                                            <div className='col-5 col-xxl-5 col-sm-5 col-md-5 col-lg-5 col-xl-5'>
                                                <a class="nav-item nav-link active  text-tabcolor  text-primary addlefmob" style={{ color: " #0080FF" }} id="nav-home-tab" data-toggle="tab" role="tab" aria-controls="nav-home" aria-selected="true">{t('address')}</a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#53B7B7" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-3 col-xxl-3 col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                                                <div class=" mt-1 taxlefmob"> <img className='img-fluid ms-4' src={`Images/tax.svg`} ></img></div>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <a class="nav-item nav-link text-black text-tabcolor taxtext taxlefmob" id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">{t('tax')} </a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#53B7B7" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                        <div className='row'>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <div class=" mt-1 banklefmob"> <img className='img-fluid ms-5' src={`Images/bank.svg`} ></img></div>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <a class="nav-item nav-link text-black text-tabcolor border-bottom border-primary border-3 taxtext banklefmob1" id="nav-profile-tab" data-toggle="tab" role="tab" aria-controls="nav-profile" aria-selected="false">{t('bank')} </a>
                                            </div>
                                            <div className='col-4 col-xxl-4 col-sm-4 col-md-4 col-lg-4 col-xl-4'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#D9D9D9" class="bi bi-check-circle-fill mt-2" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className='row'>
                        <div className='col-3 col-xxl-3 col-sm-3 col-md-1 col-lg-3 col-xl-3'>
                        </div>
                        <div className=' col-xxl-5 col-sm-5 col-md-9 col-lg-5 col-xl-5 addtextmob'>
                            <div className='mt-5'>
                                {company_list.map((b, index) => {
                                    return (<div>
                                        {(() => {
                                            console.log(b.country, 'check');
                                            if (b.country == "Vietnam ") {
                                                return <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-5">{t('bankName')}</label>
                                                    <select class="form-select inputcolor text-start ms-5 bankaccountbox" Value={companybankname} onChange={(e) => setcompanytitle(e.target.value)} aria-label=" select Day">
                                                        <option selected>{t('bankName')} </option>
                                                        {vbanklist.map((x, index) => {
                                                            return (
                                                                <option value={x.vbankid}>{x.vbankname}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            } else {
                                                return <div class="form-group">
                                                    <label for="formGroupExampleInput" class="required ms-5">{t('bankName')}</label>
                                                    <select class="form-select inputcolor text-start ms-5 bankaccountbox" Value={companybankname} onChange={(e) => setcompanytitle(e.target.value)} aria-label=" select Day">
                                                        <option selected>{t('bankName')} </option>

                                                        <option value="1">Nil</option>

                                                    </select>
                                                </div>
                                            }
                                        })()}
                                    </div>
                                    );
                                })}
                            </div>
                            <div className='row ms-5'>

                                <form onSubmit={handleSubmit(onInsert2)} action="#" method="POST">
                                    <div className='col-2'></div>
                                    <div className='col-6 col-xxl-6 col-sm-6 col-md-9 col-lg-6 col-xl-6 ms-5'>

                                        <button class="btn btn-pri ms-5 w-100 mt-5 ms-5" type="submit" onSubmit={handleSubmit(onInsert2)} disabled={!isValid}>{t('next')}: set-up work schedule</button>
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>





                </div>


            </div>

            <div className='row'>
                <div className='col-5'></div>
                <div className='col-3 mt-5'>
                    <div className='mt-5 t-100 mobsimplytext hide_tab'>
                        <img className="img-fluid d-block mx-auto ms-5 mt-5" src="./Images/midimg1.png" /></div>
                </div>
                <div className='col-3'></div>
            </div>


            {/* <a class="nav-link fw-bold add_wrk text-secondary hide_all_pc" ><img className="hide_all_pc" src="./Images/work.png"></img>Add Workshedule</a>
            <a class="nav-link fw-bold  add_com text-secondary hide_all_pc" ><img className="hide_all_pc" src="./Images/Account.png"></img>Add Employes</a> */}
            <EHeader />

        </div>
    );
}
export default Companyaccount