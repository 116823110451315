import React, { useCallback, useState, useContext, useEffect } from 'react';
import './Todotask.css';
import { useNavigate } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Lheader from '../Lheader/Lheader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import axios from 'axios';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import ReactPaginate from "react-paginate";
import { store } from '../../store/user';
import { useLanguage } from '../Login/LanguageContext'; 
import { useTranslation } from 'react-i18next';

const Todotask = () => {
    const { state } = useContext(store);
    console.log('todotask', state.user);
    const { update, handleSubmit } = useForm();
    const [edit_basic, setbasic] = useState('');
    const [task_list, setTaskList] = useState('Tasklist');
    const [tasklist, setTask_list] = useState([])
    const [head, settitle] = useState("");
    const [description, setdescription] = useState("");
    const [startdt, setstartdt] = useState("");
    const [enddt, setenddt] = useState("");
    const [empnm, setempnm] = useState("");
    const [emplist, setEmpList] = useState([]);
    const [empimg, setEmpImg] = useState("");
    const [priority, setPriority] = useState("");

    const { language,  } = useLanguage();
    const { t, i18n } = useTranslation();
  
    const handleLanguageChange = (newLanguage) => {
      i18n.changeLanguage(newLanguage);
      // Store the selected language in localStorage
      localStorage.setItem('selectedLanguage', newLanguage);
    };
  
    useEffect(() => {
      // Retrieve the selected language from localStorage on page load
      const storedLanguage = localStorage.getItem('selectedLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    const Gettasklist = () => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/tasks/${state?.user[0]?.company_id}`).then((res) => {
                let datalist = res?.data;
                console.log('before filter: ', datalist);
                if (state?.user[0]?.emp_id) {
                    datalist = datalist.filter(x => x?.emp_id == state?.user[0]?.emp_id || x?.assigne == state?.user[0]?.emp_id)
                }
                console.log('after filter: ', datalist);
                setTask_list(datalist);
            }).catch(() => {

            })
    }
    const [taskcompletedlist, setcompletedTask_list] = useState([])
    const Gettaskcompletedlist = () => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/taskscompleted/${state?.user[0]?.company_id}`).then((res) => {
                let datalist = res?.data;
                console.log('before filter: ', datalist);
                if (state?.user[0]?.emp_id) {
                    datalist = datalist.filter(x => x?.emp_id == state?.user[0]?.emp_id || x?.assigne == state?.user[0]?.emp_id)
                }
                console.log('after filter: ', datalist);
                setcompletedTask_list(datalist);
            }).catch(() => {

            })
    }

    const [taskinprogresslist, setinprogressTask_list] = useState([])
    const Gettaskinprogresslist = () => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/tasksinprogress/${state?.user[0]?.company_id}`).then((res) => {
                let datalist = res?.data;
                console.log('before filter: ', datalist);
                if (state?.user[0]?.emp_id) {
                    datalist = datalist.filter(x => x?.emp_id == state?.user[0]?.emp_id || x?.assigne == state?.user[0]?.emp_id)
                }
                console.log('after filter: ', datalist);
                setinprogressTask_list(datalist);
            }).catch(() => {

            })
    }
    const [taskcancelledlist, setcancelledTask_list] = useState([])
    const Gettaskcancelledlist = () => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/taskscancelled/${state?.user[0]?.company_id}`).then((res) => {
                let datalist = res?.data;
                console.log('before filter: ', datalist);
                if (state?.user[0]?.emp_id) {
                    datalist = datalist.filter(x => x?.emp_id == state?.user[0]?.emp_id || x?.assigne == state?.user[0]?.emp_id)
                }
                console.log('after filter: ', datalist);
                setcancelledTask_list(datalist);
            }).catch(() => {

            })
    }
    const Getemplist = () => {
        if (state.user)
            axios.get(`${process.env.REACT_APP_API_URL}/hrmanager/${state?.user[0]?.company_id}`).then((res) => {
                console.log(res.data, 'success');
                setEmpList(res.data);

            }).catch(() => {

            })
    }

    useEffect(() => {
        Gettasklist();
        Gettaskcompletedlist();
        Gettaskinprogresslist();
        Gettaskcancelledlist();
        Getemplist();
    }, [state]);


    const onInsert = (data, e) => {
        try {
            let assigne;
            if (state?.user[0]?.emp_id === null) {
                assigne = state.user[0].user_id;
            } else {
                assigne = state.user[0].emp_id;
            }
            let address = {
                assigne: assigne,
                emp_id: empnm,
                headline: head,
                task_description: description,
                startdate: startdt,
                enddate: enddt,
                user_id: state.user[0].user_id,
                company_id: state?.user[0]?.company_id,
                priority: priority,
            };

            axios.post(`${process.env.REACT_APP_API_URL}/task`, address).then((res) => {
                console.log('address added', res.data);
                Gettasklist();
                ShowTask("Tasklist");
                setempnm("");
                settitle("");
                setstartdt("");
                setenddt("");
            })
        } catch (err) {
            console.log(err.message);
        }
    }
    const alet = () => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmCancel') ,
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => ShowTask("Tasklist"),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert( t('confirmCancel'))
                }
            ]
        })
    }
    const alett = () => {
        confirmAlert({
            //title: 'Cancle',
            message: t('confirmGoBack') ,
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => ShowDetails(""),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert( t('confirmCancel'))
                }
            ]
        })
    }
    const save = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmSave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => onInsert(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }
    const saveup = () => {
        confirmAlert({
            //title: 'Cancle',
            message:  t('confirmSave'),
            buttons: [
                {
                    label:  t('yes'),
                    onClick: () => statusinsert(),
                    style: {
                        backgroundColor: '#F36E35'

                    }
                },
                {
                    label:  t('no'),
                    onClick: () => alert(t('areYouSureNotToSave'))
                }
            ]
        })
    }

    const showbasic = (type) => {
        if (type == 'basic') {
            setbasic(type);
        }
    }

    const ShowTask = (type) => {
        if (type == 'Tasklist') {
            setTaskList(type);
            setbasic();
        }
    }
    let navigate = useNavigate();
    const taskstatus = () => {
        let path = `/addtask`;
        navigate(path);
    }
    const task = () => {
        let path = `/todotask`;
        navigate(path);
    }
    const [bind_user, setBindUser] = useState({});
    const [user_type, setUserType] = useState('');
    const [status_type, setStatusType] = useState("");
    const ShowDetails = (data, type) => {
        console.log('data', data);
        setBindUser(data);
        setUserType(type);
        // setCurrentId(data.job_id);

    }
    const statusinsert = (company_id, e) => {

        let task_sta = {
            company_id: bind_user.company_id,
            emp_id: bind_user.emp_id,
            task_status: status_type,

        };


        axios.put(`${process.env.REACT_APP_API_URL}/task/${bind_user.task_id}`, task_sta).then((res) => {
            console.log('status added', res.data);

            ShowDetails("");
            Gettasklist();
            Gettaskcompletedlist();
            Gettaskinprogresslist();
            Gettaskcancelledlist();

        }).catch(() => {

        })
    }

    const RenderView = () => {
        console.log('bind', bind_user);
        return <div className="bg-light container-fluid tasedi">

            <div className="row mt-5 ms-5">

                <div className="col-sm-2 hide_tab">

                </div>
                <div className="col-sm-10 col-xxl-10 col-md-12 col-lg-12 col-xl-10 mt-4 tbl">

                    <div className="card Card_edittask ">
                        <div className="card-body">
                            <div className="row">

                                <div className="col">
                                    <div className='row'>
                                        <div className='col-sm-3  mt-3'>
                                            <p className='text-secondary companynamesize'>{t('assignee')}
                                                {/* {bind_user.empname}<p className='text-secondary companynamesize'>{bind_user.job}</p></p> */}
                                                <p className='text-black fw-bold fs-6'>{bind_user.emp_name || bind_user.emp_name === '' ? <span>{bind_user.emp_name}</span> : <span>{bind_user.first_name}</span>}
                                                    <p className='text-secondary companynamesize'>
                                                        {bind_user.job || bind_user.job === '' ? <span>{bind_user.job}</span> : <span>Admin</span>}
                                                    </p></p></p>
                                        </div>
                                        <div className='col-sm-3 mt-3'>
                                            <p className='text-secondary companynamesize'>{t('assignTaskTo')}
                                                <p className='text-black fw-bold fs-6'>{bind_user.full_name}<p className='text-secondary companynamesize'>{bind_user.job_title}</p></p></p>
                                        </div>
                                        <div className='col-sm-2 mt-3'>
                                            <p className='text-secondary companynamesize'>{t('priority')}
                                                <p className='text-black fw-bold fs-6'>{bind_user.priority}</p></p>
                                        </div>
                                        <div className='col-sm-2 mt-3'>
                                            <p className='text-secondary companynamesize'>{t('startDate')}
                                                <p className=''>{moment(bind_user.startdate).format('DD /MM/ YYYY')}</p></p>
                                        </div>
                                        <div className='col-sm-2 mt-3'>
                                            <p className='text-secondary companynamesize'>{t('endDate')}
                                                <p className=''>{moment(bind_user.enddate).format('DD /MM/ YYYY')}</p></p>
                                        </div>

                                    </div>

                                    <p className="mt-1 text-secondary">
                                        Status
                                    </p>
                                    <div className="row">
                                        <select class=" mt-2  text-center tast_textbox1  " aria-label="select Day" Value={status_type} onChange={(e) => setStatusType(e.target.value)} disabled={bind_user.task_status === 'Completed' || bind_user.task_status === 'Cancelled'}>
                                            {/* <option selected>{bind_user.task_status}</option> */}
                                            <option selected>
                                                {bind_user.task_status ? bind_user.task_status : 'New/Pending'}
                                            </option>

                                            <option value="Completed">{t('completed')}</option>
                                            <option value="Inprogress">{t('inProgress')}</option>
                                            {/* <option value="Pending">Pending</option> */}
                                            <option value="Cancelled">{t('cancelled')}</option>

                                        </select>

                                        <p className="fw-bold mt-3">
                                            {bind_user.headline}
                                        </p>
                                        <p className="text_small">
                                            {bind_user.task_description}
                                        </p>
                                        {/* <p className="text_small">
                                            {bind_user.task_description}
                                        </p> */}
                                        <form onSubmit={handleSubmit(saveup)} action="#" method="POST">
                                            <div className='row'>
                                                <div className='col-sm-3 d-grid'>
                                                    <button class="btn btn-secondary  mt-3 " style={{height:"48px"}} type="button" onClick={alett}>{t('back')}</button>

                                                </div>
                                                <div className='col-sm-3 d-grid'>
                                                    <button class="btn btn-pri mt-3 " onSubmit={handleSubmit(saveup)} style={{height:"48px"}} type="submit" disabled={bind_user.task_status === 'Completed' || bind_user.task_status === 'Cancelled'}>{t('save')}</button>

                                                </div>

                                            </div>

                                        </form>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <Lheader />
        </div>
            ;
    }

    const [pageNumber, setPageNumber] = useState(0);

    const usersPerPage = 3;
    const pagesVisited = pageNumber * usersPerPage;

    const displayUsers = tasklist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCount = Math.ceil(tasklist.length / usersPerPage);

    const displayCompletedUsers = taskcompletedlist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageCompletedCount = Math.ceil(taskcompletedlist.length / usersPerPage);

    const displayinprogressUsers = taskinprogresslist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pageinprogressCount = Math.ceil(taskinprogresslist.length / usersPerPage);
    const displaycancelledUsers = taskcancelledlist

        .slice(pagesVisited, pagesVisited + usersPerPage)


    const pagecancelledCount = Math.ceil(taskcancelledlist.length / usersPerPage);


    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    return (
        <div className='bg-light vh-100 container-fluid'>


            <div className='row'>
                <div className='col-2 col-xxl-2 col-sm-3 col-md-3 col-lg-2 col-xl-2 hid_tab'>

                </div>
                <div className='  col-xxl-10 col-sm-10 col-md-12 col-lg-12 col-xl-10 bg-light mt-5 lefdash'>

                    <div class="card taskcard ms-5 shadow p-1 mb-5 bg-white rounded">
                        <p className=' fs-4 ms-4 mt-3 fnt-clr'>{t('tasks')}</p>
                        <div class="card-body">
                            <div className='row '>
                                <div className='col-sm-11'>
                                    <Tabs
                                        defaultActiveKey="newtask"
                                        id="uncontrolled-tab-example"
                                        className="mb-3">

                                        <Tab eventKey="newtask" className="" title={t('new')}>

                                            <div className='row'>
                                                {(() => {
                                                    if (edit_basic == "basic") {
                                                        return <div>
                                                            <div className="card " >
                                                                <div className="card-body ">
                                                                    <div className="row  ">
                                                                        <div className="col-sm-9">
                                                                            <p className='ms-2 fnt-clr'>{t('assignTaskTo')} </p>


                                                                            <p className="task_name ">


                                                                            </p>
                                                                            <div className='row'>
                                                                                <div className='col-sm-4'>
                                                                                    <select class="form-select inputcolor text-start txtaddr ms-1" Value={empnm} onChange={(e) => setempnm(e.target.value)} aria-label=" select Day">
                                                                                        <option selected>{t('employeeName')} </option>
                                                                                        {emplist.map((x, index) => {
                                                                                            return (
                                                                                                <option value={x.emp_id} >{x.reporting_name}</option>
                                                                                            );
                                                                                        })}

                                                                                    </select>
                                                                                </div>
                                                                                <div className='col-sm-4 '>
                                                                                    <select class="form-select inputcolor text-start  txtaddr mobpri" Value={priority} onChange={(e) => setPriority(e.target.value)} aria-label=" select Day">
                                                                                        <option selected>{t('priority')} </option>

                                                                                        <option value='High' >High</option>

                                                                                        <option value='Medium' >Medium</option>
                                                                                        <option value='Low' >Low</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-sm-8'>
                                                                                    <input type="text" minlength="2" maxlength="30" class="form-control inputcolor txtaddr ms-1 mt-2" Value={head} onChange={(e) => settitle(e.target.value)} placeholder={t('headline')} aria-label="Username" />
                                                                                </div>
                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className='col-sm-8'>
                                                                                    <textarea minlength="2" maxlength="350" class="form-control inputcolor taskdes mt-2 ms-1" Value={description} onChange={(e) => setdescription(e.target.value)} placeholder={t('taskDescription')} id="exampleFormControlTextarea1" rows="3"></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row mt-2'>
                                                                                <div className='col-sm-4'>
                                                                                    <small className=' text-start ms-2 fw-bold'> {t('startDate')}</small>
                                                                                    <input type="date" min={moment().format("YYYY-MM-DD")} class="form-control inputcolor txtaddr  ms-1 " Value={startdt} onChange={(e) => setstartdt(e.target.value)} placeholder=" " aria-label="" />
                                                                                </div>
                                                                                <div className='col-sm-4'>
                                                                                    <small className=' text-start ms-2 fw-bold mobpri'> {t('endDate')}</small>
                                                                                    <input type="date" min={moment().format("YYYY-MM-DD")} class="form-control inputcolor txtaddr" Value={enddt} onChange={(e) => setenddt(e.target.value)} placeholder="" aria-label="" />

                                                                                </div>
                                                                            </div>




                                                                            <div className="row">
                                                                                <div className="col-sm-4 d-grid">



                                                                                    {/* <button onClick={alet} class="btn btn-secondary task_btn  mt-5 cancel-mv ms-1" type="button">{t('cancel')}</button> */}
                                                                                    <button onClick={alet} class="btn btn-secondary   mt-5  ms-1" style={{ height: "48px" }} type="button">{t('cancel')}</button>

                                                                                </div>

                                                                                <div className="col-sm-5 d-grid">


                                                                                    <form onSubmit={handleSubmit(save)} action="#" method="POST">
                                                                                        <button class="btn btn-pri  mt-5 mobsave" style={{ height: "48px" }} type="submit">{t('save')}</button>
                                                                                    </form>

                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    else {
                                                        return <div><button class="btn btn-pri taskbtn" onClick={() => { showbasic("basic") }} type="button">{t('addTask')}</button></div>

                                                    }
                                                })
                                                    ()}
                                            </div>

                                            {(() => {
                                                if (task_list == "Tasklist") {
                                                    return (<div>
                                                        {displayUsers.map((x, index) => {
                                                            return (<div>
                                                                {(() => {
                                                                    if (x.task_status == null) {
                                                                        return (
                                                                            <div class="card  mt-3" >
                                                                                <div className='row'>
                                                                                    <div className='col-sm-3 ms-4 mt-3'>
                                                                                        <p className='text-secondary companynamesize  pointer_ tascent' onClick={() => ShowDetails(x, 'view')}>{t('assignee')}
                                                                                            <p className='fs-6 pointer_ greyh' onClick={() => ShowDetails(x, 'view')}>
                                                                                                {x.empname || x.empname === '' ? <span>{x.emp_name}</span> : <span>{x.admin_name}</span>}
                                                                                                <p className='text-secondary companynamesize'>
                                                                                                    {x.job || x.job === '' ? <span>{x.job}</span> : <span>Admin</span>}
                                                                                                </p>
                                                                                            </p>

                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='col-sm-2 mt-3 cents'>
                                                                                        <p className='text-secondary companynamesize '>{t('assignTaskTo')}
                                                                                            <p className='greyh fs-6'>{x.full_name}<p className='text-secondary companynamesize'>{x.job_title}</p></p></p>
                                                                                    </div>
                                                                                    <div className='col-sm-2 mt-3 cents'>
                                                                                        <p className='text-secondary companynamesize grey'>{t('priority')}
                                                                                            <p className='greyh fs-6'>{x.priority}</p></p>
                                                                                    </div>
                                                                                    <div className='col-sm-2 mt-3 cents'>
                                                                                        <p className='text-secondary companynamesize'>{t('startDate')}
                                                                                            <p className=''>{moment(x.startdate).format('DD /MM/ YYYY')}</p></p>
                                                                                    </div>
                                                                                    <div className='col-sm-2 mt-3 cents'>
                                                                                        <p className='text-secondary companynamesize ' >{t('endDate')}
                                                                                            <p className=''>{moment(x.enddate).format('DD /MM/ YYYY')}</p></p>
                                                                                    </div>

                                                                                </div>
                                                                                <div class="">
                                                                                    <p className='ms-4  mt-2 greyh'>{x.headline}
                                                                                        <p className='fw-normal greyh'>{x.task_description} </p>
                                                                                    </p>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    }

                                                                })
                                                                    ()}
                                                            </div>

                                                            );
                                                        })}
                                                        <br></br>
                                                        <ReactPaginate
                                                            previousLabel={"Previous"}
                                                            nextLabel={"Next"}
                                                            pageCount={pageCount}
                                                            onPageChange={changePage}
                                                            containerClassName={"paginationBttns"}
                                                            previousLinkClassName={"previousBttn"}
                                                            nextLinkClassName={"nextBttn"}
                                                            disabledClassName={"paginationDisabled"}
                                                            activeClassName={"paginationActive"}
                                                        />
                                                    </div>

                                                    )
                                                }

                                            })
                                                ()}

                                        </Tab>
                                        <Tab eventKey="completedtask" className="grey" title={t('completed')}>

                                            {displayCompletedUsers.map((x, index) => {
                                                return (<div>
                                                    {(() => {
                                                        if (x.task_status == "Completed") {
                                                            return (
                                                                <div class="card  mt-3 " >
                                                                    <div className='row'>
                                                                        <div className='col-sm-3 ms-4 mt-3 '>
                                                                            <p className='text-secondary companynamesize pointer_ tascent' onClick={() => ShowDetails(x, 'view')}>{t('assignee')}
                                                                                <p className='greyh fs-6 pointer_' onClick={() => ShowDetails(x, 'view')}>
                                                                                    {x.empname || x.empname === '' ? <span>{x.emp_name}</span> : <span>{x.admin_name}</span>}
                                                                                    <p className='text-secondary companynamesize'>
                                                                                        {x.job || x.job === '' ? <span>{x.job}</span> : <span>Admin</span>}
                                                                                    </p>
                                                                                </p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3'>
                                                                            <p className='text-secondary companynamesize cents'>{t('assignTaskTo')}
                                                                                <p className='greyh fs-6'>{x.full_name}<p className='text-secondary companynamesize'>{x.job_title}</p></p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3'>
                                                                            <p className='text-secondary companynamesize cents'>{t('priority')}
                                                                                <p className='greyh fs-6'>{x.priority}</p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 '>
                                                                            <p className='text-secondary companynamesize cents'>{t('startDate')}
                                                                                <p className=''>{moment(x.startdate).format('DD /MM/ YYYY')}</p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3'>
                                                                            <p className='text-secondary companynamesize cents' >{t('endDate')}
                                                                                <p className=''>{moment(x.enddate).format('DD /MM/ YYYY')}</p></p>
                                                                        </div>

                                                                    </div>
                                                                    <div class="">
                                                                        <p className='ms-4 greyh mt-2'>{x.headline}
                                                                            <p className='fw-normal'>{x.task_description} </p>
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }

                                                    })
                                                        ()}
                                                </div>
                                                );
                                            })}
                                            <br></br>
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageCompletedCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </Tab>
                                        <Tab eventKey="inprogresstask" className="" title={t('inProgress')}>
                                            {displayinprogressUsers.map((x, index) => {
                                                return (<div>
                                                    {(() => {
                                                        if (x.task_status == "Inprogress") {
                                                            return (
                                                                <div class="card mt-3" >
                                                                    <div className='row'>
                                                                        <div className='col-sm-3 ms-4 mt-3'>
                                                                            <p className='text-secondary companynamesize pointer_ tascent' onClick={() => ShowDetails(x, 'view')}>{t('assignee')}
                                                                                <p className='greyh fs-6 pointer_' onClick={() => ShowDetails(x, 'view')}>
                                                                                    {x.empname || x.empname === '' ? <span>{x.emp_name}</span> : <span>{x.admin_name}</span>}
                                                                                    <p className='text-secondary companynamesize'>
                                                                                        {x.job || x.job === '' ? <span>{x.job}</span> : <span>Admin</span>}
                                                                                    </p>
                                                                                </p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3'>
                                                                            <p className='text-secondary companynamesize cents'>{t('assignTaskTo')}
                                                                                <p className='greyh fs-6'>{x.full_name}<p className='text-secondary companynamesize'>{x.job_title}</p></p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize'>{t('priority')}
                                                                                <p className='greyh fs-6'>{x.priority}</p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize'>{t('startDate')}
                                                                                <p className=''>{moment(x.startdate).format('DD /MM/ YYYY')}</p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize ' >{t('endDate')}
                                                                                <p className=''>{moment(x.enddate).format('DD /MM/ YYYY')}</p></p>
                                                                        </div>

                                                                    </div>
                                                                    <div class="">
                                                                        <p className='ms-4 greyh mt-2'>{x.headline}
                                                                            <p className='fw-normal'>{x.task_description} </p>
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }

                                                    })
                                                        ()}

                                                </div>
                                                );
                                            })}
                                            <br></br>
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageinprogressCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </Tab>
                                        <Tab eventKey="pendingtasks" className="" title={t('pending')}>
                                            {displayUsers.map((x, index) => {
                                                return (<div>
                                                    {(() => {
                                                        if (x.task_status == null) {
                                                            return (
                                                                <div class="card mt-3" >
                                                                    <div className='row'>
                                                                        <div className='col-sm-3 ms-4 mt-3'>
                                                                            <p className='text-secondary companynamesize  pointer_ tascent' onClick={() => ShowDetails(x, 'view')}>{t('assignee')}
                                                                                <p className='greyh fs-6 pointer_' onClick={() => ShowDetails(x, 'view')}>
                                                                                    {x.empname || x.empname === '' ? <span>{x.emp_name}</span> : <span>{x.admin_name}</span>}
                                                                                    <p className='text-secondary companynamesize'>
                                                                                        {x.job || x.job === '' ? <span>{x.job}</span> : <span>Admin</span>}
                                                                                    </p>
                                                                                </p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize '>{t('assignTaskTo')}
                                                                                <p className='greyh fs-6'>{x.full_name}<p className='text-secondary companynamesize'>{x.job_title}</p></p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize'>{t('priority')}
                                                                                <p className='greyh fs-6'>{x.priority}</p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize'>{t('startDate')}
                                                                                <p className=''>{moment(x.startdate).format('DD /MM/ YYYY')}</p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize ' >{t('endDate')}
                                                                                <p className=''>{moment(x.enddate).format('DD /MM/ YYYY')}</p></p>
                                                                        </div>

                                                                    </div>
                                                                    <div class="">
                                                                        <p className='ms-4 greyh mt-2'>{x.headline}
                                                                            <p className='fw-normal'>{x.task_description} </p>
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }

                                                    })
                                                        ()}
                                                </div>
                                                );
                                            })}
                                            <br></br>
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pageCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />

                                        </Tab>
                                        <Tab eventKey="cancelledtask" className="" title={t('cancelled')}>
                                            {displaycancelledUsers.map((x, index) => {
                                                return (<div>
                                                    {(() => {
                                                        if (x.task_status == "Cancelled") {
                                                            return (
                                                                <div class="card  mt-3" >
                                                                    <div className='row'>
                                                                        <div className='col-sm-3 ms-4 mt-3'>
                                                                            <p className='text-secondary companynamesize  pointer_ tascent' onClick={() => ShowDetails(x, 'view')}>{t('assignee')}
                                                                                <p className='greyh fs-6 pointer_' onClick={() => ShowDetails(x, 'view')}>
                                                                                    {x.empname || x.empname === '' ? <span>{x.emp_name}</span> : <span>{x.admin_name}</span>}
                                                                                    <p className='text-secondary companynamesize'>
                                                                                        {x.job || x.job === '' ? <span>{x.job}</span> : <span>Admin</span>}
                                                                                    </p>
                                                                                </p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize'>{t('assignTaskTo')}
                                                                                <p className='greyh fs-6'>{x.full_name}<p className='text-secondary companynamesize'>{x.job_title}</p></p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3'>
                                                                            <p className='text-secondary companynamesize cents'>{t('priority')}
                                                                                <p className='greyh fs-6'>{x.priority}</p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize'>{t('startDate')}
                                                                                <p className=''>{moment(x.startdate).format('DD /MM/ YYYY')}</p></p>
                                                                        </div>
                                                                        <div className='col-sm-2 mt-3 cents'>
                                                                            <p className='text-secondary companynamesize ' >{t('endDate')}
                                                                                <p className=''>{moment(x.enddate).format('DD /MM/ YYYY')}</p></p>
                                                                        </div>

                                                                    </div>
                                                                    <div class="">
                                                                        <p className='ms-4 greyh mt-2'>{x.headline}
                                                                            <p className='fw-normal'>{x.task_description} </p>
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }

                                                    })
                                                        ()}
                                                </div>
                                                );
                                            })}
                                            <br></br>
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                pageCount={pagecancelledCount}
                                                onPageChange={changePage}
                                                containerClassName={"paginationBttns"}
                                                previousLinkClassName={"previousBttn"}
                                                nextLinkClassName={"nextBttn"}
                                                disabledClassName={"paginationDisabled"}
                                                activeClassName={"paginationActive"}
                                            />
                                        </Tab>


                                    </Tabs>
                                </div>
                                <div className='col-1 taskfilt'>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
            <div>{(() => {
                switch (user_type) {
                    case "view": return RenderView();
                    //case "edit": return RenderEdit();
                    //case "delete": return RenderDelete();
                    default: return "";
                }
            })()}</div>
            <Lheader />

        </div>
    );
}
export default Todotask;